const unblockControls = () => {
  $('.cblock__filters, .tailwind-search').find('input:not(.keep-blocked), select:not(.keep-blocked), button:not(.keep-blocked)').prop( "disabled", false );
  $('.cblock__filters .btn:not(.keep-blocked), #details_slider_container, .checkbox-inline-label').removeClass('disabled');
}

const blockControls = () => {
  $('.cblock__filters, .tailwind-search').find('input, select, button').prop( "disabled", true );
  $('.cblock__filters .btn, #details_slider_container, .checkbox-inline-label').addClass('disabled');
}

const injectSkeletons = (turboFrameIdentifiers = []) => {
  // If no Ids are passed, try to inject skeletons for everything
  // TODO - delete this when we convert all reports to use turbo-frames
  if (turboFrameIdentifiers.length === 0) {
    turboFrameIdentifiers = Object.keys(window.skeletonCache)
  }

  turboFrameIdentifiers.forEach(function (turboFrameIdentifier) {
    const skeletonContainer = document.getElementById(turboFrameIdentifier)
    const skeletonElement = window.skeletonCache[turboFrameIdentifier]
    if (skeletonContainer !== null && skeletonContainer !== undefined && skeletonElement !== null && skeletonElement !== undefined) {
      skeletonContainer.textContent = ''
      if ( turboFrameIdentifier.includes('report-block') ) {
        // TODO delete this when we convert all reports to use turbo-frames
        skeletonContainer.innerHTML = skeletonElement
      } else {
        skeletonContainer.appendChild(skeletonElement)
      }

    }
  })
}

export {
  unblockControls,
  blockControls,
  injectSkeletons
};