import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

// Connects to data-controller="amazon-login-modal"
export default class extends Controller {
  connect() {
    const element = this.element
    const connectBtn = $("#connect-amazon-marketplace")
    const amazonCountryInput =  $("#amazon-country")

    element.addEventListener('click', (event) => {
      const modal = document.getElementById('tailwind-amazon-login-modal');
      if (modal) {
        modal.classList.remove('hidden');
      }
    })

    amazonCountryInput.on('change', function(){
      Rails.ajax({
        url: `/integrations/amazon/create_amazon_auth_url?country=${amazonCountryInput.val()}`,
        type: 'get',
        success: (res) => {
          connectBtn.attr("href", res["auth_url"]);        
        },
      });
    });
  }
}