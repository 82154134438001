import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="integrations-accounts-datatable"
export default class extends Controller {
    static targets = [ "datatable"]

    connect() {
        const dataTableElement = this.datatableTarget
        const platform = this.data.get("platform");
        const integrationsAccountsTable = $(dataTableElement).DataTable({
            "bLengthChange": false,
            "info": false,
            "paging": false,
            columnDefs: [{
                orderable: false,
                className: 'select',
                targets: 0
            }],
            select: {
                style: 'multi',
                selector: 'td:first-child'
            },
            order: [[1, 'asc']]
        });

        integrationsAccountsTable
            .on( 'select', function ( e, dt, type, indexes ) {
                let selected_accounts = $(dataTableElement).DataTable().rows('.selected').data().toArray()
                let oldUrl = $("#btn-disconnect-accounts").data("url");
                selected_accounts = selected_accounts.map(x => x[1].replace('&amp;', '%26'));
                let newUrl = oldUrl + '&selected_accounts=' + encodeURIComponent(selected_accounts.join())
                $("#btn-disconnect-accounts").attr("href", newUrl)
                if (selected_accounts.length > 0 ) {
                    $('.tailwind-primary-btn').removeClass("disabled");
                    $('.tailwind-red-btn').removeClass("disabled");
                }else {
                    $('.tailwind-primary-btn').addClass("disabled");
                    $('.tailwind-red-btn').addClass("disabled");
                }
            } )
            .on( 'deselect', function ( e, dt, type, indexes ) {
                let selected_accounts = $(dataTableElement).DataTable().rows('.selected').data().toArray()
                selected_accounts = selected_accounts.map(x => x[1]);
                let oldUrl = $("#btn-disconnect-accounts").data("url");
                let newUrl = oldUrl + '&selected_accounts=' + encodeURIComponent(selected_accounts.join())
                $("#btn-disconnect-accounts").attr("href", newUrl)
                if (selected_accounts.length > 0 ) {
                    $('.tailwind-primary-btn').removeClass("disabled");
                    $('.tailwind-red-btn').removeClass("disabled");
                }else {
                    $('.tailwind-primary-btn').addClass("disabled");
                    $('.tailwind-red-btn').addClass("disabled");
                }
            } );

        $(".dataTables_filter").addClass("hidden"); // hidden search input
        function delay(fn, ms) {
            let timer = 0
            return function(...args) {
                clearTimeout(timer)
                timer = setTimeout(fn.bind(this, ...args), ms || 0)
            }
        }

        $('#btn-connect-accounts').click(function (e) {
            $(e.target).addClass('disabled');
            let selected_accounts = $(dataTableElement).DataTable().rows('.selected').data().toArray()
            if (!selected_accounts || selected_accounts.length == 0) {
                ToastCustom.fireAlertToast('No ad account selected. Please select an account from the list.');
                $(e.target).prop('disabled', false);
                return;
            }
            $.post({
                url: '/integrations/' + platform + '/select-accounts',
                data: {
                    accounts: selected_accounts
                }
            }).always(function(){
                $(e.target).prop('disabled', false);
            });
        });

        $('#btn-disconnect-accounts').click(function (e) {
            $(e.target).addClass('disabled');
            let platform = $(e.target).data("platform")
            let selected_accounts = $(dataTableElement).DataTable().rows('.selected').data().toArray()

            $.ajax({
                url: '/integrations/show-delete-modal',
                data: {
                    selected_accounts: selected_accounts,
                    platform: platform
                }
            }).always(function(){
                $(e.target).prop('disabled', false);
            })
        });

        $('#integrations_accounts_table_search').val(integrationsAccountsTable.search());

        // Make Search API work, because the (custom) searchbar is outside the datatable
        $(document).on("keypress keyup", '#integrations_accounts_table_search', delay(function () {
            integrationsAccountsTable.search($(this).val()).draw();
            console.log("asd asd ")
        }, 300));
    }
}
