import { Controller } from "@hotwired/stimulus"
import { getSliderValuesIfChanged } from "../filters_and_options";
const skeleton_helper = require('../skeleton_helper');

// Connects to data-controller="legacy-filters-sidedrawer"
export default class extends Controller {
  static targets = ['applyBtn', 'resetBtn']

  static values = {
    formController: String
  }

  connect() {
    const applyBtn = this.applyBtnTarget
    const resetBtn = this.resetBtnTarget
    const formController = this.formControllerValue

    initLegacyApplyBtn(applyBtn, formController)
    initLegacyResetBtn(resetBtn, formController)
  }
}

// LEGACY CODE - delete when all reports use Turbo
const initLegacyApplyBtn = (applyBtn, formController) => {
  $(applyBtn).click(function () {
    let country_filters = $('select[id=country_filter]').val();
    let state_filters = $('select[id=state_filter]').val();
    let customer_tags_filters = $('select[id=customer_tags_filter]').val();
    let customer_tags_exclude_filters = $('select[id=customer_tags_exclude_filter]').val();
    let discount_codes_filters = $('select[id=discount_codes_filter]').val();
    let order_tags_filters = $('select[id=order_tags_filter]').val();
    let order_tags_exclude_filters = $('select[id=order_tags_exclude_filter]').val();
    let klaviyo_segments_filters = $('select[id=klaviyo_segments_filter]').val();
    let klaviyo_lists_filters = $('select[id=klaviyo_lists_filter]').val();
    let klaviyo_campaigns_filters = $('select[id=klaviyo_campaigns_filter]').val();
    let product_filters = $('select[id=product_filter]').val();
    let product_tag_filters = $('select[id=product_tag_filter]').val();
    let sku_filters = $('select[id=sku_filter]').val();
    let collection_filters = $('select[id=collection_filter]').val();
    let product_type_filters = $('select[id=product_type_filter]').val();
    let vendor_filters = $('select[id=vendor_filter]').val();
    let sales_channel_filters = $('select[id=sales_channel_filter]').val();
    let app_sales_channel_filters = $('select[id=app_sales_channel_filter]').val();
    let sales_channel_exclude_filters = $('select[id=sales_channel_exclude_filter]').val();
    let first_utm_channel_filters = $('select[id=first_utm_channel_filter]').val();
    let first_utm_source_filters = $('select[id=first_utm_source_filter]').val();
    let first_utm_medium_filters = $('select[id=first_utm_medium_filter]').val();
    let first_utm_term_filters = $('select[id=first_utm_term_filter]').val();
    let first_utm_content_filters = $('select[id=first_utm_content_filter]').val();
    let first_utm_campaign_filters = $('select[id=first_utm_campaign_filter]').val();
    let last_utm_channel_filters = $('select[id=last_utm_channel_filter]').val();
    let last_utm_source_filters = $('select[id=last_utm_source_filter]').val();
    let last_utm_medium_filters = $('select[id=last_utm_medium_filter]').val();
    let last_utm_term_filters = $('select[id=last_utm_term_filter]').val();
    let last_utm_content_filters = $('select[id=last_utm_content_filter]').val();
    let last_utm_campaign_filters = $('select[id=last_utm_campaign_filter]').val();
    let metric = $('select[id=lifetime_select]').val();
    let metric_lifecycle = $('select[id=lifecycle_select]').val();
    let show_comparison = $('#singledate-compare-wrapper').is(":visible");
    let ltv_show_format = $("#format-toggle .active input").attr('data-ltv_show_format');
    const first_order_value_filter = $('input[id=first_order_value_filter]')
    let first_order_value_filter_values = getSliderValuesIfChanged(first_order_value_filter)
    const first_order_discount_filter = $('input[id=first_order_discount_filter]')
    let first_order_discount_filter_values = getSliderValuesIfChanged(first_order_discount_filter)

    $.post({
      url: formController + '/refresh-report',
      data: {
        "country_filters": country_filters,
        "state_filters": state_filters,
        "customer_tags_filters": customer_tags_filters,
        "customer_tags_exclude_filters": customer_tags_exclude_filters,
        "discount_codes_filters": discount_codes_filters,
        "order_tags_filters": order_tags_filters,
        "order_tags_exclude_filters": order_tags_exclude_filters,
        "product_filters": product_filters,
        "product_tag_filters": product_tag_filters,
        "sku_filters": sku_filters,
        "collection_filters": collection_filters,
        "product_type_filters": product_type_filters,
        "vendor_filters": vendor_filters,
        "sales_channel_filters": sales_channel_filters,
        "app_sales_channel_filters": app_sales_channel_filters,
        "sales_channel_exclude_filters": sales_channel_exclude_filters,
        "first_utm_channel_filters": first_utm_channel_filters,
        "first_utm_campaign_filters": first_utm_campaign_filters,
        "first_utm_source_filters": first_utm_source_filters,
        "first_utm_medium_filters": first_utm_medium_filters,
        "first_utm_term_filters": first_utm_term_filters,
        "first_utm_content_filters": first_utm_content_filters,
        "last_utm_channel_filters": last_utm_channel_filters,
        "last_utm_source_filters": last_utm_source_filters,
        "last_utm_medium_filters": last_utm_medium_filters,
        "last_utm_term_filters": last_utm_term_filters,
        "last_utm_content_filters": last_utm_content_filters,
        "last_utm_campaign_filters": last_utm_campaign_filters,
        "metric": metric,
        "metric_lifecycle": metric_lifecycle,
        "show_comparison": show_comparison,
        "ltv_show_format": ltv_show_format,
        "first_order_value_filter": first_order_value_filter_values,
        "first_order_discount_filter": first_order_discount_filter_values,
        "klaviyo_segments_filters": klaviyo_segments_filters,
        "klaviyo_lists_filters": klaviyo_lists_filters,
        "klaviyo_campaigns_filters": klaviyo_campaigns_filters

      },
      success: function () {
        skeleton_helper.unblockControls();
        lifetime_compare_cohorts.updateControlsStates();
      }
    })

    skeleton_helper.blockControls();
    skeleton_helper.injectSkeletons();
  });
}

const initLegacyResetBtn = (resetBtn, formController) => {
  $(resetBtn).click(function () {
    $('select[id=country_filter]').val([]).trigger('change');
    $('select[id=state_filter]').val([]).trigger('change');
    $('select[id=customer_tags_filter]').val([]).trigger('change');
    $('select[id=order_tags_filter]').val([]).trigger('change');
    $('select[id=order_tags_exclude_filter]').val([]).trigger('change');
    $('select[id=customer_tags_exclude_filter]').val([]).trigger('change');
    $('select[id=discount_codes_filter]').val([]).trigger('change');
    $('select[id=product_filter]').val([]).trigger('change');
    $('select[id=product_tag_filter]').val([]).trigger('change');
    $('select[id=sku_filter]').val([]).trigger('change');
    $('select[id=collection_filter]').val([]).trigger('change');
    $('select[id=product_type_filter]').val([]).trigger('change');
    $('select[id=vendor_filter]').val([]).trigger('change');
    $('select[id=sales_channel_filter]').val([]).trigger('change');
    $('select[id=app_sales_channel_filter]').val([]).trigger('change');
    $('select[id=first_utm_channel_filter]').val([]).trigger('change');
    $('select[id=first_utm_source_filter]').val([]).trigger('change');
    $('select[id=first_utm_medium_filter]').val([]).trigger('change');
    $('select[id=first_utm_term_filter]').val([]).trigger('change');
    $('select[id=first_utm_content_filter]').val([]).trigger('change');
    $('select[id=first_utm_campaign_filter]').val([]).trigger('change');
    $('select[id=last_utm_source_filter]').val([]).trigger('change');
    $('select[id=last_utm_channel_filter]').val([]).trigger('change');
    $('select[id=last_utm_medium_filter]').val([]).trigger('change');
    $('select[id=last_utm_term_filter]').val([]).trigger('change');
    $('select[id=last_utm_content_filter]').val([]).trigger('change');
    $('select[id=last_utm_campaign_filter]').val([]).trigger('change');
    $('select[id=sales_channel_exclude_filter]').val([]).trigger('change');
    $('select[id=klaviyo_segments_filter]').val([]).trigger('change');
    $('select[id=klaviyo_lists_filter]').val([]).trigger('change');
    $('select[id=klaviyo_campaigns_filter]').val([]).trigger('change');
    let metric = $('select[id=lifetime_select]').val();
    let metric_lifecycle = $('select[id=lifecycle_select]').val();
    let show_comparison = $('#singledate-compare-wrapper').is(":visible");
    $("input[id=first_order_value_filter]").bootstrapSlider("refresh");
    $("input[id=first_order_discount_filter]").bootstrapSlider("refresh");
    $("#first_order_discount_filter_min_value").val(0);
    $("#first_order_discount_filter_max_value").val(100);
    $("#first_order_value_filter_min_value").val($("#first_order_value_filter_min_value").data('default-value'));
    $("#first_order_value_filter_max_value").val($("#first_order_value_filter_max_value").data('default-value'));

    $.post({
      url: formController + '/refresh-report',
      data: {
        "country_filters": [],
        "state_filters": [],
        "customer_tags_filters": [],
        "customer_tags_exclude_filters": [],
        "discount_codes_filters": [],
        "order_tags_filters": [],
        "order_tags_exclude_filters": [],
        "product_filters": [],
        "product_tag_filters": [],
        "sku_filters": [],
        "collection_filters": [],
        "product_type_filters": [],
        "vendor_filters": [],
        "sales_channel_filters": [],
        "app_sales_channel_filters": [],
        "sales_channel_exclude_filters": [],
        "first_utm_channel_filters": [],
        "first_utm_source_filters": [],
        "first_utm_medium_filters": [],
        "first_utm_term_filters": [],
        "first_utm_content_filters": [],
        "first_utm_campaign_filters": [],
        "last_utm_channel_filters": [],
        "last_utm_source_filters": [],
        "last_utm_medium_filters": [],
        "last_utm_term_filters": [],
        "last_utm_content_filters": [],
        "last_utm_campaign_filters": [],
        "klaviyo_segments_filters": [],
        "klaviyo_lists_filters": [],
        "klaviyo_campaigns_filters": [],
        "metric": metric,
        "metric_lifecycle": metric_lifecycle,
        "show_comparison": show_comparison,
        "first_order_value_filter": null,
        "first_order_discount_filter": null
      },
      success: function () {
        skeleton_helper.unblockControls();
        lifetime_compare_cohorts.updateControlsStates();
      }
    })

    skeleton_helper.blockControls();
    skeleton_helper.injectSkeletons();
  });
}