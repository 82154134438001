import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="repurchase-rate-charts"
export default class extends Controller {
  static values = {
    type: String,
    newCustomerExportUrl: String,
    returningCustomerExportUrl: String
  }

  connect() {

    if (this.hasTypeValue && this.typeValue == 'custom_dashboard_card') {
      window.load_chart.customDashboardRepurchaseRateChart(this.element);
    } else {
      // Report page
      const basicChartCount = this.element.querySelectorAll('.basic-user-line-chart-container').length

      if (basicChartCount > 0) {
        window.load_chart.basicUserDisabledLineChart();
      } else {
        window.load_chart.repurchaseRateCharts();
      }

      const newCustomerExportBtn = document.getElementById('new-customer-export-btn')
      const newCustomerExportUrl = this.newCustomerExportUrlValue
      if (newCustomerExportBtn && newCustomerExportUrl) {
        newCustomerExportBtn.href = newCustomerExportUrl
      }

      const returningCustomerExportBtn = document.getElementById('returning-customer-export-btn')
      const returningCustomerExportUrl = this.returningCustomerExportUrlValue
      if (returningCustomerExportBtn && returningCustomerExportUrl) {
        returningCustomerExportBtn.href = returningCustomerExportUrl
      }
    }
  }
}
