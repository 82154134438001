import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="compare-repurchasers-datatable"
export default class extends Controller {
    static targets = [ "datatable" ]

    static values = {
        exportUrl: String,
        params: String
    }

    connect() {
        const dataTableElement = this.datatableTarget

        const exportBtn = document.getElementById('export-btn')

        exportBtn.href = this.exportUrlValue
        
        const reportParams = this.paramsValue
        if (exportBtn && reportParams) {
          $('#export-btn').data('body', reportParams)
        }

        $(dataTableElement).DataTable(
            {
              "order": [[ 2, "desc" ]],
              "searching": false,
              "bLengthChange": false,
              initComplete: function() {
                $('.dataTables_info, .dataTables_paginate, .dataTables_length').appendTo($('.tailwind-datatable-details'));
              },
            }
        );
    }
}
