import {Controller} from "@hotwired/stimulus"
import * as CSRFTokenHelper from "../csrf_token_helper.js"

// Connects to data-controller="timeLag"
export default class extends Controller {
    static values = {
        url: String,
        params: String
    }

    showProducts(evt) {
        // Get URL and report params stored in the "Show purchased products" link element
        const url = this.urlValue
        const params = JSON.parse(this.paramsValue)

        // Show modal and fix scroll on body
        const modalContainer = document.querySelector('.js-modal-container')
        const modalSkeletonHTML = modalContainer.innerHTML
        modalContainer.classList.remove('hidden')
        document.body.classList.add('modal-open')

        fetch(url, {
            method: "POST",
            body: JSON.stringify(params),
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Origin": "*",
                "X-CSRF-Token": CSRFTokenHelper.getCSRFToken()
            }
        }).then(response => response.text())
            .then(html => modalContainer.innerHTML = html)
            .then(() => {
                // Initialize everything
                initDatatable(params);
                initCloseModalElements(modalContainer, modalSkeletonHTML);
                initExportBtn();
            })
    }
}

const initDatatable = (params) => {
    // Store skeleton so we can re-display it on 'preDrawCallback' when table reloads
    const skeletonTableBodyHTML = document.querySelector('table#time-lag-show-purchased-products-table tbody').innerHTML
    $("#time-lag-show-purchased-products-table").DataTable(
        {
            "columnDefs": [
                {
                    "targets": 0,
                    "searchable": false,
                    'data': 'num_orders'
                },
                {
                    "targets": 1,
                    "searchable": false,
                    'data': 'initial_purchased_product',
                },
                {
                    "targets": 2,
                    "searchable": false,
                    'data': 'next_purchased_product',
                }
            ],

            "order": [[0, "desc"]],
            "searching": false,
            "bLengthChange": true,
            "processing": true,
            "serverSide": true,
            "dom": "ltip",
            "ajax": {
                "url": '/time_lag/purchased_products_data',
                "data": function (data) {
                    const picker = $('input[name="time_lag_daterange"]').data('daterangepicker');
                    data.date_from = picker.startDate.format('YYYY-MM-DD');
                    data.date_to = picker.endDate.format('YYYY-MM-DD');

                    // Merge params into data
                    for (const attribute in params) { data[attribute] = params[attribute]; }
                }
            },
            initComplete: function() {
                $('.dataTables_info, .dataTables_paginate, .dataTables_length').appendTo($('.tailwind-datatable-details'));
            },
            preDrawCallback: function() {
                document.querySelector('table#time-lag-show-purchased-products-table tbody').innerHTML = skeletonTableBodyHTML;
            }
        }
    );
}

const initCloseModalElements = (modalContainer, modalSkeletonHTML) => {
    const closeModalElements = modalContainer.querySelectorAll("[data-action-close-modal]")
    closeModalElements.forEach(element => {
        element.addEventListener('click', event => {
            modalContainer.classList.add('hidden');
            modalContainer.innerHTML = modalSkeletonHTML;
            document.body.classList.remove('modal-open');
        })
    });
}

const initExportBtn = () => {
    const exportBtn = document.querySelector('#time-lag-purchased-products-export-btn')
    exportBtn.addEventListener('click', event => {
        event.preventDefault();

        const dataTable = $('table#time-lag-show-purchased-products-table').DataTable()
        const params = $.param(dataTable.ajax.params());
        exportBtn.href = "/time_lag/export_show_purchased_products.csv?" + params

        const totalEntries = dataTable.page.info().recordsDisplay
        const confirmResult = confirm(`Are you sure you want to download a CSV file with ${totalEntries} rows?`);

        if (confirmResult) {
            window.location = exportBtn.href
        }
    });
}
