import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="funnel-step-filter"
export default class extends Controller {
  static values = {
    filterType: String
  }

  static targets = ['kindSelect', 'operatorSelect', 'valuesSelect', 'searchNode']

  connect() {
    const funnelStepFilterController = this
    const funnelStepFilterControllerElement = this.element
    const kindSelect = this.kindSelectTarget
    const searchNode = this.searchNodeTarget
    let previousKindType = getKindType(kindSelect.value)
    const operatorSelect = this.operatorSelectTarget

    $(kindSelect).on('select2:select', function (e) {
      // If the kind has changed from numerical to string or vice versa, destroy the "operator select" and rebuild it with the right options
      const currentKindType = getKindType(kindSelect.value)
      if (previousKindType !== currentKindType) {
        rebuildOperatorSelect(operatorSelect, currentKindType, funnelStepFilterController)
        // Rebuild "value" select because it is now a simple numerical value...
        dispatchOperatorSelectChangeEvent(funnelStepFilterControllerElement, operatorSelect.value)
      }
      previousKindType = currentKindType

      // fire custom event that checkbox_select_controller will be listening for
      const filterType = kindSelect.value
      dispatchKindSelectChangeEvent(funnelStepFilterControllerElement, filterType)
    });

    $(operatorSelect).on('select2:select', function (e) {
      // fire custom event that checkbox_select_controller will be listening for
      // and launch funnelDefinitonForm.requestSubmit() from the checkbox_select_controller in the operatorSelectValueChangeHandler
      const operatorType = operatorSelect.value
      let placeHolder = ['contains', 'does_not_contain'].includes(operatorType) ? "Enter value..." : "Select values..."
      searchNode.setAttribute("placeholder", placeHolder);
      dispatchOperatorSelectChangeEvent(funnelStepFilterControllerElement, operatorType)
    });

    updateAddFilterHrefValue(funnelStepFilterController)
  }

  removeAndSubmitForm(event) {
    const funnelStepFilterControllerElement = this.element
    const funnelDefinitonForm = funnelStepFilterControllerElement.closest("[data-funnel-definition-form-target=\"funnelForm\"]")

    funnelStepFilterControllerElement.remove()

    funnelDefinitonForm.requestSubmit()
  }
}

const updateAddFilterHrefValue = (funnelStepFilterController) => {
  // Update filter index param in URL of "Add filter" link
  if (funnelStepFilterController.filterTypeValue === 'customer') {
    updateAddCustomerFilterHrefValue(funnelStepFilterController)
  } else {
    // filterTypeValue === 'step'
    updateAddStepFilterHrefValue(funnelStepFilterController)
  }
}

const updateAddStepFilterHrefValue = (funnelStepFilterController) => {
  const parentElement = funnelStepFilterController.element.closest("[data-controller='funnel-step']")
  const lastFilterIndex = Number(Array.from(parentElement.querySelectorAll("[data-funnel-step-filter-filter-index]")).pop().getAttribute("data-funnel-step-filter-filter-index"))
  const addFilterLink = parentElement.querySelector('a[data-funnel-step-filter-link]')
  const addFilterHrefParts = addFilterLink.href.split('/')
  addFilterHrefParts[addFilterHrefParts.length - 1] = lastFilterIndex + 1

  // Rebuild updated link
  addFilterLink.href = addFilterHrefParts.join('/')
}

const updateAddCustomerFilterHrefValue = (funnelStepFilterController) => {
  const parentElement = funnelStepFilterController.element.closest("[data-funnel-defition-customers-container]")
  const lastFilterIndex = Number(Array.from(parentElement.querySelectorAll("[data-funnel-customer-filter-filter-index]")).pop().getAttribute("data-funnel-customer-filter-filter-index"))
  const addFilterLink = parentElement.querySelector('a[data-funnel-customer-filter-link]')
  const addFilterHrefParts = addFilterLink.href.split('/')
  addFilterHrefParts[addFilterHrefParts.length - 1] = lastFilterIndex + 1

  // Rebuild updated link
  addFilterLink.href = addFilterHrefParts.join('/')
}

const dispatchKindSelectChangeEvent = (funnelStepFilterControllerElement, filterType) => {
  const event = document.createEvent("CustomEvent")
  event.initCustomEvent("kind-select-value-change", true, true, { funnelStepFilterControllerElement: funnelStepFilterControllerElement, filterType: filterType })
  funnelStepFilterControllerElement.dispatchEvent(event)
}

const dispatchOperatorSelectChangeEvent = (funnelStepFilterControllerElement, operatorType) => {
  const event = document.createEvent("CustomEvent")
  event.initCustomEvent("operator-select-value-change", true, true, { funnelStepFilterControllerElement: funnelStepFilterControllerElement, operatorType: operatorType })
  funnelStepFilterControllerElement.dispatchEvent(event)
}

const getKindType = (kindSelectvalue) => {
  let kindType = 'string'
  if (kindSelectvalue === 'order_value_filter') {
    kindType = 'numerical'
  }

  return kindType
}


const rebuildOperatorSelect = (operatorSelect, kindType, funnelStepFilterController) => {
  const optionsDataAttribute = (kindType === 'numerical') ? 'numerical-options' : 'string-options'

  $(operatorSelect).select2('destroy')

  const optionsData = JSON.parse(operatorSelect.getAttribute(`data-${optionsDataAttribute}`))
  operatorSelect.innerHTML = optionsData.map((optionData) => {
    const optionValue = optionData[1]
    const optionTitle = optionData[0]

    return `<option value=${optionValue}>${optionTitle}</option>`
  }).join('\n')

  funnelStepFilterController.application.getControllerForElementAndIdentifier(operatorSelect, "select2-blue").connect()
}

