import {Controller} from "@hotwired/stimulus"
import Highcharts from "highcharts";

const buildYAxisConfig = (seriesData, comparisonShouldShareYAxis) => {
  const yAxisConfig = []
  seriesData.forEach((serie, index) => {
    const metricType = serie.metric_type
    const serieName = serie.name
    let titleConfig;
    if (seriesData.length === 1 || comparisonShouldShareYAxis) {
      titleConfig = { enabled: false }
    } else {
      titleConfig = {
        text: serieName,
        style: {
          fontWeight: '600',
          color: '#A8ABB0',
          fontSize: '12px'
        }
      }
    }

    const Numberformatter = Intl.NumberFormat('en', { notation: 'compact' });

    const config = {
      title: titleConfig,
      labels: {
        formatter: function(){
          return (metricType === 'percent') ? (Math.round(this.value * 100).toLocaleString() + '%') : Numberformatter.format(this.value);
        },
        style: {
          fontWeight: '400',
          color: '#A8ABB0'
        }
      }
    }

    if (index === 1 && !comparisonShouldShareYAxis) {
      config.opposite = true
    }

    yAxisConfig.push(config)
  })


  return yAxisConfig;
}

const buildSeriesConfig = (seriesData, comparisonShouldShareYAxis) => {
  const seriesConfig = []
  seriesData.forEach((serie, index) => {
    const serieName = serie.name
    let graphStyle;
    if (serie.graph_style === 'line') {
      graphStyle = (seriesData.length === 1) ? 'area' : 'line'
    } else {
      graphStyle = 'column'
    }

    const color = (index === 0) ? '#63CFEF' : '#143E99'

    let fillColorConfig;
    if (color === '#63CFEF') {
      fillColorConfig = {
        linearGradient: [0, 0, 0, 400],
        stops: [
          [0, 'rgba(99, 207, 239, .2)']
        ]
      }
    } else {
      fillColorConfig = {
        linearGradient: [0, 0, 0, 400],
        stops: [
          [0, 'rgba(20, 62, 153, .2)']
        ]
      }
    }

    const zIndex = (index === 0 && graphStyle === 'line') ? 2 : 1

    const config = {
      name: serieName,
      type: graphStyle,
      color: color,
      zIndex: zIndex,
      fillColor: fillColorConfig,
      data: serie.data_points.map(val => parseFloat(val))
    }

    if (index === 1 && seriesData.length > 1 && !comparisonShouldShareYAxis) {
      config.yAxis = 1
    }

    seriesConfig.push(config)
  })

  return seriesConfig;
}

const buildYAxisSplitGraphConfig = (seriesData) => {
  const yAxisConfig = []
  seriesData.forEach((serie) => {
    const metricType = serie.metric_type

    const Numberformatter = Intl.NumberFormat('en', { notation: 'compact' });

    const config = {
      title: { enabled: false },
      labels: {
        formatter: function(){
          return (metricType === 'percent') ? (Math.round(this.value * 100).toLocaleString() + '%') : Numberformatter.format(this.value);
        },
        style: {
          fontWeight: '400',
          color: '#A8ABB0'
        }
      }
    }

    yAxisConfig.push(config)
  })


  return yAxisConfig;
}

const buildSeriesConfigSplitGraphConfig = (seriesData) => {
  const colorsMap = {
    // List sources and associated colors
    'total_sales': '#DD25A8',
    'new_customers': '#8CC330',
    'google': '#5BD0ED',
    'facebook': '#2350AA',
    'bing': '#EF9D26',
    'snapchat': '#8CC330',
    'tiktok': '#DA045E',
    'pinterest': '#E34E35'
  }
  

  const seriesConfig = []
  seriesData.forEach((serie) => {
    const serieName = serie.name
    let graphStyle = (serie.graph_style === 'line') ? 'line' : 'column';
    const sourceName = serie.name.split(' ')[0].toLowerCase()
    const color = colorsMap[sourceName]

    const config = {
      name: serieName,
      type: graphStyle,
      color: color,
      data: serie.data_points.map(val => parseFloat(val))
    }

    seriesConfig.push(config)
  })

  return seriesConfig;
}

const buildHighChartOptions = (categories, seriesData, yAxisConfig, seriesConfig, currency, hideGrid) => {
  const maxYAxisValue = Math.max(...seriesData.map((x) => x.data_points).flat())

  return {
    chart: {
      zoomType: 'xy',
      style: {
        fontFamily: "Inter",
        color: '#475666'
      }
    },
    title: {
      text: null
    },
    legend: {
      enabled: false
    },
    xAxis: {
      visible: !hideGrid,
      title: {
        enabled: false
      },
      type: 'category',
      categories: categories,
      labels: {
        formatter: function(){
          if (this.isLast || this.isFirst) {
            return this.value
          }
        },
        style: {
          fontWeight: '400',
          color: '#A8ABB0',
          textOverflow: 'none',
          whiteSpace: 'nowrap'
        },
        rotation: 0
      }
    },
    yAxis: (hideGrid) ? {visible: false, tickInterval: 1, ceiling: maxYAxisValue} : yAxisConfig,
    tooltip: {
      backgroundColor: 'transparent',
      borderWidth: 0,
      padding: 0,
      shared: true,
      style: {
        fontSize: '13px',
        color: '#475666'
      },
      useHTML: true,
      headerFormat: '<span style="font-size: 16px;font-weight: 700;">{point.key}</span><br/>',
      formatter: function () {
        const points = this.points;
        const pointsLength = points.length;
        const headerHTML = pointsLength ?  '<div style="font-size: 12px;font-weight: 700;margin-bottom: 0.5rem;">' + points[0].key + '</div>' : '';
        let innerHTML = ''
        let index;

        for(index = 0; index < pointsLength; index += 1) {
          let formattedValue = points[index].y
          const metricType = seriesData[index].metric_type
          if (metricType === 'percent') {
            // For placed_order_rate round to the nearest 2 decimals because this metric can be very close to 0.
            if (seriesData[index].name === "Placed order rate") {
              formattedValue = (formattedValue * 100).toFixed(2) + '%'
            } else {
              formattedValue = Math.round(formattedValue * 100).toLocaleString() + '%'
            }
          } else if (metricType === 'roas') {
            formattedValue = formattedValue.toLocaleString() + 'X'
          } else if (metricType === 'non_monetary') {
            formattedValue = formattedValue.toLocaleString()
          } else if (metricType === 'small_monetary') {
            formattedValue = currency + (Math.round(formattedValue * 100) / 100).toLocaleString()
          } else {
            formattedValue = currency + Math.round(formattedValue).toLocaleString()
          }
          innerHTML += '<tr style="margin-bottom: 0.25rem;"><td style="padding-right: 1rem"><span style="padding-right:0.25rem;color:' + points[index].series.color + '">\u25CF</span>' + points[index].series.name + '</td><td style="padding-right: 1rem;text-align: right"><b>' + formattedValue + '</b></td></tr>';
        }

        return  `<div style="color:#475666">${headerHTML}<table>${innerHTML}</table></div>`;
      },
      shadow: false,
      crosshairs: {
        width: 2
      }
    },
    credits: {
      enabled: false
    },
    colors: ['#63CFEF'],
    plotOptions: {
      series: {
        marker: {
          enabled: (categories.length === 1) // show the marker if there's only one date (one point on X axis)
        }
      }
    },
    series: seriesConfig
  };
}

// Connects to data-controller="cardGraph"
export default class extends Controller {
  static values = {
    categories: Array,
    series: Array,
    currency: String,
    splitGraph: Boolean,
    comparisonSharesYAxis: Boolean,
    hideGrid: Boolean
  }

  connect() {
    const chartContainer = this.element

    const isSplitGraph = this.splitGraphValue
    const hideGrid = this.hideGridValue
    const categories = this.categoriesValue
    const seriesData = this.seriesValue
    const currency = this.currencyValue
    const comparisonShouldShareYAxis = this.comparisonSharesYAxisValue
    const chartId = chartContainer.id

    let yAxisConfig;
    if (isSplitGraph) {
      yAxisConfig = buildYAxisSplitGraphConfig(seriesData);
    } else {
      yAxisConfig = buildYAxisConfig(seriesData, comparisonShouldShareYAxis);
    }

    let seriesConfig;
    if (isSplitGraph) {
      seriesConfig = buildSeriesConfigSplitGraphConfig(seriesData);
    } else {
      seriesConfig = buildSeriesConfig(seriesData, comparisonShouldShareYAxis);
    }

    const highCharOptions = buildHighChartOptions(categories, seriesData, yAxisConfig, seriesConfig, currency, hideGrid)
    const highChartsChart = Highcharts.chart(chartId, highCharOptions);
    highChartsChart.reflow();

    $('.btn-closefilters, #left-main-menu-toggle').click( function (e) {
      e.preventDefault();
      highChartsChart.reflow();
    });
  }
}
