import { Controller } from "@hotwired/stimulus"
const skeleton_helper = require('../skeleton_helper');

// Connects to data-controller="select"
export default class extends Controller {
  connect() {
    const selectElem = this.element;

    selectElem.addEventListener('change', event => {
      const form = selectElem.form
      form.requestSubmit()

      const turboFrameIdentifier = form.dataset.turboFrame
      skeleton_helper.blockControls();
      skeleton_helper.injectSkeletons([turboFrameIdentifier]);
    })
  }
}
