import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="helpBox"
export default class extends Controller {
  connect() {
    //-- Show & close helper box
    const helpBox = this.element
    const helpBoxButton = this.element.querySelector('.btn-helper')
    const body = document.body

    helpBoxButton.addEventListener('click', function(e){
      e.preventDefault();

      body.classList.add('showoverlay');
      helpBox.classList.add('active');

      body.addEventListener("click", handleBodyClick);
    })

    const handleBodyClick = (event) => {
      if (!event.target.classList.contains('btn-helper')) {
        body.classList.remove('showoverlay');

        setTimeout(function () {
          helpBox.classList.remove('active');
        }, 500);

        body.removeEventListener("click", handleBodyClick);
      }
    }
  }
}
