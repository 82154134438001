// Re-position the show comparison checkbox
const repositionComparisonCheckbox = () => {
    const showComparisonCheckboxContainer = document.querySelector('.show-comparison-checkbox-container');

    if (showComparisonCheckboxContainer) {
        const highchartsLegendItems = document.querySelectorAll('div.highcharts-legend-item');
        const lastHighchartsLegendItem = highchartsLegendItems[highchartsLegendItems.length - 1];
        const chartLeftPadding = 24;
        const extraSvgLeftPx = 6;
        const lastItemLeftOffset = parseInt(lastHighchartsLegendItem.style.left, 10);
        const lastLabelWidth = lastHighchartsLegendItem.getElementsByTagName('span')[0].offsetWidth;
        const widthOfSymbol = 14;
        const marginLeft = 24;

        showComparisonCheckboxContainer.style.left = (chartLeftPadding + extraSvgLeftPx + lastItemLeftOffset + lastLabelWidth + widthOfSymbol + marginLeft) + 'px';
    }
}

export {
    repositionComparisonCheckbox
};