import { Controller } from "@hotwired/stimulus"
import Highcharts from "highcharts";

// Connects to data-controller="productJourneyReport"
export default class extends Controller {
  static values = {
      exportUrl: String
  }

  static targets = ['sankeyWrapper', 'tableWrapper', 'toggleContainer', 'hidingNodesToggle', 'allNodesTable']

  connect() {
      const controller = this

      initSankeyDiagram(controller)

      if (controller.hasExportUrlValue) {
          document.getElementById('export-btn').href = controller.exportUrlValue
      }

      if (controller.hasTableWrapperTarget) {
          initTable()
      }

      if (controller.hasToggleContainerTarget) {
          initHidingNodesControl(controller)
      }
  }
}

const delay = (fn, ms) => {
    let timer = 0
    return function(...args) {
        clearTimeout(timer)
        timer = setTimeout(fn.bind(this, ...args), ms || 0)
    }
}

const initHidingNodesControl = (controller) => {
    const toggleContainer = controller.toggleContainerTarget
    const toggleBtn = controller.hidingNodesToggleTarget
    const allNodesTable = controller.allNodesTableTarget
    const noisyNodesSearchInput = $('#noisy_nodes_search')

    toggleBtn.addEventListener('click', function(e) {
        toggleContainer.classList.toggle('active')
        noisyNodesSearchInput.focus()
    })

    const allNodesDatatable = $(allNodesTable).DataTable(
        {
            "order": [[ 1, "desc" ]],
            "searching": true,
            "ordering": false,
            "paging": false,
            "bLengthChange": false,
            "dom": "t"
        }
    );

    // TODO - make search a target too
    noisyNodesSearchInput.on("keypress keyup", delay(function () {
        $(allNodesTable).dataTable().fnFilter($(this).val(), [1]);
    }, 300));
}

const initSankeyDiagram = (controller) => {
    const chartContainer = document.querySelector('.product-journey-diagram-container')
    const chart_identifier = $(chartContainer).attr("id");
    const seriesData = $(chartContainer).data("journey-series");
    const seriesNodes = $(chartContainer).data("journey-nodes");
    const commonProducts = $(chartContainer).data("common-products");
    const detailsLevel = parseInt($("#level_of_details_slider").val());
    const journeyByTooltipValue = $( "#journey_by_select option:selected" ).text()

    if (detailsLevel > 145) {
        chartContainer.style.height = '1250px'
    } else if (detailsLevel > 115) {
        chartContainer.style.height = '1150px'
    } else if (detailsLevel > 85) {
        chartContainer.style.height = '1050px'
    } else if (detailsLevel > 65) {
        chartContainer.style.height = '950px'
    } else if (detailsLevel > 45) {
        chartContainer.style.height = '850px'
    }

    require("highcharts/modules/sankey")(Highcharts);

    const headers = ["1st order", "2nd order", "3rd order", "4th order"];
    let renderedCustomHeaders = [];
    const sankeyChart = Highcharts.chart(chart_identifier, {
        chart: {
            marginTop: 70,
            style: {
                fontFamily:
                    "'Inter', 'Lucida Grande', 'Lucida Sans Unicode', Arial, Helvetica, sans-serif",
                color: "#475666",
            },
            type: "sankey",
            events: {
                render: function () {
                    renderedCustomHeaders.forEach(header => header.destroy())

                    var chart = this,
                        series = chart.series[0],
                        columns = series.nodeColumns,
                        isFirst,
                        isLast,
                        xPos;

                    if (!series.customHeaders) {
                        series.customHeaders = [];
                    }

                    columns.forEach(function (column, i) {
                        xPos = column[0].nodeX + chart.plotLeft;
                        isFirst = i === 0;
                        isLast = i === columns.length - 1;

                        if (!series.customHeaders[i]) {
                            series.customHeaders.push(
                                chart.renderer
                                    .text(headers[i], xPos, 30)
                                    .attr({
                                        translateX: isFirst
                                            ? 0
                                            : isLast
                                                ? series.options.nodeWidth
                                                : series.options.nodeWidth / 2,
                                        align: isFirst ? "left" : isLast ? "right" : "center",
                                        "font-weight": "600",
                                        "font-style": "normal",
                                        "font-size": "13px",
                                    })
                                    .add()
                            );
                            renderedCustomHeaders = series.customHeaders;
                        } else {
                            series.customHeaders[i].attr({
                                x: xPos,
                            });
                        }
                    });
                }
            },
        },
        title: {
            text: null,
        },
        accessibility: {
            point: {
                valueDescriptionFormat:
                    "{index}. {point.from} to {point.to}, {point.weight}.",
            },
        },
        plotOptions: {
            sankey: {
                dataLabels: {
                    enabled: true,
                    style: {
                        textOverflow: "ellipsis",
                    },
                },
            },  
            series: {
                point: {
                    events: {
                        click: function() {
                            let chart = this
                            let name = chart.isNode == true ? chart.name : chart.from.slice(0, chart.from.length - 2)
                            let sequence = this.column + 1
                            let title_number = { "1": "st", "2": "nd", "3": "rd", "4": "th"}[sequence]
                            $('#common_products_data').html("");

                            if (commonProducts[name] && commonProducts[name][sequence]){
                                
                                $('#common-products-title-header').html(`${sequence}${title_number} order product`)
                                let orders_count = commonProducts['_totals'][name][sequence]

                                for (var i = 0; i < commonProducts[name][sequence].length; i++) {
                                    let common_product = commonProducts[name][sequence][i]
                                    let percentage = orders_count == 0 ? 0 : common_product["count"]/orders_count
                                    var product = `
                                    <tr class='product'>
                                        <td style="word-break: break-word;">${common_product["title"]}</td>
                                        <td>${common_product["count"]} (${Math.round(percentage * 100)}%)</td>
                                    </tr>`;
                                    $('#common_products_data').append(product);
                                }

                                $("#common-product-title").text(`${name} (${commonProducts['_totals'][name][sequence]})`)
                                $('#common-products-sidedrawer').css('display', 'block')
                            }
                        }
                    }
                },
            }
        },
        tooltip: {
            backgroundColor: "#FFF",
            borderWidth: 0,
            distance: 40,
            padding: 0,
            shadow: false,
            stickOnContact: true,
            style: {
                fontFamily:
                    "'Inter', 'Lucida Grande', 'Lucida Sans Unicode', Arial, Helvetica, sans-serif",
                fontSize: "12px",
                color: "#475666",
                whiteSpace: "normal",
            },
            useHTML: true,
        },
        credits: {
            enabled: false,
        },
        series: [
            {
                allowPointSelect: true,
                keys: ["from", "to", "weight"],
                data: seriesData,
                minLinkWidth: 1,
                dataLabels: {
                    enabled: true,
                    color: "#475666",
                    crop: false,
                    overflow: "allow",
                    fontSize: "13px",
                    fontWeight: "600",
                    padding: 0,
                    inside: false,
                    verticalAlign: "bottom",
                    style: {
                        width: "200px",
                        whiteSpace: "pre-wrap",
                        wordWrap: "break-word",
                    },
                    y: -4,
                },
                type: "sankey",
                name: null,
                nodes: seriesNodes,
                nodePadding: 30,
                tooltip: {
                    headerFormat: "",
                    nodeFormatter: function () {
                        let prev_order_text, next_order_text;

                        if (this.days_after_prev) {
                            prev_order_text =
                                "<td><b>Avg. time from prev order</b><br/>" +
                                this.days_after_prev +
                                " days</td>";
                        } else {
                            prev_order_text = "";
                        }

                        if (this.days_before_next) {
                            next_order_text =
                                "<td><b>Avg. time to next order</b><br/>" +
                                this.days_before_next +
                                " days</td>";
                        } else {
                            next_order_text = "";
                        }

                        let common_products_text = ""
                        if(journeyByTooltipValue == "Product variant") this.name = this.name.split(/ \/ (.*)/s)[1] || this.name.split(/ \/ (.*)/s)[0]
                        if (commonProducts[this.name] && commonProducts[this.name][this.column + 1]){
                            common_products_text += `<hr><div class="flex"><b class="mr-1">Often purchased with</b> ${this.name.length > 50 ? (this.name.substring(0,50) + "...") : this.name} ${commonProducts['_totals'][this.name][this.column + 1]}</div>` 
                            common_products_text += commonProducts[this.name][this.column + 1].slice(0, 3).map (common_product => `<span>${common_product["title"].length > 70 ? (common_product["title"].substring(0,70) + "...") : common_product["title"]}  (${common_product["count"]})</span><br/>`).join("")
                            common_products_text += `<div class="relative right-1 mt-2"><svg class="inline-block relative mr-1" style="bottom: 2px;" width="20" height="20" viewBox="0 0 20 20" fill="grey" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM11 6C11 6.55228 10.5523 7 10 7C9.44772 7 9 6.55228 9 6C9 5.44772 9.44772 5 10 5C10.5523 5 11 5.44772 11 6ZM9 9C8.44772 9 8 9.44772 8 10C8 10.5523 8.44772 11 9 11V14C9 14.5523 9.44772 15 10 15H11C11.5523 15 12 14.5523 12 14C12 13.4477 11.5523 13 11 13V10C11 9.44772 10.5523 9 10 9H9Z"/>
                            </svg><b class="inline-block font-bold" style="color: rgba(71, 86, 102, .8)">Click product to see more</b></div>`
                        }
                        return (
                            "<b>" +
                            this.name +
                            "</b><br/>" +
                            `${journeyByTooltipValue} included in ` +
                            this.percentage +
                            "% of all purchases as " +
                            this.order +
                            " order<br/>" +
                            "<hr><table>" +
                            prev_order_text +
                            "<td></td>" +
                            next_order_text +
                            "</table>" + 
                            common_products_text 
                        );
                    },
                    formatFormat: "",
                }
            },
        ],
    });

    // Re-scale chart when closing filters box or left main menu
    $(".btn-closefilters, #left-main-menu-toggle").click(function (e) {
        e.preventDefault();

        setTimeout(function() {
            sankeyChart.reflow();
        }, 1000);

    });

    if (controller.hasToggleContainerTarget) {
        initHideNoisyNodesApplyBtn(sankeyChart, seriesData, seriesNodes)
    }
}

const initHideNoisyNodesApplyBtn = (sankeyChart, seriesData, seriesNodes) => {
    let cachedTableViewRows = []
    const hideNoisyNodesApplyBtn = document.querySelector('.noisy-nodes-container button[name="apply"]')
    hideNoisyNodesApplyBtn.addEventListener('click', () => {

        // Get new series data
        const checkedTRsArray = Array.from(document.querySelectorAll('.noisy-nodes-container tr.checked'))
        const hiddenNodes = checkedTRsArray.map(elem => elem.lastElementChild.textContent)

        const checkMatchHiddenProduct = (element) => {
            if (typeof element === 'string') {
                for (let i = 0; i < hiddenNodes.length; i++) {
                    if (element.includes(`${hiddenNodes[i]}|`)) {
                        return true;
                    }
                }
            }
            return false;
        }

        const filteredSeriesData = seriesData.filter(array => !array.some(element => checkMatchHiddenProduct(element)))
        const filteredSeriesNodes = seriesNodes.filter(node => !hiddenNodes.includes(node.name))

        // Update sankey data
        sankeyChart.series[0].update({
            data: filteredSeriesData,
            nodes: filteredSeriesNodes
        }, true);


        const hidingTable = document.querySelector('#noisy-hiding-table')
        const checkedTRsHTML = checkedTRsArray.map(elem => elem.outerHTML).join("\n")
        const allTable = document.querySelector('#noisy-all-table')
        const currentlyHiddenNodes = allTable.querySelectorAll('tr.hidden')

        // Reset dropdown table and table view table
        Array.from(currentlyHiddenNodes).forEach((row) => {
            row.classList.remove('hidden')
        })


        const nodeQuerySelector = hiddenNodes.map(node => `[data-journey-node-value="${node}"]`).join(', ')
        const hiddenNodeRows = (nodeQuerySelector) ? allTable.querySelectorAll(nodeQuerySelector) : []

        Array.from(hiddenNodeRows).forEach((row) => {
            row.classList.remove('checked')
            row.classList.add('hidden')
        })

        // Add cache...
        const productJourneyDatatable = $('#product-journey-table').DataTable()
        cachedTableViewRows.forEach(row => productJourneyDatatable.row.add(row))
        productJourneyDatatable.draw()
        const hiddenTableViewRows = productJourneyDatatable.rows().nodes().filter(node => (hiddenNodes.includes(node.dataset.journeyProductOneValue) || hiddenNodes.includes(node.dataset.journeyProductTwoValue)))

        // Reset cache...
        cachedTableViewRows = []
        Array.from(hiddenTableViewRows).forEach((row) => {
            // Refresh cache...
            cachedTableViewRows.push(row)
            // Remove rows...
            productJourneyDatatable.row(row).remove()
        })
        productJourneyDatatable.draw()

        // Reset search
        $('#noisy_nodes_search').val('').trigger('keypress')

        // Inject rows into "Hiding" section of the dropdown
        const hideNoisyProductsBtnContainer = document.querySelector('.hide-noisy-products-btn-container')
        hideNoisyProductsBtnContainer.classList.remove('active');
        const hideNoisyProductsBtnLabel = hideNoisyProductsBtnContainer.querySelector('.js-hide-noisy-products-btn-dynamic-label')

        if (hiddenNodes.length > 0) {
            hidingTable.innerHTML = "<thead>\n" +
                "                <tr>\n" +
                "                  <th>Hiding</th>\n" +
                "                  <th class=\"text-right hover:opacity-90\"><button class=\"btn-clear-all\" type=\"button\" name=\"clear_all\" onclick=\"Array.from(document.querySelectorAll('.noisy-nodes-container tr.checked')).forEach(node => node.classList.remove('checked'))\">Clear all</button></th>\n" +
                "                </tr>\n" +
                "                </thead>\n" +
                "                <tbody>\n" +
                checkedTRsHTML +
                "                </tbody>"

            hideNoisyProductsBtnContainer.classList.add('hiding')
            hideNoisyProductsBtnLabel.textContent = `Hiding ${hiddenNodes.length}`
        } else {
            hidingTable.innerHTML = ''
            hideNoisyProductsBtnContainer.classList.remove('hiding')
            hideNoisyProductsBtnLabel.textContent = 'Hide'
        }


    });
}

const initTable = () => {
    $("#product-journey-table").DataTable(
      {
        "order": [[ 3, "desc" ]],
        "searching": true,
        "bLengthChange": false,
        "dom": "ltip",
        initComplete: function() {
          $('.dataTables_info, .dataTables_paginate, .dataTables_length').appendTo($('.tailwind-datatable-details'));
        }
      }
    );

    const sankeyIcon = document.getElementById('sankey-icon')
    const tableIcon = document.getElementById('table-icon')
    sankeyIcon.addEventListener('click', () => {
        $('.product-journey-diagram-wrapper').show();
        $('.product-journey-table-wrapper, .product-journey-search-product-wrapper').hide();
        $('#sankey-icon').addClass("active")
        $('#table-icon').removeClass("active")
    })
    tableIcon.addEventListener('click', () => {
        $('.product-journey-table-wrapper, .product-journey-search-product-wrapper').show();
        $('.product-journey-diagram-wrapper').hide();
        $('#sankey-icon').removeClass("active")
        $('#table-icon').addClass("active")
    })

    // Reset search value
    const productJourneyTableSearch = $('#product_journey_table_search')
    productJourneyTableSearch.val('')

    productJourneyTableSearch.on("keypress keyup", delay(function () {
        $("table#product-journey-table").dataTable().fnFilter($(this).val(), [2,3]);
    }, 300));
}