import {Controller} from "@hotwired/stimulus";
import Highcharts from "highcharts";
import { attributionChartConfig } from './../../../chart_configs/attribution';

// Connects to data-controller="attribution/overview/attributedRevenue"
export default class extends Controller {
    static values = {
        categories: Array,
        series: Array,
        chartName: String,
        currencySymbol: String
    }

    connect() {
        const chartContainer = this.element
        const chartAttributes = {
            categories: this.categoriesValue,
            seriesData: this.seriesValue,
            chartName: this.chartNameValue,
            currencySymbol: this.currencySymbolValue
        }

        const chartConfig = attributionChartConfig(chartAttributes)

        Highcharts.chart(chartContainer, chartConfig);
    }
}
