import { Controller } from "@hotwired/stimulus"
import * as CustomerSegmentsHelper from "../customer_segments_helper";

// Connects to data-controller="customer-segments-sub-rule-group"
export default class extends Controller {
  connect() {
  }

  removeAndSubmitForm(event) {
    const customerSegmentsSubRuleGroup = this.element

    customerSegmentsSubRuleGroup.remove()

    CustomerSegmentsHelper.refreshSegmentReport();
  }
}
