import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="ltv-breakdown-report"
export default class extends Controller {
  static values = {
    exportUrl: String,
    params: String
  }

  static targets = ['breakdownSearchInput']

  connect() {
    window.lifetime_value.refreshTableCellData();
    window.lifetime_value.initHoverCards();

    if (this.hasBreakdownSearchInputTarget) {
      this.breakdownSearchInputTarget.addEventListener('keyup', (event) => {
        if (event.key === 'Enter' || event.keyCode === 13) {
          window.lifetime_value.refreshLTVReportTurbo();
        }
      })
    }

    const pagyBtns = this.element.querySelectorAll('nav.pagy-nav.pagination .page a')
    pagyBtns.forEach((btn) => {
      btn.addEventListener('click', (event) => {
        event.preventDefault();
        const urlQuery = event.target.href.split( '?' )[1];
        const searchParams = new URLSearchParams(urlQuery)
        const page = searchParams.get('page') || 1
        window.lifetime_value.refreshLTVReportTurbo(page);
      })
    })

    // Update CSV export href
    const exportBtn = document.getElementById('export-btn')
    const exportUrl = this.exportUrlValue
    if (exportBtn && exportUrl) {
      exportBtn.href = exportUrl
    }
    
    const reportParams = this.paramsValue
    if (exportBtn && reportParams) {
      $('#export-btn').data('body', reportParams)
    }

  }
}
