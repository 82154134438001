import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['locationType', 'worldwideHelper', 'countryHelper', 'costType',
                    'fixedCostInputSection', 'fixedCostInput', 'variableCostInputSection', 'variableCostType',
                    'weightBasedInputSection', 'unitBasedInputSection', 'unitInputs', 'countriesInputSection',
                    'countriesSelect', 'weightLineItems', 'weightLineItem', 'currency', 'unitCostsContainer',
                    'nameInput', 'existingCountries', 'existingCostData', 'shippingZoneCostId']

  static values = {
    currentLocationType: String,
    currentCostType: String,
    currentVariableCostType: String,
    numberOfUnits: String,
  }

  connect() {
    $(this.costTypeTarget).on('select2:select', function() { this.#updateCurrentCostType() }.bind(this))
    $(this.countriesSelectTarget).on('select2:select', function() {
      $(this).next('.select2-container').find('.select2-search__field').val('')
    })
    this.updateCurrentLocationType()
    this.#updateCurrentCostType()
    this.updateCurrentVariableCostType()
  }

  saveAndClose(event) {
    event.preventDefault()
    const id = this.shippingZoneCostIdTarget.value
    const payload = {
      name: this.nameInputTarget.value,
      cost_calculation_type: this.#getCostType(),
      regions: this.#getRegions(),
      cost_data: this.#getCostData(),
    }

    if (id) {
      $.ajax({
        type: 'PATCH',
        url: '/cost/shipping-zone-cost-update',
        contentType: 'application/json',
        data: JSON.stringify({ id, ...payload }),
        success: () => {
          this.#closeModal()
          const event = new CustomEvent('shipping-zone-cost-updated', { bubbles: true })
          document.dispatchEvent(event)
        }
      })
    } else {
      $.ajax({
        type: 'POST',
        url: '/cost/shipping-zone-cost-create',
        contentType: 'application/json',
        data: JSON.stringify(payload),
        success: () => {
          this.#closeModal()
          const event = new CustomEvent('shipping-zone-cost-created', { bubbles: true })
          document.dispatchEvent(event)
        }
      })
    }
  }

  updateCurrentLocationType() {
    this.currentLocationType = this.locationTypeTargets.find((element) => element.checked).value

    if (this.currentLocationType === 'worldwide') {
      this.worldwideHelperTarget.hidden = false
      this.countryHelperTarget.hidden = true
      this.countriesInputSectionTarget.hidden = true
    } else {
      this.countryHelperTarget.hidden = false
      this.worldwideHelperTarget.hidden = true
      this.countriesInputSectionTarget.hidden = false
      if (this.existingCountriesTarget.value.length) {
        const countries = this.existingCountriesTarget.value.split(',')
        $(this.countriesSelectTarget).val(countries).trigger('change')
      } else {
        $(this.countriesSelectTarget).select2('open')
      }
    }
  }

  updateCurrentVariableCostType() {
    if (this.currentCostType === 'fixed') {
      this.weightBasedInputSectionTarget.hidden = true
      this.unitBasedInputSectionTarget.hidden = true
      return
    }

    this.currentVariableCostType = this.variableCostTypeTargets.find((element) => element.checked).value

    if (this.currentVariableCostType === 'weight') {
      this.weightBasedInputSectionTarget.hidden = false
      this.unitBasedInputSectionTarget.hidden = true
    } else if (this.currentVariableCostType === 'unit') {
      this.weightBasedInputSectionTarget.hidden = true
      this.unitBasedInputSectionTarget.hidden = false
    }
  }

  addUnitBasedItem() {
    this.#addUnitBasedCosts({ startingPoint: this.numberOfUnits })
    this.numberOfUnits += 1
  }

  addWeightBasedLineItem({ withLabel, min, max, cost }) {
    const lineItemTemplate = `
      <div class="flex gap-2 mb-2" data-shipping-zone-form-target="weightLineItem">
        <div>
          ${withLabel ? '<label class="font-semibold text-sm">Min. weight</label>' : ''}
          <div class="input-group relative">
            <div class="js-cost-prefix absolute text-sm text-gray-40 z-10" style="top: 9px; left: 10px;">
              kg
            </div>
            <input name="minWeight" value="${min}" type="number" step="0.01" class="form-control" style="border-radius: 3px; padding-left: 30px;" />
          </div>
        </div>
        <div>
          ${withLabel ? '<label class="font-semibold text-sm">Max. weight</label>' : ''}
          <div class="input-group relative">
            <div class="js-cost-prefix absolute text-sm text-gray-40 z-10" style="top: 9px; left: 10px;">
              kg
            </div>
            <input name="maxWeight" value="${max}" type="number" step="0.01" class="form-control" style="border-radius: 3px; padding-left: 30px;" />
          </div>
        </div>
        <div>
          ${withLabel ? '<label class="font-semibold text-sm">Cost</label>' : ''}
          <div class="input-group relative">
            <div class="js-cost-prefix absolute text-sm text-gray-40 z-10" style="top: 9px; left: 10px;">
              ${this.currencyTarget.value}
            </div>
            <input name="cost" value="${cost}" type="number" step="0.01" class="form-control" style="border-radius: 3px; padding-left: 25px;" />
          </div>
        </div>
      </div>
    `
    this.weightLineItemsTarget.insertAdjacentHTML('beforeend', lineItemTemplate)
    this.#scrollToBottom()
  }

  #closeModal() {
    document.querySelector('#shipping_zone_modal').innerText = ''
    document.body.classList.remove('modal-open')
  }

  #initializeVariableCostInputs() {
    if (this.currentCostType === 'variable') {
      const costDataJson = JSON.parse(this.existingCostDataTarget.value || '{}')

      if (this.currentVariableCostType === 'weight' && costDataJson.rules) {
        costDataJson.rules.forEach(({ min, max, cost }, index) => {
          this.addWeightBasedLineItem({ withLabel: index === 0, min, max, cost })
        })
      } else {
        this.addWeightBasedLineItem({ withLabel: true })
      }

      if (this.currentVariableCostType === 'unit' && costDataJson.rules) {
        const costData = costDataJson.rules
        this.numberOfUnits = Math.max(...costData.map((rule) => parseInt(rule.units)))
        this.#addUnitBasedCosts({ times: this.numberOfUnits, costData })
      } else {
        this.numberOfUnits = 5
        this.#addUnitBasedCosts({ times: this.numberOfUnits })
      }
    }
  }

  #getRegions() {
    if (this.currentLocationType === 'worldwide') {
      return { countries: [{ code: 'ww', name: 'Rest of the world' }] }
    } else {
      return { countries: $(this.countriesSelectTarget).select2('data').map(({ text, id }) => ({ code: id, name: text })) }
    }
  }

  #getCostData() {
    if (this.currentCostType === 'fixed') {
      return { cost: this.fixedCostInputTarget.value }
    } else if (this.currentVariableCostType === 'weight') {
      return {
        rules: this.weightLineItemTargets.map((lineItem) => {
          const min = lineItem.querySelector('[name="minWeight"]').value
          const max = lineItem.querySelector('[name="maxWeight"]').value
          const cost = lineItem.querySelector('[name="cost"]').value
          return (min.length && max.length && cost.length) ? { min, max, cost } : null
        }).filter((lineItem) => lineItem !== null)
      }
    } else if (this.currentVariableCostType === 'unit') {
      return {
        rules: this.unitInputsTargets.map((unitInputs) => {
          const units = unitInputs.querySelector('[name="unitCount"]').value
          const cost = unitInputs.querySelector('[name="unitCost"]').value
          return (units.length && cost.length) ? { units, cost } : null
        }).filter((lineItem) => lineItem !== null)
      }
    }
  }

  #getCostType() {
    if (this.currentCostType === 'fixed') {
      return 'fixed'
    } else {
      return this.currentVariableCostType
    }
  }

  #addUnitBasedCosts({ times = 1, startingPoint = 0, costData }) {
    const currencySymbol = this.currencyTarget.value

    function generateUnitCostTemplate(count, cost) {
      const template = `
        <div style="width: 30%" data-shipping-zone-form-target="unitInputs">
          <label class="font-semibold text-sm">Item ${count} cost</label>
          <div class="input-group relative">
            <div class="js-cost-prefix absolute text-sm text-gray-40 z-10" style="top: 9px; left: 10px;">
              ${currencySymbol}
            </div>
            <input name="unitCount" type="hidden" value="${count}" />
            <input name="unitCost" type="number" value="${cost}" step="0.01" class="form-control" style="border-radius: 3px; padding-left: 30px;" />
          </div>
        </div>
      `
      return template
    }

    const template = Array.from({ length: times }).map((_, index) => {
      const matchedUnit = costData && costData.find((rule) => rule.units === `${index + 1}`)
      if (matchedUnit) {
        return generateUnitCostTemplate(matchedUnit.units, matchedUnit.cost)
      } else {
        return generateUnitCostTemplate(startingPoint + index + 1)
      }
    }).join("\n")

    this.unitCostsContainerTarget.insertAdjacentHTML('beforeend', template)
    this.#scrollToBottom()
  }

  #scrollToBottom() {
    const container = document.querySelector('#form-container')
    container.scrollTop = container.scrollHeight
  }

  #updateCurrentCostType() {
    this.currentCostType = this.costTypeTarget.value
    if (this.currentCostType === 'fixed') {
      this.fixedCostInputSectionTarget.hidden = false
      this.variableCostInputSectionTarget.hidden = true
      this.weightBasedInputSectionTarget.hidden = true
      this.unitBasedInputSectionTarget.hidden = true
    } else {
      this.fixedCostInputSectionTarget.hidden = true
      this.variableCostInputSectionTarget.hidden = false
      this.updateCurrentVariableCostType()
      this.#initializeVariableCostInputs()
    }
  }
}
