import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="remove-button"
export default class extends Controller {
  static values = {
    formSubmitterSelector: String
  }

  remove() {
    this.element.remove()
  }

  removeAndSubmitForm() {
    // If formSubmitterSelector is provided, use that value as the form's submitter. If not, submit form without that value.
    // https://developer.mozilla.org/en-US/docs/Web/API/HTMLFormElement/requestSubmit
    let submitter;
    let form = this.element.closest('form')

    if (this.hasFormSubmitterSelectorValue) {
      submitter = document.querySelector(this.formSubmitterSelectorValue)
      form = submitter.form
    }

    this.element.remove();

    if (submitter) {
      submitter.click()
    } else {
      form.requestSubmit()
    }
  }
}
