import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="custom-metrics--formula-buttons"
export default class extends Controller {
  addMetric() {
    this.#getFormulaBoxController().addMetric()
  }

  addOperator(event) {
    this.#getFormulaBoxController().addOperator(event.params.value)
  }

  #getFormulaBoxController() {
    const formulaBoxControllerElement = document.querySelector('[data-controller="custom-metrics--formula-box"]')
    const formulaBoxController = this.application.getControllerForElementAndIdentifier(formulaBoxControllerElement, 'custom-metrics--formula-box')
    return formulaBoxController;
  }
}
