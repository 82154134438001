import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="modal"
export default class extends Controller {
  static values = {
    contentWrapperSelector: String,
    id: String,
  };

  connect() {}

  open() {
    document.body.classList.add("modal-open");

    const modalEl = document.getElementById(this.idValue);
    modalEl?.classList.remove("hidden");
  }

  close() {
    // Modal closed
    document.body.classList.remove("modal-open");
    this.element.classList.add("hidden");
  }

  closeAndErase() {
    // Modal closed and remove content (because the next time it is opened, we reset the content and we don't want a lag)
    document.body.classList.remove("modal-open");
    this.element.classList.add("hidden");

    if (this.hasContentWrapperSelectorValue) {
      this.element.querySelector(this.contentWrapperSelectorValue).innerHTML =
        "";
    }
  }
}
