import { Controller } from "@hotwired/stimulus"
import 'gridstack/dist/gridstack.min.css';
import { GridStack } from 'gridstack';
import 'gridstack/dist/h5/gridstack-dd-native';
import Highcharts from "highcharts";

// Connects to data-controller="custom-dashboard-grid"
export default class extends Controller {
  static values = {
    locked: Boolean
  }

  connect() {
    const isLocked = this.lockedValue
    const gridOptions = {
      oneColumnModeDomSort: true,
      column: 6,
      cellHeight: '115px',
      disableResize: isLocked,
      disableDrag: isLocked,
    }


    // I think there is a bug regarding 'oneColumnModeDomSort' which causes this option to not be respected when loading
    // the grid stack when the page is <= 860: https://github.com/gridstack/gridstack.js/issues/1975#issuecomment-1190489843
    // (It resizes to correct 'oneColumnModeDomSort' order when the grid is first loaded in a window that is > 860 and
    // then made smaller.) So a quick-fix is to remove the X and Y coordinates from
    // the grid-stack-item attributes when the grid is loaded on a small screen. This will still not work if the user then
    // resizes the small screen to a larger screen. But I think we can live with that since it won't happen often.
    const windowWidth = $(window).width()
    const gridStackOneColumnBreakpoint = 860
    if (windowWidth <= gridStackOneColumnBreakpoint) {
      // For mobile and small screens
      removeGridStackXYCoordinates(this.element)
    }

    let grid = GridStack.init(gridOptions);

    // new-grid-stack-item-connected is a custom event emitted by addedGridStackItem_controller.js
    document.addEventListener("new-grid-stack-item-connected", this.addNewItemHandler.bind(this, grid))
    document.addEventListener("remove-grid-stack-item", this.removeItemHandler.bind(this, grid))
    // updated-grid-stack-item-connected is a custom event emitted by updatedGridStackItem_controller.js
    document.addEventListener("updated-grid-stack-item-connected", this.updateItemHandler.bind(this, grid))

    grid.on('resizestop', function(event, element) {
      const highchartsGraphElement = element.querySelector("[data-controller='cardGraph'], [data-controller='timeLagChart'], [data-controller='repurchaseRateChart'], [data-controller='goalGraph'], [data-controller='customDashboardCardGauge']")

      if (highchartsGraphElement) {
        $(highchartsGraphElement).highcharts().reflow()
      }
    });
  }

  disconnect() {
    document.removeEventListener("new-grid-stack-item-connected", this.addNewItemHandler.bind(this))
    document.removeEventListener("remove-grid-stack-item", this.removeItemHandler.bind(this))
    document.removeEventListener("updated-grid-stack-item-connected", this.updateItemHandler.bind(this))
  }

  addNewItemHandler(grid, event) {
    let addedGridItem = event.detail.addedGridItem
    grid.makeWidget(addedGridItem);
  }

  removeItemHandler(grid, event) {
    let removedGridItem = event.detail.removedGridItem
    grid.removeWidget(removedGridItem)
    grid.compact()
  }

  updateItemHandler(grid, event) {
    // updatedGridItem: updatedGridItem, gridStackItemParams: gridStackItemParams
    let updatedGridItem = event.detail.updatedGridItem
    let gridStackItemParams = event.detail.gridStackItemParams

    const currentWidth = parseInt(updatedGridItem.getAttribute('gs-w'))
    const currentHeight = parseInt(updatedGridItem.getAttribute('gs-h'))

    // The below calculations are based on methods in custom_dashboard_card.rb - if we update here we need to also update there.
    let newMinWidth;
    if (gridStackItemParams.kind === 'goal') {
      newMinWidth = 2
    } else if ((gridStackItemParams.kind === 'report' && gridStackItemParams.report === 'ltv_cohorts') || ((gridStackItemParams.kind === 'metric' || gridStackItemParams.kind === 'marketing') && gridStackItemParams.metric_layout === 'with_graph' && gridStackItemParams.comparison_metric)) {
      newMinWidth = 3
    } else {
      newMinWidth = 2
    }

    let newMinHeight;
    if (gridStackItemParams.kind === 'report' || gridStackItemParams.kind === 'goal' || gridStackItemParams.metric_layout === 'with_graph') {
      newMinHeight = 4
    } else if (gridStackItemParams.metric_layout === 'gauge') {
      newMinHeight = 3
    } else {
      newMinHeight = 2
    }

    const newWidth = (currentWidth < newMinWidth) ? newMinWidth : currentWidth
    const newHeight = (currentHeight < newMinHeight) ? newMinHeight : currentHeight

    let options = {
      minW: newMinWidth,
      minH: newMinHeight,
      w: newWidth,
      h: newHeight
    }

    grid.update(updatedGridItem, options)

    // 'Compact' grid if dimensions of this cards have changed
    if (newWidth !== currentWidth || newHeight !== currentHeight) {
      grid.compact()
    }
  }
}

const removeGridStackXYCoordinates = (gridElement) => {
  const gridStackItems = gridElement.querySelectorAll('.grid-stack-item')
  gridStackItems.forEach((item) => {
    item.removeAttribute('gs-x')
    item.removeAttribute('gs-y')
  })
}
