import { Controller } from "@hotwired/stimulus"
import * as FunnelHelper from "../funnel_helper.js"

// Connects to data-controller="funnel-definition-form"
export default class extends Controller {
  static targets = ['funnelForm', 'toggleBtn', 'toggleIcon']

  connect() {
    const formElement = this.funnelFormTarget
    FunnelHelper.setFunnelStepLabels(formElement)

    formElement.addEventListener('submit', (event) => {
      formElement.classList.add('loading')
      skeleton_helper.injectSkeletons()
      skeleton_helper.blockControls()
    })

    $('#order_kind').on('select2:select', function (e) {
      FunnelHelper.setFunnelStepLabels(formElement)
      formElement.requestSubmit()
    });

    $('#customer_kind').on('select2:select', function (e) {
      formElement.requestSubmit()
    });

    const toggleBtn = this.toggleBtnTarget
    const toggleIcon = this.toggleIconTarget
    toggleBtn.addEventListener('click', (event) => {
      formElement.classList.toggle('hidden')
      toggleIcon.classList.toggle('rotate-180')
    })
  }
}
