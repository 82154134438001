const init = () => {

    if ( $('#onboarding-info-form').length ) {
        const shopSettingsCategorySelect = $('#shop_category_select').select2({
            placeholder: "What's your category?",
            minimumResultsForSearch: -1
        });

        const otherCategoryContainer = $('#js-shop-other-category-container');
        shopSettingsCategorySelect.on('select2:select', function (e) {
            if (this.value.length) { $(this).closest('.form-group').removeClass('has-error') }

            if ( this.value === 'Other' ) {
                otherCategoryContainer.fadeIn();
            } else {
                otherCategoryContainer.fadeOut();
            }
        });

        const shopSettingsLogisticsManagementSelect = $('#shop_logistics_management_select').select2({
            placeholder: "How do you manage your logistics?",
            minimumResultsForSearch: -1
        });

        const otherLogisticsManagementContainer = $('#js-shop-other-logistics-management-container');
        shopSettingsLogisticsManagementSelect.on('select2:select', function (e) {
            if (this.value.length) { $(this).closest('.form-group').removeClass('has-error') }

            if ( this.value === 'Other' ) {
                otherLogisticsManagementContainer.fadeIn();
            } else {
                otherLogisticsManagementContainer.fadeOut();
            }
        });

        $('#onboarding-info-form').find('.form-control').keyup(function () {
            if ($.trim($(this).val()).length > 0) {
                $(this).removeClass('has-error');
            }
        });
    }

}


module.exports = {
    init: init
};
