//-- Firing informational message
export function fireInfoToast(message) {
    $.toast({
        text: message,
        position: 'top-right',
        bgColor: '#fff',
        textColor: '#475666',
        loader: false,
        hideAfter: 5000,
        showHideTransition: 'slide'
    });
}

//-- Firing alert message
export function fireAlertToast(message) {
    $.toast({
        text: message,
        position: 'top-right',
        bgColor: '#F24738',
        textColor: '#fff',
        loader: false,
        hideAfter: 5000,
        icon: 'error',
        showHideTransition: 'slide',
        afterShown: function (msg) {
            msg.addClass('active');
        }
    });
}

//-- Firing success message
export function fireSuccessToast(message) {
    $.toast({
        text: message,
        position: 'top-right',
        bgColor: '#00C853',
        textColor: '#fff',
        loader: false,
        hideAfter: 5000,
        icon: 'success',
        showHideTransition: 'slide',
        afterShown: function (msg) {
            msg.addClass('active');
        }
    });
}