import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="setup-guide-tabs"
export default class extends Controller {
  static targets = ['tabButton', 'tabContent']

  connect() {
    const tabButtons = this.tabButtonTargets
    const tabContents = this.tabContentTargets

    tabButtons.forEach(function (tabButton) {
      tabButton.addEventListener("click", function (e) {
        tabButtons.forEach(otherTabButton => otherTabButton.classList.remove('setup-guide-tab--selected'))
        tabButton.classList.add('setup-guide-tab--selected');

        tabContents.forEach(otherTabContent => otherTabContent.classList.add('hidden'))
        const tabId = tabButton.dataset.tabId
        document.getElementById(tabId).classList.remove('hidden')
      })
    })
  }
}
