import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="createUpdateCustomDashboardForm"
export default class extends Controller {
  connect() {
    const form = this.element
    if (form.attachEvent) {
      form.attachEvent("submit", updateCardsGridItemValues);
    } else {
      form.addEventListener("submit", updateCardsGridItemValues);
    }
  }
}

const updateCardsGridItemValues = (event) => {
  const gridItemValuesInputs = document.querySelectorAll("input[type='hidden'][name='custom_dashboard_card_attributes[]']")

  gridItemValuesInputs.forEach((input) => {
    const gridStackWrapper = input.closest('.grid-stack-item')
    const cardAttributes = JSON.parse(input.value)

    const isMarkedForDestruction = cardAttributes['_destroy']
    if (isMarkedForDestruction) {
      return;
    }

    const gridItemWidth = gridStackWrapper.getAttribute('gs-w')
    const gridItemHeight = gridStackWrapper.getAttribute('gs-h')
    const gridItemPositionX = gridStackWrapper.getAttribute('gs-x')
    const gridItemPositionY = gridStackWrapper.getAttribute('gs-y')

    cardAttributes.grid_item_width = gridItemWidth
    cardAttributes.grid_item_height = gridItemHeight
    cardAttributes.grid_item_position_x = gridItemPositionX
    cardAttributes.grid_item_position_y = gridItemPositionY

    input.value = JSON.stringify(cardAttributes)
  })

  return true;
}