import { Controller } from "@hotwired/stimulus"
import Highcharts from "highcharts";
import * as Bullet from 'highcharts/modules/bullet';

// Connects to data-controller="goalGraph"
export default class extends Controller {
  static values = {
    target: Number,
    current: Number,
    targetPace: Number,
    paceDifference: Number
  }

  connect() {
    const chartId = this.element.id
    const targetValue = this.targetValue
    const currentValue = this.currentValue
    const targetPaceValue = this.targetPaceValue
    const paceDifference = this.paceDifferenceValue

    Bullet(Highcharts);

    const highCharOptions = buildHighChartOptions(chartId, targetValue, currentValue, targetPaceValue, paceDifference)
    const highChartsChart = Highcharts.chart(chartId, highCharOptions);

    $('.btn-closefilters, #left-main-menu-toggle').click( function (e) {
      e.preventDefault();
      highChartsChart.reflow();
    });
  }
}

const buildHighChartOptions = (chartId, targetValue, currentValue, targetPaceValue, paceDifference) => {
  const {pointPadding, pointWidth, borderRadius, width} = chartPlotOptions(chartId)
  const maxChartValue = Math.max(targetValue, currentValue)
  const targetColor = (paceDifference >= 0) ? '#00C853' : '#F24738'

  return {
    chart: {
      inverted: true,
      type: 'bullet',
      borderRadius: 8,
      borderWidth: 2,
      borderColor: '#FFFFFF',
      backgroundColor: '#DEF6FB',
      margin: [0,0,0,0],
      spacing: [0,0,0,0]
    },
    title: null,
    xAxis: {
      title: null,
      visible: false,
      minorTickLength: 0,
      tickLength: 0,
      lineWidth: 0,
      minorGridLineWidth: 0,
      lineColor: 'transparent',
      labels: {
        enabled: false
      },
    },
    yAxis: {
      min: 0,
      max: maxChartValue,
      startOnTick: false,
      endOnTick: false,
      gridLineWidth: 0,
      labels: {
        enabled: false
      },
      title: null
    },
    series: [{
      data: [{
        y: currentValue,
        target: targetPaceValue,
        color: '#5BD0ED'
      }],
    }],
    plotOptions: {
      series: {
        pointPadding: pointPadding,
        borderWidth: 0,
        pointWidth: pointWidth,
        borderRadius: borderRadius,
        targetOptions: {
          width: width,
          height: 4,
          borderColor: '#FFFFFF',
          borderRadius: 3,
          borderWidth: 2,
          color: targetColor,
        },
      }
    },
    label: {
      enabled: false
    },
    tooltip: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    exporting: {
      enabled: false
    }
  }
}

const chartPlotOptions = (chartId) => {
  return {pointPadding: 0.25, pointWidth: 16, borderRadius: 8, width: 20}
}