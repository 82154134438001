import { Controller } from "@hotwired/stimulus"
import * as CSRFTokenHelper from "../csrf_token_helper.js"
import {get_filters_and_options_data} from "../filters_and_options";

// Connects to data-controller="export-button"
export default class extends Controller {

    static values = {
        url: String,
        method: String,
        headers: String,
        body: String
    }

    static targets = ['caller']

    connect() {
        let caller = this.callerTarget
        let url = this.urlValue
        let method = this.methodValue
        let headers = getHeaders(this.headersValue)

        caller.addEventListener('click', (event) => {
            event.preventDefault();
            callUrl(url, method, headers)
        })
    }

}

const callUrl = async (url, method, headers) => {
    let body = formatBody()
    let download_response = $('#export-btn').data('download_response')
    let response = await fetch(url, {
        method: method,
        headers: headers,
        body: JSON.stringify(body)
    })
    
    if (download_response){
        if (response.ok) {
            const blob = await response.blob();
            const filename = response.headers.get('Content-Disposition').split('"')[1];
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            link.remove();
        }
    }
}

const formatBody = () => {
    let request_body = get_filters_and_options_data()
    request_body['ltv_show_format'] = $("#format-toggle .active input").attr('data-ltv_show_format');
    request_body['metric'] = $('select#lifetime_select').val();
    request_body['show_predictive_ltv'] = $("#togglePredictiveLTV").is(":checked");

    let body = $('#export-btn').data('body')
    request_body['report_params'] = body

    return request_body
}

const getHeaders = (headers) => {
    if(!headers) return {}
    headers = headers.slice(1, -1).replace(/ /g, '').split(",")
    let result = {}
    for( let header of headers ) {
        header = header.slice(1, -1).split(":");
        result[header[0]] = header[1]
    }
    result['X-CSRF-Token'] = CSRFTokenHelper.getCSRFToken()

    return result
}
