import moment from "moment/moment";

export const attributionChartConfig = ({ categories, seriesData, chartName, currencySymbol }) => {
    const series = buildSeriesConfig(seriesData)

    return {
        chart: {
            type: 'area',
                style: {
                fontFamily: "Inter",
                    color: '#727586'
            },
            height: 200,
                marginRight: 0,
                marginLeft: 0
        },
        legend: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        title: {
            text: null
        },
        xAxis: {
            title: {
                enabled: false
            },
            type: 'category',
            categories: categories,
            labels: {
                formatter: function(){
                    return moment(this.value).format('DD/MM');
                },
                style: {
                    fontWeight: '400',
                        color: '#A8ABB0',
                        textOverflow: 'none',
                        whiteSpace: 'nowrap'
                },
            },
            gridLineWidth: 1,
                gridLineColor: '#C7C9D2',
                tickmarkPlacement: 'on',
                lineColor: 'transparent',
                lineWidth: 0,
                gridLineDashStyle: 'Dash',
                rotation: 0
        },
        yAxis: {
            title: {
                enabled: false
            },
            labels: {
                enabled: false
            },
            gridLineWidth: 0,
        },
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: false
                },
                turboThreshold: 0,
                    marker: {
                    enabled: true
                }
            },
            areaspline: {
                lineWidth: 3,
                    marker: {
                    symbol: 'circle',
                        enabled: false
                }
            },
        },
        tooltip: {
            backgroundColor: 'transparent',
                borderWidth: 0,
                padding: 0,
                shared: true,
                style: {
                fontSize: '12px',
                    color: '#727586',
                    pointerEvents: 'auto',
            },
            useHTML: true,
                shadow: false,
                crosshairs: {
                width: 2
            },
            formatter: function () {
                const dateLabel = moment(this.x).format('MMM DD');

                let formattedValueHTML;
                // Add a label for each series
                const points = this.points;
                const pointsLength = points.length;
                formattedValueHTML = '';
                let index;

                for(index = 0; index < pointsLength; index += 1) {
                    let seriesName = this.points[index].series.name
                    let formattedValue;
                    if (chartName.toLowerCase().includes('roas')) {
                        formattedValue = points[index].y.toLocaleString() + 'x'
                    } else {
                        formattedValue = currencySymbol + points[index].y.toLocaleString()
                    }
                    formattedValueHTML += `<p class='whitespace-normal font-normal text-xs mb-0'>
                                                 <span style="color:${points[index].series.color}">\u25CF</span>
                                                 ${seriesName}: <strong>${formattedValue}</strong>
                                               </p>`;
                }

                return `<div class="whitespace-normal w-40 h-auto text-gray-600">
                          <p class="font-normal mb-1 text-xs">${chartName} on ${dateLabel}:</p>
                          ` + formattedValueHTML + `
                        </div>`;
            },
        },
        series: series
    }
}

const buildSeriesConfig = (seriesData) => {
    const baseSerieConfig = {
        pointPlacement: 'on',
        name: 'Total', // Passed in through seriesData
        color: '#45B0E8',
        marker: {
            fillColor: 'white',
            lineColor: '#45B0E8',
            lineWidth: 1,
            symbol: 'circle'
        },
        fillColor: {
            linearGradient: {x1: 0, x2: 0, y1: 0, y2: 1},
            stops: [
                [0, 'rgba(147,197,253, 0.3)'],
                [1, 'rgba(147,197,253, 0.0)']
            ]
        },
        data: [] // Passed in through seriesData
    }

    const series = [];

    seriesData.forEach(serieConfig => {
        const mergedSerieConfig = { ...baseSerieConfig, ...serieConfig }
        setSerieColorAttributes(mergedSerieConfig);
        setSerieName(mergedSerieConfig);
        series.push(mergedSerieConfig);
    });

    return series
}

const setSerieColorAttributes = (serie) => {
    const lowercaseName = serie.name.toLowerCase()
    if (lowercaseName === 'total') {
        // Do nothing, defaults to blue which is already present
    } else if (lowercaseName === 'google') {
        serie.color = '#00BF86'
        serie.marker = {
            fillColor: 'white',
            lineColor: '#00BF86',
            lineWidth: 1,
            symbol: 'circle'
        }
        serie.fillColor = {
            linearGradient: {x1: 0, x2: 0, y1: 0, y2: 1},
            stops: [
                [0, 'rgba(0, 191, 134, 0.3)'],
                [1, 'rgba(0, 191, 134, 0.0)']
            ]
        }
    } else if (lowercaseName === 'facebook') {
        serie.color = '#45B0E8'
        serie.marker = {
            fillColor: 'white',
            lineColor: '#45B0E8',
            lineWidth: 1,
            symbol: 'circle'
        }
        serie.fillColor = {
            linearGradient: {x1: 0, x2: 0, y1: 0, y2: 1},
            stops: [
                [0, 'rgba(147,197,253, 0.3)'],
                [1, 'rgba(147,197,253, 0.0)']
            ]
        }
    } else if (lowercaseName === 'tiktok') {
        serie.color = '#8C75FF'
        serie.marker = {
            fillColor: 'white',
            lineColor: '#8C75FF',
            lineWidth: 1,
            symbol: 'circle'
        }
        serie.fillColor = {
            linearGradient: {x1: 0, x2: 0, y1: 0, y2: 1},
            stops: [
                [0, 'rgba(140, 117, 255, 0.3)'],
                [1, 'rgba(140, 117, 255, 0.0)']
            ]
        }
    }
}

const setSerieName = (serie) => {
    const lowercaseName = serie.name.toLowerCase()
    if (lowercaseName === 'total') {
        serie.name = 'Total'
    } else if (lowercaseName === 'google') {
        serie.name = 'Google Ads'
    } else if (lowercaseName === 'facebook') {
        serie.name = 'Meta'
    } else if (lowercaseName === 'tiktok') {
        serie.name = 'Titkok'
    }
}