class TailwindToast {
  constructor(duration) {
    (this.duration = duration),
    this.id,
    this.icon,
    this.title,
    this.message
  }

  danger(title, message = "", duration = null) {
    this.duration = duration ? duration : 3000;
    this.title = title;
    this.message = message;
    this.icon = "";
    this.show();
  }

  success(title, message = "", duration = null) {
    this.duration = duration ? duration : 3000;
    this.title = title;
    this.message = message;
    this.icon = '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                  '<path d="M9 12L11 14L15 10M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#00D96D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>\n' +
                '</svg>';
    this.show();
  }

  warning(title, message = "", duration = null) {
    this.duration = duration ? duration : 3000;
    this.title = title;
    this.message = message;
    this.icon = "<svg width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
                  "<path d=\"M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM12 20.7188C7.18594 20.7188 3.28125 16.8141 3.28125 12C3.28125 7.18594 7.18594 3.28125 12 3.28125C16.8141 3.28125 20.7188 7.18594 20.7188 12C20.7188 16.8141 16.8141 20.7188 12 20.7188Z\" fill=\"#F24738\"/>\n" +
                  "<path d=\"M10.875 16.125C10.875 16.4234 10.9935 16.7095 11.2045 16.9205C11.4155 17.1315 11.7016 17.25 12 17.25C12.2984 17.25 12.5845 17.1315 12.7955 16.9205C13.0065 16.7095 13.125 16.4234 13.125 16.125C13.125 15.8266 13.0065 15.5405 12.7955 15.3295C12.5845 15.1185 12.2984 15 12 15C11.7016 15 11.4155 15.1185 11.2045 15.3295C10.9935 15.5405 10.875 15.8266 10.875 16.125ZM11.4375 13.5H12.5625C12.6656 13.5 12.75 13.4156 12.75 13.3125V6.9375C12.75 6.83437 12.6656 6.75 12.5625 6.75H11.4375C11.3344 6.75 11.25 6.83437 11.25 6.9375V13.3125C11.25 13.4156 11.3344 13.5 11.4375 13.5Z\" fill=\"#F24738\"/>\n" +
                "</svg>\n";
    this.show();
  }

  show() {
    this.id = `tawilwind-toast-${Math.random().toString(36).substring(7)}`;
    let wrapper = `
      <div aria-live="assertive" id="${this.id}" class="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start" style="z-index: 3000">
        <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
          <div class="max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div class="tailwind-p-4">
              <div class="flex items-start">
                <div class="flex-shrink-0 tailwind-mr-3">
                  ${this.icon}
                </div>
                <div class="w-0 flex-1 pt-0.5 text-gray-90">
                  <p class="text-sm leading-5 font-medium !mb-0">
                    ${this.title}
                  </p>`

    // Only inject message if it is present.
    if (this.message !== undefined && this.message !== null && this.message !== '') {
     wrapper += `<p class="text-sm leading-5 font-normal text-gray-60 !mb-0 tailwind-mt-1">
                  ${this.message}
                </p>`
    }

    wrapper += `</div>
                <div class="tailwind-mt-1 ml-4 flex-shrink-0 flex">
                  <button class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <span class="sr-only">Close</span>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 6C0 2.68629 2.68629 0 6 0H14C17.3137 0 20 2.68629 20 6V14C20 17.3137 17.3137 20 14 20H6C2.68629 20 0 17.3137 0 14V6Z" fill="white"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M4.29289 4.29289C4.68342 3.90237 5.31658 3.90237 5.70711 4.29289L10 8.58579L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L11.4142 10L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L10 11.4142L5.70711 15.7071C5.31658 16.0976 4.68342 16.0976 4.29289 15.7071C3.90237 15.3166 3.90237 14.6834 4.29289 14.2929L8.58579 10L4.29289 5.70711C3.90237 5.31658 3.90237 4.68342 4.29289 4.29289Z" fill="#BEC0C4"/>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    `;

    $(wrapper).appendTo('body');
    let toast = $("#" + this.id);

    toast.find("button").click(function(){
      toast.fadeOut(400,function(){
        toast.remove();
      })
    })

    setTimeout(() => {
      toast.fadeOut();
    }, this.duration);
  }
}


module.exports = {
  tailwind_toast: () => {
    return new TailwindToast(300000);
  }
};
