import { Controller } from "@hotwired/stimulus"
import Highcharts from "highcharts";
import * as HighchartsMore from 'highcharts/highcharts-more.src';
import * as SolidGauge from 'highcharts/modules/solid-gauge';

// Connects to data-controller="custom-dashboard-card-gauge"
export default class extends Controller {
  static values = {
    current: Number,
    minNumber: Number,
    maxNumber: Number,
    aboveStatus: String,
    aboveThreshold: Number,
    belowStatus: String,
    belowThreshold: Number,
    metricType: String,
    currency: String
  }

  connect() {
    HighchartsMore(Highcharts)
    SolidGauge(Highcharts)

    const chartMinValue = this.minNumberValue
    const chartMaxValue = this.maxNumberValue
    let gaugeDialValue = this.currentValue
    if (this.currentValue < chartMinValue) {
      gaugeDialValue = chartMinValue
    }

    if (this.currentValue > chartMaxValue) {
      gaugeDialValue = chartMaxValue
    }

    let plotBandsConfig = []
    if (this.aboveStatusValue.length > 0) {
      const aboveThresholdColor = (this.aboveStatusValue === 'success') ? '#1ACE64' : '#F3594C'
      plotBandsConfig.push({
        from: this.aboveThresholdValue,
        to: chartMaxValue,
        color: aboveThresholdColor,
        innerRadius: '50%'
      })
    }

    if (this.belowStatusValue.length > 0) {
      const belowThresholdColor = (this.belowStatusValue === 'success') ? '#1ACE64' : '#F3594C'
      plotBandsConfig.push({
        from: chartMinValue,
        to: this.belowThresholdValue,
        color: belowThresholdColor,
        innerRadius: '50%'
      })
    }

    if (plotBandsConfig.length === 0) {
      plotBandsConfig = undefined
    }

    const metricType = this.metricTypeValue
    const currency = this.currencyValue

    const highChartsOptions = {
      chart: {
        plotBackgroundColor: null,
        plotBackgroundImage: null,
        plotBorderWidth: 0,
        plotShadow: false,
        borderWidth: 0,
        style: {
          fontFamily: "Inter",
        }
      },
      title: {
        text: null
      },
      tooltip: {
        enabled: false
      },
      pane: {
        center: ['50%', '90%'],
        size: '192px',
        startAngle: -90,
        endAngle: 90,
        background: {
          backgroundColor: '#DEF6FB',
          innerRadius: '50%',
          outerRadius: '100%',
          borderWidth: 0,
          shape: 'arc'
        }
      },
      plotOptions: {
        series: {
          states: {
            inactive: {
              opacity: 1
            },
            hover: {
              enabled: false
            }
          }
        }
      },
      yAxis: {
        stops: [
            [chartMinValue / chartMaxValue, 'transparent']
        ],
        plotBands: plotBandsConfig,
        min: chartMinValue,
        max: chartMaxValue,
        lineWidth: 0,
        tickWidth: 0,
        minorTickInterval: null,
        tickAmount: 2,
        tickPositions: [chartMinValue, chartMaxValue],
        tickmarkPlacement: 'on',
        title: {
          text: null,
        },
        labels: {
          formatter: function(){
            if (this.isLast || this.isFirst) {
              let formattedValue = this.value

              if (metricType === 'percent') {
                formattedValue = Math.round(formattedValue).toLocaleString() + '%'
              } else if (metricType === 'roas') {
                formattedValue = formattedValue.toLocaleString() + 'X'
              } else if (metricType === 'non_monetary') {
                formattedValue = formattedValue.toLocaleString()
              } else if (metricType === 'small_monetary') {
                formattedValue = currency + (Math.round(formattedValue * 100) / 100).toLocaleString()
              } else {
                formattedValue = currency + Math.round(formattedValue).toLocaleString()
              }

              return formattedValue
            }
          },
          align: 'auto',
          y: 2,
          style: {
            fontWeight: '400',
            color: '#A8ABB0',
            fontSize: '12px'
          }
        }
      },
      credits: {
        enabled: false
      },
      series: [{
        type: 'gauge',
        dataLabels: {
          enabled: false
        },
        data: [gaugeDialValue],
        pivot: {
          radius: 8,
          borderWidth: 0,
          backgroundColor: '#5BD0ED'
        },
        dial: {
          radius: '85%',
          backgroundColor: '#5BD0ED',
          borderColor: '#FFFFFF',
          borderWidth: 2,
          baseWidth: 8,
          topWidth: 8,
          baseLength: '100%', // of radius
          rearLength: '0%'
        },
      }, {
        type: 'solidgauge',
        data: [this.currentValue],
        radius: '100%',
        dataLabels: {
          enabled: false
        }
      }]

    }

    const chartId = this.element.id
    Highcharts.chart(chartId, highChartsOptions);
  }
}
