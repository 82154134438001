import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="radio-button-tab-group"
export default class extends Controller {
  static targets = [ "tabButton", "tabContent" ]

  connect() {
    const tabButtons = this.tabButtonTargets
    const tabContents = this.tabContentTargets

    tabButtons.forEach(tabButton => {
      tabButton.addEventListener('change', event => {
        // Remove class from all & hide tabContents
        tabButtons.forEach(btn => btn.closest('.svg-radio-wrapper').classList.remove('checked'))
        tabContents.forEach(tabContent => tabContent.classList.add('hidden'))

        // Add class to this one & show content
        if (tabButton.checked) {
          tabButton.closest('.svg-radio-wrapper').classList.add('checked')
          const shownTabContents = Array.prototype.filter.call(tabContents, function(tabContent){
            return tabContent.matches(`div[data-tab-content-values*='${tabButton.value}']`);
          });
          shownTabContents.forEach(showTabContent => showTabContent.classList.remove('hidden'))
        }
      })
    })
  }
}
