import useCsvPlugin from "@usecsv/js";
let useCsvOptions = {}

const validateProductCost = (sku, product_cost, shipping_cost) => {
    const isNumberWithOptionalDecimal = /^[0-9]*\.?[0-9]*$/;
    const data = {};

    if (!isNumberWithOptionalDecimal.test(product_cost)) {
        data.product_cost = [
            {
                level: "error",
                message: "Must be a numerical value with no more than one decimal point.",
            }
        ]

    }

    if (shipping_cost && !isNumberWithOptionalDecimal.test(shipping_cost)) {
        data.shipping_cost = [
            {
                message: "Must be a numerical value with no more than one decimal point.",
                level: "error"
            }
        ]
    }

    if (Object.keys(data).length !== 0) {
        return data;
    }
    return null;
};
const validateShopifyAndApp = (amount, charge_category, original_amount) => {
    const isNumberWithOptionalDecimal = /^[0-9]*\.?[0-9]*$/;
    const data = {};

    if (!isNumberWithOptionalDecimal.test(amount)) {
        data.amount =  [
                {
                    message: "Must be a numerical value with no more than one decimal point.",
                    level: "error"
                }
        ]
    }

    charge_category = charge_category.toLowerCase();
    if (charge_category != 'apps') {
        data.charge_category = [
                {
                    message: "Must equal Apps.",
                    level: "error"
                }
        ]

    }

    if (!isNumberWithOptionalDecimal.test(original_amount)) {
        data.original_amount = [
                {
                    message: "Must be a numerical value with no more than one decimal point.",
                    level: "error"
                }
        ]
    }

    if (Object.keys(data).length !== 0) {
        return data;
    }
    return null;
}
const validateCustomCost = (category, type_of_expense, value) => {
    const isNumberWithOptionalDecimal = [/^\$[0-9]*\.?[0-9]*$/, /^[0-9]*\.?[0-9]*\$$/];
    const isPercentage = [/(^100(\.0{1,2})?\%$)|(^([1-9]([0-9])?|0)(\.[0-9]{1,2})?\%$)/, /(^\%100(\.0{1,2})?$)|(^\%([1-9]([0-9])?|0)(\.[0-9]{1,2})?$)/];

    let getTypeOfExpenseData = {
        "fixed": {
            "validationRegex": isNumberWithOptionalDecimal,
            "errorMessage": "Must be a numerical value with no more than one decimal point with a '$' on either side."
        },
        "% of total sales": {
            "validationRegex": isPercentage,
            "errorMessage": "Must be a percentage value with no more than 100.00 with a '%' on either side."
        }
    }

    const Categories = {"other expenses": true, "other marketing": true, "other ads channels": true, "agencies": true, "influencer": true, "other cogs": true, "shopify and apps": true}

    let data = {};

    category = category.toLowerCase();
    type_of_expense = type_of_expense.toLowerCase();
    value = value.replace(/,/g, '').trim();

    if ( !getTypeOfExpenseData[type_of_expense] ) {
        data.type_of_expense = [
            {
                message: `Wrong type of expense, Available types are: ${Object.keys(getTypeOfExpenseData).map ( key => `\"${key}\"`)}`,
                level: "error"
            }
        ]

    } else if (!(getTypeOfExpenseData[type_of_expense]['validationRegex'][0].test(value) || getTypeOfExpenseData[type_of_expense]['validationRegex'][1].test(value))) {
        data.value = [
            {
                message: getTypeOfExpenseData[type_of_expense]['errorMessage'],
                level: "error"
            }
        ]
    }

    if ( !Categories[category] ) {
        data.category = [
            {
                message: `Wrong category type, Available categories are: (${Object.keys(Categories)})`,
                level: "error"
            }
        ]

    }

    if (Object.keys(data).length !== 0) {
        return data;
    }
    return null;
}
const validateShippingCost = (shipping_cost) => {
    const isNumberWithOptionalDecimal = /^[0-9]*\.?[0-9]*$/;

    let data = {};

    if (!isNumberWithOptionalDecimal.test(shipping_cost)) {
        data.shipping_cost = [
            {
                message: "Must be a numerical value with no more than one decimal point.",
                level: "error"
            }
        ]
    }


    if (Object.keys(data).length !== 0) {
        return data;
    }
    return null;
}

const productCostColumns = [
    {
        name: "sku",
        displayName: "SKU",
        valueCannotBeBlank: true,
        validationFormat: { type: "string" },
    },
    {
        name: "product_cost",
        displayName: "Product cost",
    },
    {
        name: "shipping_cost",
        displayName: "Shipping cost",
    },
]
const shopifyAndAppsColumns = [
    {
        name: "bill",
        displayName: "Bill #",
    },
    {
        name: "store_name",
        displayName: "Store name",
    },
    {
        name: "myshopify_url",
        displayName: ".myshopify.com URL",
    },
    {
        name: "charge_category",
        displayName: "Charge category",
    },
    {
        name: "description",
        displayName: "Description",
    },
    {
        name: "amount",
        displayName: "Amount",
    },
    {
        name: "currency",
        displayName: "Currency",
    },
    {
        name: "start_of_billing_cycle",
        displayName: "Start of billing cycle",
    },
    {
        name: "end_of_billing_cycle",
        displayName: "End of billing cycle",
    },
    {
        name: "date",
        displayName: "Date",
    },
    {
        name: "order",
        displayName: "Order",
    },
    {
        name: "rate",
        displayName: "Rate",
    },
    {
        displayName: "App",
        name: "app"
    },
    {
        displayName: "Original amount",
        name: "original_amount"
    },
    {
        displayName: "Original currency",
        name: "original_currency"
    },
    {
        displayName: "Exchange rate",
        name: "exchange_rate"
    }
]
const customCostColumns = [
        {
            displayName: "Name",
            name: "name"
        },
        {
            displayName: "Type of expense",
            name: "type_of_expense"
        },
        {
            displayName: "Frequency",
            name: "frequency"
        },
        {
            displayName: "Value",
            name: "value",
            valueCannotBeBlank: true,
            validationFormat: { type: "string" },
        },
        {
            displayName: "Start date",
            name: "start_date"
        },
        {
            displayName: "End date",
            name: "end_date"
        },
        {
            displayName: "Category",
            name: "category"
        }
]
const shippingCostColumns = [
        {
            displayName: "Order Id",
            name: "order_name",
            valueCannotBeBlank: true,
            validationFormat: { type: "string" },
        },
        {
            displayName: "Shipping cost in store currency",
            name: "shipping_cost"
        }
]

const LoadShopifyAndAppsImporter = () => {
    useCsvOptions.importerKey = "bae388d7-0ff6-4fb1-8c50-3c0c30e07a6b"

        useCsvOptions.onRecordsInitial = ({uploadId, fileName, importerId, batch, user, metadata, rows }) => {
            const arr = [];
            rows.forEach((row) => {
                const data = validateShopifyAndApp(
                    row.data.amount.value,
                    row.data.charge_category.value,
                    row.data.original_amount.value,
                );

                if (!!data) {
                    arr.push({ row: row.row, data });
                }
            });
            if (arr.length > 0) {
                return arr;
            }
            return;
        }

        useCsvOptions.onRecordEdit = ({ uploadId, fileName, importerId, user, metadata, row }) => {
            const data = validateShopifyAndApp(
                row.data.amount.value,
                row.data.charge_category.value,
                row.data.original_amount.value,
            );
            if (!!data) {
                return { row: row.row, data };
            }
            return;
        }

        useCsvOptions.dynamicColumns = shopifyAndAppsColumns;
}

const LoadCustomCostImporter = () => {
    useCsvOptions.dynamicColumns = customCostColumns
        useCsvOptions.importerKey = "424cc199-1c81-4a66-97f2-e4f96abd0c9d"

        useCsvOptions.onRecordsInitial = ({uploadId, fileName, importerId, batch, user, metadata, rows }) => {
            const arr = [];
            rows.forEach((row) => {
                const data = validateCustomCost(
                    row.data.category.value,
                    row.data.type_of_expense.value,
                    row.data.value.value,
                );
                if (!!data) {
                    arr.push({ row: row.row, data });
                }
            });
            if (arr.length > 0) {
                return arr;
            }
            return;
        }

        useCsvOptions.onRecordEdit = ({ uploadId, fileName, importerId, user, metadata, row }) => {
            const data = validateCustomCost(
                row.data.category.value,
                row.data.type_of_expense.value,
                row.data.value.value,
            );
            if (!!data) {
                return { row: row.row, data };
            }
            return;
        }
}

const LoadProductCostImporter = () => {
    useCsvOptions.importerKey = "fa8fb00b-95f4-4a23-b779-c108b388ec6b"

    useCsvOptions.onRecordsInitial = ({uploadId, fileName, importerId, batch, user, metadata, rows }) => {
        const arr = [];
        rows.forEach((row) => {
            const data = validateProductCost(
                row.data.sku.value,
                row.data.product_cost.value,
                row.data.shipping_cost.value,
            );
            if (!!data) {
                arr.push({ row: row.row, data });
            }
        });
        if (arr.length > 0) {
            return arr;
        }
        return;
    }

    useCsvOptions.onRecordEdit = ({ uploadId, fileName, importerId, user, metadata, row }) => {
        const data = validateProductCost(
            row.data.sku.value,
            row.data.product_cost.value,
            row.data.shipping_cost.value
        );
        if (!!data) {
            return { row: row.row, data };
        }
        return;
    }

    useCsvOptions.dynamicColumns = productCostColumns
}

const LoadShippingCostImporter = () => {
    useCsvOptions.dynamicColumns = shippingCostColumns;
    useCsvOptions.importerKey = "63df85be-3b81-49d7-a142-a6f7e5af5598"

    useCsvOptions.onRecordsInitial = ({uploadId, fileName, importerId, batch, user, metadata, rows }) => {
        const arr = [];
        rows.forEach((row) => {
            const data = validateProductCost(
                row.data.sku.value,
                row.data.product_cost.value,
                row.data.shipping_cost.value,
            );
            if (!!data) {
                arr.push({ row: row.row, data });
            }
        });
        if (arr.length > 0) {
            return arr;
        }
        return;
    }

    useCsvOptions.onRecordEdit = ({ uploadId, fileName, importerId, user, metadata, row }) => {
        const data = validateShippingCost(
            row.data.shipping_cost.value,
        );
        if (!!data) {
            return { row: row.row, data };
        }
        return;
    }
}

const importerLoaders = {
    'shopify_and_apps': LoadShopifyAndAppsImporter,
    'custom_cost': LoadCustomCostImporter,
    'product_cost': LoadProductCostImporter,
    'shipping_cost': LoadShippingCostImporter
};

export function initCSVImporter() {
    useCsvOptions.importerDisplay = "modal"

    $(".js-import-csv-button").click(function() {
        // For each button on page (in case of multiples)
        const shopDomain = $(this).data('shop-domain');
        const postURL = $(this).data('post-url');
        const importerType = $(this).data('importer-type');
        const refreshTableUrl = $(this).data('refresh-table-url');
        const refreshPageAfterImport = $(this).data('refresh-page-after-import');

        if (importerLoaders[importerType]) {
            importerLoaders[importerType]();
        } else {
            console.log('Invalid importer type:', importerType);
        }

        useCsvOptions.user =  { userId: shopDomain }
        useCsvOptions.metadata = { shopDomain: shopDomain }
        useCsvOptions.onData = (data) => {
            data.importer_type = importerType
            $.post({url: postURL, data: data})
            .then(res => {
                // Reload table
                if (refreshTableUrl) {
                    $.post({
                        url: refreshTableUrl,
                    });
                } else if (refreshPageAfterImport) {
                    document.location.reload(true);
                }
            })
            .catch(error => {
                console.log(error)
            });
        }

        useCsvOptions.onClose = () => {
            if (refreshTableUrl) {
                $.post({
                    url: refreshTableUrl,
                });
            } else if (refreshPageAfterImport) {
                document.location.reload(true);
            }
        }


        useCsvPlugin(useCsvOptions)

    });
    
}
