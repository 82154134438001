import { injectSkeletons, blockControls } from "./skeleton_helper";

const prepareTurbolinksCachedInitialContent = () => {
    // Get active links, prepare cache with these too so that the active link transition doesn't look glitchy on-click
    const initialActiveLinks = $('a.navbar-object.active, a.navbar-object-nested.active');

    // Turbolinks caches a page right before you leave it.
    // Then when you re-visit this page, it instantaneously and temporarily shows
    // this cache while it loads the real-uncached version of the page.
    // On before-cache, prepare the cached preview for the next time the user
    // visits this page.
    document.addEventListener("turbo:before-cache", () => {
        hideTurbolinksSpinner();
        $('[data-remove-from-turbolinks-cache], .turbolinks-spinner-container').remove();

        // Make sure only the initial active links have the 'active' class,
        // code in left_main_menu.js makes is transition to the clicked destination's link
        $('a.navbar-object, a.navbar-object-nested').removeClass('active');
        initialActiveLinks.addClass('active');

        // Disable metrics and options menu
        blockControls();
        // Inject skeletons
        injectSkeletons();
    }, { once: true });
}
const turbolinksReloadOnRestorationVisits = () => {
    // By default, for restoration visits ('back' or 'go forward' in browser history), Turbolinks
    // only shows the latest cache of the page and doesn't actually reload it.
    // This won't work for us because we 'prepare' our caches by making sure it shows the reports' skeletons.
    // So we need to ask turbolinks to reload the page and not just use the cache.
    requestAnimationFrame(function() {
        Turbo.visit(window.location.toString(), { action: 'replace' })
    })
}

const showTurbolinksSpinner = () => {
    $('.main-content, footer, .site-header').css('display', 'none');
    const mainTag = $('main');
    mainTag.addClass("has-turbolinks-spinner");
    mainTag.append("<div class='turbolinks-spinner-container'><span class='turbolinks-spinner'></span></div>");
}

const hideTurbolinksSpinner = () => {
    $('.main-content, footer, .site-header').css('display', 'block');
    const mainTag = $('main');
    mainTag.removeClass("has-turbolinks-spinner");
    mainTag.remove(".turbolinks-spinner");
}

export {
    prepareTurbolinksCachedInitialContent,
    turbolinksReloadOnRestorationVisits,
    showTurbolinksSpinner
};
