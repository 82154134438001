import { Controller } from "@hotwired/stimulus"
import { unblockControls} from "../skeleton_helper";

// Connects to data-controller="lifetime-value-report"
export default class extends Controller {
  static values = {
    exportUrl: String,
    cohortSelectContainerHtml: String,
    compareCohortsRowSkeletonHtml: String,
    params: String
  }

  connect() {
    window.load_chart.predictionsRevenueChart();
    window.load_chart.basicUserDisabledPredictedRevenueChart();
    window.lifetime_value.initPredictiveLTVToggleBtn();
    window.lifetime_value.initCACPaybackLTVToggleBtn();
    window.lifetime_value.initToggleSpaghettiGraphBtn();
    window.lifetime_value.refreshTableCellData();
    window.lifetime_value.initHoverCards();

    if (this.hasCohortSelectContainerHtmlValue && this.hasCompareCohortsRowSkeletonHtmlValue) {
      const cohortSelectContainerHTML = this.cohortSelectContainerHtmlValue
      const rowSkeletonHTML = this.compareCohortsRowSkeletonHtmlValue

      window.lifetime_compare_cohorts.initAddCohortButton(cohortSelectContainerHTML, rowSkeletonHTML);
      window.lifetime_compare_cohorts.updateControlsStates();
      document.querySelector("input[name=\"compare_cohorts\"]").removeAttribute('data-timeperiod-or-timeframe-updated');
    }

    // Update CSV export href
    const exportBtn = document.getElementById('export-btn')
    const exportUrl = this.exportUrlValue
    if (exportBtn && exportUrl) {
      exportBtn.href = exportUrl
    }

    const reportParams = this.paramsValue
    if (exportBtn && reportParams) {
      $('#export-btn').data('body', reportParams)
    }

    const chartElement = this.element.querySelector('#basic-spaghetti-diagram')
    if (chartElement) {
      window.load_chart.ltvSpaghettiChart(chartElement);
    }

    unblockControls();
  }
}