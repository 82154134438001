import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="select2-blue"
export default class extends Controller {
  static values = {
    search: Boolean,
    ajaxUrl: String,
    disabled: Boolean,
    preselected: Array,
    dropdownAutoWidth: Boolean
  }

  connect() {
    const selectElement = this.element;
    const searchValue = (this.searchValue) ? 5 : -1
    const isMultiple = this.element.getAttribute('multiple') === 'multiple'
    const preselectedValues = this.preselectedValue

    const options = {
      minimumResultsForSearch: searchValue,
      containerCssClass: ['tailwind-select2-wrapper', 'tailwind-blue-small-select2-wrapper'],
      dropdownCssClass: ['tailwind-blue-small-dropdown-select2-wrapper'],
      allowClear: isMultiple,
      closeOnSelect: !isMultiple,
      dropdownAutoWidth: this.dropdownAutoWidthValue
    }

    if (this.element.id.includes('operator')) {
      addTemplateResultToOptions(options)
    }

    if (this.hasAjaxUrlValue) {
      addAjaxToOptions(options, this.ajaxUrlValue)
    }

    $(selectElement).select2(options);

    if (this.disabledValue) {
      $(selectElement).prop('disabled',true);
    }

    if (preselectedValues) {
      insertPreselectedValues(selectElement, preselectedValues)
    }
  }
}

const addAjaxToOptions = (options, ajaxUrl) => {
  options.dataType = 'json'
  options.ajax = {
    url: ajaxUrl, // '/filters?filter_type=campaigns&campaigns_type=google'
    data: function (params) {
      // Query parameters will be ?search=[term]&page=[page]
      return {
        search: params.term,
        page: params.page || 1
      };
    }
  }
}

const insertPreselectedValues = (selectElement, preselectedValues) => {
  preselectedValues.forEach((value) => {
    $(selectElement).append(new Option(value, value, false, true)).trigger('change');
  })
}

const addTemplateResultToOptions = (options) => {
  function formatOperatorState (state) {
    if (!state.id) {
      return state.text;
    }

    let formattedText;

    switch(state.id) {
      case 'equals':
        formattedText = '= equals'
        break;
      case 'does_not_equal':
        formattedText = "≠ doesn't equal"
        break;
      case 'contains':
        formattedText = $("<span><span class='text-threatening-red'>**</span> contains</span>")
        break;
      case 'does_not_contain':
        formattedText = $("<span><span class='text-threatening-red'>**</span> doesn't contain</span>")
        break;
      case 'greater_than':
        formattedText = "> greater than"
        break;
      case 'greater_than_or_equal_to':
        formattedText = "≥ greater than or equal to"
        break;
      case 'less_than':
        formattedText = "< less than"
        break;
      case 'less_than_or_equal_to':
        formattedText = "≤ less than or equal to"
        break;
      default:
        state.text
    }

    return formattedText
  }

  options.templateResult = formatOperatorState
}
