import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="filter-bootstrap-slider"
export default class extends Controller {
  static targets = ['sliderInput', 'minInput', 'maxInput']

  connect() {
    const sliderInput = this.sliderInputTarget
    const minInput = this.minInputTarget
    const maxInput = this.maxInputTarget

    const slider = $(sliderInput).bootstrapSlider()

    slider.on('slideStop', (event) => {
      let value = sliderInput.value
      value = value.split(",").map(Number)
      minInput.value = value[0]
      maxInput.value = value[1]
      toggleDisabledState(sliderInput)
    });

    [minInput, maxInput].forEach((textInput) => {
      textInput.addEventListener('keyup', (event) => {
        let minValue = minInput.value
        let maxValue = maxInput.value
        if (!isNaN(minValue) && !isNaN(maxValue)) {
          let sliderValue = [minValue, maxValue]
          sliderInput.value = sliderValue
          slider.bootstrapSlider('setValue', sliderValue.map(Number))
          toggleDisabledState(sliderInput)
        }
      })
    })
  }
}

const toggleDisabledState = (sliderInput) => {
  // When values are updated, check to see if the default_min value matches the selected min,
  // and also if the default_max value matches the selected max. If the values match, then the user has
  // not moved the slider at all, and we can disabled the input in order to ignore this filter on the back-end.
  // When we disabled it, we avoid sending it as a param. We want to do this when default values remain selected
  // because it would be useless to filter by the default min/max values, as this would filter nothing (all orders
  // would match these conditions.

  const defaultMin = Number(sliderInput.dataset.sliderMin)
  const defaultMax = Number(sliderInput.dataset.sliderMax)

  let selectedValues = sliderInput.value
  selectedValues = selectedValues.split(",").map(Number)
  const min = selectedValues[0]
  const max = selectedValues[1]

  if (defaultMin === min && defaultMax === max) {
    sliderInput.setAttribute('disabled', true)
  } else {
    sliderInput.removeAttribute('disabled')
  }
}