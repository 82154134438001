const initDisabledRanges = () => {
    const lifetimelyPlanMetaTag = document.head.querySelector('[name="lifetimely-plan"]');
    if (lifetimelyPlanMetaTag === null) {
        return;
    }

    const shopHasFreePlan = lifetimelyPlanMetaTag.content.includes('Free')
    if (!shopHasFreePlan) {
        return;
    }

    const disabledRangesSelector =  'li[data-range-key="Last 180 Days"], ' +
                                    'li[data-range-key="Last 365 Days"], ' +
                                    'li[data-range-key="Last Month"], ' +
                                    'li[data-range-key="Last 6 Months"], ' +
                                    'li[data-range-key="This Quarter"], ' +
                                    'li[data-range-key="Last Quarter"], ' +
                                    'li[data-range-key="This Year"], ' +
                                    'li[data-range-key="Last Year"]'

    const disabledRanges = document.querySelectorAll(disabledRangesSelector)

    disabledRanges.forEach((rangeElement) => {
        rangeElement.classList.add('disabled')
        rangeElement.title = "Upgrade to use data older than 30 days"

        // We can't disable the range, but we can change the value to 'null' - if they click it it throws a JS error and doesn't do anything.
        rangeElement.setAttribute('data-range-key', null)

        // Add Stimulus controller label so that modal opens on click
        rangeElement.setAttribute('data-controller','upgrade-plan-modal')
    })
}

export { initDisabledRanges };