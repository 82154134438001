import {triggerChangeAllCohortSelects} from "./lifetime_compare_cohorts";
import {updatePredictiveAndCACFormInputs} from "./lifetime_value";

const cb = (start, end, datePickerIdentifier) => {
    $(`#${datePickerIdentifier} span`).html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
}

const getYesterdayDate = (timezone = null) => {
    const date = new Date();
    if (timezone) {
        return moment(date).tz(timezone).subtract(1, 'day').format("MM/DD/YYYY")
    }else {
        return moment(date).subtract(1, 'day').format("MM/DD/YYYY")
    }
}

const setUpPicker = (datePickerIdentifier, lastMonthsRanges, hasSkeleton = false, setmaxDateYesterday = false, has_sidedrawer_filters = true) => {

    var dateRangePickerElement = $(`#${datePickerIdentifier}`);

    var start_ltv = dateRangePickerElement.attr('data-date-start');
    var end_ltv = dateRangePickerElement.attr('data-date-end');
    var timeZone = dateRangePickerElement.attr('data-shop-timezone');

    var rangesHash = {};

    for (var i = 0; i < lastMonthsRanges.length; i++) {
        var num = lastMonthsRanges[i];
        // create picker range hash from array of digits
        if(timeZone){
            rangesHash[`Last ${num} months`] = [(moment().tz(timeZone).subtract(1, 'days')).subtract(num, 'months'), moment().tz(timeZone).subtract(1, 'days')];
        }else{
            rangesHash[`Last ${num} months`] = [(moment().subtract(1, 'days')).subtract(num, 'months'), moment().subtract(1, 'days')];
        }
    }

    var dateRangePickerConfig = {
        startDate: start_ltv,
        endDate: end_ltv,
        alwaysShowCalendars: true,
        autoApply: true,
        showDropdowns: true,
        linkedCalendars: false,
        ranges: rangesHash
    }

    if (setmaxDateYesterday) {
        dateRangePickerConfig["maxDate"] = getYesterdayDate(timeZone)
    } else {
        dateRangePickerConfig["maxDate"] = new Date() // Max date = today
    }

    dateRangePickerElement.daterangepicker(dateRangePickerConfig);

    if(timeZone){
        cb(moment(start_ltv, "MM/DD/YYYY").tz(timeZone), moment(end_ltv, "MM/DD/YYYY").tz(timeZone), datePickerIdentifier);
    }else{
        cb(moment(start_ltv, "MM/DD/YYYY"), moment(end_ltv, "MM/DD/YYYY"), datePickerIdentifier);
    }

    setUpEventListeners(dateRangePickerElement, datePickerIdentifier)
};

const setUpDateRangePicker = (datePickerIdentifier, setmaxDateYesterday = false, hasSkeleton = false, ranges = {}) => {
    var dateRangePickerElement = $(`#${datePickerIdentifier}`);
    var start = dateRangePickerElement.attr('data-date-start');

    var end = dateRangePickerElement.attr('data-date-end');
    var timeZone = dateRangePickerElement.attr('data-shop-timezone');

    // If ranges aren't passed as an argument, use default ranges
    if ($.isEmptyObject(ranges)) {
        if (timeZone){
            ranges = {
                'Today': [moment().tz(timeZone), moment().tz(timeZone)],
                'Yesterday': [moment().tz(timeZone).subtract(1, 'days'), moment().tz(timeZone).subtract(1, 'days')],
                'Last 7 Days': [moment().tz(timeZone).subtract(6, 'days'), moment().tz(timeZone)],
                'Last 30 Days': [moment().tz(timeZone).subtract(29, 'days'), moment().tz(timeZone)],
                'Last 180 Days': [moment().tz(timeZone).subtract(180, 'days'), moment().tz(timeZone)],
                'Last 365 Days': [moment().tz(timeZone).subtract(365, 'days'), moment().tz(timeZone)],
                'This Month': [moment().tz(timeZone).startOf('month'), moment().tz(timeZone).subtract(1, 'day')],
                'Last Month': [moment().tz(timeZone).subtract(1, 'month').startOf('month'), moment().tz(timeZone).subtract(1, 'month').endOf('month')]
            }
        }else {
            ranges = {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'Last 180 Days': [moment().subtract(180, 'days'), moment()],
                'Last 365 Days': [moment().subtract(365, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().subtract(1, 'day')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            }
        }
    }

    var dateRangePickerConfig = {
        startDate: start,
        endDate: end,
        alwaysShowCalendars: true,
        autoApply: true,
        showDropdowns: true,
        linkedCalendars: false,
        ranges: ranges
    }

    if (setmaxDateYesterday) {
        dateRangePickerConfig["maxDate"] = getYesterdayDate(timeZone)
    } else {
        dateRangePickerConfig["maxDate"] = new Date() // Max date = today
    }

    dateRangePickerElement.daterangepicker(dateRangePickerConfig);
    if (timeZone){
        cb(moment(start, "MM/DD/YYYY").tz(timeZone), moment(end, "MM/DD/YYYY").tz(timeZone), datePickerIdentifier);
    }else {
        cb(moment(start, "MM/DD/YYYY"), moment(end, "MM/DD/YYYY"), datePickerIdentifier);
    }

    setUpEventListeners(dateRangePickerElement, datePickerIdentifier);
}

const setUpEventListeners = (dateRangePickerElement, datePickerIdentifier) => {
    dateRangePickerElement.on('apply.daterangepicker', function (ev, picker) {

        // Prioritize the input's 'form' attribute if it exists
        let form;
        const formAttribute = dateRangePickerElement.attr('form')
        if (formAttribute) {
            form = document.getElementById(formAttribute)
        } else {
            form = document.getElementById(datePickerIdentifier).closest('form')
        }

        form.querySelector("input[name='date_from']").value = picker.startDate.format('YYYY-MM-DD')
        form.querySelector("input[name='date_to']").value = picker.endDate.format('YYYY-MM-DD')

        form.requestSubmit()

        skeleton_helper.blockControls();
        const turboFrameIdentifier = form.dataset.turboFrame
        skeleton_helper.injectSkeletons([turboFrameIdentifier]);

        cb(moment(picker.startDate.format('YYYY-MM-DD')), moment(picker.endDate.format('YYYY-MM-DD')), datePickerIdentifier);

    });

    dateRangePickerElement.on("keydown", function(e){
        if (e.which == 13) {
            $('.daterangepicker button.applyBtn').click();
        }
    });
}

// LTV picker
const setUpYearlyPicker = (datePickerIdentifier, yearSteps, hasSkeleton = false, setmaxDateYesterday = false, hasCompareCohorts = false) => {

    var dateRangePickerElement = $(`#${datePickerIdentifier}`);
    var startYear = moment().format('YYYY');
    var start_ltv = dateRangePickerElement.attr('data-date-start');
    var end_ltv = dateRangePickerElement.attr('data-date-end');
    var rangesHash = {};
    var timeZone = dateRangePickerElement.attr('data-shop-timezone');

    if (timeZone){
        rangesHash["Last 6 months"] = [moment().subtract(6, 'months').startOf('month'), moment().tz(timeZone).subtract(1, 'days')];
    }else{
        rangesHash["Last 6 months"] = [moment().subtract(6, 'months').startOf('month'), moment().subtract(1, 'days')];
    }

    for (var i = 0; i < yearSteps; i++) {
        var year = startYear - i
        if(timeZone){
            rangesHash[`Since ${year}`] = [moment([year]).startOf('year'), moment().tz(timeZone).subtract(1, 'days')];
        }else{
            rangesHash[`Since ${year}`] = [moment([year]).startOf('year'), moment().subtract(1, 'days')];
        }
    }

    var dateRangePickerConfig = {
        startDate: start_ltv,
        endDate: end_ltv,
        alwaysShowCalendars: true,
        autoApply: true,
        showDropdowns: true,
        linkedCalendars: false,
        ranges: rangesHash
    }

    if (setmaxDateYesterday) {
        dateRangePickerConfig["maxDate"] = getYesterdayDate(timeZone)
    } else {
        dateRangePickerConfig["maxDate"] = new Date() // Max date = today
    }

    dateRangePickerElement.daterangepicker(dateRangePickerConfig);

    if (timeZone){
        cb(moment(start_ltv, "MM/DD/YYYY").tz(timeZone), moment(end_ltv, "MM/DD/YYYY").tz(timeZone), datePickerIdentifier);
    }else{
        cb(moment(start_ltv, "MM/DD/YYYY"), moment(end_ltv, "MM/DD/YYYY"), datePickerIdentifier);
    }

    dateRangePickerElement.on('apply.daterangepicker', function (ev, picker) {

        const compareCohortsCheckbox = $('input[name="compare_cohorts"]');
        const compareCohortsChecked = compareCohortsCheckbox.is(':checked');

        if (hasCompareCohorts && compareCohortsChecked) {
            triggerChangeAllCohortSelects();
            compareCohortsCheckbox.attr('data-timeperiod-or-timeframe-updated', true)
        } else {
            // Prioritize the input's 'form' attribute if it exists
            let form;
            const formAttribute = dateRangePickerElement.attr('form')
            if (formAttribute) {
                form = document.getElementById(formAttribute)
            } else {
                form = document.getElementById(datePickerIdentifier).closest('form')
            }

            form.querySelector("input[name='date_from']").value = picker.startDate.format('YYYY-MM-DD')
            form.querySelector("input[name='date_to']").value = picker.endDate.format('YYYY-MM-DD')
            updatePredictiveAndCACFormInputs(form);

            form.requestSubmit()

            skeleton_helper.blockControls();
            const turboFrameIdentifier = form.dataset.turboFrame
            skeleton_helper.injectSkeletons([turboFrameIdentifier]);

        }

        cb(moment(picker.startDate.format('YYYY-MM-DD')), moment(picker.endDate.format('YYYY-MM-DD')), datePickerIdentifier);

    });

    dateRangePickerElement.on("keydown", function(e){
        if (e.which == 13) {
            $('.daterangepicker button.applyBtn').click();
        }
    });
};

export { setUpPicker, setUpDateRangePicker, setUpYearlyPicker };