import { Controller } from "@hotwired/stimulus"
import { setUpPicker, setUpDateRangePicker, setUpYearlyPicker } from "../stimulus_setup_datepicker";
import { initDisabledRanges } from "../free_plan_datepicker"

const moment = require('moment-timezone');

// Connects to data-controller="datepicker"
export default class extends Controller {
  static values = {
    type: String,
    ranges: Array
  }

  connect() {
    const datePickerType = this.typeValue
    const datePickerIdentifier = this.element.id
    const ranges = this.rangesValue

    if (datePickerType === 'default') {
      setUpPicker(datePickerIdentifier, ranges, true, true);
    } else if (datePickerType === 'dashboard') {
      const ranges = buildDashboardRanges(datePickerIdentifier)
      setUpDateRangePicker(datePickerIdentifier, false, true, ranges);
    } else if (datePickerType === 'attribution') {
      const ranges = buildAttributionRanges(datePickerIdentifier)
      setUpDateRangePicker(datePickerIdentifier, false, true, ranges);
    } else if (datePickerType === 'monthly') {
      // setUpMonthlyPicker
    } else if (datePickerType === 'yearly') {
      setUpYearlyPicker(datePickerIdentifier, 6, true, true, true);
    }

    initDisabledRanges();

  }
}

const buildDashboardRanges = (datePickerIdentifier) => {
  // set up income statement
  const timeZone = $(`#${datePickerIdentifier}`).attr('data-shop-timezone');
  let incomeStatementRanges;
  if (timeZone) {
    incomeStatementRanges = {
      'Today': [moment().tz(timeZone), moment().tz(timeZone)],
      'Yesterday': [moment().tz(timeZone).subtract(1, 'days'), moment().tz(timeZone).subtract(1, 'days')],
      'Last 7 Days': [moment().tz(timeZone).subtract(7, 'days'), moment().tz(timeZone).subtract(1, 'days')],
      'Last 30 Days': [moment().tz(timeZone).subtract(30, 'days'), moment().tz(timeZone).subtract(1, 'days')],
      'Last 365 Days': [moment().tz(timeZone).subtract(365, 'days'), moment().tz(timeZone)],
      'This Month': [moment().tz(timeZone).startOf('month'), moment()],
      'Last Month': [moment().tz(timeZone).subtract(1, 'month').startOf('month'), moment().tz(timeZone).subtract(1, 'month').endOf('month')],
      'Last 6 Months': [moment().tz(timeZone).subtract(6, 'month').startOf('month'), moment().tz(timeZone)],
      'This Quarter': [moment().tz(timeZone).startOf('quarter'), moment().tz(timeZone)],
      'Last Quarter': [moment().tz(timeZone).subtract(1, 'Q').startOf('quarter'), moment().tz(timeZone).startOf('quarter').subtract(1, 'day')],
      'This Year': [moment().tz(timeZone).startOf('year'), moment().tz(timeZone)],
      'Last Year': [moment().tz(timeZone).subtract(1, 'year').startOf('year'), moment().tz(timeZone).subtract(1, 'year').endOf('year')]
    }
  }else {
    incomeStatementRanges = {
      'Today': [moment(), moment()],
      'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(7, 'days'), moment().subtract(1, 'days')],
      'Last 30 Days': [moment().subtract(30, 'days'), moment().subtract(1, 'days')],
      'Last 365 Days': [moment().subtract(365, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment()],
      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
      'Last 6 Months': [moment().subtract(6, 'month').startOf('month'), moment()],
      'This Quarter': [moment().startOf('quarter'), moment()],
      'Last Quarter': [moment().subtract(1, 'Q').startOf('quarter'), moment().startOf('quarter').subtract(1, 'day')],
      'This Year': [moment().startOf('year'), moment()],
      'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
    }
  }

  return incomeStatementRanges
}

const buildAttributionRanges = (datePickerIdentifier) => {
  // set up income statement
  const timeZone = $(`#${datePickerIdentifier}`).attr('data-shop-timezone');
  let attributionRanges;
  if (timeZone) {
    attributionRanges = {
      'Last 7 Days': [moment().tz(timeZone).subtract(7, 'days'), moment().tz(timeZone).subtract(1, 'days')],
      'Last 7 Weeks': [moment().tz(timeZone).subtract(7, 'weeks'), moment().tz(timeZone).subtract(1, 'days')],
      'This Month': [moment().tz(timeZone).startOf('month'), moment()],
      'Last Month': [moment().tz(timeZone).subtract(1, 'month').startOf('month'), moment().tz(timeZone).subtract(1, 'month').endOf('month')],
      'Last 6 Months': [moment().tz(timeZone).subtract(6, 'month').startOf('month'), moment().tz(timeZone)],
      'This Quarter': [moment().tz(timeZone).startOf('quarter'), moment().tz(timeZone)],
      'This Year': [moment().tz(timeZone).startOf('year'), moment().tz(timeZone)],
    }
  }else {
    attributionRanges = {
      'Last 7 Days': [moment().subtract(7, 'days'), moment().subtract(1, 'days')],
      'Last 7 Weeks': [moment().subtract(7, 'weeks'), moment().subtract(1, 'days')],
      'This Month': [moment().startOf('month'), moment()],
      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
      'Last 6 Months': [moment().subtract(6, 'month').startOf('month'), moment()],
      'This Quarter': [moment().startOf('quarter'), moment()],
      'This Year': [moment().startOf('year'), moment()],
    }
  }

  return attributionRanges
}