import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="complex-filter-card"
export default class extends Controller {
  static values = {
    kind: String
  }

  static targets = ['complexFilterRulesWrapper', 'addRuleButton', 'removeRuleButton']

  connect() {
    const complexFilterRulesWrapper = this.complexFilterRulesWrapperTarget
    const addRuleButton = this.addRuleButtonTarget
    const removeRuleButton = this.removeRuleButtonTarget

    const getComplexFilterRuleCount = () => {
      return complexFilterRulesWrapper.querySelectorAll('.complex-filter-rule').length;
    }

    const hideAddRuleButtonIfMaxRules = (filterRuleCount) => {
      // Right now the max rule count per filter is 4. Just because we don't want endless rules,
      // and CH queries timeout after 60 seconds, so not we can handle many filters with 4+ rules.
      if (filterRuleCount && filterRuleCount >= 4) {
        addRuleButton.classList.add('invisible')
      } else {
        addRuleButton.classList.remove('invisible')
      }
    }

    addRuleButton.addEventListener('click', (event) => {
      const currentHref = addRuleButton.href
      const currentHrefSplit = currentHref.split('/')

      const currentFilterIndex = parseInt(currentHrefSplit.slice(-1))
      const nextFilterIndex = currentFilterIndex + 1

      const nextHrefSplit = [...currentHrefSplit]
      nextHrefSplit[nextHrefSplit.length - 1] = nextFilterIndex
      addRuleButton.href = nextHrefSplit.join('/')

      complexFilterRulesWrapper.classList.add('loading')

      const complexFilterRuleCount = getComplexFilterRuleCount()
      hideAddRuleButtonIfMaxRules(complexFilterRuleCount + 1)
    })

    removeRuleButton.addEventListener('click', (event) => {
      const complexFilterRuleCount = getComplexFilterRuleCount()

      if (complexFilterRuleCount > 1) {
        const lastRuleElement = complexFilterRulesWrapper.querySelectorAll('.complex-filter-rule')[complexFilterRuleCount - 1]
        lastRuleElement.remove()
      }

      // Hide 'Remove' button there is only going to be 1 rule left (after remove)
      if (complexFilterRuleCount <= 2) {
        removeRuleButton.classList.add('hidden')
      } else {
        removeRuleButton.classList.remove('hidden')
      }

      hideAddRuleButtonIfMaxRules(complexFilterRuleCount - 1)
    })
  }

}