import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="button-spinner-on-click"
export default class extends Controller {
  connect() {
    const btn = this.element

    btn.addEventListener('click', () => {
      // Don't add spinner if button/link opens in new tab.
      const btnOpensInNewTab = btn.target === '_blank'

      if (btn.tagName === 'BUTTON' && btn.getAttribute('type') === 'submit') {
        // Check form validity before showing spinner
        // prioritize button "form" attribute and then look for closest form
        let form;
        const formId = btn.getAttribute('form')
        if (formId) {
          form = document.getElementById(formId)
        }
        if (form === null || form === undefined) {
          form = btn.closest('form')
        }
        if (form) {
          if (form.checkValidity() === true) {
            btn.setAttribute('loading', '')
          }
        } else {
          btn.setAttribute('loading', '')
        }
      } else if (!btnOpensInNewTab) {
        btn.setAttribute('loading', '')
      }
    })
  }
}
