const init = () => {
    // Show "begin" overlay when we first start scrolling
    // Hide "end" overlay when we scroll fully to the end
    const scrollableOuterContainers = $('.js-lt-scrollable-container'); // must be flex
    scrollableOuterContainers.each(function() {

        const scrollableOuterContainer = $(this);
        // Get first child or explicitly given selector
        const scrollableChildSelector = scrollableOuterContainer.attr('data-lt-scrollable-child') || '>:first-child';
        // Get explicitly given direction or default to 'vertical'
        const scrollDirection = scrollableOuterContainer.attr('data-lt-scrollable-direction') || 'vertical';
        // Get explicitly given selector or default selector
        const endScrollOverlayElementSelector = scrollableOuterContainer.attr('data-lt-scrollable-end-overlay') || '.end-scroll-fade-overlay';
        // Get explicitly given selector or default selector
        const beginScrollOverlayElementSelector = scrollableOuterContainer.attr('data-lt-scrollable-begin-overlay') || '.begin-scroll-fade-overlay';

        const scrollableChild = scrollableOuterContainer.find(scrollableChildSelector);
        const endScrollOverlayElement = $(endScrollOverlayElementSelector);
        const beginScrollOverlayElement = $(beginScrollOverlayElementSelector);

        scrollToActiveItem(scrollDirection, scrollableOuterContainer, scrollableChild, beginScrollOverlayElement);

        if ( scrollDirection === 'vertical' ) {
            scrollableOuterContainer.scroll(function () {
                let amountScrolled = scrollableOuterContainer.scrollTop()
                let scrollDistancePlusWidth = amountScrolled + scrollableOuterContainer.height();

                if ( amountScrolled === 0 ) {
                    beginScrollOverlayElement.removeClass('began-scrolling');
                }
                else if ((scrollDistancePlusWidth + 1) >= scrollableChild.outerHeight(true)) {
                    endScrollOverlayElement.addClass('fully-scrolled');
                } else {
                    endScrollOverlayElement.removeClass('fully-scrolled');
                    beginScrollOverlayElement.addClass('began-scrolling');
                }
            })
        } else {
            // horizontal
            scrollableOuterContainer.scroll(function () {
                let amountScrolled = scrollableOuterContainer.scrollLeft()
                let scrollDistancePlusWidth = amountScrolled + scrollableOuterContainer.width();

                if ( amountScrolled === 0 ) {
                    beginScrollOverlayElement.removeClass('began-scrolling');
                }
                else if ((scrollDistancePlusWidth + 1) >= scrollableChild.outerWidth(true)) {
                    endScrollOverlayElement.addClass('fully-scrolled');
                } else {
                    endScrollOverlayElement.removeClass('fully-scrolled');
                    beginScrollOverlayElement.addClass('began-scrolling');
                }
            })
        }

    });
}

const scrollToActiveItem = (scrollDirection, scrollableOuterContainer, scrollableChild, beginScrollOverlayElement) => {
    // 'Dimensions' could be height or width
    const visibleDimensions = (scrollDirection === 'vertical') ? scrollableOuterContainer.height()  : scrollableOuterContainer.width();
    const items = scrollableChild.find('.navbar-object, .navbar-object-nested, .settings-tab');

    // Try to return early if scrollable container isn't visible or if nothing is active in menu
    if ( visibleDimensions === 0 || !items.hasClass('active') ) {
        return false;
    }

    let sumDimensions = 0
    let activeItemDimensions = 0

    // Add up dimensions (heights/widths) of items in nav until we hit the active one,
    // then see if the active one is in view - if not, then scroll to it.
    items.each(function() {
        let currentItem = $(this);
        let currentItemOuterDimensions = (scrollDirection === 'vertical') ? currentItem.outerHeight(true) : currentItem.outerWidth(true);
        sumDimensions = sumDimensions + currentItemOuterDimensions
        if ( currentItem.hasClass('active') ) {
            activeItemDimensions = currentItemOuterDimensions;
            return false;
        }
    });

    if (visibleDimensions < (sumDimensions + activeItemDimensions)) {
        if (scrollDirection === 'vertical') {
            scrollableOuterContainer.scrollTop(sumDimensions - activeItemDimensions);
        } else {
            scrollableOuterContainer.scrollLeft(sumDimensions - activeItemDimensions);
        }
        beginScrollOverlayElement.addClass('began-scrolling');
    }
}

module.exports = {
    init: init
};