import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="checkbox-select-dropdown"
export default class extends Controller {
  static values = {
    refreshReportUrl: String,
    items: Array
  }

  static targets = ['toggleBtn', 'select', 'selectedNodesTable', 'search','allNodesTable', 'applyBtn', 'cancelBtn']

  connect() {
    initCheckboxSelectDropdown(this)
  }

  disconnect() {
    document.removeEventListener("click", this.handleClickOutsideCheckboxListener)
  }

  handleClickOutsideCheckbox(checkboxSelectDropdownContainer, event) {
    if (checkboxSelectDropdownContainer.classList.contains('active') && !checkboxSelectDropdownContainer.contains(event.target)) {
      checkboxSelectDropdownContainer.classList.remove('active')
    }
  }

  clearBtnAction() {
    const checkboxSelectDropdownController = this
    const selectedNodesTable = checkboxSelectDropdownController.selectedNodesTableTarget
    const allNodesTable = checkboxSelectDropdownController.allNodesTableTarget
    const selectElement = checkboxSelectDropdownController.selectTarget
    const toggleBtn = checkboxSelectDropdownController.toggleBtnTarget

    clearEverything(checkboxSelectDropdownController)
    redrawSelectedValuesTableAndSelectElement(selectedNodesTable, allNodesTable, selectElement, toggleBtn)
  }
}

const initCheckboxSelectDropdown = (checkboxSelectDropdownController) => {
  const checkboxSelectDropdownContainer = checkboxSelectDropdownController.element
  const toggleBtn = checkboxSelectDropdownController.toggleBtnTarget
  const allNodesTable = checkboxSelectDropdownController.allNodesTableTarget
  const selectedNodesTable = checkboxSelectDropdownController.selectedNodesTableTarget
  const nodesSearchInput = $(checkboxSelectDropdownController.searchTarget)
  const selectElement = checkboxSelectDropdownController.selectTarget
  const applyBtn = checkboxSelectDropdownController.applyBtnTarget
  const cancelBtn = checkboxSelectDropdownController.cancelBtnTarget
  const refreshReportUrl = checkboxSelectDropdownController.refreshReportUrlValue

  buildCheckboxSelectDatatableOptions(checkboxSelectDropdownController)
  redrawSelectedValuesTableAndSelectElement(selectedNodesTable, allNodesTable, selectElement, toggleBtn)

  toggleBtn.addEventListener('click', (event) => {
    if (checkboxSelectDropdownContainer.classList.contains('active')) {
      // Select is open, so close it
      checkboxSelectDropdownContainer.classList.remove('active')
    } else {
      // Select is closed, so open it
      checkboxSelectDropdownContainer.classList.add('active')

      nodesSearchInput.focus()
    }

    const windowHeight = (window.innerHeight || document.documentElement.clientHeight)
    const nodesContainer = checkboxSelectDropdownContainer.querySelector('.checkbox-select-nodes-container')
    const nodesContainerBottom = nodesContainer.getBoundingClientRect().bottom
    const nodesContainerHeight = nodesContainer.getBoundingClientRect().height
    const defaultNodesContainerHeight = 353
    const adjustedNodesContainerBottom = (nodesContainerHeight < defaultNodesContainerHeight) ? (nodesContainerBottom + (defaultNodesContainerHeight - nodesContainerHeight)) : nodesContainerBottom;

    if (adjustedNodesContainerBottom >= windowHeight) {
      // Container is out of view, open it above the button
      nodesContainer.classList.add('bottom-9')
    } else {
      // Container is in view, open below the button (default)
      nodesContainer.classList.remove('bottom-9')
    }
  })

  cancelBtn.addEventListener('click', (event) => {
    checkboxSelectDropdownContainer.classList.remove('active')
  })

  selectedNodesTable.querySelectorAll('tbody tr').forEach((row) => {
    // createToggleCheckmarkEventListener(row)
  })

  nodesSearchInput.on("keyup", (event) => {
    allNodesTable.querySelectorAll('tr').forEach((row) => {
      if (!row.getAttribute('data-node-value').toLowerCase().includes(nodesSearchInput.val().toLowerCase())) {
        row.classList.add('hidden')
      }
      else {
        row.classList.remove('hidden')
      }
    })
  });

  applyBtn.addEventListener('click', (event) => {
    redrawSelectedValuesTableAndSelectElement(selectedNodesTable, allNodesTable, selectElement, toggleBtn)
    checkboxSelectDropdownContainer.classList.remove('active')
    const data = Array.from(selectedNodesTable.querySelectorAll('tr.checked')).map((row) => {
      return row.getAttribute('data-node-value')
    })
    
    $.post({
        url: refreshReportUrl,
        data: {"filter_keys": data},
        success: function () {}
    })
  })

  checkboxSelectDropdownController.handleClickOutsideCheckboxListener = checkboxSelectDropdownController.handleClickOutsideCheckbox.bind(null, checkboxSelectDropdownContainer)
  document.addEventListener("click", checkboxSelectDropdownController.handleClickOutsideCheckboxListener)
}

const buildCheckboxSelectDatatableOptions = (checkboxSelectDropdownController) => {
  const allNodesTable = checkboxSelectDropdownController.allNodesTableTarget
  const items = checkboxSelectDropdownController.itemsValue

  const tableBodyElement = allNodesTable.createTBody()
  items.forEach((selectedValue) => {
      createSelectValuesTableRow(tableBodyElement, selectedValue, true)
  })
}

const removeRedudantSelectedRows = (selectElement, allNodesTable) => {
  const selectedIds = Array.from(selectElement.querySelectorAll('option')).map((option) => option.value)
  if (selectedIds.length > 0) {
    const querySelectorString = selectedIds.map((selectedId) => `tr[data-node-id='${CSS.escape(selectedId)}']`).join(', ')
    allNodesTable.querySelectorAll(querySelectorString).forEach((selectedRow) => selectedRow.remove())
  }
}

const createSelectValuesTableRow = (tableBodyElement, value, checked=false) => {
  const rowElement = tableBodyElement.insertRow()
  if(checked) rowElement.classList.add('checked')

  const firstCell = rowElement.insertCell()
  firstCell.innerHTML = checkboxTableCellContent
  firstCell.style.width = '1%'

  const secondCell = rowElement.insertCell()
  secondCell.innerText = value

  rowElement.setAttribute('data-node-value', value)
  rowElement.setAttribute('data-node-id', value)
  createToggleCheckmarkEventListener(rowElement)
  // .replaceWith($('#myEl').clone());
}

const createToggleCheckmarkEventListener = (rowElement) => {
  rowElement.addEventListener('click', (event) => {
    if (rowElement.classList.contains('checked')) {
      rowElement.classList.remove('checked')
    } else {
      rowElement.classList.add('checked')
    }
  })
}

const redrawSelectedValuesTableAndSelectElement = (selectedNodesTable, allNodesTable, selectElement, toggleBtn) => {
  // Get existing selected values from Table
  const existingUnselectedValues = Array.from(allNodesTable.querySelectorAll('tr:not(.checked)')).map((row) => {
    return row.getAttribute('data-node-value')
  })

  // Get existing selected values from Table
  const recentlyUnselectedValues = Array.from(selectedNodesTable.querySelectorAll('tr:not(.checked)')).map((row) => {
    return row.getAttribute('data-node-value')
  })

  // find unique values
  const allUnselectedValues = existingUnselectedValues.concat(recentlyUnselectedValues)
  const uniqueUnselectedValues = [... new Set(allUnselectedValues)].filter(n => n != null)

  // Get existing selected values from Table
  const existingSelectedValues = Array.from(selectedNodesTable.querySelectorAll('tr.checked')).map((row) => {
    return row.getAttribute('data-node-value')
  })

  // Get selected values from dropdown table
  const recentlySelectedValuesElements = allNodesTable.querySelectorAll('tr.checked')
  const recentlySelectedValues = Array.from(recentlySelectedValuesElements).map((row) => {
    return row.getAttribute('data-node-value')
  })

  // find unique values
  const allSelectedValues = existingSelectedValues.concat(recentlySelectedValues)
  const uniqueSelectedValues = [... new Set(allSelectedValues)]

  // clear table and select
  clearSelectedValues(allNodesTable, selectElement)
  clearSelectedValues(selectedNodesTable, selectElement)

  // re-create unique values
  if (uniqueSelectedValues.length > 0) {
    const tableHead = selectedNodesTable.createTHead()
    const tableHeadRow = tableHead.insertRow()
    tableHeadRow.insertCell(0).outerHTML = `
      <th colspan='2'>Selected <span class=\"text-right hover:opacity-90 float-right\"><button class=\"btn-clear-all\" type=\"button\" name=\"clear_all\" data-action=\"click->checkbox-select-dropdown#clearBtnAction\">Clear all</button></span></th>
    `

    const tableBodyElement = selectedNodesTable.createTBody()
    uniqueSelectedValues.forEach((selectedValue) => {
        createSelectValuesTableRow(tableBodyElement, selectedValue, true)
        createOptionForSelect(selectElement, selectedValue)
    })

    toggleBtn.parentElement.classList.remove('text-gray-40')
    removeRedudantSelectedRows(selectElement, allNodesTable)
  } 

  if (uniqueUnselectedValues.length > 0) {
    const unselectedTableBodyElement = allNodesTable.createTBody()
    uniqueUnselectedValues.forEach((selectedValue) => {
      createSelectValuesTableRow(unselectedTableBodyElement, selectedValue)
    })
  }

  return uniqueSelectedValues
}


const createOptionForSelect = (selectElement, value) => {
  const optionElement = document.createElement('option');
  optionElement.value = value;
  optionElement.innerHTML = value;
  optionElement.setAttribute('selected', '')
  optionElement.selected = true
  selectElement.appendChild(optionElement);
}

const clearSelectedValues = (selectedValuesTable, selectElement) => {
  // clear table and select
  selectedValuesTable.textContent = ''
  selectElement.textContent = ''
}

const clearEverything = (checkboxSelectDropdownController) => {
  const selectedNodesTable = checkboxSelectDropdownController.selectedNodesTableTarget
  const allNodesTable = checkboxSelectDropdownController.allNodesTableTarget
  const nodesSearchInput = $(checkboxSelectDropdownController.searchTarget)

  allNodesTable.querySelectorAll('tbody tr').forEach((rowElement) => {
    if (rowElement.classList.contains('checked')) {
      rowElement.classList.remove('checked')
    } 
  })

  selectedNodesTable.querySelectorAll('tbody tr').forEach((rowElement) => {
    if (rowElement.classList.contains('checked')) {
      rowElement.classList.remove('checked')
    } 
  })

  nodesSearchInput.val('')
}

const checkboxTableCellContent = `<svg class="checked-input rounded" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 4C0 1.79086 1.79086 0 4 0H12C14.2091 0 16 1.79086 16 4V12C16 14.2091 14.2091 16 12 16H4C1.79086 16 0 14.2091 0 12V4Z" fill="#112C7B"/>
                                    <path d="M12.2069 4.79279C12.3944 4.98031 12.4997 5.23462 12.4997 5.49979C12.4997 5.76495 12.3944 6.01926 12.2069 6.20679L7.20692 11.2068C7.01939 11.3943 6.76508 11.4996 6.49992 11.4996C6.23475 11.4996 5.98045 11.3943 5.79292 11.2068L3.79292 9.20679C3.61076 9.01818 3.50997 8.76558 3.51224 8.50339C3.51452 8.24119 3.61969 7.99038 3.8051 7.80497C3.99051 7.61956 4.24132 7.51439 4.50352 7.51211C4.76571 7.50983 5.01832 7.61063 5.20692 7.79279L6.49992 9.08579L10.7929 4.79279C10.9804 4.60532 11.2348 4.5 11.4999 4.5C11.7651 4.5 12.0194 4.60532 12.2069 4.79279Z" fill="white"/>
                                    <path d="M4 1H12V-1H4V1ZM15 4V12H17V4H15ZM12 15H4V17H12V15ZM1 12V4H-1V12H1ZM4 15C2.34315 15 1 13.6569 1 12H-1C-1 14.7614 1.23858 17 4 17V15ZM15 12C15 13.6569 13.6569 15 12 15V17C14.7614 17 17 14.7614 17 12H15ZM12 1C13.6569 1 15 2.34315 15 4H17C17 1.23858 14.7614 -1 12 -1V1ZM4 -1C1.23858 -1 -1 1.23858 -1 4H1C1 2.34315 2.34315 1 4 1V-1Z" fill="#112C7B"/>
                                  </svg>
                                  <svg class="unchecked-input rounded" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 4C0 1.79086 1.79086 0 4 0H12C14.2091 0 16 1.79086 16 4V12C16 14.2091 14.2091 16 12 16H4C1.79086 16 0 14.2091 0 12V4Z" fill="white"/>
                                    <path d="M4 1H12V-1H4V1ZM15 4V12H17V4H15ZM12 15H4V17H12V15ZM1 12V4H-1V12H1ZM4 15C2.34315 15 1 13.6569 1 12H-1C-1 14.7614 1.23858 17 4 17V15ZM15 12C15 13.6569 13.6569 15 12 15V17C14.7614 17 17 14.7614 17 12H15ZM12 1C13.6569 1 15 2.34315 15 4H17C17 1.23858 14.7614 -1 12 -1V1ZM4 -1C1.23858 -1 -1 1.23858 -1 4H1C1 2.34315 2.34315 1 4 1V-1Z" fill="#D1D5DB"/>
                                  </svg>`