import Highcharts from "highcharts";
import {buildLTVHoverCard} from './lifetime_value_hover_cards';
import patternFill from "highcharts/modules/pattern-fill";
import { get_filters_and_options_data } from "./filters_and_options";

patternFill(Highcharts);
export function configChart() {

    var cfg = {
        chart: {
            type: 'line',
            style: {
                fontFamily: "Inter",
                color: '#475666'
            },
            events: {
                load: requestData
            }
        },
        title: {
            text: null
        },
        legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'top',
            borderWidth: 0,
            backgroundColor: '#FFF',
            itemDistance: 30,
            squareSymbol: false,
            symbolWidth: 28,
            symbolHeight: 11,
            symbolPadding: 8,
            itemStyle: {
                fontSize: '13px',
                fontWeight: '700',
                color: '#475666'
            }
        },
        xAxis: {
            type: 'category',
            labels: {
                formatter: function(){
                    return moment(this.value).format('DD-MMM-YY');
                },
                style: {
                    fontWeight: '700',
                    fontSize: '13px',
                    color: '#475666'
                }
            },
            tickmarkPlacement: 'on',
            minorGridLineWidth: 0,
            minorTickInterval: 'auto',
            minorTickLength: 10,
            minorTickWidth: 1
        },
        yAxis: {
            title: {
                //          text: $ch.attr('data-chart-title'),
            }
        },
        tooltip: {
            backgroundColor: 'transparent',
            borderWidth: 0,
            padding: 0,
            shared: true,
            style: {
                fontSize: '13px',
                color: '#475666'
            },
            useHTML: true,
            shadow: false
            /*
            formatter: function () {
              var s = this.x;

              $.each(this.points, function () {
                  s += '<b><br/>' + this.y + '</b> ' + this.series.name;
              });

              return s;
            } */
        },
        credits: {
            enabled: false
        },
        colors: ['#63CFEF', '#8DC410', '#EC9E0E', '#DA25AD'],
        plotOptions: {
            series: {
                turboThreshold: 0
            },
            areaspline: {
                lineWidth: 3,
                marker: {
                    symbol: 'circle',
                    enabled: false
                }
            },
        },
    };

// if (chart_data.no_spline)
// {
//     cfg.chart.type='area';
// }
//
// if (chart_data.money_symbol)
// {
//     cfg.tooltip.valueSuffix = " " + chart_data.money_symbol;
//     cfg.yAxis = [];
//     cfg.yAxis[0] = {};
//     cfg.yAxis[0].labels = {};
//     cfg.yAxis[0].labels.format = "{value} " + chart_data.money_symbol;
// }

    cfg.series = [];

//var chart_id = $ch.attr('id');


    var serie_styles = [];

    serie_styles[0] = {
        name: 'Revenue',
        color: '#63CFEF',
        fillColor: {
            linearGradient: [0, 0, 0, 300],
            stops: [
                [0, 'rgba(99, 207, 239, .2)'],
                [1, 'rgba(99, 207, 239, 0)']
            ]
        },
        //data: chart_lines['30 days segment'], // [null, null, null, null, 30, 42, 48, 50, 56]
    };

    serie_styles[1] = {
        name: 'Product costs',
        color: '#8DC410',
        fillColor: {
            linearGradient: [0, 0, 0, 300],
            stops: [
                [0, 'rgba(141, 196, 16, .2)'],
                [1, 'rgba(141, 196, 16, .0)']
            ]
        },
        //data: chart_lines['60 days segment'], //  [null, null, null, null, 34, 48, 65, 68, 65]
    };

    serie_styles[2] = {
        name: 'Marketing costs',
        color: '#EC9E0E',
        fillColor: {
            linearGradient: [0, 0, 0, 300],
            stops: [
                [0, 'rgba(236, 158, 14, .2)'],
                [1, 'rgba(236, 158, 14, 0)']
            ]
        },
        //data:  chart_lines['180 days segment'], // [null, null, null, null, 48, 70, 58, 69, 90]
    }

    serie_styles[3] = {
        name: 'Net profit',

        color: '#DA25AD',
        fillColor: {
            linearGradient: [0, 0, 0, 300],
            stops: [
                [0, 'rgba(218, 37, 173, .2)'],
                [1, 'rgba(218, 37, 173, 0)']
            ]
        },
        //data: chart_lines['360 days segment'], //  [null, null, null, null, 58, 53, 52, 84, 81]
    };

    cfg.series.push(serie_styles[0]);
    cfg.series.push(serie_styles[1]);
    cfg.series.push(serie_styles[2]);
    cfg.series.push(serie_styles[3]);

    var myChart = Highcharts.chart('chart-container', cfg);

    function requestData() {
      var chartContainer = $("#chart-container")
        $.ajax({
             url: '/' + chartContainer.attr('data-controller') + '/chart_data',
            success: function (data) {
                myChart.series[0].setData(data["data"][0]);
                myChart.series[1].setData(data["data"][1]);
                myChart.series[2].setData(data["data"][2]);
                myChart.series[3].setData(data["data"][3]);
            },
            cache: false
        });
    };

};

export function dashboardAreaChart() {
  var dashboard_area_chart_container = $('#dashboard-area-chart-container');
  if ( dashboard_area_chart_container.length != 0 ){
    var cfg = {
      chart: {
        type: 'column', // Changle this line to 'area' if we want to reset the chart
        style: {
          fontFamily: "Inter",
          color: '#475666'
        },
        events: {
          load: requestData
        }
      },
      title: {
        text: null
      },
      legend: {
        layout: 'horizontal',
        align: 'left',
        verticalAlign: 'top',
        borderWidth: 0,
        backgroundColor: '#FFF',
        itemDistance: 30,
        squareSymbol: false,
        symbolWidth: 28,
        symbolHeight: 11,
        symbolPadding: 8,
        itemMarginTop: -5,
        itemMarginBottom: 40,
        itemStyle: {
          fontSize: '13px',
          fontWeight: '700',
          color: '#475666'
        }
      },
      xAxis: {
        type: 'category',
        labels: {
          formatter: function(){
            return moment(this.value).format('DD-MMM-YY');
          },
          style: {
            fontWeight: '700',
            fontSize: '13px',
            color: '#475666'
          }
        },
        tickmarkPlacement: 'on',
        minorGridLineWidth: 0,
        minorTickInterval: 'auto',
        minorTickLength: 10,
        minorTickWidth: 1
      },
      yAxis: {
        title: 'Net profit'
      },
      tooltip: {
        backgroundColor: 'transparent',
        borderWidth: 0,
        padding: 0,
        shared: true,
        style: {
          fontSize: '13px',
          color: '#475666'
        },
        headerFormat: '<span style="font-size: 16px;font-weight: 700;">{point.key}</span><br/>',
        useHTML: true,
        shadow: false,
        formatter: function () {
                                var points = this.points;
                                var pointsLength = points.length;
                                var tooltipMarkup = pointsLength ?  '<span style="font-size: 12px;font-weight: 700;">' + moment(points[0].key).format("DD MMM YY") + '</span><br>' : '';
                                var index;

                                for(index = 0; index < pointsLength; index += 1) {

                                  tooltipMarkup += '<span style="color:' + points[index].series.color + '">\u25CF</span> ' + points[index].series.name + ': <b>' + points[index].y + '</b>' + '<br/>';
                                }

                                return tooltipMarkup;
                            }
      },
      credits: {
        enabled: false
      },
      colors: ['#63CFEF', '#8DC410', '#EC9E0E', '#DA25AD'],
      plotOptions: {
        series: {
          turboThreshold: 0
        },
        areaspline: {
          lineWidth: 3,
          marker: {
            symbol: 'circle',
            enabled: false
          }
        },
      },
    };

    cfg.series = [];
    var serie_styles = [];

    serie_styles[0] = {
        name: 'Sales',
        color: '#EC9E0E',
        fillColor: {
            linearGradient: [0, 0, 0, 300],
            stops: [
                [0, 'rgba(236, 158, 14, .2)'],
                [1, 'rgba(236, 158, 14, 0)']
            ]
        },
    };

    serie_styles[1] = {
        name: 'Marketing costs',
        color: '#63CFEF',
        fillColor: {
            linearGradient: [0, 0, 0, 300],
            stops: [
                [0, 'rgba(99, 207, 239, .2)'],
                [1, 'rgba(99, 207, 239, 0)']
            ]
        },
    };

    serie_styles[2] = {
        name: 'Net profit',
        color: '#8DC410',
        fillColor: {
            linearGradient: [0, 0, 0, 300],
            stops: [
                [0, 'rgba(141, 196, 16, .2)'],
                [1, 'rgba(141, 196, 16, .0)']
            ]
        },
    };



     cfg.series.push(serie_styles[0]);
     cfg.series.push(serie_styles[1]);
     cfg.series.push(serie_styles[2]);

    var areaChart = Highcharts.chart('dashboard-area-chart-container', cfg);

    function requestData() {
      var chartContainer = $("#dashboard-area-chart-container")
      $.ajax({
        url: '/' + chartContainer.attr('data-controller') + '/hidden_chart_data',
        success: function (data) {
          areaChart.series[0].setData(data["data"][0]);
          areaChart.series[1].setData(data["data"][1]);
          areaChart.series[2].setData(data["data"][2]);
        },
        cache: false
      });
    };
  }
}


//-- Time Lag Chart
export function timeLagChart(timeLagChartContainer) {
    const categories = JSON.parse(timeLagChartContainer.dataset.categories)
    const ordersCounts = JSON.parse(timeLagChartContainer.dataset.ordersCounts)
    const percentOfRepurchases = JSON.parse(timeLagChartContainer.dataset.percentageOfRepurchases)
    const cumulativePercentOfRepurchases = JSON.parse(timeLagChartContainer.dataset.cumulativePercentageOfRepurchases)
    const filtersParams = JSON.parse(timeLagChartContainer.dataset.filtersParams)

    Highcharts.chart('time-lag-chart-container', {
        chart: {
            type: 'column',
            style: {
                fontFamily: "Inter",
                color: '#475666'
            },
        },
        title: {
            text: null
        },
        xAxis: {
            margin: 16,
            title: {
                text: 'Time lag between order (days)',
                style: {
                    fontWeight: '500',
                    fontSize: '13px',
                    color: '#7D8188'
                }
            },
            categories: categories,
            labels: {
                style: {
                    fontWeight: '500',
                    fontSize: '13px',
                    color: '#7D8188'
                }
            }
        },
        yAxis: {
            min: 0,
            max: 100,
            margin: 16,
            title: {
                text: '% of repeat orders',
                style: {
                    fontWeight: '500',
                    fontSize: '13px',
                    color: '#7D8188'
                }
            },
            labels: {
                formatter: function(){
                    return `${this.value}%` ;
                },
                style: {
                    fontWeight: '500',
                    fontSize: '13px',
                    color: '#7D8188'
                }
            }
        },
        legend: {
            enabled: true,
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'top',
            borderWidth: 0,
            backgroundColor: '#FFF',
            itemDistance: 30,
            squareSymbol: false,
            symbolWidth: 28,
            symbolHeight: 11,
            symbolPadding: 8,
            margin: 32,
            itemStyle: {
                fontSize: '13px',
                fontWeight: '700',
                color: '#475666'
            }
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            }
        },
        tooltip: {
            backgroundColor: 'transparent',
            borderWidth: 0,
            padding: 0,
            shared: true,
            style: {
                fontSize: '13px',
                color: '#475666',
                pointerEvents: 'auto',
            },
            useHTML: true,
            shadow: false,
            crosshairs: {
                width: 2
            },
            hideDelay: 1000, // Delay 1 second so users have time to click the link to "Show purchased products"
            formatter: function () {
                const daysBetween = this.x;
                const percentRepurchases = this.y;
                const orderFrequency = $('select[id=time_lag_order_frequency]').val()
                const granularity = $('select[id=time_lag_granularity]').val()
                const totalRepurchases = this.points[0].point.total_repurchases
                const cumulativePercent = this.points[1].y

                const reportParams = {
                    'time_lag_order_frequency': orderFrequency,
                    'time_lag_granularity': granularity,
                    'days_between_orders_group': daysBetween,
                    'percent_repurchases': percentRepurchases,
                    'cumulative_percent': cumulativePercent,
                    'total_repurchases': totalRepurchases
                }

                const allParams = (filtersParams) ? Object.assign(reportParams, filtersParams) : reportParams;
                const paramsString = JSON.stringify(allParams);

                const linkHTML = `<div data-controller="timeLag" class="mx-auto block" data-timeLag-url-value="/time_lag/show_purchased_products" data-timeLag-params-value='${paramsString}'><button data-action="click->timeLag#showProducts" class='block mx-auto font-semibold text-center' style='color: #0F98E1;'>Show purchased products</button></div>`

                return `<div class="whitespace-normal w-60 h-auto"><p class='whitespace-normal font-normal text-sm text-center'><strong>${percentRepurchases}&#37;</strong> of repurchases occur <strong>${daysBetween}</strong> days after the customer's previous purchase.</p>${linkHTML}</div>`;
            },
            positioner: function(width, height, point) {
                const tooltipWidth = 240
                const tooltipHeight = 132
                const xValue = point.plotX - width + tooltipWidth
                const yValue = height - (point.h - tooltipHeight)

                return {
                    x: xValue,
                    y: yValue
                };
            }
        },
        credits: {
            enabled: false
        },
        series: [{
            type: 'column',
            name: '% of repeat orders',
            data: percentOfRepurchases.map((percent, index) => {
                return {
                    y: percent,
                    total_repurchases: ordersCounts[index]
                };
            }),
            color: "#72D4F1",
            dataLabels: {
                enabled: true,
                format: '{y}%',
                style: {
                    fontWeight: '700',
                    fontSize: '13px',
                    color: '#475666'
                }
            }
        },{
            type: 'spline',
            name: 'Cumulative % of repeat orders',
            data: cumulativePercentOfRepurchases,
            color: "#EE9F29",
            dataLabels: {
                enabled: true,
                format: '{y}%',
                style: {
                    fontWeight: '700',
                    fontSize: '13px',
                    color: '#EE9F29'
                }
            }
        }]
    });

    // Re-scale chart when closing filters box or left main menu
    $('.btn-closefilters, #left-main-menu-toggle').click( function (e) {
        e.preventDefault();
        var timeLagChart = $('#time-lag-chart-container');
        if (timeLagChart.length) {
            timeLagChart.highcharts().reflow();
        }
        return true
    });
}

//-- Custom dashboard card time lag chart
export function customDashboardTimeLagChart(timeLagChartContainer) {
    const categories = JSON.parse(timeLagChartContainer.dataset.categories)
    const ordersCounts = JSON.parse(timeLagChartContainer.dataset.ordersCounts)
    const percentOfRepurchases = JSON.parse(timeLagChartContainer.dataset.percentageOfRepurchases)
    const cumulativePercentOfRepurchases = JSON.parse(timeLagChartContainer.dataset.cumulativePercentageOfRepurchases)

    Highcharts.chart(timeLagChartContainer.id, {
        chart: {
            type: 'column',
            style: {
                fontFamily: "Inter",
                color: '#475666'
            },
        },
        title: {
            text: null
        },
        xAxis: {
            title: {
                enabled: false
            },
            categories: categories,
            labels: {
                style: {
                    fontWeight: '400',
                    fontSize: '13px',
                    color: '#A8ABB0'
                }
            }
        },
        yAxis: {
            min: 0,
            max: 100,
            title: {
                enabled: false
            },
            labels: {
                formatter: function(){
                    return `${this.value}%` ;
                },
                style: {
                    fontWeight: '400',
                    fontSize: '13px',
                    color: '#A8ABB0'
                }
            }
        },
        legend: {
            enabled: false,
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            }
        },
        tooltip: {
            backgroundColor: 'transparent',
            borderWidth: 0,
            padding: 0,
            shared: true,
            style: {
                fontSize: '13px',
                color: '#475666',
                pointerEvents: 'auto',
            },
            useHTML: true,
            shadow: false,
            crosshairs: {
                width: 2
            },
            formatter: function () {
                const daysBetween = this.x;
                const percentRepurchases = this.y;

                return `<div class="whitespace-normal w-60 h-auto"><p class='whitespace-normal font-normal text-sm text-center mb-0'><strong>${percentRepurchases}&#37;</strong> of repurchases occur <strong>${daysBetween}</strong> days after the customer's previous purchase.</p></div>`;
            },
            positioner: function(width, height, point) {
                const tooltipWidth = 240
                const tooltipHeight = 132
                const xValue = point.plotX - width + tooltipWidth
                const yValue = height - (point.h - tooltipHeight)

                return {
                    x: xValue,
                    y: yValue
                };
            }
        },
        credits: {
            enabled: false
        },
        series: [{
            type: 'column',
            name: '% of repeat orders',
            data: percentOfRepurchases.map((percent, index) => {
                return {
                    y: percent,
                    total_repurchases: ordersCounts[index]
                };
            }),
            color: "#72D4F1",
            dataLabels: {
                enabled: true,
                format: '{y}%',
                style: {
                    fontWeight: '700',
                    fontSize: '13px',
                    color: '#475666'
                }
            }
        },{
            type: 'spline',
            name: 'Cumulative % of repeat orders',
            data: cumulativePercentOfRepurchases,
            color: "#EE9F29",
            dataLabels: {
                enabled: true,
                format: '{y}%',
                style: {
                    fontWeight: '700',
                    fontSize: '13px',
                    color: '#EE9F29'
                }
            }
        }]
    });
}

//-- LTV Average  Chart
function calc_ltv_avg(customers, val, index, num_time_intervals) {
    var val_sum = 0;
    var custom_sum = 0;
    var avg = 0;
    for (var j = 0; j < num_time_intervals - index; j ++) {
        val_sum += customers[j] * val[j];
        custom_sum += parseInt(customers[j]);
    }

    if (custom_sum == 0) {
        avg = 0;
    } else {
        avg = parseInt(val_sum / custom_sum);
    }

    return avg;
}

const getLtvSpaghettiChartData = (chartElement) => {

    var currency = chartElement.dataset.currency;
    var metric = $('select[id=lifetime_select]').val();
    var num_time_intervals = $('tbody .tbl--ordertime').length;
    var order_dates = [];
    $('tbody .tbl--ordertime').each(function(index, value) {
        order_dates[index] = $(this).text();
    });

    var is_predictive_metric = (metric === "sales_per_customer" || metric === "sales_per_cohort" || metric === "cac_sales_per_customer" || metric === "sales_margin_per_customer" || metric === "cac_sales_margin_per_customer")
    // This logic is just for the UI - If the shop doesn't have PLUS, then we don't send predictive
    // data to the front-end. Or, at least, we shouldn't :)
    var shop_has_plus_plan = $("#togglePredictiveLTV").attr('data-shop-has-plus-plan') === 'true'
    var show_predictive_summary_data = $("#togglePredictiveLTV").is(":checked") && shop_has_plus_plan && is_predictive_metric;

    var show_cac_payback = $("#toggleCAC-LTV").is(":checked");

    var format = $("#format-toggle .active input").attr('data-ltv_show_format');
    var spaghetti_val = [];
    var dashStartMarkers = [];

    // get values per timeframes
    for (var i = 0; i < num_time_intervals; i ++) {
        let dashedStarted = false
        var val = [];
        $(".row" + i + " .js-table-data").each(function(index) {
            let tableCellDataMetric = metric;
            if ( $(this).hasClass('js-ltv-summary-table-data') && show_predictive_summary_data ) {
                tableCellDataMetric = 'predictive_' + metric
            }

            if ($(this).hasClass('tbl--predictive')) {
                if (show_predictive_summary_data) {
                    // Mark this as where to start dashed line
                    if (dashedStarted === false) {
                        dashStartMarkers[i] = (index - 1) // -1 to account for 'First Order' column
                        dashedStarted = true;
                    }
                } else {
                    // Skip, don't show predictive data
                    return null
                }
            }

            let value;
            if (format === 'value') {
                value = $(this).data(tableCellDataMetric).value;
                value = value.replace(currency, '');
            } else {
                value = $(this).data(tableCellDataMetric).percentage_change;
            }

            let dataPoint = { y: parseFloat(value.replace(/,/g, '')), custom: {metric: tableCellDataMetric, row: i, index: index} }

            if (show_cac_payback && $(this).hasClass('tbl--bar-marker')) {
                dataPoint.marker = {
                    enabled: true,
                    fillColor: '#00D96D',
                    radius: 5,
                    symbol: 'circle'
                }
            } else {
                dataPoint.marker = {
                    enabled: undefined,
                    fillColor: undefined,
                    radius: 4,
                    symbol: undefined
                }
            }

            val.push(dataPoint);
        });
        spaghetti_val[i] = val;
    }

    var categories = [];
    var series = [];

    for (var i = 0; i < num_time_intervals; i ++) {
        categories[i] = i;

        series[i] = {
            name: order_dates[i],
            marker: {enabled: false},
            data: spaghetti_val[i],
            dataLabels: {enabled: false},
            zoneAxis: 'x',
            zIndex: 1
        };

        if (show_predictive_summary_data) {
            series[i].zones = [{value: dashStartMarkers[i]},{dashStyle: 'dash'}]
        }
    }

    categories.unshift('First order');

    // get average values
    var avg_val = [];
    $(".js-ltv-average-data").each(function(index) {
        let tableCellDataMetric = metric;
        if ( $(this).hasClass('js-ltv-summary-table-data') && show_predictive_summary_data) {
            tableCellDataMetric = 'predictive_' + metric
        }

        let value;
        if (format === 'value') {
            value = $(this).data(tableCellDataMetric).value;
            value = value.replace(currency, '');
        } else {
            value = $(this).data(tableCellDataMetric).percentage_change;
        }

        let dataPoint = { y: parseFloat(value.replace(/,/g, '')), custom: {metric: tableCellDataMetric, row: 'average', index: index} }

        if (show_cac_payback && $(this).hasClass('tbl--bar-marker')) {
            dataPoint.marker = {
                enabled: true,
                fillColor: '#00D96D',
                radius: 5,
                symbol: 'circle'
            }
        } else {
            dataPoint.marker = {
                enabled: undefined,
                fillColor: undefined,
                radius: 4,
                symbol: undefined
            }
        }

        avg_val.push(dataPoint);
    });

    // Store values in Custom field so that
    // we can use them when refreshing in chart,
    // and we do have to use expensive JQuery to find these
    // values for every axis label, tooltip, etc.
    var config = {
        metric: metric,
        format: format
    }

    const avgSeriesData = {
        name: 'Average',
        marker: {enabled: false},
        data: avg_val,
        color: '#121212',
        dashStyle: 'LongDash',
        dataLabels: {enabled: false},
        zoneAxis: 'x',
        lineWidth: 3,
        zIndex: 2,
        custom: config
    }

    series.unshift(avgSeriesData);

    return { categories, series }
}

export function ltvSpaghettiChart(chartElement) {
    require('highcharts/modules/exporting')(Highcharts);
    require('highcharts/modules/offline-exporting')(Highcharts);

    let { categories, series } = getLtvSpaghettiChartData(chartElement);
    // Establish colors
    const colors = ['#DA045E', '#7584AB', '#C17B3A', '#33ACCA', '#D9A900', '#FF1919', '#14C324', '#8D46FF', '#1877F2', '#8CC330', '#DD25A8', '#EF9D26', '#5BD0ED']

    var currency = chartElement.dataset.currency;
    var XAxisText = chartElement.dataset.timeframeLabel;
    var chartCanvas = chartElement.firstElementChild
    var ltv_spaghetti_chart = Highcharts.chart(chartCanvas, {
        chart: {
            type: 'line',
            style: {
                fontFamily: "Inter",
                color: '#475666',
                fontSize: '12px',
                fontWeight: '600'
            },
            reflow: false // We'll use a setSize instead of reflow; reflow doesn't work because this chart is in a table
        },
        exporting: false,
        title: {
            text: "",
        },
        colors: colors,
        xAxis: {
            categories: categories,
            title: {
                text: XAxisText,
                y: 15,
            },
            type: 'category',
            labels: {
                style: {
                    fontWeight: '600',
                    color: '#475666',
                    fontSize: '12px'
                }
            },
            tickmarkPlacement: 'on',
            minorGridLineWidth: 0,
            minorTickInterval: 'auto',
            minorTickLength: 10,
            minorTickWidth: 1
        },
        yAxis: {
            title: {
                text: null
            },
            labels: {
                style: {
                    fontWeight: '600',
                    color: '#475666',
                    fontSize: '12px'
                },
                formatter: function () {
                    let value = this.axis.defaultLabelFormatter.call(this);
                    let metric = this.chart.series[0].options.custom.metric
                    let format = this.chart.series[0].options.custom.format

                    if (format === 'percentage_change') {
                        return value + '%';
                    }

                    if (metric === 'orders_per_customer') {
                        return value + 'X';
                    } else if (metric !== 'customers' && metric !== 'transactions_per_cohort') {
                        return currency + value;
                    }

                    return value;
                }
            },
        },
        credits: {
            enabled: false
        },
        series: series,
        tooltip: {
            backgroundColor: "#FFF",
            borderWidth: 0,
            distance: 20,
            padding: 0,
            shadow: false,
            stickOnContact: true,
            style: {
                textAlign: "center",
                width: "272px",
                color: '#475666',
                fontSize: '14px',
                fontWeight: '400'
            },
            useHTML: true,
            formatter: function () {
                const header = '<div style="margin-bottom: 12px"><b>' + this.series.name + '</b></div>'
                let tableCellNode;

                if (this.point.custom.row === 'average') {
                    tableCellNode = $(".js-ltv-average-data")[this.point.custom.index]
                } else {
                    tableCellNode = $(".row" + this.point.custom.row + " .js-table-data")[this.point.custom.index]
                }

                // Hacky way to hide the stat label
                const content = buildLTVHoverCard(tableCellNode, true)

                return header + content
            }
        },
        legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'top',
            symbolPadding: 0,
            symbolWidth: 0,
            symbolHeight: 0,
            squareSymbol: false,
            useHTML: true,
            labelFormatter: function () {
                const checkMarkHTML = '<div class="padded-icon" style="background-color: ' + this.color + '"><svg class="rounded" width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                    '<path d="M9.20741 0.793C9.39488 0.980528 9.50019 1.23484 9.50019 1.5C9.50019 1.76516 9.39488 2.01947 9.20741 2.207L4.20741 7.207C4.01988 7.39447 3.76557 7.49979 3.50041 7.49979C3.23524 7.49979 2.98094 7.39447 2.79341 7.207L0.793407 5.207C0.611249 5.0184 0.510455 4.7658 0.512733 4.5036C0.515012 4.2414 0.62018 3.99059 0.805589 3.80518C0.990997 3.61977 1.24181 3.5146 1.50401 3.51233C1.7662 3.51005 2.0188 3.61084 2.20741 3.793L3.50041 5.086L7.79341 0.793C7.98094 0.605529 8.23524 0.500214 8.50041 0.500214C8.76557 0.500214 9.01988 0.605529 9.20741 0.793Z" fill="white"/>\n' +
                    '</svg></div>\n'
                return '<div class="chart-legend-checkbox-container" title="' + this.name + '">' + checkMarkHTML + this.name + '</span></div>';
            }
        },
        plotOptions: {
            series: {
                turboThreshold: 0,
                marker: {
                    enabled: false
                }
            },
        },
    }, function(chart) {
        // Re-scale chart when closing filters box or left main menu
        // Perform reflow via setSize function because this chart is in a table so reflow() doesn't work
        const resizeSpaghetti = () => {
            const width = chartElement.closest('.table-responsive').offsetWidth;
            chart.setSize(width, 500, false);
        }

        $('#left-main-menu-toggle, #graph-icon').click( function (e) {
            resizeSpaghetti();
        });

        $(window).resize(function(){
            resizeSpaghetti();
        });

        // Set up export
        $(".js-export-chart-dropdown a[role='button']").click(function() {
            const button = $(this);
            const exportType = button.attr('data-export-type');

            if (exportType === 'print') {
                chart.print();
            } else {
                chart.exportChartLocal({
                    type: exportType,
                    filename: 'lifetime-value-spaghetti-graph',
                    fallbackToExportServer: false
                })
            }
        });

    });

    chartElement.addEventListener("ltvToggleControlChange", (event) => {
        let { categories, series } = getLtvSpaghettiChartData(chartElement);

        ltv_spaghetti_chart.update({
            series: series
        });
    });
}


//--Repurchase Rate Charts
export function repurchaseRateCharts() {

  var chart_containers = $('.repurchase-rate-chart-container');

  if ( chart_containers.length )
  {
    chart_containers.each(function() {

      var categories = $(this).data('categories');
      var chart_identifier = $(this).attr('id');

      // Advanced information for hover content (tooltips)
      var numberOfTotalRepurchasersArray = $(this).data('number-total-repurchasers');
      var numberOfRepurchasersSeriesArrays = []
      numberOfRepurchasersSeriesArrays.push($(this).data('number-90-days'));

      // Text that changes depending on New customers chart or Returning customers chart
      var xAxisText;
      var hoverContentText;
      if (chart_identifier == 'repurchase-rate-chart-new-customers') {
        xAxisText = "Customer's first order month";
        hoverContentText = 'customers making their first order in';
      } else {
        xAxisText = "Repurchase order month"
        hoverContentText = 'customers making a repurchase in'
      }

      // Only show one 90-day series if it is the dashboard chart
      var seriesData = [];
      var markerData = {};
      var dataLabelsData = {};

      // Mark columns that are not old enough to give complete data,
      // so that we can use "dash" lines in the chart UI.
      // When the customer doesn't have a full 30/90/180/365 days since their last order,
      // we use a dashed line because this customer's data too recent to have fully accounted for the 30/90/180/365 days
      // In other words, there's still time left for the customers of this cohort to make repurchases, and the
      // repurchase rates of these time windows could still change in the future.
      var dashStartMarkers = $(this).data('dash-start-markers');

      var percent_90_days = $(this).data('percent-90-days');
      seriesData.push({
            name: '% repurchasing w/in 90 days',
            color: '#63CFEF',
            fillColor: {
                linearGradient: [0, 0, 0, 400],
                stops: [
                    [0, 'rgba(99, 207, 239, .2)'],
                    [1, 'rgba(99, 207, 239, 0)']
                ]
            },
            data: percent_90_days,
            zoneAxis: 'x',
            zones: [{value: dashStartMarkers['start_dash_90_days']},{dashStyle: 'dash', fillColor: 'rgba(0,0,0,0)'}]
          });


      // Config Repurchase Report charts vs Dashboard retention chart
      if (chart_identifier != "repurchase-rate-chart-dashboard") {

        // Move to top when applying to all charts.
        require('highcharts/modules/exporting')(Highcharts);
        require('highcharts/modules/offline-exporting')(Highcharts);


        // Add 180 and 365 day series
        // For tooltips
        numberOfRepurchasersSeriesArrays.unshift($(this).data('number-30-days'));
        numberOfRepurchasersSeriesArrays.push($(this).data('number-180-days'));
        numberOfRepurchasersSeriesArrays.push($(this).data('number-365-days'));

        // For chart coordinates
        var percent_30_days = $(this).data('percent-30-days');
        var percent_180_days = $(this).data('percent-180-days');
        var percent_365_days = $(this).data('percent-365-days');

        seriesData.unshift({
            name: '% repurchasing w/in 30 days',
            color: '#DA25AC',
            fillColor: {
                linearGradient: [0, 0, 0, 400],
                stops: [
                    [0, 'rgba(218, 37, 173, .2)'],
                    [1, 'rgba(218, 37, 173, 0)']
                ]
            },
            data: percent_30_days,
            zoneAxis: 'x',
            zones: [{value: dashStartMarkers['start_dash_30_days']},{dashStyle: 'dash', fillColor: 'rgba(0,0,0,0)'}]
          });
        seriesData.push({
            name: '% repurchasing w/in 180 days',
            color: '#8DC410',
            fillColor: {
                linearGradient: [0, 0, 0, 400],
                stops: [
                    [0, 'rgba(141, 196, 16, .2)'],
                    [1, 'rgba(141, 196, 16, 0)']
                ]
            },
            data: percent_180_days,
            zoneAxis: 'x',
            zones: [{value: dashStartMarkers['start_dash_180_days']},{dashStyle: 'dash', fillColor: 'rgba(0,0,0,0)'}]
          });
        seriesData.push({
            name: '% repurchasing w/in 365 days',
            color: '#EC9E0E',
            fillColor: {
                linearGradient: [0, 0, 0, 400],
                stops: [
                    [0, 'rgba(236, 158, 14, .2)'],
                    [1, 'rgba(236, 158, 14, 0)']
                ]
            },
            data: percent_365_days,
            zoneAxis: 'x',
            zones: [{value: dashStartMarkers['start_dash_365_days']},{dashStyle: 'dash', fillColor: 'rgba(0,0,0,0)'}]
        });

        dataLabelsData.enabled = false;
        markerData.enabled = false;
      } else {
        dataLabelsData.enabled = true;
        dataLabelsData.y = -10;
        dataLabelsData.format = '{y}%'
        markerData.radius = 8;
      }

      var chart_container = Highcharts.chart(chart_identifier, {
        chart: {
            type: 'area',
            style: {
                fontFamily: "Inter",
                color: '#475666'
            }
        },
        exporting: {
          enabled: true,
          chartOptions: { // specific options for the exported image
              chart: {
                  style: {
                      fontFamily:"'Inter', sans-serif",
                      color: '#475666'
                  }
              },
              title: {
                text: 'Repurchase Rate',
                style: {
                  color: '#475666'
                }
              },
              plotOptions: {
                  series: {
                      dataLabels: {
                          // enabled: true
                      }
                  }
              }
          },
          filename: 'repurchase-rate-chart',
          fallbackToExportServer: false,
          buttons: {
            contextButton: {
              symbolFill: '#475666',
              symbolStroke: '#475666',
              menuItems: ["printChart", "separator", "downloadPNG", "downloadJPEG", "downloadSVG"]
            }
          }
        },
        title: {
            text: null
        },
        legend: {
            enabled: (chart_identifier != "repurchase-rate-chart-dashboard"),
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'top',
            borderWidth: 0,
            backgroundColor: '#FFF',
            itemDistance: 30,
            squareSymbol: false,
            symbolWidth: 28,
            symbolHeight: 11,
            symbolPadding: 8,
            itemStyle: {
                fontSize: '13px',
                fontWeight: '700',
                color: '#475666'
                    }
        },
        xAxis: {
            title: {
                text: xAxisText,
                enabled: (chart_identifier != "repurchase-rate-chart-dashboard")
            },
            type: 'category',
            categories: categories,
            labels: {
                formatter: function(){
                    return moment(this.value, 'MMM-YYYY').format('MMM<br>YYYY');
                },
                style: {
                    fontWeight: (chart_identifier != "repurchase-rate-chart-dashboard") ? '300' : '700',
                    color: '#475666'
                }
            },
            tickmarkPlacement: 'on',
            minorGridLineWidth: 0,
            minorTickInterval: 'auto',
            minorTickLength: 10,
            minorTickWidth: 1
        },
        yAxis: {
            title: {
                enabled: false
            },
            labels: {
                formatter: function(){
                    return `${this.value}%` ;
                },
                style: {
                    fontWeight: '300',
                    color: '#475666'
                }
            }
        },
        tooltip: {
            backgroundColor: 'transparent',
            borderWidth: 0,
            padding: 0,
            shared: true,
            style: {
                fontSize: '13px',
                color: '#475666'
            },
            useHTML: true,
            shadow: false,
            crosshairs: {
                width: 2
            },
            formatter: function () {
                var points = this.points;
                var xIndex = points[0].point.index; // index of month/year
                var pointsLength = points.length;
                var tooltipMarkup = pointsLength ?  'With <b>' + numberOfTotalRepurchasersArray[xIndex] + '</b> ' + hoverContentText + ' <b>' + this.x + '</b>:<br/>' : '';
                var index;

                for(index = 0; index < pointsLength; index += 1) {
                  var yIndex = points[index].series.index // index of series number 0 = 90 days series, 1 = 180 days series, 2 = 365 days series
                  var numberOfRepurchasersInSeries = numberOfRepurchasersSeriesArrays[yIndex][xIndex];
                  var seriesName = points[index].series.name.substr(14)
                  tooltipMarkup += '<span style="color:' + points[index].series.color + '">\u25CF</span> <b>' + numberOfRepurchasersInSeries + ' (' + points[index].y + '%)</b> repurchased' + seriesName + '<br/>'; // make dynamic for returning customers chart
                }

                return tooltipMarkup;
            }
        },
        credits: {
            enabled: false
        },
        colors: ['#63CFEF', '#8DC410', '#EC9E0E', '#DA25AD'],
        plotOptions: {
            series: {
                dataLabels: dataLabelsData,
                turboThreshold: 0,
                marker: markerData
            },
            areaspline: {
                lineWidth: 3,
                marker: {
                    symbol: 'circle',
                    enabled: false
                }
            },
        },
        series: seriesData
        });

        // Re-scale chart when closing filters box or left main menu
        $('.btn-closefilters, #left-main-menu-toggle').click( function (e) {
            e.preventDefault();
            chart_container.reflow();
        });
    });
  }
}

//-- Custom dashboard Repurchase Rate Chart
export function customDashboardRepurchaseRateChart(repurchaseRateChartContainer) {
        const categories = JSON.parse(repurchaseRateChartContainer.dataset.categories)

        // Mark columns that are not old enough to give complete data,
        // so that we can use "dash" lines in the chart UI.
        // When the customer doesn't have a full 30/90/180/365 days since their last order,
        // we use a dashed line because this customer's data too recent to have fully accounted for the 30/90/180/365 days
        // In other words, there's still time left for the customers of this cohort to make repurchases, and the
        // repurchase rates of these time windows could still change in the future.
        const dashStartMarkers = JSON.parse(repurchaseRateChartContainer.dataset.dashStartMarkers)

        // Advanced information for hover content (tooltips)
        const numberOfTotalRepurchasersArray = JSON.parse(repurchaseRateChartContainer.dataset.numberTotalRepurchasers)
        const numberOfRepurchasersSeriesArrays = []
        numberOfRepurchasersSeriesArrays.unshift(JSON.parse(repurchaseRateChartContainer.dataset['number-30Days']));
        numberOfRepurchasersSeriesArrays.push(JSON.parse(repurchaseRateChartContainer.dataset['number-90Days']));
        numberOfRepurchasersSeriesArrays.push(JSON.parse(repurchaseRateChartContainer.dataset['number-180Days']));
        numberOfRepurchasersSeriesArrays.push(JSON.parse(repurchaseRateChartContainer.dataset['number-365Days']));

        // For chart coordinates
        const percent_30_days = JSON.parse(repurchaseRateChartContainer.dataset['percent-30Days'])
        const percent_90_days = JSON.parse(repurchaseRateChartContainer.dataset['percent-90Days'])
        const percent_180_days = JSON.parse(repurchaseRateChartContainer.dataset['percent-180Days'])
        const percent_365_days = JSON.parse(repurchaseRateChartContainer.dataset['percent-365Days'])

        const seriesData = [];

        const hoverContentText = repurchaseRateChartContainer.dataset.hoverContentText

        seriesData.unshift({
            name: '% repurchasing w/in 30 days',
            color: '#DA25AC',
            fillColor: {
                linearGradient: [0, 0, 0, 400],
                stops: [
                    [0, 'rgba(218, 37, 173, .2)'],
                    [1, 'rgba(218, 37, 173, 0)']
                ]
            },
            data: percent_30_days,
            zoneAxis: 'x',
            zones: [{value: dashStartMarkers['start_dash_30_days']},{dashStyle: 'dash', fillColor: 'rgba(0,0,0,0)'}]
        });
        seriesData.push({
            name: '% repurchasing w/in 90 days',
            color: '#63CFEF',
            fillColor: {
                linearGradient: [0, 0, 0, 400],
                stops: [
                    [0, 'rgba(99, 207, 239, .2)'],
                    [1, 'rgba(99, 207, 239, 0)']
                ]
            },
            data: percent_90_days,
            zoneAxis: 'x',
            zones: [{value: dashStartMarkers['start_dash_90_days']},{dashStyle: 'dash', fillColor: 'rgba(0,0,0,0)'}]
        });
        seriesData.push({
            name: '% repurchasing w/in 180 days',
            color: '#8DC410',
            fillColor: {
                linearGradient: [0, 0, 0, 400],
                stops: [
                    [0, 'rgba(141, 196, 16, .2)'],
                    [1, 'rgba(141, 196, 16, 0)']
                ]
            },
            data: percent_180_days,
            zoneAxis: 'x',
            zones: [{value: dashStartMarkers['start_dash_180_days']},{dashStyle: 'dash', fillColor: 'rgba(0,0,0,0)'}]
        });
        seriesData.push({
            name: '% repurchasing w/in 365 days',
            color: '#EC9E0E',
            fillColor: {
                linearGradient: [0, 0, 0, 400],
                stops: [
                    [0, 'rgba(236, 158, 14, .2)'],
                    [1, 'rgba(236, 158, 14, 0)']
                ]
            },
            data: percent_365_days,
            zoneAxis: 'x',
            zones: [{value: dashStartMarkers['start_dash_365_days']},{dashStyle: 'dash', fillColor: 'rgba(0,0,0,0)'}]
        });

        const highChart = Highcharts.chart(repurchaseRateChartContainer.id, {
            chart: {
                type: 'area',
                style: {
                    fontFamily: "Inter",
                    color: '#475666'
                }
            },
            title: {
                text: null
            },
            xAxis: {
                title: {
                    enabled: false
                },
                type: 'category',
                categories: categories,
                labels: {
                    formatter: function(){
                        if (this.isLast || this.isFirst) {
                            return this.value
                        }
                    },
                    style: {
                        fontWeight: '400',
                        color: '#A8ABB0',
                        textOverflow: 'none',
                        whiteSpace: 'nowrap'
                    },
                    rotation: 0
                },
            },
            yAxis: {
                min: 0,
                title: {
                    enabled: false
                },
                labels: {
                    formatter: function(){
                        return `${this.value}%` ;
                    },
                    style: {
                        fontWeight: '400',
                        fontSize: '13px',
                        color: '#A8ABB0'
                    }
                }
            },
            legend: {
                enabled: false,
            },
            tooltip: {
                backgroundColor: 'transparent',
                borderWidth: 0,
                padding: 0,
                shared: true,
                style: {
                    fontSize: '13px',
                    color: '#475666'
                },
                useHTML: true,
                shadow: false,
                crosshairs: {
                    width: 2
                },
                formatter: function () {
                    var points = this.points;
                    var xIndex = points[0].point.index; // index of month/year
                    var pointsLength = points.length;
                    var tooltipMarkup = pointsLength ?  'With <b>' + numberOfTotalRepurchasersArray[xIndex] + '</b> ' + hoverContentText + ' <b>' + this.x + '</b>:<br/>' : '';
                    var index;

                    for(index = 0; index < pointsLength; index += 1) {
                        var yIndex = points[index].series.index // index of series number 0 = 90 days series, 1 = 180 days series, 2 = 365 days series
                        var numberOfRepurchasersInSeries = numberOfRepurchasersSeriesArrays[yIndex][xIndex];
                        var seriesName = points[index].series.name.substr(14)
                        tooltipMarkup += '<span style="color:' + points[index].series.color + '">\u25CF</span> <b>' + numberOfRepurchasersInSeries + ' (' + points[index].y + '%)</b> repurchased' + seriesName + '<br/>'; // make dynamic for returning customers chart
                    }

                    return tooltipMarkup;
                }
            },
            credits: {
                enabled: false
            },
            colors: ['#63CFEF', '#8DC410', '#EC9E0E', '#DA25AD'],
            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: false
                    },
                    turboThreshold: 0,
                    marker: {
                        enabled: false
                    }
                },
                areaspline: {
                    lineWidth: 3,
                    marker: {
                        symbol: 'circle',
                        enabled: false
                    }
                },
            },
            series: seriesData
        });

        // Re-scale chart when closing filters box or left main menu
        $('.btn-closefilters, #left-main-menu-toggle').click( function (e) {
            e.preventDefault();
            highChart.reflow();
        });
}

//--Basic user blurred/disabled Line Chart
export function basicUserDisabledLineChart() {

    var chart_containers = $('.basic-user-line-chart-container');

    if ( chart_containers.length != 0 )
    {
        chart_containers.each(function() {

            var chart_identifier = $(this).attr('id');
            var categories = ["Mar 2019", "Apr 2019", "May 2019", "Jun 2019", "Jul 2019", "Aug 2019", "Sep 2019", "Oct 2019", "Nov 2019", "Dec 2019", "Jan 2020", "Feb 2020"];
            var percent_30_days = ["", 0.7, 3.7, 4.0, 4.4, 8.0, 7.0, 9.3, 12.0, 13.0, 15.3, 16];
            var percent_90_days = ["", 1.0, 4.7, 5.0, 5.4, 10.0, 8.0, 11.3, 14.0, 16.0, 18.3, ""];
            var percent_180_days = ["", 2.4, 5.7, 6.0, 6.4, 11.0, 9.2, 12.8, 16.1, 20.0, "", ""];
            var percent_365_days = ["", 4.0, 6.7, 7.0, 7.4, 13.0, 10.0, 17.2, "", "", "", ""];
            var series = [{
                name: null,
                color: '#63CFEF',
                fillColor: {
                    linearGradient: [0, 0, 0, 300]
                },
                data: percent_90_days
            }];

            if (chart_identifier !== "basic-user-chart-new-customers--single-series") {
                series.unshift({
                        name: null,
                        color: '#DA25AC',
                        fillColor: {
                            linearGradient: [0, 0, 0, 300]
                        },
                        data: percent_30_days
                    })
                series.push({
                        name: null,
                        color: '#8DC410',
                        fillColor: {
                            linearGradient: [0, 0, 0, 300]
                        },
                        data: percent_180_days
                    },
                    {
                        name: null,
                        color: '#EC9E0E',
                        fillColor: {
                            linearGradient: [0, 0, 0, 300]
                        },
                        data: percent_365_days
                    })
            }

            var chart_container = Highcharts.chart(chart_identifier, {
                chart: {
                    type: 'line',
                    style: {
                        fontFamily: "Inter",
                        color: '#475666'
                    }
                },
                title: {
                    text: null
                },
                legend: {
                    layout: 'horizontal',
                    align: 'center',
                    verticalAlign: 'top',
                    borderWidth: 0,
                    backgroundColor: '#FFF',
                    itemDistance: 30,
                    squareSymbol: false,
                    symbolWidth: 28,
                    symbolHeight: 11,
                    symbolPadding: 8,
                    itemStyle: {
                        fontSize: '13px',
                        fontWeight: '700',
                        color: '#475666'
                    }
                },
                xAxis: {
                    title: {
                        text: null
                    },
                    type: 'category',
                    categories: categories,
                    labels: {
                        formatter: function(){
                            return moment(this.value).format('MMM<br>YYYY');
                        },
                        style: {
                            fontWeight: '300',
                            color: '#475666'
                        }
                    },
                    tickmarkPlacement: 'on',
                    minorGridLineWidth: 0,
                    minorTickInterval: 'auto',
                    minorTickLength: 10,
                    minorTickWidth: 1
                },
                yAxis: {
                    title: {
                        text: null
                    },
                    labels: {
                        style: {
                            fontWeight: '300',
                            color: '#475666'
                        }
                    }
                },
                tooltip: { enabled: false },
                credits: {
                    enabled: false
                },
                colors: ['#000000', '#3d3d3d', '#606060', '#727272'],
                plotOptions: {
                    series: {
                        enableMouseTracking: false,
                        animation: false,
                        turboThreshold: 0,
                        marker: {
                            enabled: false
                        },
                        states: {
                            inactive: {
                                opacity: 1,
                            },
                            hover: {
                                enabled: false
                            },
                        },
                        events: {
                            legendItemClick: function () {
                                return false;
                            }
                        }
                    },
                    areaspline: {
                        lineWidth: 3,
                        marker: {
                            symbol: 'circle',
                            enabled: false
                        }
                    },
                },
                series: series
            });
        });
    }
}

//--Basic user blurred/disabled Bar Chart
export function basicUserDisabledBarChart() {

    var chart_containers = $('.basic-user-bar-chart-container');

    if ( chart_containers.length != 0 )
    {
        chart_containers.each(function() {

            var chart_identifier = $(this).attr('id');
            var categories = ["0-7", "8-15", "16-30", "31-60", "61-90", "91-120", "121-150", "151-180", "181-210", "211-240", "241-270", "271-300", "301-330", "331-360", "361+"]
            var percentages_array = [30, 14, 13, 13, 9, 7, 5, 4, 3, 3, 2, 2, 1, 1, 3]
            var cumulative_percentages = [];
            for (var i = 0; i < percentages_array.length; i ++) {
                if (i == 0) {
                    cumulative_percentages[i] = percentages_array[i];
                } else {
                    cumulative_percentages[i] = cumulative_percentages[i - 1] + percentages_array[i];
                }
                cumulative_percentages[i] = Number(parseFloat(cumulative_percentages[i]).toFixed(2));
            }

            var chart_container = Highcharts.chart(chart_identifier, {
                chart: {
                    style: {
                        fontFamily: "Inter",
                        color: '#475666'
                    }
                },
                colors: ['#a5a5a5', '#ededed'],
                title: {
                    text: null
                },
                xAxis: {
                    categories: categories,
                    title: {
                        text: null
                    },
                    labels: {
                        style: {
                            fontWeight: '700',
                            fontSize: '13px',
                            color: '#475666'
                        }
                    }
                },
                yAxis: [
                    {
                        title: {
                            text: null,
                        },
                        max: 100
                    }
                ],
                credits: {
                    enabled: false
                },
                legend: {
                    layout: 'horizontal',
                    align: 'center',
                    verticalAlign: 'top',
                    borderWidth: 0,
                    backgroundColor: '#FFF',
                    itemDistance: 30,
                    squareSymbol: false,
                    symbolWidth: 28,
                    symbolHeight: 11,
                    symbolPadding: 8,
                    itemStyle: {
                        fontSize: '13px',
                        fontWeight: '700',
                        color: '#a5a5a5'
                    }
                },
                tooltip: { enabled: false },
                series: [{
                    type: 'column',
                    name: null,
                    data: percentages_array,
                    color: 'rgba(99, 207, 239, .9)',
                    dataLabels: {
                        enabled: true,
                        format: '{y} %',
                        color: 'rgba(99, 207, 239, .9)'
                    }
                },
                    {
                        name: null,
                        data: cumulative_percentages,
                        color: 'rgba(236, 158, 14, .9)',
                        dataLabels: {
                            enabled: true,
                            format: '{y} %',
                            color: 'rgba(236, 158, 14, .9)'
                        }
                    }],
                plotOptions: {
                    column: {
                        enableMouseTracking: false,
                        animation: false,
                        turboThreshold: 0,
                        marker: {
                            enabled: false
                        },
                        states: {
                            inactive: {
                                opacity: 1,
                            },
                            hover: {
                                enabled: false
                            },
                        },
                        events: {
                            legendItemClick: function () {
                                return false;
                            }
                        },
                        dataLabels: {
                            enabled: true,
                            color: '#a5a5a5'
                        }
                    },
                    series: {
                        enableMouseTracking: false,
                        animation: false,
                        turboThreshold: 0,
                        marker: {
                            enabled: false
                        },
                        states: {
                            inactive: {
                                opacity: 1,
                            },
                            hover: {
                                enabled: false
                            },
                        },
                        events: {
                            legendItemClick: function () {
                                return false;
                            }
                        }
                    }
                }
            });

            // Re-scale chart when closing filters box or left main menu
            $('.btn-closefilters, #left-main-menu-toggle').click( function (e) {
                e.preventDefault();
                chart_container.reflow();
            });
        });
    }
}

export function subscriptionCharts() {
    if ($('#subscription-chart-container-monthly-recurring-revenue').length) {
        subscriptionChart('subscription-chart-container-monthly-recurring-revenue')
    }

    if ($('#subscription-chart-container-active-subscribers').length) {
        subscriptionChart('subscription-chart-container-active-subscribers')
    }

    if ($('#subscription-chart-container-new-subscribers').length) {
        subscriptionChart('subscription-chart-container-new-subscribers')
    }

    if ($('#subscription-chart-container-average-revenue-per-subscriber').length) {
        subscriptionChart('subscription-chart-container-average-revenue-per-subscriber')
    }

    if ($('#subscription-chart-container-subscriber-lifetime-value').length) {
        subscriptionChart('subscription-chart-container-subscriber-lifetime-value')
    }

    if ($('#subscription-chart-container-subscriber-churn-rate').length) {
        subscriptionChart('subscription-chart-container-subscriber-churn-rate')
    }
}

export function subscriptionChart(name) {

    var cfg = {
        chart: {
            type: 'line',
            style: {
                fontFamily: "Inter",
                color: '#475666'
            },
            events: {
                // load: requestData
            }
        },
        title: {
            text: null
        },
        legend: {
            enabled: false,
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom',
            borderWidth: 0,
            backgroundColor: '#FFF',
            itemDistance: 30,
            squareSymbol: false,
            symbolWidth: 28,
            symbolHeight: 11,
            symbolPadding: 8,
            itemStyle: {
                fontSize: '13px',
                fontWeight: '700',
                color: '#475666'
            }
        },
        xAxis: {
            type: 'category',
            labels: {
                formatter: function(){
                    return moment(this.value).format('DD-MMM-YY');
                },
                style: {
                    fontWeight: '100',
                    fontSize: '13px',
                    color: '#475666'
                }
            },
            tickmarkPlacement: 'on',
            minorGridLineWidth: 0,
            minorTickInterval: 'auto',
            minorTickLength: 10,
            minorTickWidth: 1
        },
        yAxis: {
            title: {
                text: $("#" + name).attr('chart-y-axis-label'),
                margin: 30
            },
            labels: {
                formatter: function(){
                    // do not show y-axis value if it is the only one
                    return this.isFirst && this.isLast ? "" : this.value
                }
            },
        },
        tooltip: {
            backgroundColor: 'transparent',
            borderWidth: 0,
            padding: 0,
            shared: true,
            style: {
                fontSize: '13px',
                color: '#475666'
            },
            useHTML: true,
            shadow: false,
            formatter: function () {
              var s = moment(this.points[0].key).format('DD-MMM-YY');

              $.each(this.points, function () {
                  s += '<br/>' + this.series.name + ' <b>' + (Math.round(this.y * 100) / 100) + '</b> ';
              });

              return s;
            }
        },
        credits: {
            enabled: false
        },
        colors: ['#63CFEF', '#8DC410', '#EC9E0E', '#DA25AD'],
        plotOptions: {
            series: {
                turboThreshold: 0,
                connectNulls: true
            },
            areaspline: {
                lineWidth: 3,
                marker: {
                    symbol: 'circle',
                    enabled: false
                }
            },
        },
    };

// if (chart_data.no_spline)
// {
//     cfg.chart.type='area';
// }
//
// if (chart_data.money_symbol)
// {
//     cfg.tooltip.valueSuffix = " " + chart_data.money_symbol;
//     cfg.yAxis = [];
//     cfg.yAxis[0] = {};
//     cfg.yAxis[0].labels = {};
//     cfg.yAxis[0].labels.format = "{value} " + chart_data.money_symbol;
// }

    cfg.series = [];

//var chart_id = $ch.attr('id');


    var serie_styles = [];

    serie_styles[0] = {
        name: 'Current',
        color: '#60CFEF',
        fillColor: {
            linearGradient: [0, 0, 0, 300],
            stops: [
                [0, 'rgba(99, 207, 239, .2)'],
                [1, 'rgba(99, 207, 239, 0)']
            ]
        },
        //data: chart_lines['30 days segment'], // [null, null, null, null, 30, 42, 48, 50, 56]
    };

    serie_styles[1] = {
        name: 'Previous',
        color: '#C83BA8',
        fillColor: {
            linearGradient: [0, 0, 0, 300],
            stops: [
                [0, 'rgba(141, 196, 16, .2)'],
                [1, 'rgba(141, 196, 16, .0)']
            ]
        },
        //data: chart_lines['60 days segment'], //  [null, null, null, null, 34, 48, 65, 68, 65]
    };

    cfg.series.push(serie_styles[0]);
    cfg.series.push(serie_styles[1]);

    var chart = Highcharts.chart(name, cfg);

    var container = $('#' + name)

    chart.series[0].setData(container.data('series-current'))
    chart.series[1].setData(container.data('series-previous'))
};


// Customer base chart for the hidden dashboard-2
export function customerBaseChartDashboard() {
    var customer_base_dashboard_chart_container = $('#customer-base-chart-dashboard');
    if ( customer_base_dashboard_chart_container.length != 0 ){
        var cfg = {
            chart: {
                type: 'column', // Changle this line to 'area' if we want to reset the chart
                style: {
                    fontFamily: "Inter",
                    color: '#475666'
                }
            },
            title: {
                text: null
            },
            legend: {
                enabled: false
            },
            xAxis: {
                type: 'category',
                labels: {
                    formatter: function(){
                        var orders = this.value + 1
                        var orderLabel = (orders === 1) ? 'order' : 'orders'
                        return `${orders} ${orderLabel}`
                    },
                    style: {
                        fontWeight: '700',
                        fontSize: '13px',
                        color: '#475666'
                    }
                },
                tickmarkPlacement: 'on',
                minorGridLineWidth: 0,
                minorTickInterval: 'auto',
                minorTickLength: 10,
                minorTickWidth: 1
            },
            yAxis: {
                min: 0,
                title: 'Order frequency distribution',
                labels: {
                    formatter: function(){
                        return `${this.value}%` ;
                    }
                },
                stackLabels: {
                    enabled: true,
                    style: {
                        fontWeight: '700',
                        fontSize: '13px',
                        color: '#EC9E0E'
                    },
                    formatter: function () {
                        return `+${this.points[0][1] - this.points[0][0]}%`
                    }
                }
            },
            tooltip: {
                backgroundColor: 'transparent',
                borderWidth: 0,
                padding: 0,
                shared: true,
                style: {
                    fontSize: '13px',
                    color: '#475666'
                },
                headerFormat: '<span style="font-size: 16px;font-weight: 700;">{point.key}</span><br/>',
                useHTML: true,
                shadow: false,
                formatter: function () {
                    var orders = this.points[0].key + 1
                    var tooltipMarkup = `<strong>${this.points[0].total}%</strong> of your customers have <strong>${orders}</strong> total orders.</br>This has seen a recent increase of <strong>${this.points[0].y}%</strong>`

                    return tooltipMarkup;
                }
            },
            credits: {
                enabled: false
            },
            colors: ['#63CFEF', '#EC9E0E'],
            plotOptions: {
                column: {
                    stacking: 'normal'
                },
                series: {
                    turboThreshold: 0
                },
                areaspline: {
                    lineWidth: 3,
                    marker: {
                        symbol: 'circle',
                        enabled: false
                    }
                },
            },
        };

        cfg.series = [];
        var serie_styles = [];

        serie_styles[0] = {
            name: 'Percent increase',
            color: '#EC9E0E',
            fillColor: {
                linearGradient: [0, 0, 0, 300],
                stops: [
                    [0, 'rgba(236, 158, 14, .2)'],
                    [1, 'rgba(236, 158, 14, 0)']
                ]
            },
            data: [4,1,9,4,4,4]
        };

        serie_styles[1] = {
            name: 'Orders',
            color: '#63CFEF',
            fillColor: {
                linearGradient: [0, 0, 0, 300],
                stops: [
                    [0, 'rgba(236, 158, 14, .2)'],
                    [1, 'rgba(236, 158, 14, 0)']
                ]
            },
            data: [29,18,12,11,1,5]
        };



        cfg.series.push(serie_styles[0]);
        cfg.series.push(serie_styles[1]);


        Highcharts.chart('customer-base-chart-dashboard', cfg);
    }
}


export function predictionsRevenueChart() {
  var predicted_revenue_chart_container = $('.predicted_revenue_chart_container');
  if ( predicted_revenue_chart_container.length != 0 ){
    var currency = predicted_revenue_chart_container.attr('data-shop-currency')
    var cfg = {
      chart: {
        height: 350,
        x: 0,
        type: 'column', // Changle this line to 'area' if we want to reset the chart
        style: {
          fontFamily: "Inter",
          color: '#475666'
        }
      },
      title: {
        text: "Predicted sales from the existing customer base",
        align: 'left',
        y: 50,
        style: {
          fontSize: "16px",
          color: '#475666'
        }
      },
      legend: {
        layout: 'horizontal',
        align: 'right',
        verticalAlign: 'top',
        borderWidth: 0,
        backgroundColor: '#FFF',
        itemDistance: 30,
        squareSymbol: false,
        symbolWidth: 11,
        symbolHeight: 11,
        symbolPadding: 8,
        itemMarginTop: -5,
        itemMarginBottom: 40,
        itemStyle: {
          fontSize: '13px',
          fontWeight: '500',
          color: '#7D8188'
        }
      },
      xAxis: {
        type: 'category',
        labels: {
          formatter: function(){
            return moment(this.value).format('MMM\'YY');
          },
          style: {
            fontWeight: '400',
            fontSize: '13px',
            color: '#7D8188'
          }
        },
        tickmarkPlacement: 'on',
        minorGridLineWidth: 0,
        minorTickInterval: 'auto',
        minorTickLength: 10,
        minorTickWidth: 1
      },
      yAxis: {
        title: 'Sales'
      },
      credits: {
        enabled: false
      },
      tooltip: {
        backgroundColor: 'transparent',
        borderWidth: 0,
        padding: 0,
        shared: true,
        style: {
          fontSize: '13px',
          color: '#475666'
        },
        headerFormat: '<p style="font-size: 16px;font-weight: 700;">{point.key}</p>',
        useHTML: true,
        shadow: false,
        formatter: function () {
          var points = this.points;
          var pointsLength = points.length;
          var tooltipMarkup = pointsLength ?  '<p style="font-size: 12px;font-weight: 600; margin-bottom: 10px;">' + moment(points[0].key).format("MMMM YYYY") + '</p>' : '';
          var index;
          var percentage;
          if (pointsLength > 1) {
            for(index = 0; index < pointsLength; index += 1) {
              percentage = points[index].series.userOptions.data[points[index].x][2];
              if(index == 1) {
                tooltipMarkup += '<p style="margin-bottom: 5px; width: 340px;font-size:14px;">' + numberWithCommas(points[index].y) + '<span>'+ currency +'</span> in existing customer sales' + percentageFormat(percentage) + '</p>';
              }

              if(index == 0) {
                tooltipMarkup += '<p style="margin-bottom: 5px; width: 340px;font-size:14px;">' + numberWithCommas(points[index].y) + '<span>'+ currency +'</span> in new customer sales' + percentageFormat(percentage) + '</p>';
              }

            }
          } else {
            percentage = points[0].series.userOptions.data[points[0].x - 6][2];
            tooltipMarkup += '<p style="margin-bottom: 5px; width: 400px;font-size:14px;">' + numberWithCommas(points[0].y) + '<span>'+ currency +'</span> in predicted existing customer sales' + percentageFormat(percentage) + '</p>';
          }

          return tooltipMarkup;
        }
      },

      plotOptions: {
        column: {
          stacking: 'normal'
        },
        series: {
          pointWidth: 25,
          states: {
            inactive: { opacity: 1 }
          },
          fillOpacity: 1
        }
      }
    };

    cfg.series = [];
    var serie_styles = [];

    serie_styles[0] = {
      name: 'New customers',
      color: '#8cc330',
      states: {
          hover: {
            color: '#9BD934',
          }
      },
    };

    serie_styles[1] = {
      name: 'Repeat customers',
      color: '#70d3ef',
      states: {
          hover: {
            color: '#85EAFE',
          }
      },
    };

    serie_styles[2] = {
      name: 'Prediction for repeat',
      color: '#dbf4fb',
      borderColor: '#70d3ef',
      dashStyle: 'dash',
      states: {
          hover: {
            color: '#E0F9FE',
            borderColor: '#85EAFE'
          }
      },
    };



     cfg.series.push(serie_styles[0]);
     cfg.series.push(serie_styles[1]);
     cfg.series.push(serie_styles[2]);

    var salesChart = Highcharts.chart('predicted_revenue_chart_container', cfg);
    var chartContainer = $("#predicted_revenue_chart_container")

    salesChart.series[0].setData(JSON.parse(chartContainer.attr('data-new-sales')));
    salesChart.series[1].setData(JSON.parse(chartContainer.attr('data-repeat-sales')));
    salesChart.series[2].setData(JSON.parse(chartContainer.attr('data-predicted-sales')));

  }

  function percentageFormat(num) {
    if (num > 0){
      return '<span style="float:right;color: #00C853;"><i class="lifeicon-arrow-up" style="width: 16px;height: 16px;background: #d9f7e5;border-radius: 50%;margin-right: 3px;font-size: 10px;display:inline-flex;justify-content: center;align-items: center;"></i> <span style="font-weight:500;">'+ numberWithCommas(num) + '%</span></span>';
    }else {
      return '<span style="float:right;color: #F24738;"><i class="lifeicon-arrow-dn" style="width: 16px;height: 16px;background: #fde4e1;border-radius: 50%;margin-right: 3px;font-size: 10px;display:inline-flex;justify-content: center;align-items: center;"></i> <span style="font-weight:500;">'+ numberWithCommas(num) + '%</span></span>';
    }
  }

  function numberWithCommas(x) {
    return x.toLocaleString()
  }

    // Re-scale chart when closing left main menu
    $('#left-main-menu-toggle').click( function (e) {
        e.preventDefault();
        var predictedRevenueChart = $('#predicted_revenue_chart_container');
        if (predictedRevenueChart.length) {
            predictedRevenueChart.highcharts().reflow();
        }
        return true
    });

}

//--Basic user blurred/disabled Predicted sales chart
export function basicUserDisabledPredictedRevenueChart() {
  var basic_user_predicted_revenue_chart_container = $('.basic_user_predicted_revenue_chart_container');
  if (basic_user_predicted_revenue_chart_container.length != 0){
    var cfg = {
      chart: {
        height: 350,
        x: 0,
        type: 'column', // Changle this line to 'area' if we want to reset the chart
        style: {
          fontFamily: "Inter",
          color: '#475666'
        }
      },
      title: {
        text: "Predicted sales from the existing customer base",
        align: 'left',
        y: 50,
        style: {
          fontSize: "16px",
          color: '#475666'
        }
      },
      legend: {
        layout: 'horizontal',
        align: 'right',
        verticalAlign: 'top',
        borderWidth: 0,
        backgroundColor: '#FFF',
        itemDistance: 30,
        squareSymbol: false,
        symbolWidth: 11,
        symbolHeight: 11,
        symbolPadding: 8,
        itemMarginTop: -5,
        itemMarginBottom: 40,
        itemStyle: {
          fontSize: '13px',
          fontWeight: '500',
          color: '#7D8188'
        }
      },
      xAxis: {
        type: 'category',
        labels: {
          formatter: function(){
            return moment(this.value).format('MMM\'YY');
          },
          style: {
            fontWeight: '400',
            fontSize: '13px',
            color: '#7D8188'
          }
        },
        tickmarkPlacement: 'off',
        minorGridLineWidth: 0,
        minorTickInterval: 'auto',
        minorTickLength: 10,
        minorTickWidth: 1
      },
      yAxis: {
        title: 'Sales'
      },
      credits: {
        enabled: false
      },
      tooltip: { enabled: false },
      plotOptions: {
        column: {
          stacking: 'normal'
        },
        series: {
          pointWidth: 25,
          states: {
            hover: { enabled: false },
            inactive: { opacity: 1 }
          },
          events: {
            legendItemClick: function () {
              return false;
            }
          },
          fillOpacity: 1
        }
      }
    };

    cfg.series = [];
    var serie_styles = [];

    serie_styles[0] = {
      name: 'New customers',
      color: '#8cc330',
      data: [['2021-01-01', 25705, 43], ['2021-02-01', 35456, 38], ['2021-03-01', 32809, -7], ['2021-04-01', 25865, -21], ['2021-05-01', 20764, -20], ['2021-06-01', 21287, -94]]
    };

    serie_styles[1] = {
      name: 'Repeat customers',
      color: '#70d3ef',
      data: [['2021-01-01', 87894, -3], ['2021-02-01', 85510, -2], ['2021-03-01', 84395, -1], ['2021-04-01', 81396, -3], ['2021-05-01', 77101, -5], ['2021-06-01', 60201, -88]]
    };

    serie_styles[2] = {
      name: 'Prediction for repeat',
      color: '#dbf4fb',
      borderColor: '#70d3ef',
      dashStyle: 'dash',
      data: [['2021-07-01', 96493, -5], ['2021-08-01', 94416, -2], ['2021-09-01', 86728, -8], ['2021-10-01', 85102, -2], ['2021-11-01', 85770, 1], ['2021-12-01', 84644, -1], ['2022-01-01', 84109, -1], ['2022-02-01', 78277, -7], ['2022-03-01', 77939, 0], ['2022-04-01', 77284, -1], ['2022-05-01', 76755, -1], ['2022-06-01', 75481, -2]]
    };

    cfg.series.push(serie_styles[0]);
    cfg.series.push(serie_styles[1]);
    cfg.series.push(serie_styles[2]);

    var salesChart = Highcharts.chart('basic_user_predicted_revenue_chart_container', cfg);
  }
}

export function incomeStatementChart(chart_type = 'column', show_comparison = false) {
    const income_statement_chart_container = $('#income-statement-chart-container');

    if ( income_statement_chart_container.length !== 0 ){
        const currency = income_statement_chart_container.data('currency');
        const categories = income_statement_chart_container.data('categories');

        const cfg = {
            chart: {
                type: chart_type,
                style: {
                    fontFamily: "Inter",
                    fontWeight: '400',
                    color: '#7D8188'
                },
                // events: {
                //     load: requestData
                // }
            },
            title: {
                text: null
            },
            legend: {
                layout: 'horizontal',
                align: 'left',
                verticalAlign: 'top',
                borderWidth: 0,
                backgroundColor: '#FFF',
                itemDistance: 24,
                squareSymbol: false,
                itemMarginTop: -5,
                itemMarginBottom: 40,
                symbolHeight: 8,
                symbolWidth: 8,
                symbolPadding: 6,
                useHTML: true,
                itemStyle: {
                    fontSize: '14px',
                    fontWeight: '400',
                    color: '#7D8188',
                    lineHeight: '22px',
                    display: 'inline-block',
                    verticalAlign: 'middle'
                }
            },
            xAxis: {
                categories: categories,
                labels: {
                    style: {
                        fontWeight: '400',
                        fontSize: '13px',
                        color: '#7D8188'
                    }
                },
                tickmarkPlacement: 'on',
                minorGridLineWidth: 0,
                minorTickInterval: 'auto',
                minorTickLength: 10,
                minorTickWidth: 1
            },
            yAxis: {
                labels: {
                    format: currency + '{value}'
                },
                title: {
                    text: '',
                    reserveSpace: false
                }
            },
            tooltip: {
                backgroundColor: 'transparent',
                borderWidth: 0,
                padding: 0,
                shared: true,
                style: {
                    fontSize: '13px',
                    color: '#7D8188'
                },
                headerFormat: '<span style="font-size: 16px;font-weight: 700;">{point.key}</span><br/>',
                useHTML: true,
                shadow: false,
                formatter: function () {
                    var points = this.points;
                    var pointsLength = points.length;
                    var headerHTML = pointsLength ?  '<div style="font-size: 12px;font-weight: 700;margin-bottom: 0.5rem;">' + points[0].key + '</div>' : '';
                    var innerHTML = ''
                    var index;

                    for(index = 0; index < pointsLength; index += 1) {
                        if (points[index].point.custom.comp_value) {
                          continue;
                        }
                         
                        let reverseColor = points[index].point.custom.reverse_color
                        let compareValue = points[index].point.custom.compare_value
                        let isPositive = compareValue >= 0
                        let shouldFlipArrow = isPositive;

                        // Flip logic if color is reverse
                        if (reverseColor) {
                            isPositive = !isPositive;
                        }

                        let arrowHTML;
                        let arrowRotateDegrees = (shouldFlipArrow) ? '0' : '180';
                        if (isPositive) {
                            // Green arrow
                            arrowHTML = "<svg style=\"transform:rotate(" + arrowRotateDegrees + "deg)\" width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
                                "<circle cx=\"8\" cy=\"8\" r=\"8\" transform=\"rotate(180 8 8)\" fill=\"#E6FAEE\"/>\n" +
                                "<path d=\"M8.0001 12.8004V2.8004M8.0001 2.8004L11.6001 6.4004M8.0001 2.8004L4.4001 6.4004\" stroke=\"#00C853\" stroke-linecap=\"round\"/>\n" +
                                "</svg>\n"
                        } else {
                            // Red arrow
                            arrowHTML = "<svg style=\"transform:rotate(" + arrowRotateDegrees + "deg)\" width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n" +
                                "<circle cx=\"8\" cy=\"8\" r=\"8\" transform=\"rotate(-180 8 8)\" fill=\"#FEEDEC\"/>\n" +
                                "<path d=\"M8.0001 12.8004V2.80039M8.0001 2.80039L11.6001 6.40039M8.0001 2.80039L4.4001 6.40039\" stroke=\"#F24738\" stroke-linecap=\"round\"/>\n" +
                                "</svg>\n"
                        }

                        let compareTextColor;
                        if (isPositive) {
                            compareTextColor = '#00C853';
                        } else {
                            compareTextColor = '#F24738';
                        }

                        let compareHTML = '<div style="display:flex">' + arrowHTML + '<b style="color:' + compareTextColor + ';">' + compareValue + '&#37;</b></div>'

                        innerHTML += '<tr style="margin-bottom: 0.25rem;"><td style="padding-right: 1rem"><span style="padding-right:0.25rem;color:' + points[index].series.color + '">\u25CF</span>' + points[index].series.name + '</td><td style="padding-right: 1rem;text-align: right"><b>' + currency + points[index].y.toLocaleString() + '</b></td>' + '<td>' +  compareHTML + '</td></tr>';
                    }

                    return  `<div style="color:#475666">${headerHTML}<table>${innerHTML}</table></div>`;
                }
            },
            credits: {
              enabled: false
            },
            plotOptions: {
              series: {
                turboThreshold: 0,
                events: {
                  legendItemClick: function() {
                    var name = this.name;
                    var _i = this._i;
                    Highcharts.each(this.chart.series, function(p, i) {
                      if (p.name.includes(name) && _i !== p._i) {
                        (!p.visible) ? p.show(): p.hide()
                      }
                    })
                  }
                }
              },
              areaspline: {
                  lineWidth: 3,
                  marker: {
                      symbol: 'circle',
                      enabled: false
                  }
              },
            },
        };

        cfg.series = [];
        const seriesData = income_statement_chart_container.data('series-data');
        const compSeriesData = income_statement_chart_container.data('comp-series-data');
        
        if (show_comparison) {
          cfg.series.push({
              name: 'Comp Total sales',
              showInLegend: false,
              color: {
                pattern: {
                  backgroundColor: '#e7f8fd',
                  color: '#63CFEF',
                  path: {
                    d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
                    strokeWidth: 2
                  },
                  width: 10,
                  height: 10
                }
              },
              data: compSeriesData[0].slice(0, seriesData[0].length)
          });

          cfg.series.push({
              name: 'Total sales',
              color: '#63CFEF',
              fillColor: {
                  linearGradient: [0, 0, 0, 300],
                  stops: [
                      [0, 'rgba(236, 158, 14, .2)'],
                      [1, 'rgba(236, 158, 14, 0)']
                  ]
              },
              data: seriesData[0]
          });

          cfg.series.push({
              name: 'Comp Marketing',
              color: '#8DC410',
              showInLegend: false,
              color: {
                pattern: {
                  backgroundColor: '#eef6e0',
                  color: '#8DC410',
                  path: {
                    d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
                    strokeWidth: 2
                  },
                  width: 10,
                  height: 10
                }
              },
              fillColor: {
                  linearGradient: [0, 0, 0, 300],
                  stops: [
                      [0, 'rgba(141, 196, 16, .2)'],
                      [1, 'rgba(141, 196, 16, .0)']
                  ]
              },
              data: compSeriesData[1].slice(0, seriesData[1].length)
          });

          cfg.series.push({
              name: 'Marketing',
              color: '#8DC410',
              fillColor: {
                  linearGradient: [0, 0, 0, 300],
                  stops: [
                      [0, 'rgba(99, 207, 239, .2)'],
                      [1, 'rgba(99, 207, 239, 0)']
                  ]
              },
              data: seriesData[1]
          });

          cfg.series.push({
              name: 'Comp COGS',
              color: '#EC9E0E',
              showInLegend: false,
              color: {
                pattern: {
                  backgroundColor: '#fdf1df',
                  color: '#EC9E0E',
                  path: {
                    d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
                    strokeWidth: 2
                  },
                  width: 10,
                  height: 10
                }
              },
              fillColor: {
                  linearGradient: [0, 0, 0, 300],
                  stops: [
                      [0, 'rgba(141, 196, 16, .2)'],
                      [1, 'rgba(141, 196, 16, .0)']
                  ]
              },
              data: compSeriesData[2].slice(0, seriesData[2].length)
          });

          cfg.series.push({
              name: 'COGS',
              color: '#EC9E0E',
              fillColor: {
                  linearGradient: [0, 0, 0, 300],
                  stops: [
                      [0, 'rgba(141, 196, 16, .2)'],
                      [1, 'rgba(141, 196, 16, .0)']
                  ]
              },
              data: seriesData[2]
          });

          cfg.series.push({
              name: 'Comp Net profit',
              color: '#DA25AD',
              showInLegend: false,
              color: {
                pattern: {
                  backgroundColor: '#fadff2',
                  color: '#DA25AD',
                  path: {
                    d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
                    strokeWidth: 2
                  },
                  width: 10,
                  height: 10
                }
              },
              data: compSeriesData[3].slice(0, seriesData[3].length)
          });

          cfg.series.push({
              name: 'Net profit',
              color: '#DA25AD',
              fillColor: {
                  linearGradient: [0, 0, 0, 300],
                  stops: [
                      [0, 'rgba(141, 196, 16, .2)'],
                      [1, 'rgba(141, 196, 16, .0)']
                  ]
              },
              data: seriesData[3]
          });

  
        }else {
          cfg.series.push({
              name: 'Total sales',
              color: '#63CFEF',
              fillColor: {
                  linearGradient: [0, 0, 0, 300],
                  stops: [
                      [0, 'rgba(236, 158, 14, .2)'],
                      [1, 'rgba(236, 158, 14, 0)']
                  ]
              },
              data: seriesData[0]
          });

          cfg.series.push({
              name: 'Marketing',
              color: '#8DC410',
              fillColor: {
                  linearGradient: [0, 0, 0, 300],
                  stops: [
                      [0, 'rgba(99, 207, 239, .2)'],
                      [1, 'rgba(99, 207, 239, 0)']
                  ]
              },
              data: seriesData[1]
          });

          cfg.series.push({
              name: 'COGS',
              color: '#EC9E0E',
              fillColor: {
                  linearGradient: [0, 0, 0, 300],
                  stops: [
                      [0, 'rgba(141, 196, 16, .2)'],
                      [1, 'rgba(141, 196, 16, .0)']
                  ]
              },
              data: seriesData[2]
          });

          cfg.series.push({
              name: 'Net profit',
              color: '#DA25AD',
              fillColor: {
                  linearGradient: [0, 0, 0, 300],
                  stops: [
                      [0, 'rgba(141, 196, 16, .2)'],
                      [1, 'rgba(141, 196, 16, .0)']
                  ]
              },
              data: seriesData[3]
          });
        }

        Highcharts.chart('income-statement-chart-container', cfg);
    }
}