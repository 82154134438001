import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="checkbox-select"
export default class extends Controller {
  static values = {
    filterType: String,
    listenForFunnelReportEvents: Boolean,
    ajaxSearchOn: { type: Boolean, default: true }
  }

  static targets = ['toggleBtn', 'select', 'search', 'selectedNodesTable', 'allNodesTable', 'applyBtn', 'cancelBtn', 'clearBtn']

  connect() {
    initCheckboxSelect(this)
  }

  disconnect() {
    if (this.listenForFunnelReportEventsValue) {
      document.removeEventListener("kind-select-value-change", this.conditionSelectValueChangeHandlerListener)
      document.removeEventListener("operator-select-value-change", this.operatorSelectValueChangeHandlerListener)
    }

    document.removeEventListener("click", this.handleClickOutsideCheckboxListener)
  }

  conditionSelectValueChangeHandler(allNodesDatatable, event) {
    const eventFunnelStepFilterControllerElement = event.detail.funnelStepFilterControllerElement
    const currentFunnelStepFilterControllerElement = this.element.closest("[data-controller='funnel-step-filter']")

    // Check to see if this checkboxSelect corresponds with the changed condition select...
    if (eventFunnelStepFilterControllerElement === currentFunnelStepFilterControllerElement) {
      clearSelectedValues(this.selectedNodesTableTarget, this.selectTarget)
      clearToggleBtnValues(this)

      // Open checkbox select and focus search input
      this.element.classList.add('active')

      const filterType = event.detail.filterType
      const newAjaxURL = `/funnel/step_filters?filter_type=${filterType}`

      if (filterType !== 'order_value_filter') {
        allNodesDatatable.ajax.url(newAjaxURL).load();
        this.ajaxSearchOnValue = true
      } else {
        // Don't perform search for numeric value
        this.ajaxSearchOnValue = false
      }

      $(this.searchTarget).focus()
    }
  }

  operatorSelectValueChangeHandler(allNodesDatatable, event) {
    const eventFunnelStepFilterControllerElement = event.detail.funnelStepFilterControllerElement
    const currentFunnelStepFilterControllerElement = this.element.closest("[data-controller='funnel-step-filter']")

    // Check to see if this checkboxSelect corresponds with the changed operator select...
    if (eventFunnelStepFilterControllerElement === currentFunnelStepFilterControllerElement) {
      const funnelDefinitionForm = this.element.closest("[data-funnel-definition-form-target=\"funnelForm\"]")
      const operatorType = event.detail.operatorType

      // Check if the "operator" has changed the type, like from 'equals' to 'contains' or from 'equals' to 'does_not_contain', etc.
      if (operatorType === 'contains' || operatorType === 'does_not_contain') {
        if (!this.element.classList.contains('check-select-btn-container--contains-operator')) {
          // change to contains
          clearEverything(this)
          this.element.classList.add('check-select-btn-container--contains-operator')
        }
      } else {
        if (this.element.classList.contains('check-select-btn-container--contains-operator')) {
          clearEverything(this)
          this.element.classList.remove('check-select-btn-container--contains-operator')
        }
      }

      funnelDefinitionForm.requestSubmit()
    }
  }

  handleClickOutsideCheckbox(checkboxSelectContainer, event) {
    if (checkboxSelectContainer.classList.contains('active') && !checkboxSelectContainer.contains(event.target)) {
      checkboxSelectContainer.classList.remove('active')
    }
  }
}

const initCheckboxSelect = (checkboxSelectController) => {
  const checkboxSelectContainer = checkboxSelectController.element
  const toggleBtn = checkboxSelectController.toggleBtnTarget
  const allNodesTable = checkboxSelectController.allNodesTableTarget
  const selectedNodesTable = checkboxSelectController.selectedNodesTableTarget
  const nodesSearchInput = $(checkboxSelectController.searchTarget)
  const selectElement = checkboxSelectController.selectTarget
  const applyBtn = checkboxSelectController.applyBtnTarget
  const cancelBtn = checkboxSelectController.cancelBtnTarget
  const clearBtn = checkboxSelectController.clearBtnTarget

  const funnelDefinitionForm = checkboxSelectContainer.closest("[data-funnel-definition-form-target=\"funnelForm\"]")

  const checkboxSelectDatatableOptions = buildCheckboxSelectDatatableOptions(checkboxSelectController)
  const allNodesDatatable = $(allNodesTable).DataTable(checkboxSelectDatatableOptions);

  toggleBtn.addEventListener('click', (event) => {
    if (checkboxSelectContainer.classList.contains('active')) {
      // Select is open, so close it
      checkboxSelectContainer.classList.remove('active')
    } else {
      // Select is closed, so open it
      checkboxSelectContainer.classList.add('active')
      nodesSearchInput.focus()

      if (checkboxSelectController.ajaxSearchOnValue) allNodesDatatable.ajax.reload()
    }
  })

  cancelBtn.addEventListener('click', (event) => {
    checkboxSelectContainer.classList.remove('active')
  })

  selectedNodesTable.querySelectorAll('tbody tr').forEach((row) => {
    createToggleCheckmarkEventListener(row)
  })

  clearBtn.addEventListener('click', (event) => {
    clearEverything(checkboxSelectController)
    if (checkboxSelectController.ajaxSearchOnValue) allNodesDatatable.ajax.reload()

    if (checkboxSelectController.listenForFunnelReportEventsValue) {
      // Fire form submit when this is updated
      funnelDefinitionForm.requestSubmit()
    }
  })

  nodesSearchInput.on("keypress keydown", delay(function (event) {
    if (event.key === 'Enter' || event.keyCode === 13) {
      applyBtn.click();
    } else {
      redrawSelectedValuesTableAndSelectElement(selectedNodesTable, allNodesTable, selectElement, clearBtn, checkboxSelectController)
      if (checkboxSelectController.ajaxSearchOnValue) allNodesDatatable.ajax.reload()
    }
  }, 300));

  applyBtn.addEventListener('click', (event) => {
    checkboxSelectContainer.classList.remove('active')
    let selectedValues;
    const usesSingleSearchValue = checkUsesSingleSearchValue(checkboxSelectController)
    if (usesSingleSearchValue) {
      selectedValues = redrawTableAndSelectElementWithSingleSearchValue(selectedNodesTable, selectElement, clearBtn, checkboxSelectController.searchTarget, checkboxSelectController)
    } else {
      selectedValues = redrawSelectedValuesTableAndSelectElement(selectedNodesTable, allNodesTable, selectElement, clearBtn, checkboxSelectController)
    }

    if (checkboxSelectController.ajaxSearchOnValue) {
      toggleBtn.textContent = selectedValues.join(', ')
      allNodesDatatable.ajax.reload()
    } else {
      // 'Numerical' kinds (Order value filter)
      if (selectedValues.length) toggleBtn.textContent = selectedValues.join(', ');
    }

    if (checkboxSelectController.listenForFunnelReportEventsValue) {
      // Fire form submit when this is updated
      funnelDefinitionForm.requestSubmit()
    }
  })


  if (checkboxSelectController.listenForFunnelReportEventsValue) {
    // kind-select-value-change is a custom event emitted by funnel_step_filter_controller.js
    checkboxSelectController.conditionSelectValueChangeHandlerListener = checkboxSelectController.conditionSelectValueChangeHandler.bind(checkboxSelectController, allNodesDatatable)
    document.addEventListener("kind-select-value-change", checkboxSelectController.conditionSelectValueChangeHandlerListener)

    // operator-select-value-change is a custom event emitted by funnel_step_filter_controller.js
    checkboxSelectController.operatorSelectValueChangeHandlerListener = checkboxSelectController.operatorSelectValueChangeHandler.bind(checkboxSelectController, allNodesDatatable)
    document.addEventListener("operator-select-value-change", checkboxSelectController.operatorSelectValueChangeHandlerListener)
  }

  checkboxSelectController.handleClickOutsideCheckboxListener = checkboxSelectController.handleClickOutsideCheckbox.bind(null, checkboxSelectContainer)
  document.addEventListener("click", checkboxSelectController.handleClickOutsideCheckboxListener)
}

const delay = (fn, ms) => {
  let timer = 0
  return function(...args) {
    clearTimeout(timer)
    timer = setTimeout(fn.bind(this, ...args), ms || 0)
  }
}

const createSelectedValuesTableRow = (tableBodyElement, value, id) => {
  const rowElement = tableBodyElement.insertRow()
  rowElement.classList.add('checked')

  const firstCell = rowElement.insertCell()
  firstCell.innerHTML = checkboxTableCellContent
  firstCell.style.width = '1%'

  const secondCell = rowElement.insertCell()
  secondCell.innerText = value

  rowElement.setAttribute('data-node-value', value)
  rowElement.setAttribute('data-node-id', id)
  createToggleCheckmarkEventListener(rowElement)
}

const createToggleCheckmarkEventListener = (rowElement) => {
  rowElement.addEventListener('click', (event) => {
    if (rowElement.classList.contains('checked')) {
      rowElement.classList.remove('checked')
    } else {
      rowElement.classList.add('checked')
    }
  })
}

const redrawSelectedValuesTableAndSelectElement = (selectedNodesTable, allNodesTable, selectElement, clearBtn, checkboxSelectController) => {
  // Get existing selected values from Table
  const existingSelectedValues = Array.from(selectedNodesTable.querySelectorAll('tr.checked')).map((row) => {
    return {
      value: row.getAttribute('data-node-value'),
      id: row.getAttribute('data-node-id')
    }
  })

  // Get selected values from dropdown table
  const recentlySelectedValuesElements = allNodesTable.querySelectorAll('tr.checked')
  const recentlySelectedValues = Array.from(recentlySelectedValuesElements).map((row) => {
    return {
      value: row.getAttribute('data-node-value'),
      id: row.getAttribute('data-node-id')
    }
  })

  // find unique values
  const allSelectedValues = existingSelectedValues.concat(recentlySelectedValues)
  const uniqueSelectedValues = [...new Map(allSelectedValues.map((selectedValue) => [selectedValue.id, selectedValue])).values()]

  // clear table and select
  clearSelectedValues(selectedNodesTable, selectElement)

  // re-create unique values
  if (uniqueSelectedValues.length > 0) {
    const tableHead = selectedNodesTable.createTHead()
    const tableHeadRow = tableHead.insertRow()
    tableHeadRow.insertCell(0).outerHTML = "<th colspan='2'>Selected</th>"

    const tableBodyElement = selectedNodesTable.createTBody()
    uniqueSelectedValues.forEach((selectedValue) => {
      createSelectedValuesTableRow(tableBodyElement, selectedValue.value, selectedValue.id)
      createOptionForSelect(selectElement, selectedValue.value, selectedValue.id)
    })

    clearBtn.classList.remove('hidden')
  } else {
    // If values are empty
    clearToggleBtnValues(checkboxSelectController)
  }

  return uniqueSelectedValues.map((selectedValues) => selectedValues.value)
}

const redrawTableAndSelectElementWithSingleSearchValue = (selectedNodesTable, selectElement, clearBtn, searchElement, checkboxSelectController) => {
  // clear table and select
  clearSelectedValues(selectedNodesTable, selectElement)

  const selectedValue = {
    value: searchElement.value,
    id: searchElement.value
  }

  // re-create value
  if (selectedValue.value) {
    const tableHead = selectedNodesTable.createTHead()
    const tableHeadRow = tableHead.insertRow()
    tableHeadRow.insertCell(0).outerHTML = "<th colspan='2'>Selected</th>"

    const tableBodyElement = selectedNodesTable.createTBody()
    createSelectedValuesTableRow(tableBodyElement, selectedValue.value, selectedValue.id)
    createOptionForSelect(selectElement, selectedValue.value, selectedValue.id)

    clearBtn.classList.remove('hidden')

    return [selectedValue.value]
  } else {
    // If values are empty
    clearToggleBtnValues(checkboxSelectController)

    return []
  }
}

const createOptionForSelect = (selectElement, value, id) => {
  const optionElement = document.createElement('option');
  optionElement.value = id;
  optionElement.innerHTML = value;
  optionElement.setAttribute('selected', '')
  optionElement.selected = true
  selectElement.appendChild(optionElement);
}

const removeRedudantSelectedRows = (selectElement, allNodesTable) => {
  const selectedIds = Array.from(selectElement.querySelectorAll('option')).map((option) => option.value)

  if (selectedIds.length > 0) {
    const querySelectorString = selectedIds.map((selectedId) => `tr[data-node-id='${CSS.escape(selectedId)}']`).join(', ')
    allNodesTable.querySelectorAll(querySelectorString).forEach((selectedRow) => selectedRow.remove())
  }
}

const checkUsesSingleSearchValue = (checkboxSelectController) => {
  const checkboxSelectContainer = checkboxSelectController.element
  let operatorSelectValue;
  if (checkboxSelectController.listenForFunnelReportEventsValue) {
    operatorSelectValue = checkboxSelectContainer.closest("[data-controller=\"funnel-step-filter\"]").querySelector("[data-funnel-step-filter-target=\"operatorSelect\"]").value
  }

  return (operatorSelectValue === 'contains' || operatorSelectValue === 'does_not_contain' || !checkboxSelectController.ajaxSearchOnValue)
}

const clearSelectedValues = (selectedValuesTable, selectElement) => {
  // clear table and select
  selectedValuesTable.textContent = ''
  selectElement.textContent = ''
}

const clearToggleBtnValues = (checkboxSelectController) => {
  const toggleBtn = checkboxSelectController.toggleBtnTarget
  const clearBtn = checkboxSelectController.clearBtnTarget

  const usesSingleSearchValue = checkUsesSingleSearchValue(checkboxSelectController)
  let toggleBtnText;
  if (usesSingleSearchValue) {
    toggleBtnText = 'Enter value...'
  } else {
    toggleBtnText = 'Select values...'
  }

  toggleBtn.innerText = toggleBtnText
  clearBtn.classList.add('hidden')
}

const clearEverything = (checkboxSelectController) => {
  const selectedNodesTable = checkboxSelectController.selectedNodesTableTarget
  const selectElement = checkboxSelectController.selectTarget
  const nodesSearchInput = $(checkboxSelectController.searchTarget)

  clearSelectedValues(selectedNodesTable, selectElement)
  clearToggleBtnValues(checkboxSelectController)
  nodesSearchInput.val('')
}

const checkboxTableCellContent = `<svg class="checked-input rounded" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 4C0 1.79086 1.79086 0 4 0H12C14.2091 0 16 1.79086 16 4V12C16 14.2091 14.2091 16 12 16H4C1.79086 16 0 14.2091 0 12V4Z" fill="#112C7B"/>
                                    <path d="M12.2069 4.79279C12.3944 4.98031 12.4997 5.23462 12.4997 5.49979C12.4997 5.76495 12.3944 6.01926 12.2069 6.20679L7.20692 11.2068C7.01939 11.3943 6.76508 11.4996 6.49992 11.4996C6.23475 11.4996 5.98045 11.3943 5.79292 11.2068L3.79292 9.20679C3.61076 9.01818 3.50997 8.76558 3.51224 8.50339C3.51452 8.24119 3.61969 7.99038 3.8051 7.80497C3.99051 7.61956 4.24132 7.51439 4.50352 7.51211C4.76571 7.50983 5.01832 7.61063 5.20692 7.79279L6.49992 9.08579L10.7929 4.79279C10.9804 4.60532 11.2348 4.5 11.4999 4.5C11.7651 4.5 12.0194 4.60532 12.2069 4.79279Z" fill="white"/>
                                    <path d="M4 1H12V-1H4V1ZM15 4V12H17V4H15ZM12 15H4V17H12V15ZM1 12V4H-1V12H1ZM4 15C2.34315 15 1 13.6569 1 12H-1C-1 14.7614 1.23858 17 4 17V15ZM15 12C15 13.6569 13.6569 15 12 15V17C14.7614 17 17 14.7614 17 12H15ZM12 1C13.6569 1 15 2.34315 15 4H17C17 1.23858 14.7614 -1 12 -1V1ZM4 -1C1.23858 -1 -1 1.23858 -1 4H1C1 2.34315 2.34315 1 4 1V-1Z" fill="#112C7B"/>
                                  </svg>
                                  <svg class="unchecked-input rounded" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 4C0 1.79086 1.79086 0 4 0H12C14.2091 0 16 1.79086 16 4V12C16 14.2091 14.2091 16 12 16H4C1.79086 16 0 14.2091 0 12V4Z" fill="white"/>
                                    <path d="M4 1H12V-1H4V1ZM15 4V12H17V4H15ZM12 15H4V17H12V15ZM1 12V4H-1V12H1ZM4 15C2.34315 15 1 13.6569 1 12H-1C-1 14.7614 1.23858 17 4 17V15ZM15 12C15 13.6569 13.6569 15 12 15V17C14.7614 17 17 14.7614 17 12H15ZM12 1C13.6569 1 15 2.34315 15 4H17C17 1.23858 14.7614 -1 12 -1V1ZM4 -1C1.23858 -1 -1 1.23858 -1 4H1C1 2.34315 2.34315 1 4 1V-1Z" fill="#D1D5DB"/>
                                  </svg>`

const buildCheckboxSelectDatatableOptions = (checkboxSelectController) => {
  const toggleBtn = checkboxSelectController.toggleBtnTarget
  const allNodesTable = checkboxSelectController.allNodesTableTarget
  const selectedNodesTable = checkboxSelectController.selectedNodesTableTarget
  const nodesSearchInput = $(checkboxSelectController.searchTarget)
  const filterType = checkboxSelectController.filterTypeValue
  const selectElement = checkboxSelectController.selectTarget
  const clearBtn = checkboxSelectController.clearBtnTarget

  return {
    "columnDefs": [
      {
        "targets": 0,
        "searchable": false,
        "render": function ( data, type, row, meta ) {
          return checkboxTableCellContent;
        }
      },{
        "targets": 1,
        "searchable": true,
        "render": function ( data, type, row, meta ) {
          return row;
        }
      }],
    "order": [[ 1, "desc" ]],
    "searching": true,
    "deferLoading": 0,
    "processing": true,
    "serverSide": true,
    "ordering": false,
    "bLengthChange": false,
    "dom": "tS",
    "ajax": {
      "url": `/funnel/step_filters?filter_type=${filterType}`,
      "type": "GET",
      "data": function (data) {
        data.for_datatable = true
        data.search = nodesSearchInput.val()
      }
    },
    "scrollY":        200,
    "scrollCollapse": true,
    "scroller": {
      loadingIndicator: true
    },
    createdRow: function( row, data, dataIndex ) {
      row.querySelector('td:first-child').style.width = '1%'
      row.setAttribute('data-node-value', data)
      row.setAttribute('data-node-id', data)
      createToggleCheckmarkEventListener(row)
    },
    "preDrawCallback": function( settings ) {
      redrawSelectedValuesTableAndSelectElement(selectedNodesTable, allNodesTable, selectElement, clearBtn, checkboxSelectController)
    },
    "drawCallback": function( settings ) {
      removeRedudantSelectedRows(selectElement, allNodesTable)
    }
  }
}