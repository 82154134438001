import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="custom-metrics--formula-box"
export default class extends Controller {
  static values = {
    formula: Array,
    formSubmitterSelector: String,
    metrics: Array
  }

  static targets = ['formulaBox', 'cursor', 'cursorAutocomplete', 'cursorAutocompleteHint', 'templateMetricFormulaInputContainer', 'templateOperatorInputsContainer']

  connect() {
    this.#initFormula()
    this.#initLinkCursorFocusToBox()
    this.#initKeyboardInteractions()
  }

  addMetric(selectedValue) {
    // Add metric (empty or with preselected value), insert it before the cursor
    const formulaBoxCursorContainer = this.cursorTarget.parentElement
    const metricFormulaInput = this.#buildMetricFormulaInput(selectedValue)
    formulaBoxCursorContainer.parentNode.insertBefore(metricFormulaInput, formulaBoxCursorContainer);
    if (selectedValue) {
      this.cursorTarget.focus()
    }
  }

  addOperator(operatorValue) {
    // Add operator, insert it before the cursor
    const operatorInput = this.#buildOperatorInput(operatorValue)
    const formulaBoxCursorContainer = this.cursorTarget.parentElement
    formulaBoxCursorContainer.parentNode.insertBefore(operatorInput, formulaBoxCursorContainer);
    this.cursorTarget.focus()
  }

  #initFormula() {
    // Put the parts of the formula in the formula box when a merchant
    // opens the sidedrawer
    const operatorValues = ['(', ')', '+', '-', '*', '/']

    this.formulaValue.forEach((value) => {
      if (operatorValues.includes(value)) {
        this.addOperator(value)
      } else {
        this.addMetric(value)
      }
    })

    this.#setPlaceholder()
  }

  #initLinkCursorFocusToBox() {
    const formulaBox = this.formulaBoxTarget
    const formulaBoxCursor = this.cursorTarget

    formulaBox.addEventListener('click', (event) => {
      if (event.target === formulaBox) {
        formulaBoxCursor.focus()
      }
    })

    formulaBoxCursor.addEventListener('focusin', (event) => {
      formulaBox.classList.add('focused')
    })

    formulaBoxCursor.addEventListener('focusout', (event) => {
      formulaBox.classList.remove('focused')
    })
  }

  #setPlaceholder() {
    if (this.formulaBoxTarget.childElementCount === 1) {
      // Only show placeholder if the formulaBox is empty and the only child is the input/cursor element.
      this.cursorTarget.placeholder = 'Type to select a metric'
    } else {
      this.cursorTarget.placeholder = ''
    }
  }

  #resetCursor() {
    this.cursorTarget.value = ''
    this.cursorAutocompleteTarget.innerHTML = ''
  }

  #getSelectedValueFromAutocomplete() {
    // From the autocomplete suggested value when typing, or is blank when pressing Enter with nothing typed.
    const lowerCaseAutoCompleteValue = this.cursorAutocompleteTarget.innerHTML.toLowerCase()
    return this.metricsValue.find(pair => pair[0].toLowerCase() === lowerCaseAutoCompleteValue)?.[1] || null;
  }

  #buildMetricFormulaInput(selectedValue) {
    // Create a new metric element using the template in the DOM
    const metricFormulaInputElement = document.createElement('div');
    metricFormulaInputElement.innerHTML = this.templateMetricFormulaInputContainerTarget.innerHTML;

    // Set the selected value if present.
    const selectElement = metricFormulaInputElement.querySelector('select')
    if (selectedValue) {
      selectElement.value = selectedValue
    } else {
      // Set this attribute so that it doesn't open when connected
      selectElement.setAttribute('data-custom-metrics--metric-formula-input-keep-closed-on-connect-value', 'false')
    }

    // Set unique id so that select2 works
    // Set name for form and data-controller for initiating the select2 element
    selectElement.id = `formula_${crypto.randomUUID()}`
    selectElement.name = 'formula[]'
    // By setting this attribute, the select element will be initiliazed by metric_formula_input_controller.js
    // We can put logic from that file here with a refactor.
    selectElement.setAttribute('data-controller', 'custom-metrics--metric-formula-input')

    // Return the built element so it can be added to the formula box
    return metricFormulaInputElement;
  }

  #buildOperatorInput(operatorValue) {
    // Create a new operator element using the template in the DOM
    const operatorInputElement = document.createElement('div');
    operatorInputElement.innerHTML = this.templateOperatorInputsContainerTarget.innerHTML;

    // Set the SVG
    const operatorSVGButtonIds = {
      '(': 'formula_box_add_opening_parenthesis_button',
      ')': 'formula_box_add_closing_parenthesis_button',
      '+': 'formula_box_add_plus_button',
      '-': 'formula_box_add_minus_button',
      '*': 'formula_box_add_multiply_button',
      '/': 'formula_box_add_divide_button'
    };
    const svgButtonId = operatorSVGButtonIds[operatorValue];
    const operatorSVGHTML = svgButtonId ? document.getElementById(svgButtonId).innerHTML : '';
    operatorInputElement.querySelector('.js-svg-container').innerHTML = operatorSVGHTML
    if (['+','-','*'].includes(svgButtonId)) {
      operatorInputElement.querySelector('svg').style.padding = '1.5px'
    }

    // Set the inputs id, value, and name attributes
    const hiddenOperatorInput = operatorInputElement.querySelector('input')
    hiddenOperatorInput.id = `operator_${crypto.randomUUID()}`
    hiddenOperatorInput.name = 'formula[]'
    hiddenOperatorInput.value = operatorValue

    // Return the built element so it can be added to the formula box
    return operatorInputElement;
  }

  #initKeyboardInteractions() {
    const operatorKeys = ["(", ")", "+", "-", "x", "*", "/"]
    const navAndCommandKeys = ["ArrowLeft","ArrowRight","ArrowUp","ArrowDown", "Tab", "Backspace","Delete", " "]
    const keysToPreventDefault = [...operatorKeys, ...navAndCommandKeys]
    const formulaBox = this.formulaBoxTarget
    const formulaBoxCursor = this.cursorTarget
    const formulaBoxCursorContainer = formulaBoxCursor.parentElement
    const cursorAutocomplete = this.cursorAutocompleteTarget

    formulaBoxCursor.addEventListener("keydown", (event) => {
      // Make sure we delete typed values and only move on to remove elements if the cursor value will be empty when on keyup
      if (keysToPreventDefault.includes(event.key) && ['Delete', 'Backspace'].includes(event.key) && formulaBoxCursor.value.length >= 1) {
        cursorAutocomplete.innerHTML = '';
        this.cursorAutocompleteHintTarget.classList.add('hidden')
        this.#setPlaceholder()
        return;
      }

      // The below code could be less repetitive...
      if (keysToPreventDefault.includes(event.key)) {
        if (event.key === "ArrowLeft") {
          event.preventDefault();
          formulaBox.insertBefore(formulaBoxCursorContainer, formulaBoxCursorContainer.previousElementSibling)
          formulaBoxCursor.focus()
          this.#resetCursor()
        } else if (event.key === 'ArrowRight') {
          event.preventDefault();
          formulaBox.insertBefore(formulaBoxCursorContainer, formulaBoxCursorContainer.nextElementSibling.nextElementSibling)
          formulaBoxCursor.focus()
          this.#resetCursor()
        } else if (['ArrowUp', 'ArrowDown'].includes(event.key)) {
          event.preventDefault();
          // Do nothing for now
          this.#resetCursor()
        } else if (operatorKeys.includes(event.key) && formulaBoxCursor.value.length === 0) {
          event.preventDefault();
          // Add formulaBoxCursor.value.length >= 1 to ensure that operators are only added if
          // they are typed at the beginning of the cursor input.
          // Make 'x' and '*' both respond by adding a multiplication operator
          const operatorValue = event.key === 'x' ? '*' : event.key
          this.addOperator(operatorValue)
          this.#resetCursor()
          this.#refreshFormulaBuilder()
        } else if (event.key === 'Backspace') {
          event.preventDefault();
          formulaBoxCursorContainer.previousElementSibling.remove()
          formulaBoxCursor.focus()
          this.#refreshFormulaBuilder()
          this.#resetCursor()
        } else if (event.key === 'Delete') {
          event.preventDefault();
          formulaBoxCursorContainer.nextElementSibling.remove()
          formulaBoxCursor.focus()
          this.#refreshFormulaBuilder()
          this.#resetCursor()
        } else if (event.key === 'Tab') {
          event.preventDefault();
          const selectedValue = this.#getSelectedValueFromAutocomplete()
          this.addMetric(selectedValue)
          this.#resetCursor()
          this.cursorAutocompleteHintTarget.classList.add('hidden')
          this.cursorTarget.style.width = 'auto'
          if (selectedValue) {
            this.#refreshFormulaBuilder()
          }
        } else if (event.key === " " && formulaBoxCursor.value.length === 0) {
          event.preventDefault();
          // Don't allow typing spaces at beginning of input
          this.#resetCursor()
        }

        this.#setPlaceholder()
      }
    });

    formulaBoxCursor.addEventListener('keyup', (event) => {
      // Listen for keyup and fill in the suggested metric if it matches anything from the given metricsValue
      if (formulaBoxCursor.value.length !== 0) {
        let humanizedMetricNames = this.metricsValue.map((option) => option[0])
        let cursorInput = formulaBoxCursor.value;
        cursorAutocomplete.innerHTML = cursorInput;
        let regex = new RegExp('^' + cursorInput + '.*', 'i');

        const metricMatch = humanizedMetricNames.find(name => name.match(regex));
        if (metricMatch) {
          cursorAutocomplete.innerHTML += metricMatch.slice(cursorInput.length);
          this.cursorAutocompleteHintTarget.classList.remove('hidden')
          this.cursorTarget.style.width = `${cursorAutocomplete.offsetWidth}px`;
        } else {
          this.cursorAutocompleteHintTarget.classList.add('hidden')
          this.cursorTarget.style.width = 'auto'
        }
      }
    })
  }

  #refreshFormulaBuilder() {
    // Refresh the validation indicator and the metric preview
    const submitter = document.querySelector(this.formSubmitterSelectorValue)
    // Would love to use submiter.form.requestSubmit(submitter) but it doesnt work for Safari, and no
    // polyfill available for current version of Turbo. Maybe in a future one: https://github.com/hotwired/turbo/issues/1071
    // For now, click the button.
    submitter.click()
  }
}
