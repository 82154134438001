import {Controller} from "@hotwired/stimulus"
import * as CustomerSegmentsHelper from "../customer_segments_helper.js"

// Connects to data-controller="segments-rule-filter"
export default class extends Controller {
  static values = {
    customerOnlyFilters: Array
  }

  static targets = ['orderKindSelect', 'kindSelect', 'operatorSelect', 'valuesSelect', 'searchNode']

  static outlets = ['complex-filters-checkbox-select']

  connect() {
    const segmentsRuleFilterController = this
    const segmentsRuleFilterElement = this.element
    const customerOnlyFilters = this.customerOnlyFiltersValue
    const kindSelect = this.kindSelectTarget
    const searchNode = this.searchNodeTarget
    let previousKindType = getKindType(kindSelect.value)
    const operatorSelect = this.operatorSelectTarget
    const valuesSelect = this.valuesSelectTarget
    const orderKindSelect = this.orderKindSelectTarget
    const complexFiltersCheckboxSelectOutlet = this.complexFiltersCheckboxSelectOutlet

    $(kindSelect).on('select2:select', function (e) {

      // If the kind has changed from numerical to string or vice versa, destroy the "operator select" and rebuild it with the right options
      const currentKindType = getKindType(kindSelect.value)
      if (previousKindType !== currentKindType) {
        rebuildOperatorSelect(operatorSelect, currentKindType, segmentsRuleFilterController)
        // Rebuild "value" select because it is now a simple numerical value...
        dispatchOperatorSelectChangeEvent(segmentsRuleFilterElement, operatorSelect.value)
      }
      previousKindType = currentKindType

      if (customerOnlyFilters.includes(kindSelect.value)) {
        $(orderKindSelect).prop('disabled',true);
        orderKindSelect.parentNode.classList.add('hidden');

      } else {
        $(orderKindSelect).prop('disabled',false);
        orderKindSelect.parentNode.classList.remove('hidden');
      }

      complexFiltersCheckboxSelectOutlet.reset(kindSelect.value)

      // fire custom event that checkbox_select_controller will be listening for
      const filterType = kindSelect.value
      dispatchKindSelectChangeEvent(segmentsRuleFilterElement, filterType)
    });

    $(operatorSelect).on('select2:select', function (e) {

      // fire custom event that checkbox_select_controller will be listening for
      // and launch funnelDefinitonForm.requestSubmit() from the checkbox_select_controller in the operatorSelectValueChangeHandler
      const operatorType = operatorSelect.value
      let placeHolder = ['contains', 'does_not_contain'].includes(operatorType) ? "Enter value..." : "Select values..."
      searchNode.setAttribute("placeholder", placeHolder);
      dispatchOperatorSelectChangeEvent(segmentsRuleFilterElement, operatorType)
    });
  }

  removeAndSubmitForm(event) {
    const segmentsRuleFilterElement = this.element
    segmentsRuleFilterElement.remove()

    CustomerSegmentsHelper.refreshSegmentReport();
  }
}
const dispatchKindSelectChangeEvent = (segmentsRuleFilterElement, filterType) => {
  const event = document.createEvent("CustomEvent")
  event.initCustomEvent("kind-select-value-change", true, true, { segmentsRuleFilterElement: segmentsRuleFilterElement, filterType: filterType })
  segmentsRuleFilterElement.dispatchEvent(event)
}

const dispatchOperatorSelectChangeEvent = (segmentsRuleFilterElement, operatorType) => {
  const event = document.createEvent("CustomEvent")
  event.initCustomEvent("operator-select-value-change", true, true, { segmentsRuleFilterElement: segmentsRuleFilterElement, operatorType: operatorType })
  segmentsRuleFilterElement.dispatchEvent(event)
}

const getKindType = (kindSelectvalue) => {
  let kindType = 'string'
  if (kindSelectvalue === 'order_value_filter') {
    kindType = 'numerical'
  }

  return kindType
}


const rebuildOperatorSelect = (operatorSelect, kindType, segmentsRuleFilterController) => {
  const optionsDataAttribute = (kindType === 'numerical') ? 'numerical-options' : 'string-options'

  $(operatorSelect).select2('destroy')

  const optionsData = JSON.parse(operatorSelect.getAttribute(`data-${optionsDataAttribute}`))
  operatorSelect.innerHTML = optionsData.map((optionData) => {
    const optionValue = optionData[1]
    const optionTitle = optionData[0]

    return `<option value=${optionValue}>${optionTitle}</option>`
  }).join('\n')

  segmentsRuleFilterController.application.getControllerForElementAndIdentifier(operatorSelect, "select2-blue").connect()
}

