
export const get_filters_and_options_data = () => {
    var dashboard = $("#daterange").data('dashboard');
    var dashboard_compare_to = $('select[id=dashboard_compare_to]').val();
    var country_filters = $('select[id=country_filter]').val();
    var state_filters = $('select[id=state_filter]').val();
    var customer_tags_filters = $('select[id=customer_tags_filter]').val();
    var customer_tags_exclude_filters = $('select[id=customer_tags_exclude_filter]').val();
    var discount_codes_filters = $('select[id=discount_codes_filter]').val();
    var order_tags_filters = $('select[id=order_tags_filter]').val();
    var order_tags_exclude_filters = $('select[id=order_tags_exclude_filter]').val();
    var product_filters = $('select[id=product_filter]').val();
    var product_tag_filters = $('select[id=product_tag_filter]').val();
    var asin_filters = $('select[id=asin_filter]').val();
    var sku_filters = $('select[id=sku_filter]').val();
    var collection_filters = $('select[id=collection_filter]').val();
    var product_type_filters = $('select[id=product_type_filter]').val();
    var vendor_filters = $('select[id=vendor_filter]').val();
    var sales_channel_filters = $('select[id=sales_channel_filter]').val();
    var app_sales_channel_filters = $('select[id=app_sales_channel_filter]').val();
    var first_utm_channel_filters = $('select[id=first_utm_channel_filter]').val();
    var first_utm_source_filters = $('select[id=first_utm_source_filter]').val();
    var first_utm_medium_filters = $('select[id=first_utm_medium_filter]').val();
    var first_utm_term_filters = $('select[id=first_utm_term_filter]').val();
    var first_utm_content_filters = $('select[id=first_utm_content_filter]').val();
    var first_utm_campaign_filters = $('select[id=first_utm_campaign_filter]').val();
    var last_utm_channel_filters = $('select[id=last_utm_channel_filter]').val();
    var last_utm_source_filters = $('select[id=last_utm_source_filter]').val();
    var last_utm_medium_filters = $('select[id=last_utm_medium_filter]').val();
    var last_utm_term_filters = $('select[id=last_utm_term_filter]').val();
    var last_utm_content_filters = $('select[id=last_utm_content_filter]').val();
    var last_utm_campaign_filters = $('select[id=last_utm_campaign_filter]').val();
    var sales_channel_exclude_filters = $('select[id=sales_channel_exclude_filter]').val();
    var klaviyo_segments_filters = $('select[id=klaviyo_segments_filter]').val();
    var klaviyo_lists_filters = $('select[id=klaviyo_lists_filter]').val();
    var klaviyo_campaigns_filters = $('select[id=klaviyo_campaigns_filter]').val();
    var metric = $('select[id=lifetime_select]').val();
    var breakdown_metric = $('select[id=breakdown_metric_select]').val();
    var breakdown_search = $('input[id=breakdown_search_input]').val();
    var metric_lifecycle = $('select[id=lifecycle_select]').val();
    var show_comparison = $('#singledate-compare-wrapper').is(":visible");
    var show_predictive_ltv = $("#togglePredictiveLTV").is(":checked");
    var show_cac_payback = $("#toggleCAC-LTV").is(":checked");
    var income_statement_show_comparison = $('input[id=income_statement_show_comparison]').is(':checked');

    // Only pass slider filter values if user has adjusted it
    const first_order_value_filter = $('input[id=first_order_value_filter]')
    const first_order_value_filter_values = getSliderValuesIfChanged(first_order_value_filter)
    const first_order_discount_filter = $('input[id=first_order_discount_filter]')
    let first_order_discount_filter_values = getSliderValuesIfChanged(first_order_discount_filter)


    // Check if elements exists first, when relying on a class.
    // If we don't we risk always defaulting to 'false' if it doesn't exist.
    let show_graph = undefined
    const graphElem = document.getElementById('graph-icon');
    if (graphElem !== null && graphElem !== undefined) {
        show_graph = graphElem.classList.contains('active')
    }
    let show_line_graph = undefined
    const lineGraphElem = document.getElementById('line-graph-icon');
    if (lineGraphElem !== null && lineGraphElem !== undefined) {
        show_line_graph = lineGraphElem.classList.contains('active')
    }
    let show_sankey = undefined
    const sankeyElem = document.getElementById('sankey-icon');
    if (sankeyElem !== null && sankeyElem !== undefined) {
        show_sankey = sankeyElem.classList.contains('active')
    }

    var income_statement_view = $('.js-income-statement-tab.active').data('tab-target');

    const filtersData = {
        "dashboard": dashboard,
        "dashboard_compare_to": dashboard_compare_to,
        "country_filters": country_filters,
        "state_filters": state_filters,
        "customer_tags_filters": customer_tags_filters,
        "customer_tags_exclude_filters": customer_tags_exclude_filters,
        "discount_codes_filters": discount_codes_filters,
        "order_tags_filters": order_tags_filters,
        "order_tags_exclude_filters": order_tags_exclude_filters,
        "product_filters": product_filters,
        "product_tag_filters": product_tag_filters,
        "asin_filters": asin_filters,
        "sku_filters": sku_filters,
        "collection_filters": collection_filters,
        "product_type_filters": product_type_filters,
        "vendor_filters": vendor_filters,
        "sales_channel_filters": sales_channel_filters,
        "app_sales_channel_filters": app_sales_channel_filters,
        "sales_channel_exclude_filters": sales_channel_exclude_filters,
        "first_utm_channel_filters": first_utm_channel_filters,
        "first_utm_source_filters": first_utm_source_filters,
        "first_utm_medium_filters": first_utm_medium_filters,
        "first_utm_term_filters": first_utm_term_filters,
        "first_utm_content_filters": first_utm_content_filters,
        "first_utm_utm_campaign_filters": first_utm_campaign_filters,
        "last_utm_channel_filters": last_utm_channel_filters,
        "last_utm_source_filters": last_utm_source_filters,
        "last_utm_medium_filters": last_utm_medium_filters,
        "last_utm_term_filters": last_utm_term_filters,
        "last_utm_content_filters": last_utm_content_filters,
        "last_utm_campaign_filters": last_utm_campaign_filters,
        "metric": metric,
        "breakdown_metric": breakdown_metric,
        "breakdown_search": breakdown_search,
        "metric_lifecycle": metric_lifecycle,
        "show_comparison": show_comparison,
        "show_predictive_ltv": show_predictive_ltv,
        "show_cac_payback": show_cac_payback,
        "show_graph": show_graph,
        "show_line_graph": show_line_graph,
        "income_statement_view": income_statement_view,
        "income_statement_show_comparison": income_statement_show_comparison,
        "show_sankey": show_sankey,
        "first_order_value_filter": first_order_value_filter_values,
        "first_order_discount_filter": first_order_discount_filter_values,
        "klaviyo_segments_filters": klaviyo_segments_filters,
        "klaviyo_lists_filters": klaviyo_lists_filters,
        "klaviyo_campaigns_filters": klaviyo_campaigns_filters
    }

    removeUndefinedValues(filtersData)
    return filtersData
}

const removeUndefinedValues = (filterData) => {
    Object.keys(filterData).forEach(key => {
        if (filterData[key] === undefined) {
            delete filterData[key];
        }
    });
}

export const getSliderValuesIfChanged = (jquerySliderElement) => {
    let slider_values = jquerySliderElement.val();

    if (slider_values === undefined) {
        // Slider Element doesn't exist on page
        return undefined
    }

    const slider_selected_min_value = slider_values.split(',')[0]
    const slider_absolute_min_value = jquerySliderElement.data('slider-min').toString()
    const slider_selected_max_value = slider_values.split(',')[1]
    const slider_absolute_max_value = jquerySliderElement.data('slider-max').toString()

    if (slider_selected_min_value === slider_absolute_min_value && slider_selected_max_value === slider_absolute_max_value) {
        slider_values = undefined
    }

    return slider_values
}