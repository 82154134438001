import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="copy-to-clipboard-button"
export default class extends Controller {
  static values = {
    selector: String
  }

  copy() {
    const copiedTextElement = document.querySelector(this.selectorValue)
    let copiedText;

    if (copiedTextElement.tagName === 'INPUT') {
      copiedText = copiedTextElement.value
    } else {
      copiedText = copiedTextElement.innerText
    }

    navigator.clipboard.writeText(copiedText).then(result => {
      TailwindToast().success('Copied link', `Successfully copied link to clipboard`);
    });
  }
}
