import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="clickableDropdown"
export default class extends Controller {
  static targets = [ "content", "trigger" ]

  connect() {
    const dropdownWrapper = this.element
    const dropdownTrigger = this.triggerTarget
    const dropdownContent = this.contentTarget

    dropdownWrapper.addEventListener('click', (e) => {
      dropdownTrigger.classList.toggle('active')

      if (dropdownContent.style.display === "none") {
        dropdownContent.style.display = "inherit";
      } else {
        dropdownContent.style.display = "none";
      }

      const externalClickHandler = (dropdownContent, dropdownTrigger, event) => {
        const isOpen = dropdownContent.style.display !== 'none'
        if (isOpen && !dropdownContent.contains(event.target) && !dropdownTrigger.contains(event.target)) {
          dropdownTrigger.classList.remove('active')
          dropdownContent.style.display = "none";
          document.removeEventListener("click", boundExternalClickHandler);
        }
      }

      const boundExternalClickHandler = externalClickHandler.bind(event, dropdownContent, dropdownTrigger)
      document.addEventListener('click', boundExternalClickHandler);
    })
  }
}