import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="addedGridStackItem"
export default class extends Controller {
  connect() {
    // Close modal (maybe there is a better way or place to do this)
    document.querySelectorAll("[data-controller='modal']").forEach(modal => modal.classList.add('hidden'))
    document.body.classList.remove('modal-open')

    // fire custom event that custom_dashboard_grid_controller will be listening for
    const addedGridItem = this.element.closest('.grid-stack-item')
    const event = document.createEvent("CustomEvent")
    event.initCustomEvent("new-grid-stack-item-connected", true, true, { addedGridItem: addedGridItem})
    this.element.dispatchEvent(event)
  }
}
