import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="custom-dashboards-datatable"
export default class extends Controller {
  static targets = [ "datatable" ]

  connect() {
    const dataTableElement = this.datatableTarget

    const customDashboardsDatatable = $(dataTableElement).DataTable(
        {
          "order": [[0, "desc"]],
          "responsive": true,
          "dom": "ltip",
          "lengthMenu": [10, 25, 50, 100],
          "pagingType": "simple_numbers",
          "bStateSave": true,
          "searching": true,
          "bLengthChange": true,
          "processing": true,
          "serverSide": false,
          initComplete: function() {
            $('.dataTables_info, .dataTables_paginate, .dataTables_length').appendTo($('.tailwind-datatable-details'));
          }
        }
    );

    function delay(fn, ms) {
      let timer = 0
      return function(...args) {
        clearTimeout(timer)
        timer = setTimeout(fn.bind(this, ...args), ms || 0)
      }
    }

    $('#custom_dashboards_table_search').val(customDashboardsDatatable.search());

    // Make Search API work, because the (custom) searchbar is outside the datatable
    $(document).on("keypress keyup", '#custom_dashboards_table_search', delay(function () {
      customDashboardsDatatable.search($(this).val()).draw();
    }, 300));
  }
}
