import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="attribution--drill-down--sortable-table-header"
export default class extends Controller {
  static values = {
    column: String,
    form: String, // id value of form that should be submitted when sort is toggled
  }
  toggleSort() {
    const form = document.getElementById(this.formValue)
    const formSortColumnInput = form.querySelector(`input[name="sort_column"]`)
    const formSortDirectionInput = form.querySelector(`input[name="sort_direction"]`)

    this.#setSortDirection(formSortColumnInput, formSortDirectionInput)
    formSortColumnInput.value = this.columnValue

    form.requestSubmit()

    const turboFrameIdentifier = form.dataset.turboFrame
    skeleton_helper.injectSkeletons([turboFrameIdentifier]);
  }

  #setSortDirection(formSortColumnInput, formSortDirectionInput) {
    const defaultSortDirection = 'desc'
    if (formSortColumnInput.value == this.columnValue) {
      // Toggle sort direction
      if (formSortDirectionInput.value === 'asc') {
        formSortDirectionInput.value = 'desc'
      } else {
        formSortDirectionInput.value = 'asc'
      }
    } else {
      // Set default sort direction
      formSortDirectionInput.value = defaultSortDirection
    }
  }
}
