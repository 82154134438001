import { Controller } from "@hotwired/stimulus";
require('../lifetimely/js/jquery.tooltipster.min');

// Connects to data-controller="tooltips"
export default class extends Controller {
  static values = {
    type: String,
    content: String
  }

  connect() {
    const tooltipType = this.typeValue
    const tooltipContent = this.contentValue
    const element = this.element
    // Tooltipster adds 'tooltipstered' class after initialization
    const isAlreadyInitialized = element.classList.contains('tooltipstered')

    if (!isAlreadyInitialized) {
      initTooltip(tooltipType, element, tooltipContent)
    }
  }
}

const initTooltip = (tooltipType, element, tooltipContent) => {
  if (tooltipType === 'default') {
    initDefault(element)
  } else if (tooltipType === 'html') {
    initHTML(element, false)
  } else if (tooltipType === 'dark-html') {
    initHTML(element, true)
  } else if (tooltipType === 'left-overlay') {
    initLeftOverlay(element, tooltipContent)
  }
}

const initDefault = (element) => {
  $(element).tooltipster({
    animation: 'grow',
    maxWidth: 180,
    interactive: true,
    contentAsHTML: true
  });
}

const initHTML = (element, dark) => {
  const thisBtnHelper = $(element);
  const content = thisBtnHelper.find(".html_tooltip_content");
  const maxWidth = $(element).data("tooltip-max-width") || 180;
  const tooltipPosition = $(element).data("tooltip-position") || 'top';
  const theme = (dark) ? 'tooltipster-dark' : 'tooltipster-default'
  const offsetX = $(element).data("tooltip-offset-x") || 0;
  const offsetY = $(element).data("tooltip-offset-y") || 0;

  thisBtnHelper.tooltipster({
    animation: 'grow',
    maxWidth: maxWidth,
    interactive: true,
    contentAsHTML: true,
    content: content,
    // if you use a single element as content for several tooltips, set this option to true
    contentCloning: false,
    position: tooltipPosition,
    theme: theme,
    offsetX: offsetX,
    offsetY: offsetY,
  });
}

const initLeftOverlay = (element, tooltipContent) => {
  let html_content = tooltipContent || '#tooltip_content_left_overlay'
  $(element).tooltipster({
    animation: 'grow',
    maxWidth: 250,
    interactive: true,
    contentAsHTML: true,
    position: 'left',
    offsetX: 0,
    offsetY: -80,
    content: $(html_content),
    // if you use a single element as content for several tooltips, set this option to true
    contentCloning: false
  });
}