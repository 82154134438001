import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="btnGroup"
export default class extends Controller {
  connect() {
    const btnGroup = this.element
    const radioBtnContainers = btnGroup.querySelectorAll(".btn:not(input[type='radio'])")

    radioBtnContainers.forEach(function (btnContainer) {
      btnContainer.addEventListener("click", function (e) {
        const radioBtn = btnContainer.querySelector("input[type='radio']")
        const radioBtnIsDisabled = radioBtn.disabled === true || btnContainer.classList.contains('disabled')

        if (!radioBtnIsDisabled) {
          btnGroup.querySelectorAll("input[type='radio']").forEach(otherRadioBtn => otherRadioBtn.removeAttribute('checked'))
          radioBtn.setAttribute('checked', '')

          const form = btnContainer.closest('form')
          form.requestSubmit()

          radioBtnContainers.forEach(otherBtnContainer => otherBtnContainer.classList.remove('active'))
          btnContainer.classList.add('active');
          const turboFrameIdentifier = form.dataset.turboFrame
          skeleton_helper.blockControls();
          skeleton_helper.injectSkeletons([turboFrameIdentifier]);
        }

      })
    })
  }
}
