import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="buttonRemoveGridStackItem"
export default class extends Controller {
  connect() {
    this.element.addEventListener('click', event => {
      const removedGridItem = this.element.closest('.grid-stack-item')
      const gridItemValuesInput = removedGridItem.querySelector("input[type='hidden'][name='custom_dashboard_card_attributes[]']")
      const gridItemValuesInputClone = gridItemValuesInput.cloneNode()
      const cardAttributes = JSON.parse(gridItemValuesInputClone.value)
      cardAttributes['_destroy'] = true
      gridItemValuesInputClone.value = JSON.stringify(cardAttributes)

      // Append cloned input at the end of the form to mark the card for destruction
      const form = document.getElementById('custom_dashboard_form')
      form.append(gridItemValuesInputClone)

      // Fire event so that the grid can remove it
      const customEvent = document.createEvent('CustomEvent');
      customEvent.initCustomEvent('remove-grid-stack-item', true, true, { removedGridItem: removedGridItem})
      removedGridItem.dispatchEvent(customEvent)
    })
  }
}
