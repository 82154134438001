import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="select2"
export default class extends Controller {
  static values = {
    search: Boolean,
    tailwind: Boolean,
    small: Boolean,
    ajaxUrl: String,
    disabled: Boolean,
    preselected: Array,
    onboarding: Boolean
  }
  connect() {
    const selectElement = this.element;
    const searchValue = (this.searchValue) ? 5 : -1
    const isMultiple = this.element.getAttribute('multiple') === 'multiple'
    const preselectedValues = this.preselectedValue

    const containerClasses = []
    if (this.tailwindValue) {
      containerClasses.push('tailwind-select2-wrapper')
    }
    if (this.smallValue) {
      containerClasses.push('select2--small')
    }
    if (this.onboardingValue) {
      containerClasses.push('select2--onboarding')
    }

    let dropdownClass =  (this.tailwindValue) ? 'tailwind-select2-dropdown' : ''
    if (this.onboardingValue) {
      dropdownClass += ' select2--onboarding'
    }

    const options = {
      minimumResultsForSearch: searchValue,
      containerCssClass: (containerClasses.length > 0) ? containerClasses.join(' ') : null,
      dropdownCssClass: dropdownClass,
      allowClear: isMultiple,
      closeOnSelect: !isMultiple,
    }

    if (this.hasAjaxUrlValue) {
      addAjaxToOptions(options, this.ajaxUrlValue)
    }

    const placeholderValue = this.element.getAttribute('placeholder')
    if (placeholderValue !== undefined && placeholderValue !== '') {
      options.placeholder = placeholderValue
    }

    $(selectElement).select2(options);

    if (this.disabledValue) {
      $(selectElement).prop('disabled',true);
    }

    if (preselectedValues) {
      insertPreselectedValues(selectElement, preselectedValues)
    }
  }
}

const addAjaxToOptions = (options, ajaxUrl) => {
  options.dataType = 'json'
  options.ajax = {
    url: ajaxUrl, // '/filters?filter_type=campaigns&campaigns_type=google'
    data: function (params) {
      // Query parameters will be ?search=[term]&page=[page]
      return {
        search: params.term,
        page: params.page || 1
      };
    }
  }
}

const insertPreselectedValues = (selectElement, preselectedValues) => {
  preselectedValues.forEach((value) => {
    $(selectElement).append(new Option(value, value, false, true)).trigger('change');
  })
}
