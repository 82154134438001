import { Controller } from "@hotwired/stimulus"
import * as FunnelHelper from "../funnel_helper.js"

// Connects to data-controller="funnel-step"
export default class extends Controller {
  static values = {
    triggerFormSubmitOnConnect: Boolean
  }

  static targets = ['funnelStepNumber', 'funnelStepOrderLabel']

  connect() {

    if (this.funnelStepNumberTarget.innerText === '') {
      const formElement = this.element.closest('form')
      FunnelHelper.setFunnelStepLabels(formElement)

      // Update index param in URL of "Add funnel step" link
      const lastStepIndex = Number(Array.from(formElement.querySelectorAll("[data-funnel-step-step-index-value]")).pop().getAttribute("data-funnel-step-step-index-value"))
      const funnelStepLink = formElement.querySelector('a[data-add-funnel-step-link]')

      funnelStepLink.href = `/funnel/add_funnel_step/${lastStepIndex + 1}`

      if (this.triggerFormSubmitOnConnectValue) {
        const funnelDefinitonForm = this.element.closest("[data-funnel-definition-form-target=\"funnelForm\"]")
        funnelDefinitonForm.requestSubmit()
      }
    }

  }

  removeAndSubmitForm(event) {
    const funnelStepControllerElement = this.element
    const formElement = this.element.closest('form')

    funnelStepControllerElement.remove()
    FunnelHelper.setFunnelStepLabels(formElement)

    formElement.requestSubmit()
  }
}