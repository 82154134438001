import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="largeRadioWrapper"
export default class extends Controller {
  static values = {
    name: String
  }

  connect() {
    // Inside the wrapper is the label and the hidden radio button input
    const wrapper = this.element
    const thisRadioBtn = wrapper.querySelector("input[type='radio']");
    const radioBtnValue = thisRadioBtn.value
    const allWrappers = document.querySelectorAll(`[data-controller='largeRadioWrapper'][data-largeRadioWrapper-name-value='${this.nameValue}']`)

    thisRadioBtn.addEventListener('change', function() {
      allWrappers.forEach(aWrapper => aWrapper.classList.remove('checked'))

      if (radioBtnValue === this.value) {
        wrapper.classList.add('checked')
      }
    });
  }
}
