import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="skeleton"
export default class extends Controller {
  static values = {
    type: String
  }

  cacheLegacySkeleton() {
    const skeletonContainer = this.element
    const turboFrameIdentifier = skeletonContainer.id
    window.skeletonCache[turboFrameIdentifier] = skeletonContainer.innerHTML
  }

  cacheTurboSkeleton() {
    const skeletonElement = this.element
    skeletonElement.removeAttribute('src')
    const turboFrameIdentifier = skeletonElement.closest('turbo-frame').id
    window.skeletonCache[turboFrameIdentifier] = skeletonElement
  }

  connect() {
    // Refresh skeleton cache so we can inject it later
    if (this.typeValue === 'legacy') {
      // Legacy skeleton caches the innerHTML
      this.cacheLegacySkeleton()
    } else {
      // Turbo skeleton caches the JS element
      // TODO - make all skeletons work this way, and use turbo frames in all reports
      this.cacheTurboSkeleton()
    }
  }
}
