const selectCountryDropdown = require("select_country_dropdown");

// This is for both product costs and shipping costs sidedrawers.
// Fires from "product_costs_edit.js.erb" & "product_shipping_costs_edit.js.erb" each time sidedrawer is opened
const init_product_cost_sidedrawer = () => {

      const showBottomActionButtons = (event) => {
          $('.close-nav').unbind('click');
          $('.closebtn.close-nav').hide();

          var productCostSidedrawerStickyBarBottom = $('.sidedrawer-sticky-bar-bottom');
          productCostSidedrawerStickyBarBottom.css({'z-index':'5','opacity':'1'});
          setTimeout(function () {
            productCostSidedrawerStickyBarBottom.addClass('in');

            setTimeout(function () {
              productCostSidedrawerStickyBarBottom.addClass('stop');
            }, 300);

          }, 80);

      }

      $('.sidedrawer').find('input').on('focus', function() {
          showBottomActionButtons();
      });

      $('.js-start-time-datepicker, .js-end-time-datepicker').daterangepicker({
          singleDatePicker: true,
          showDropdowns: true,
          autoUpdateInput: false,
          autoApply: true
      }).on("apply.daterangepicker", function (e, picker) {
          picker.element.val(picker.startDate.format(picker.locale.format));
      });

      // Hide product cost table row when delete button is clicked for persisted product costs
      $('.btn-product-variant-product-cost-destroy').click( function() {
          showBottomActionButtons();

          var checkBox = $(this).siblings('.product-variant-product-cost-destroy').first();

          var is_checked = checkBox.is(':checked');

          if (!is_checked) {
            var confirmation_result = confirm("Are you sure you want to delete this product cost timeframe?");

            if (confirmation_result) {
              $(this).closest('tr').addClass('hidden');
              checkBox.prop("checked", true);
            }
          }
      });


      var productCostCount = ($('.js-product-variant-product-cost-form-row').length - 1);
      var newProductCostRowHTML = $('.js-product-variant-product-cost-form-row').last().clone().wrap('<div/>').parent().html();

      function addAndInitializeNewProductCostRowInSidedrawer(e){

          e.preventDefault();
          showBottomActionButtons();

          productCostCount += 1

          // Add new blank product cost row beneath in the last position
          newProductCostRowHTML = newProductCostRowHTML.replace(/_[0-9]+_/gi, `_${productCostCount}_`).replace(/\]\[[0-9]+\]\[/gi, `][${productCostCount}][`);
          var newProductCostRow = $(newProductCostRowHTML).insertBefore($('.js-product-costs-label-row'));

          // Change previous row's "Add" btn into a "remove" btn
          var previousRowBtns = $(this).closest('.action-btn-container')
          previousRowBtns.html(' <a href="javascript:void(0)" style="width: 100%;" class="mb-0 btn btn-action btn-product-variant-product-cost-remove"><i class="icon-trash"></i></a>');

          // Initialize the newly created datepickers
          newProductCostRow.find('.js-start-time-datepicker, .js-end-time-datepicker').daterangepicker({
              singleDatePicker: true,
              showDropdowns: true,
              autoUpdateInput: false,
              autoApply: true
          }).on("apply.daterangepicker", function (e, picker) {
              picker.element.val(picker.startDate.format(picker.locale.format));
          });

          // Remove table row when "delete" btn is clicked for newly-created product costs
          previousRowBtns.find('.btn-product-variant-product-cost-remove').click( function() {
            var confirmation_result = confirm("Are you sure you want to delete this product cost timeframe?");

            if (confirmation_result) {
              $(this).closest('.js-product-variant-product-cost-form-row').remove();
            }
          });

          var newSelectCountryDropdown = newProductCostRow.find('select.form-control--country');
          selectCountryDropdown.initializeSingleSelectCountryDropdown(newSelectCountryDropdown);

          // Set up new "Add" btn so that each time is it clicked we initialize a new product cost row
          newProductCostRow.find('.js-btn-add-product-cost').on('click', addAndInitializeNewProductCostRowInSidedrawer);
      }

      $('select.form-control--country.js-select2--shipping-cost').on('select2:open', function (e) {
          showBottomActionButtons();
      });

      $('.js-btn-add-product-cost').on('click', addAndInitializeNewProductCostRowInSidedrawer);

      $('#product-costs-sidedrawer-btn--cancel, .close-nav').click(function(e) {
          e.preventDefault();
          $(".sidedrawer").hide();
          $('body').removeClass('modal-open');
      });

      selectCountryDropdown.initializeSelectCountryDropdowns();
}

module.exports = {
    init_sidedrawer: init_product_cost_sidedrawer
};
