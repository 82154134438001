import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['tbody']

  connect() {
    document.addEventListener('shipping-zone-cost-created', this.#populateTable.bind(this))
    document.addEventListener('shipping-zone-cost-updated', this.#populateTable.bind(this))
    this.#populateTable()
  }

  #populateTable() {
    const tbody = this.tbodyTarget
    $.ajax({
      url: '/cost/shipping-zone-costs',
      success: (response) => {
        tbody.innerHTML = response
      }
    }) 
  }

  deleteZone(event) {
    event.preventDefault()
    const recordId = event.currentTarget.dataset.recordId
    const recordName = event.currentTarget.dataset.recordName

    if (!confirm(`Are you sure you want to delete ${recordName}?`)) { return }

    $.ajax({
      type: 'DELETE',
      url: '/cost/shipping-zone-cost-delete',
      data: { id: recordId },
      success: () => {
        const row = document.querySelector(`#shipping_zone_cost_${recordId}`)
        row.hidden = true
      }
    })
  }
}
