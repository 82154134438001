import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="integrations-prefilters"
export default class extends Controller {
    connect() {
        const platform = this.data.get("platform");
        const account_id = this.data.get("account-id");

        let selector = '#prefilter_' + platform + '_campaigns_' + account_id
        const submitBtn = document.querySelector('.tailwind-primary-btn')
        const textInput = document.querySelector(selector)
        const initialValue = textInput.value

        textInput.addEventListener('keyup', (event) => {
            if (initialValue !== textInput.value) {
                submitBtn.classList.remove('disabled')
            } else {
                submitBtn.classList.add('disabled')
            }
        })
    }
}