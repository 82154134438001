import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="ltv-drivers-breakdown-datatable"
export default class extends Controller {
  static targets = [ "datatable", "skeletonTableBody" ]

  static values = {
      order: Array,
      filters: Object,
      exportUrl: String
  }

  connect() {
      const dataTableElement = this.datatableTarget
      const filters = this.filtersValue
      const datatableOrder = this.orderValue
      const exportURL = this.exportUrlValue
      const skeletonTableBodyHTML = this.skeletonTableBodyTarget.innerHTML

      document.getElementById('ltv-drivers-export-btn').href = exportURL;

      $(dataTableElement).DataTable(
          {
              "columnDefs": [
                {
                  "targets": 0,
                  "orderSequence": ['desc','asc'],
                  "searchable": false,
                  'data': 'first_order_record_name',
                  'className': 'first_order_record_name'
                },
                {
                  "targets": 1,
                  "orderSequence": ['desc','asc'],
                  "searchable": false,
                  'data': 'customers_count',
                  'className': 'customers_count text-right'
                },
                {
                  "targets": 2,
                  "orderSequence": ['desc','asc'],
                  "searchable": false,
                  'data': 'historical_ltv',
                  'className': 'historical_ltv text-right',
                  "createdCell": function (td, cellData, rowData, row, col) {
                    td.classList.add(`color-code-${rowData.historical_ltv_color_code}`)
                  }
                },
                {
                  "targets": 3,
                  "orderSequence": ['desc','asc'],
                  "searchable": false,
                  'data': 'orders_count',
                  'className': 'orders_count text-right',
                  "createdCell": function (td, cellData, rowData, row, col) {
                    td.classList.add(`color-code-${rowData.orders_count_color_code}`)
                  }
                }
              ],
            "order": [datatableOrder],
            "searching": true,
            "bLengthChange": true,
            "processing": true,
            "serverSide": true,
            "dom": "ltip",
            "ajax": {
                "url": '/ltv_drivers/breakdown-data',
                "type": "POST",
                "data": function (data) {
                    var picker = $('input[name="ltv_drivers_daterange"]').data('daterangepicker');
                    data.date_from = picker.startDate.format('YYYY-MM-DD');
                    data.date_to = picker.endDate.format('YYYY-MM-DD');
                    data.metric_ltv_drivers = $('select[id=metric_ltv_drivers]').val();
                    data.complex_filters = {}

                    const filtersParams = filters
                    // Merge filters params into data
                    for (const attribute in filtersParams) { data.complex_filters[attribute] = filtersParams[attribute]; }
                }
            },
            initComplete: function() {
                $('.ltv-drivers-table-wrapper .dataTables_info, .ltv-drivers-table-wrapper .dataTables_paginate, .ltv-drivers-table-wrapper .dataTables_length').appendTo($('.ltv-drivers-table-wrapper .tailwind-datatable-details'));
            },
            preDrawCallback: function() {
                dataTableElement.querySelector('tbody').innerHTML = skeletonTableBodyHTML;
            },
            drawCallback: function() {
                skeleton_helper.unblockControls();
            }
        }
    );
  }
}
