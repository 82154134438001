import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="progress-checkboxes"
export default class extends Controller {
  static values = {
    sequence: Number,
    maxSequence: Number,
    isEdit: Boolean,
    enableAt: Array
  }

  static targets = ['nextBtn', 'backBtn', 'createBtn', 'form', 'iconContainer', 'field']

  connect() {
    const nextBtn = this.nextBtnTarget
    const backBtn = this.backBtnTarget
    const createBtn = this.createBtnTarget
    const forms   = this.formTargets 
    const icons   = this.iconContainerTargets 
    const fields = this.fieldTargets
    
    if (!this.isEditValue) {
      nextBtn.disabled = true;
      createBtn.disabled = true;
    }

    for ( let field of fields ) {
      if ( field.dataset.type == 'text' ) {
        field.addEventListener('input', () => {
          validateFields(fields, this.sequenceValue, this.enableAtValue, nextBtn, createBtn)
        })
      } else if ( field.dataset.type == 'radio' ) {
        field.addEventListener('click', () => {
          validateFields(fields, this.sequenceValue, this.enableAtValue, nextBtn, createBtn)
        })
      }

    }

    nextBtn.addEventListener('click', (event) => {

      if (this.sequenceValue < this.maxSequenceValue) {
        this.sequenceValue ++

        validateFields(fields, this.sequenceValue, this.enableAtValue, nextBtn, createBtn)

        // Manipulate Forms
        forms.forEach(form => {
          form.classList.add('hidden')
          if (form.dataset.sequence == this.sequenceValue) form.classList.remove('hidden')
        });

        // Manipulate icons
        // TO DO -> this will be more generic as the number of the svg will be dynamic
        icons.forEach(icon => {
          if (icon.dataset.sequence == this.sequenceValue){
            icon.innerHTML = getSVG(1, icon.dataset.sequence)
          } 

          if (this.isEditValue) {
            if (icon.dataset.sequence < this.sequenceValue || icon.dataset.sequence > this.sequenceValue) {
              icon.innerHTML = getSVG(3, icon.dataset.sequence)
            } 
          } else {
            if (icon.dataset.sequence < this.sequenceValue) {
              icon.innerHTML = getSVG(3, icon.dataset.sequence)
            } 
            if (icon.dataset.sequence > this.sequenceValue){
              icon.innerHTML = getSVG(2, icon.dataset.sequence)
            }
          }
        })

        // Manipulate buttons [Back, Next, Create new Personnel]
        if (this.sequenceValue == this.maxSequenceValue) {
          createBtn.style.display = 'inline-block'
          nextBtn.style.display = 'none'
          backBtn.style.display = 'inline-block'
        } else {
          if( !this.isEditValue ) createBtn.style.display = 'none'
          nextBtn.style.display = 'inline-block'
          backBtn.style.display = 'inline-block'
        }
      }
    })

    backBtn.addEventListener('click', (event) => {
      if (this.sequenceValue > 1) {
        this.sequenceValue --

        validateFields(fields, this.sequenceValue, this.enableAtValue, nextBtn, createBtn)

        // Manipulate Forms
        forms.forEach(form => {
          form.classList.add('hidden')
          if (form.dataset.sequence == this.sequenceValue) form.classList.remove('hidden')
        });

        // Manipulate icons
        // TO DO -> this will be more generic as the number of the svg will be dynamic
        icons.forEach(icon => {
          if (icon.dataset.sequence == this.sequenceValue){
            icon.innerHTML = getSVG(1, icon.dataset.sequence)
          } 

          if (this.isEditValue) {
            if (icon.dataset.sequence < this.sequenceValue || icon.dataset.sequence > this.sequenceValue) {
              icon.innerHTML = getSVG(3, icon.dataset.sequence)
            } 
          } else {
            if (icon.dataset.sequence < this.sequenceValue) {
              icon.innerHTML = getSVG(3, icon.dataset.sequence)
            } 
            if (icon.dataset.sequence > this.sequenceValue){
              icon.innerHTML = getSVG(2, icon.dataset.sequence)
            }
          }
        })

        // Manipulate buttons [Back, Next, Create new Personnel]
        if (this.sequenceValue == 1) {
          backBtn.style.display = 'none'
        } else {
          if ( !this.isEditValue ) createBtn.style.display = 'none'
          nextBtn.style.display = 'inline-block'
        }
      }
    })
  }

}

const validateFields = (fields, currentSequence, enableAt, nextBtn, createBtn) => {
  let count = 0
  for ( let field of fields ) {
    if ( field.dataset.sequence == currentSequence ) {
      if ( field.dataset.type == 'text' ) {
        if ( field.value != "" ) count ++
      } else if ( field.dataset.type == 'radio' ) { 
        if ( field.checked ) count ++
      } else if ( field.dataset.type == 'select' ) { 
        if ( field.selectedIndex >= 0 ) count ++
      }
    }
  }

  if ( count < enableAt[currentSequence-1] ) {
    nextBtn.disabled = true;
    createBtn.disabled = true;
  } else {
    nextBtn.disabled = false;
    createBtn.disabled = false;
  }

  return count >= enableAt[currentSequence-1]
} 

const getSVG = (icon_number, sequence) => {
  return {
    1: `<svg style="position: relative; top: 2px;" class="mr-2" width="16" height="16" xmlns="http://www.w3.org/2000/svg" fill="none">
            <circle cx="8" cy="8" r="8" fill="#CFD5E5" id="svg_1"/>
            <path stroke="null" id="svg_2" d="m8,12c-2.20994,0 -4,-1.79006 -4,-4c0,-2.20994 1.79006,-4 4,-4c2.20994,0 4,1.79006 4,4c0,2.20994 -1.79006,4 -4,4z" opacity="undefined" fill="#112C7B"/>
        </svg>`,
    2: `<svg style="position: relative; top: 6px;" class="mr-2" width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="4" cy="4.5" r="4" fill="#D1D5DB"/>  
        </svg>`,
    3: `<svg style="position: relative; top: 2px;" class="mr-2" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM11.7071 6.70711C12.0976 6.31658 12.0976 5.68342 11.7071 5.29289C11.3166 4.90237 10.6834 4.90237 10.2929 5.29289L7 8.58579L5.70711 7.29289C5.31658 6.90237 4.68342 6.90237 4.29289 7.29289C3.90237 7.68342 3.90237 8.31658 4.29289 8.70711L6.29289 10.7071C6.68342 11.0976 7.31658 11.0976 7.70711 10.7071L11.7071 6.70711Z" fill="#112C7B"/>
        </svg>`
  }[icon_number]
}