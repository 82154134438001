export function buildLTVHoverCard(nodeElement, for_spaghetti_graph = false) {
    // If no argument if passed, then use default 'this'
    // If argument is passed then it should be a node element (i.e. for the spaghetti graph hover cards)
    if (nodeElement === undefined) {
        nodeElement = this
    }

    let data_metric = $('select[id=lifetime_select]').val();
    let is_predictive = false
    let is_summary_card = false

    if (nodeElement.classList.contains('tbl--predictive')) {
        is_predictive = true
    }

    if (nodeElement.classList.contains('js-ltv-average-data')) {
        is_summary_card = true
    }

    if (nodeElement.classList.contains('js-ltv-summary-table-data') && shouldShowPredictiveSummaryData(data_metric)) {
        data_metric = 'predictive_' + data_metric
    }

    if ($('tbody.blur-predictive-data').length > 0 && is_predictive) {
        return "<div class='stat no-padding'><p class='stat__val_hover_content' style='margin-bottom: 0'><strong>Upgrade your plan</strong> to see prediction for customers' future behavior</p></div>"
    }

    const sale_data = JSON.parse( nodeElement.getAttribute(`data-${data_metric}`) );
    const value = sale_data["value"];
    const percentage_change = sale_data["percentage_change"];
    const months_later_number = parseInt( nodeElement.getAttribute('data-months_later_number') );
    const timeframe = $('select[id=lifetime_timeframe_select]').val();

    const main_HTML = buildMainHTML(value, percentage_change, data_metric, timeframe, months_later_number, is_predictive, is_summary_card);
    const cohort_timeinterval_HTML = for_spaghetti_graph ? '' : buildCohortTimeintervalHTML($(nodeElement), is_summary_card); // Don't show this for LTV spaghetti graph
    const cac_payback_HTML = buildCacPaybackHTML(nodeElement, timeframe, is_predictive);

    return `<div class='stat no-padding'><p class='stat__val_hover_content mb-2'>${main_HTML}</p>${cohort_timeinterval_HTML}${cac_payback_HTML}</div>`
}

const buildMainHTML = (value, percentage_change, metric, timeframe, months_later_number, is_predictive, is_summary_card) => {
    switch(metric) {
        case 'customers':
            switch (months_later_number) {
                case -1:
                    return `${is_summary_card ? 'On average, this' : 'This'} cohort has <strong>${value}</strong> customers making their first order in this ${timeframe}`
                case 0:
                    return `${is_summary_card ? 'On average, this' : 'This'} cohort has <strong>${value}</strong> ${value === 1 ? 'customer' : 'customers'} already making a repurchase in the ${timeframe} of their first purchase.`
                default:
                    return `${is_summary_card ? 'On average, this' : 'This'} cohort had <strong>${value} (${percentage_change})</strong> ${value === 1 ? 'customer' : 'customers'} repurchasing <strong>${months_later_number}</strong> ${months_later_number === 1 ? timeframe : timeframe += 's'} later.`
            }
        case 'orders_per_customer':
            switch (months_later_number) {
                case -1:
                    return `We start at <strong>${value}</strong> cumulative order per customer. This reflects their first purchase.`
                case 0:
                    return `${is_summary_card ? 'On average, customers' : 'Customers'} from this cohort made on average <strong>${value}</strong> cumulative ${ value == 1 ? 'order' : 'orders' } in the same ${timeframe}} of their first purchase.`
                default:
                    return `${is_summary_card ? 'On average, customers' : 'Customers'} from this cohort have made on average <strong>${value}</strong> cumulative ${ value == 1 ? 'order' : 'orders' } <strong>${months_later_number}</strong> ${months_later_number === 1 ? timeframe : timeframe += 's'} later.`
            }
        case 'sales_per_cohort':
        case 'net_sales_per_cohort':
            switch (months_later_number) {
                case -1:
                    return `${is_summary_card ? 'On average, this' : 'This'} cohort spent a combined <strong>${value}</strong> for all their first purchases.`
                case 0:
                    return `${is_summary_card ? 'On average, this' : 'This'} cohort spent an additional <strong>${value}</strong> in the same ${timeframe} of their first purchase, equalling <strong>${percentage_change} </strong>of the first purchase value.`
                default:
                    return `${is_summary_card ? 'On average, this' : 'This'} cohort ${is_predictive ? 'is predicted to spend' : 'spent' } <strong>${value}</strong> ${months_later_number} ${months_later_number === 1 ? timeframe : timeframe += 's'} later, equalling <strong>${percentage_change} </strong>of the first purchase value.`
            }
        case 'transactions_per_cohort':
                    switch (months_later_number) {
                        case -1:
                            return `${is_summary_card ? 'On average, this' : 'This'} cohort made <strong>${value}</strong> first-purchase ${ value === 1 ? 'transaction' : 'transactions' }.`
                        case 0:
                            return `${is_summary_card ? 'On average, this' : 'This'} cohort made ${ value === 1 ? 'an' : '' } additional <strong>${value}</strong> ${ value === 1 ? 'transaction' : 'transactions' } in the same ${timeframe} of their first purchase.`
                        default:
                            return `${is_summary_card ? 'On average, this' : 'This'} cohort made <strong>${value}</strong> ${ value === 1 ? 'transaction' : 'transactions' } ${months_later_number} ${months_later_number === 1 ? timeframe : timeframe += 's'} later.`
                    }
                case 'customers_who_purchased_x_times':
                    return `${is_summary_card ? 'On average, this' : 'This'} cohort has <strong>${value} (${percentage_change})</strong> customers with ${months_later_number} or more orders`
        case 'cac_sales_per_customer':
                if (value) {
                    switch (months_later_number) {
                        case -1:
                            return `${is_summary_card ? 'On average, this' : 'This'} cohort has an Accumulated sales per customer / CAC ratio of <strong>${value}</strong> at their first purchase.`
                        case 0:
                            return `${is_summary_card ? 'On average, this' : 'This'} cohort has an Accumulated sales per customer / CAC ratio of <strong>${value}</strong> in the same ${timeframe} of their first purchase, equalling <strong>${percentage_change}</strong> from when they first purchased.`
                        default:
                            return `${is_summary_card ? 'On average, this' : 'This'} cohort ${is_predictive ? 'is predicted to have' : 'has'} an Accumulated sales per customer / CAC ratio of <strong>${value}</strong> ${months_later_number} ${months_later_number === 1 ? timeframe : timeframe += 's'} later, equalling <strong>${percentage_change}</strong> from when they first purchased.`
                    }
                } else {
                    return `Set up your ads costs in order to see your Accumulated sales per customer / CAC ratio. <em>Filters are not yet compatible with this metric.</em>`
                }
        case 'cac_sales_margin_per_customer':
                if (value) {
                    switch (months_later_number) {
                        case -1:
                            return `${is_summary_card ? 'On average, this' : 'This'} cohort has an Accumulated gross margin per customer / CAC ratio of <strong>${value}</strong> at their first purchase.`
                        case 0:
                            return `${is_summary_card ? 'On average, this' : 'This'} cohort has an Accumulated gross margin per customer / CAC ratio of <strong>${value}</strong> in the same ${timeframe} of their first purchase, equalling <strong>${percentage_change}</strong> from when they first purchased.`
                        default:
                            return `${is_summary_card ? 'On average, this' : 'This'} cohort ${is_predictive ? 'is predicted to have' : 'has'} an Accumulated gross margin per customer / CAC ratio of <strong>${value}</strong> ${months_later_number} ${months_later_number === 1 ? timeframe : timeframe += 's'} later, equalling <strong>${percentage_change}</strong> from when they first purchased.`
                    }
                } else {
                    return `Set up your ads costs in order to see your Accumulated gross margin per customer / CAC ratio. <em>Filters are not yet compatible with this metric.</em>`
                }
        case 'average_order_value':
            switch (months_later_number) {
                case -1:
                    return `${is_summary_card ? 'On average, this' : 'This'} cohort has an average value of <strong>${value}</strong> per order for first-purchases in this ${timeframe}`
                case 0:
                    return `${is_summary_card ? 'On average, this' : 'This'} cohort has an average value of <strong>${value}</strong> per order for repurchases in the same ${timeframe} of the customer's first purchase, equalling <strong>${percentage_change} </strong>of the first purchase value.`
                default:
                    return `${is_summary_card ? 'On average, this' : 'This'} cohort had an average value of <strong>${value} (${percentage_change})</strong> per order for repurchases occurring <strong>${months_later_number}</strong> ${months_later_number === 1 ? timeframe : timeframe += 's'} later.`
            }
        default:
            switch (months_later_number) {
                case -1:
                    return `${is_summary_card ? 'On average, this' : 'This'} cohort has <strong>${value}</strong> in accumulated ${metric.replaceAll('_',' ').replace('sales margin', 'gross margin')} for their first purchase.`
                case 0:
                    return `${is_summary_card ? 'On average, this' : 'This'} cohort has <strong>${value}</strong> in accumulated ${metric.replaceAll('_',' ').replace('sales margin', 'gross margin')} in the same ${timeframe} of their first purchase, equalling <strong>${percentage_change} </strong>of the first purchase value.`
                default:
                    return `${is_summary_card ? 'On average, this' : 'This'} cohort ${is_predictive ? 'is predicted to have' : 'has'} <strong>${value}</strong> in accumulated ${metric.replaceAll('_',' ').replace('sales margin', 'gross margin')} <strong>${months_later_number}</strong> ${months_later_number === 1 ? timeframe : timeframe += 's'} later, equalling <strong>${percentage_change} </strong>of the first purchase value.`
            }
    }
}

const buildCohortTimeintervalHTML = (jQuery_table_cell, is_summary_card) => {
    let inner_content;
    if (is_summary_card) {
        const cohort_timeintervals = $('tbody .js-cohort-timeinterval')
        inner_content = `Cohorts: Customers who made their first order between ${cohort_timeintervals[0].textContent} and ${cohort_timeintervals[cohort_timeintervals.length - 1].textContent}`
    } else {
        inner_content = `Cohort: Customers who made their first order in ${jQuery_table_cell.closest('tr').find('td')[0].textContent}`;
    }

    return `<p class='stat__label'>${inner_content}</p>`
}


const buildCacPaybackHTML = (nodeElement, timeframe, is_predictive) => {
    const cac_payback_value = nodeElement.getAttribute('data-cac_payback');
    if (cac_payback_value === undefined || cac_payback_value === null || cac_payback_value.length === 0) {
        return '';
    }

    let inner_content;
    if (cac_payback_value === '-1.0') {
        inner_content = `<strong>${is_predictive ? 'Predicted ' : ''}CAC payback: first order</strong>`
    } else {
        inner_content = `<strong>${is_predictive ? 'Predicted ' : ''}CAC payback: ${cac_payback_value} ${ cac_payback_value === '1.0' ? timeframe : timeframe + 's' }</strong>`
    }

    return `<p class='stat__val_hover_content cac-payback mt-3'>${inner_content}</p>`
}

const shouldShowPredictiveSummaryData = (data_metric) => {
    // This logic is taken almost exactly from the lifetime_value.js file
    let is_predictive_metric = ['sales_per_customer', 'sales_per_cohort', 'cac_sales_per_customer', 'sales_margin_per_customer', 'cac_sales_margin_per_customer'].includes(data_metric);

    // This logic is just for the UI - If the shop doesn't have PLUS, then we don't send predictive
    // data to the front-end. Or, at least, we shouldn't :)
    const predictiveLTVToggle = $("#togglePredictiveLTV")
    const shop_has_plus_plan = predictiveLTVToggle.attr('data-shop-has-plus-plan') === 'true'
    const show_predictive_summary_data = predictiveLTVToggle.is(":checked") && shop_has_plus_plan && is_predictive_metric;

    return show_predictive_summary_data;
}