import {Controller} from "@hotwired/stimulus";
import Highcharts from "highcharts";
import HighchartsMore from 'highcharts/highcharts-more';

function getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

// Connects to data-controller="attribution/overview/attributedRevenue"
export default class extends Controller {
    static values = {}

    connect() {
        const chartContainer = this.element

        HighchartsMore(Highcharts);

        Highcharts.chart(chartContainer, {
            chart: {
                // type: 'line',
                height: 400,
                marginRight: 0,
                marginLeft: 0
            },
            title: {
                text: ''
            },
            legend: {
                enabled: false
            },
            tooltip: {
                shared: true,
                crosshairs: true,
            },
            xAxis: {
                gridLineWidth: 1,
                tickmarkPlacement: 'on',
                lineColor: 'transparent',
                lineWidth: 0,
                gridLineDashStyle: 'Dash',
                type: 'datetime'
            },
            yAxis: {
                title: {
                    text: '',

                },
                labels: {
                    enabled: false
                },
                gridLineWidth: 0
            },
            plotOptions: {
                series: {
                    marker: {
                        radius: 3,
                        fillColor: '#FFFFFF',
                        lineWidth: 1.5,
                        symbol: "circle"
                    },
                    pointStart: Date.UTC(2023, 0, 1),
                    pointIntervalUnit: 'day'
                }
            },
            series: [
                {
                    name: 'avg',
                    color: '#00BF86',
                    type: 'spline',
                    marker: {
                        lineColor: '#00BF86'
                    },
                    data: [
                        getRandomNumber(250, 750),
                        getRandomNumber(250, 750),
                        getRandomNumber(250, 750),
                        getRandomNumber(250, 750),
                        getRandomNumber(250, 750),
                        getRandomNumber(250, 750),
                        getRandomNumber(250, 750),
                        getRandomNumber(250, 750),
                        getRandomNumber(250, 750),
                        getRandomNumber(250, 750),
                        getRandomNumber(250, 750),
                        getRandomNumber(250, 750),
                        getRandomNumber(250, 750),
                        getRandomNumber(250, 750),
                        getRandomNumber(250, 750),
                        getRandomNumber(250, 750),
                        getRandomNumber(250, 750),
                        getRandomNumber(250, 750),
                        getRandomNumber(250, 750),
                        getRandomNumber(250, 750),
                    ]
                },
                {
                    name: 'avg_range',
                    color: '#45B0E8',
                    type: 'areasplinerange',
                    marker: {
                        lineColor: '#45B0E8'
                    },
                    linkedTo: ':previous',
                    fillOpacity: 0.3,
                    data: [
                        [getRandomNumber(250, 500), getRandomNumber(500, 750)],
                        [getRandomNumber(250, 500), getRandomNumber(500, 750)],
                        [getRandomNumber(250, 500), getRandomNumber(500, 750)],
                        [getRandomNumber(250, 500), getRandomNumber(500, 750)],
                        [getRandomNumber(250, 500), getRandomNumber(500, 750)],
                        [getRandomNumber(250, 500), getRandomNumber(500, 750)],
                        [getRandomNumber(250, 500), getRandomNumber(500, 750)],
                        [getRandomNumber(250, 500), getRandomNumber(500, 750)],
                        [getRandomNumber(250, 500), getRandomNumber(500, 750)],
                        [getRandomNumber(250, 500), getRandomNumber(500, 750)],
                        [getRandomNumber(250, 500), getRandomNumber(500, 750)],
                        [getRandomNumber(250, 500), getRandomNumber(500, 750)],
                        [getRandomNumber(250, 500), getRandomNumber(500, 750)],
                        [getRandomNumber(250, 500), getRandomNumber(500, 750)],
                        [getRandomNumber(250, 500), getRandomNumber(500, 750)],
                        [getRandomNumber(250, 500), getRandomNumber(500, 750)],
                        [getRandomNumber(250, 500), getRandomNumber(500, 750)],
                        [getRandomNumber(250, 500), getRandomNumber(500, 750)],
                        [getRandomNumber(250, 500), getRandomNumber(500, 750)],
                        [getRandomNumber(250, 500), getRandomNumber(500, 750)],
                    ]
                },
            ]
        });
    }
}
