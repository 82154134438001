export const setFunnelStepLabels = (formElement) => {
    const order_kind = document.getElementById('order_kind').value

    formElement.querySelectorAll("[data-funnel-step-target='funnelStepNumber']").forEach((funnelStepNumber, index) => funnelStepNumber.textContent = (index + 1))
    formElement.querySelectorAll("[data-funnel-step-target='funnelStepOrderLabel']").forEach((funnelStepOrderLabel, index) => {
        let label;

        if (order_kind === 'sequential') {
            const number = index + 1
            if (number === 1) {
                label = '1st Order'
            } else if (number === 2) {
                label = '2nd Order'
            } else if (number === 3) {
                label = '3rd Order'
            } else {
                label = `${number}th Order`
            }
        } else {
            label = 'Order'
        }

        funnelStepOrderLabel.textContent = label
    })
}