import { Controller } from "@hotwired/stimulus"
const skeleton_helper = require('../skeleton_helper');

// Connects to data-controller="complex-filters-sidedrawer"
export default class extends Controller {
  static targets = ['applyBtn', 'clearBtn', 'filterGroupBtn', 'allFiltersWrapper', 'savedFilterBtn']

  static values = {
    formController: String,
    refreshReportOnLoad: Boolean
  }
  connect() {
    const applyBtn = this.applyBtnTarget
    const clearBtn = this.clearBtnTarget
    const formController = this.formControllerValue
    const filterGroupBtns = this.filterGroupBtnTargets
    const allFiltersWrapper = this.allFiltersWrapperTarget
    const savedFilterBtns = this.savedFilterBtnTargets
    const sidedrawerElement = this.element

    initToggleBtns(filterGroupBtns)
    initApplyBtn(applyBtn, formController)
    initClearBtn(clearBtn, sidedrawerElement, allFiltersWrapper)
    initSavedFilterBtns(savedFilterBtns, allFiltersWrapper)
    initSaveNewFilterForm(formController, allFiltersWrapper, sidedrawerElement)

    if (this.refreshReportOnLoadValue) {
      submitReport(formController)
    }

    // TODO saved filters...
  }
}

const initApplyBtn = (applyBtn, formController) => {
  applyBtn.addEventListener('click', function(e) {
    submitReport(formController)
  })
}

const submitReport = (formController) => {
  // Handle skeleton and block controls
  const form = document.querySelector(`form[action='/${formController}/refresh-report']`)
  form.requestSubmit()

  const turboFrameIdentifier = form.dataset.turboFrame
  skeleton_helper.blockControls();
  skeleton_helper.injectSkeletons([turboFrameIdentifier]);
}

const initToggleBtns = (filterGroupBtns) => {
  filterGroupBtns.forEach((btn) => {
    btn.addEventListener('click', (event) => {
      btn.closest('.filters--group-container').classList.toggle('open')
    })
  })
}

const initClearBtn = (clearBtn, sidedrawerElement, allFiltersWrapper) => {
  clearBtn.addEventListener('click', (event) => {
    event.preventDefault()
    allFiltersWrapper.classList.add('loading')

    // Trigger turbo visit this way instead of in HTML because we
    // update the link's parameters dynamically for open_toggle_containers
    // Update "Clear filters" btn href so that the refreshed sidedrawer appears with the same toggles open
    const openGroupContainers = sidedrawerElement.querySelectorAll('.filters--group-container.open')
    const openGroupNames = [...openGroupContainers].map((container) => container.dataset.containerName)
    const clearBtnUrl = new URL(clearBtn.href)
    clearBtnUrl.searchParams.set('open_toggle_containers', openGroupNames)
    clearBtn.href = clearBtnUrl.href;

    Turbo.visit(clearBtn.href, { method: "get", historyChanged: true })
  })
}

const initSavedFilterBtns = (savedFilterBtns, allFiltersWrapper) => {
  savedFilterBtns.forEach((btn) => {
    btn.addEventListener('click', (event) => {
      allFiltersWrapper.classList.add('loading')
    })
  })
}

const initSaveNewFilterForm = (formController, allFiltersWrapper, sidedrawerElement) => {
  const saveNewFilterForm = document.getElementById('save_new_complex_filter_form')
  saveNewFilterForm.addEventListener('submit', (event) => {
    allFiltersWrapper.classList.add('loading')

    const formInputs = sidedrawerElement.querySelectorAll(`[form="${formController}_form"]`)
    formInputs.forEach((input) => {
      input.setAttribute('form', 'save_new_complex_filter_form')
    })

    $('#save-filters-modal').modal('hide');
  })
}