import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="custom-metrics--format-radios"
export default class extends Controller {
  static targets = ['radioButton']
  connect() {
    const submitter = this.element.closest('form').querySelector("[type='submit'][value='Refresh builder']")

    this.radioButtonTargets.forEach((radioButton) => {
      radioButton.addEventListener('click', (event) => {
        radioButton.querySelector('input[type="radio"]').setAttribute('checked', '')
        setTimeout(() => {
          submitter.click()
        }, 150)
      })
    })
  }
}
