import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="complex-filter-rule"
export default class extends Controller {
  connect() {
    const wrapper = this.element.closest('.complex-filter-wrapper.block-during-load')
    if (wrapper) {
      wrapper.classList.remove('loading')
    }

    const filterRuleCount = wrapper.querySelectorAll('[data-controller="complex-filter-rule"]').length
    if (filterRuleCount && filterRuleCount > 1) {
      const removeRuleBtn = this.element.closest('[data-controller="complex-filter-card"]').querySelector('[data-complex-filter-card-target="removeRuleButton"]')
      removeRuleBtn.classList.remove('hidden')
    }
  }
}
