// JQuery methods for blocking and unblocking UI
(function ( $ ) {
    var elementLoadingOverlayHTML = '<div class="loadingoverlay"><div class="spinnerbox"><span class="progress-text"></span><div class="loader">Loading...</div></div></div>';

    $.fn.block = function() {
        this.append(elementLoadingOverlayHTML);

        return this.each(function() {
            this.classList.add("isloading");
        });
    };

    $.fn.unblock = function() {
        $('.loadingoverlay').remove();

        return this.each(function() {
            this.classList.remove("isloading");
        });
    };

    $.blockUI = function() {
        $('body').append(elementLoadingOverlayHTML);
        document.body.classList.add("pageloading");
    };

    $.unblockUI = function() {
        $('.loadingoverlay').remove();
        document.body.classList.remove("pageloading");
    };

}( jQuery ));
