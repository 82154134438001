import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="changed-segment-state"
export default class extends Controller {
  static values = {
    segmentId: Number,
    state: String
  }

  connect() {
    const state = this.stateValue

    if (state === 'editable') {
      unlockRulesBuilder()
      if (!window.location.href.endsWith("/new") && !window.location.href.endsWith("/duplicate")) {
        updateUrlToEdit()
      }
    } else {
      // Fixed, uneditable dashboard
      updateUrlToShow(this.segmentIdValue)
      lockRulesBuilder()
    }

  }
}

const updateUrlToEdit = () => {
  // Update URL to "edit"
  const currentURL = window.location.href
  if (!currentURL.endsWith("/edit")) {
    const newURL = currentURL + '/edit'
    window.history.pushState({}, "", newURL);
  }
}

const updateUrlToShow = (segmentId) => {
  // Update URL back to show
  const currentURL = window.location.href
  let newURL;

  if (currentURL.includes('new?template=')) {
    newURL = currentURL.replace(/new\?template=(.*)/, segmentId)
  } else if (currentURL.endsWith("/edit")) {
    newURL = currentURL.replace(/(\/edit$)/, '')
  } else if (currentURL.endsWith("/duplicate")) {
    newURL = currentURL.replace(/([0-9]+\/duplicate$)/, segmentId)
  } else if (currentURL.endsWith("/new")) {
    newURL = currentURL.replace(/(new$)/, segmentId)
  }

  window.history.pushState({}, "", newURL);
}

const unlockRulesBuilder = () => {
  // TODO enable rules builder
}

const lockRulesBuilder = () => {
  // Return to "show" view; uneditable state
  // TODO disable rules builder
}