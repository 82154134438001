import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="checkboxWrapper"
export default class extends Controller {
  connect() {
    const checkboxWrapper = this.element
    const checkbox = checkboxWrapper.querySelector('input[type=checkbox]')

    checkbox.addEventListener('change', function() {
      if (this.checked) {
        checkboxWrapper.classList.add('checked')
      } else {
        checkboxWrapper.classList.remove('checked')
      }
    });
  }
}
