import consumer from "./consumer"

consumer.subscriptions.create("NotificationChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {

    if (data["tailwind"] == true) {
      if (data["kind"] === 'success') {
        TailwindToast().success(data["title"], data["text"], data["duration"]);
      } else if (data["kind"] === 'warning') {
        TailwindToast().warning(data["title"], data["text"], data["duration"]);
      } else {
        TailwindToast().success(data["title"], data["text"], data["duration"]);
      }

      return
    }

    if (data["notification_kind"] == 'info') {
      ToastCustom.fireInfoToast(data["text"]);
    } else if (data["notification_kind"] == 'alert') {
        ToastCustom.fireAlertToast(data["text"]);
    } else {
      // Most often called when there's incoming data on the websocket for this channel
      ToastCustom.fireSuccessToast(data["text"]);
    }
  }
});
