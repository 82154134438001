const selectCountryDropdown = require("select_country_dropdown");
const stickyElements = require('sticky_elements');

function effects() {

    selectCountryDropdown.initializeSelectCountryDropdowns();

    //-- Date Range Picker
    if ($('.form-daterange').length != 0) {

        $('.form-daterange').on('click', function (e) {
            e.preventDefault();
        });


        $('.form-daterange').daterangepicker({
            autoApply: true,
            showDropdowns: true,
            linkedCalendars: false,
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(7, 'days'), moment().subtract(1, 'days')],
                'Last 30 Days': [moment().subtract(30, 'days'), moment().subtract(1, 'days')],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            },
            "alwaysShowCalendars": true,
            "startDate": "07/28/2018",
            "endDate": "08/03/2018"
        }, function (start, end, label) {
            console.log('New date range selected: ' + start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD') + ' (predefined range: ' + label + ')');
        });
    }


    //-- Date Range Picker
    if ($('.form-date').length != 0) {

        $('.form-date').on('click', function (e) {
            e.preventDefault();
        });


        $('.form-date').daterangepicker({
            "singleDatePicker": true,
            "showDropdowns": true,
            "autoApply": true,
            "showCustomRangeLabel": false,
            "startDate": "08/01/2018",
            "endDate": "08/07/2018"
        }, function (start, end, label) {
            // console.log('New date range selected: ' + start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD') + ' (predefined range: ' + label + ')');
        });
    }


    //-- Multi select dropdown
    $('.form--multi').each(function () {

        var place = $(this).attr('data-placeholder');

        $(this).select2({
            placeholder: place,
            allowClear: true,
            tags: true
        });

    });

    $('body').on('click', function (e) {

        if ($(e.target).parents('.side').length == 0 && !$(e.target).hasClass('btn-mobilemenu')) {
            $('body').removeClass('showside');
        }

    });


    //-- Animate in object
    var animobjects = $('.animatedin').length;
    var currobj = 0;

    if (animobjects != 0) {

        $('.animatedin').each(function () {
            var obj = $(this);

//      console.log(.3 * currobj);

            setTimeout(function () {
                obj.addClass('in');

                setTimeout(function () {
                    obj.addClass('stop');
                }, 300)

            }, 80 * currobj);

            currobj += 1;
        });
    }

    //-- Switch buttons
    // TODO - remove and refactor when all reports use Turbo
    $('.js-btn-group-legacy').each(function () {

        var group = $(this);
        var buttons = group.find('.btn');

        buttons.on('click', function (e) {
            e.preventDefault();

            var clickedButton = $(this);

            if (!clickedButton.is(':disabled') && !clickedButton.hasClass('disabled')) {
                buttons.removeClass('active');
                clickedButton.addClass('active');
            }
        });

    });

    //-- Lifecycle Report show tabs
    $('.btn-showanalysis').on('click', function (e) {
        e.preventDefault();

        //-- Hide filters
        $('body').removeClass('showfilters');

        //-- Activate tabs
        $('body').addClass('tabs-active');
        $('.content-tabs--lifecycle').addClass('visible');
        $('.content-tabs--lifecycle').trigger('sticky_kit:recalc');

        //-- Show newly created tab
        $('.content-tabs--lifecycle a[href="#analysis1"]').tab('show');

    });


    $('.content-tabs--lifecycle a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
        $('.content-tabs--lifecycle').trigger('sticky_kit:recalc');
    });


    //-- Inline editing
    $('.form-editable').each(function () {

        var edblock = $(this);
        var edit = edblock.find('.btn-edit, .btn-edit--shippingcost');
        var save = edblock.find('.btn-save');
        var cancel = edblock.find('.btn-cancel');
        var edinput = edblock.find('.form-control');
        var currvalue = edinput.val();

        edit.on('click', function (e) {
            e.preventDefault();

            edblock.addClass('editmode');
            edinput.select().focus();

        });

        cancel.on('click', function (e) {
            e.preventDefault();

            edinput.val(currvalue);
            edblock.removeClass('editmode');
        });

        save.on('click', function (e) {
            e.preventDefault();

            var newvalue = edinput.val();


            currvalue = newvalue;

            $.post({
                url: '/' + edinput.attr('data-controller') + '/' + edinput.attr('data-method'),
                data: {
                    edit_value: newvalue,
                    edit_key: edinput.attr('data-key')
                },
                success: function (data) {
                  if (data) {
                    var parsed_data = JSON.parse(data);
                    var realvalue  = "#shipping-cost-table tbody #shipping_cost-" + parsed_data.country_shipping_cost_id + "  .realvalue";
                    $(realvalue).text(parsed_data.new_value);
                    edinput.removeClass('has-error');
                    edblock.removeClass('editmode');
                  } else {
                    edinput.addClass('has-error');
                  }
                }
            });

        });

    });

    //-- Toast info messages
    if ($('.btn-toast-info').length != 0) {
        $('.btn-toast-info').on('click', function () {

            var message = $(this).attr('data-message');
            fireInfoToast(message);

        });
    }

    //-- Toast alert messages
    if ($('.btn-toast-alert').length != 0) {
        $('.btn-toast-alert').on('click', function () {

            var message = $(this).attr('data-message');
            fireAlertToast(message);

        });
    }

    //-- Toast success messages
    if ($('.btn-toast-success').length != 0) {
        $('.btn-toast-success').on('click', function () {

            var message = $(this).attr('data-message');
            fireSuccessToast(message);

        });
    }


    //-- Page loader example
    $('.btn-fullpageload').on('click', function () {

        $('body').addClass('pageloading');

        setTimeout(function () {
            $('body').removeClass('pageloading');
        }, 3000);

    });


    //-- Element loader example
    $('.btn-elementloader').on('click', function () {

        $('.cbox--example').addClass('isloading');

        setTimeout(function () {
            $('.cbox--example').removeClass('isloading');
        }, 3000);

    });


    //-- Data loader example
    $('.btn-dataloader').on('click', function () {

        $('.dataloading').addClass('active');

        setTimeout(function () {
            $('.dataloading').addClass('loaded');

            $('.dataloading p').text('Customers loaded: 9889 / 9894 Orders loaded: 5840 / 7469')
        }, 3000);

        setTimeout(function () {
            $('.dataloading').removeClass('active');
        }, 4200);

    });

    $('.btn-clean-time').click(function (e) {
      e.preventDefault();
      $(this).closest('.input-group').find('input[name=singledate], input[name=singledate_custom]').val('');
    })

    // ### Custom Costs New/Edit Modal
    $('select[id="custom-cost-select"]').change(function () {
        var optionValue = $(this).val();

        if (optionValue == "1") {
            $('div[id="one_time"]').show();
            $('div[id="many_times"]').hide();
        } else {
            $('div[id="one_time"]').hide();
            $('div[id="many_times"]').show();
        }
    });


    function clear_customcost_modal() {
        $('input[id=customcost-name]').val("");
        $('select[id=custom-cost-select]').val("1");
        $('input[id=customcost-cost]').val("");
        $('input[id=customcost-start_date]').val("");
        $('input[id=customcost-end_date]').val("");
        $('input[id=customcost-date]').val("");
        $('input[id=custom_cost_marketing_category]').prop( "checked", false );
        $('div[id="one_time"]').show();
        $('div[id="many_times"]').hide();
    }


    $('input[name="singledate_custom"]').daterangepicker({
        singleDatePicker: true,
        showDropdowns: true,
        autoUpdateInput: false,
        autoApply: true
    }).on("apply.daterangepicker", function (e, picker) {
        picker.element.val(picker.startDate.format(picker.locale.format));
    });

    $('select[id="custom-cost-select"]').change(function () {
        var optionValue = $(this).val();

        if (optionValue == "1") {
            $('div[id="one_time"]').show();
            $('div[id="many_times"]').hide();
        } else {
            $('div[id="one_time"]').hide();
            $('div[id="many_times"]').show();
        }
    });

    $('#add-continue-custom-cost, #add-close-custom-cost').click(function () {
        $.post({
            url: '/cost/custom-costs-create',
            data: {
                name: $('input[id=customcost-name]').val(),
                frequency: $('select[id=custom-cost-select]').val(),
                cost: $('input[id=customcost-cost]').val(),
                start_date: $('input[id=customcost-start_date]').val(),
                end_date: $('input[id=customcost-end_date]').val(),
                date: $('input[id=customcost-date]').val(),
                marketing_category: $('input[id=custom_cost_marketing_category]:checked').val()
            }
        });
        clear_customcost_modal();
    });

    $('#save-custom-cost').click(function () {
        $.post({
            url: '/cost/update-custom-cost',
            data: {
                cost_id: $('input[id=hidden_cost_id]').val(),
                name: $('input[id=customcost-name]').val(),
                frequency: $('select[id=custom-cost-select]').val(),
                cost: $('input[id=customcost-cost]').val(),
                start_date: $('input[id=customcost-start_date]').val(),
                end_date: $('input[id=customcost-end_date]').val(),
                date: $('input[id=customcost-date]').val(),
                marketing_category: $('input[id=custom_cost_marketing_category]:checked').val()
            }
        });
    });

    // ### Transaction Costs - Payment Gateway New/Edit Modal
    function clear_payment_gateway_modal() {
        $('select[id=paymentGateway-alias]').val(null).trigger('change');
        $('input[id=paymentGateway-percentage-fee]').val("");
        $('input[id=paymentGateway-fixed-fee]').val("");
    }

    $('#modal-newpaymentgateway').on('hidden.bs.modal', function () {
        $.post({
            url: '/cost/reload-payment-gateways-table',
        })
    });

    $('#add-close-payment-gateway').click(function () {
        $.post({
            url: '/cost/payment-gateways-create',
            data: {
                payment_gateway: {
                    alias: $('select[id=paymentGateway-alias]').val(),
                    percentage_fee: $('input[id=paymentGateway-percentage-fee]').val(),
                    fixed_fee: $('input[id=paymentGateway-fixed-fee]').val()
                }
              }
        });
        clear_payment_gateway_modal();
    });

    $('#add-continue-payment-gateway').click(function () {
        alias = $('select[id=paymentGateway-alias]').val();
        $.post({
            url: '/cost/payment-gateways-create',
            data: {
                payment_gateway: {
                    alias: alias,
                    percentage_fee: $('input[id=paymentGateway-percentage-fee]').val(),
                    fixed_fee: $('input[id=paymentGateway-fixed-fee]').val()
                },
              },
            success: function(){
                $(`select[id=paymentGateway-alias] option[value='${alias}']`).remove();
              },
        });
        clear_payment_gateway_modal();
    });

    $('#save-payment-gateway').click(function () {
        $.post({
            url: '/cost/update-payment-gateway',
            data: {
              payment_gateway: {
                  id: $('input[id=hidden-payment-gateway-id]').val(),
                  alias: $('select[id=paymentGateway-alias]').val(),
                  percentage_fee: $('input[id=paymentGateway-percentage-fee]').val(),
                  fixed_fee: $('input[id=paymentGateway-fixed-fee]').val()
               }
            }
        });
    });

    $('#set-custom-shopify-payments-currency-link').click(function (e) {
        e.preventDefault();
        $('#shopify-payments-currency-label-text').text('Currency');
        $('select#shopify-payments-currency').show();
    });

    stickyElements.init_elements();
};

module.exports = {
    effects: effects
};
