import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="timeLagChart"
export default class extends Controller {
  static values = {
    type: String,
    exportUrl: String
  }

  connect() {
    if (this.typeValue === 'basic') {
      window.load_chart.basicUserDisabledBarChart();
    } else if (this.typeValue === 'custom_dashboard_card') {
      window.load_chart.customDashboardTimeLagChart(this.element);
    } else {
      if (this.hasExportUrlValue) {
        document.getElementById('time-lag-export-btn').href = this.exportUrlValue
      }
      window.load_chart.timeLagChart(this.element);
    }
  }
}
