import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="upgrade-plan-modal"
export default class extends Controller {
  connect() {
    const element = this.element
    const modalTitle = element.getAttribute("data-modal-title") || "Get more from Lifetimely"
    const modalBody = element.getAttribute("data-modal-body") || "Your free plan is limited to 30 days of data. To access your complete historical P&L and track your metrics over time, upgrade your plan."


    element.addEventListener('click', (event) => {
      const modal = document.getElementById('tailwind-upgrade-plan-modal');
      if (modal) {
        modal.classList.remove('hidden');
        $(modal).find("#modal-title").html(modalTitle);
        $(modal).find("#modal-body").html(modalBody);
      }

      // Close daterangepicker if exists
      const daterangepicker = $('#income_statement_daterange, #product_metrics_daterange, #order_details_daterange')
      if (daterangepicker.length > 0) {
        daterangepicker.data('daterangepicker').hide();
      }

    })
  }
}
