import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="attribution--drill-down--table-page-button"
export default class extends Controller {
  static values = {
    page: Number,
    form: String, // id value of form that should be submitted when sort is toggled
  }

  changePage() {
    const form = document.getElementById(this.formValue)
    const formPageInput = form.querySelector(`input[name="page"]`)

    formPageInput.value = this.pageValue

    form.requestSubmit()

    const turboFrameIdentifier = form.dataset.turboFrame
    skeleton_helper.injectSkeletons([turboFrameIdentifier]);
  }
}
