import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="confirm-deletion-form"
export default class extends Controller {
  static values = {
    confirmation: String
  }

  connect() {
    const confirmationValue = this.confirmationValue

    const submitBtn = this.element.querySelector("button[type='submit'], input[type='submit']")
    submitBtn.disabled = true

    const confirmationValueInput = this.element.querySelector("input[name='confirmation_value']")
    confirmationValueInput.addEventListener('input', () => {
      console.log(confirmationValueInput.value)
      if (confirmationValueInput.value === confirmationValue) {
        submitBtn.disabled = false
      } else {
        submitBtn.disabled = true
      }
    })
  }
}
