import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="custom-metrics--metric-formula-input"
export default class extends Controller {
  static values = {
    disabled: Boolean,
    focusOnConnectIfEmpty: Boolean,
    formSubmitterSelector: String,
    keepClosedOnConnect: Boolean
  }

  connect() {
    const selectElement = this.element;

    const options = {
      minimumResultsForSearch: 5,
      containerCssClass: ['tailwind-select2-wrapper', 'tailwind-blue-custom-metric-select2-wrapper'],
      dropdownCssClass: ['tailwind-blue-small-dropdown-select2-wrapper'],
      allowClear: false,
      closeOnSelect: true,
      dropdownAutoWidth: true
    }

    const placeholderValue = this.element.getAttribute('placeholder')
    if (placeholderValue !== undefined && placeholderValue !== '') {
      options.placeholder = placeholderValue
    }

    const select2Element = $(selectElement).select2(options);

    this.#disableSelectIfDisabled(select2Element)
    this.#openSelectOnConnectIfEmptyAndAllowed(select2Element)
    this.#initSubmitFormOnSelect(select2Element)
  }

  #focusCursor() {
    document.getElementById('cursor_input').focus()
  }

  #openSelectOnConnectIfEmptyAndAllowed(select2Element) {
    if (this.keepClosedOnConnectValue) {
      return;
    }

    // The placeholder "Select a metric..." prompt is technically a selected value
    // who's id is blank (''), so if that is present, open the select. If not, that
    // means a value is already selected, so don't open it.
    if (select2Element.select2('data')[0].id === '') {
      select2Element.select2('open');
    }
  }

  #disableSelectIfDisabled(select2Element) {
    if (this.disabledValue) {
      select2Element.prop('disabled',true);
    }
  }

  #initSubmitFormOnSelect(select2Element) {
    // If formSubmitterSelector is provided, use that value as the form's submitter. If not, submit form without that value.
    // https://developer.mozilla.org/en-US/docs/Web/API/HTMLFormElement/requestSubmit
    let submitter;
    let form = select2Element.closest('form')

    if (this.hasFormSubmitterSelectorValue) {
      submitter = document.querySelector(this.formSubmitterSelectorValue)
      form = submitter.form
    }

    select2Element.on('select2:select', (event) => {
      if (submitter) {
        submitter.click()
      } else {
        form.requestSubmit()
      }
      this.#focusCursor()
    })
  }
}