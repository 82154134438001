import {Controller} from "@hotwired/stimulus"

const checkMetricOnly = (formObject) => {
  formObject.element.querySelectorAll('[data-show-compare-button-wrapper]').forEach(elem => elem.classList.add('hidden'))
  formObject.element.querySelectorAll('[data-comparison-select-wrapper]').forEach(elem => elem.classList.add('hidden'))
  formObject.element.querySelector('.js-split-graph-checkbox-wrapper').classList.add('hidden')
  formObject.element.querySelectorAll("[data-toggle-on-corresponding-select]").forEach(elem => elem.classList.add('hidden'))

  if (formObject.hasGaugeOptionsWrapperTarget) {
    formObject.gaugeOptionsWrapperTargets.forEach(wrapper => wrapper.classList.add('hidden'))
  }
}

const checkMetricGraphStyle = (formObject, metricLayoutValue) => {
  const metricSelects = formObject.metricSelectTargets

  metricSelects.forEach((metricSelect) => {
    const metricSelectValue = metricSelect.value
    const toggleOnMetricSelectTargets = formObject.element.querySelectorAll(`[data-toggle-on-corresponding-select="${metricSelect.id}"]`)

    if (metricSelectValue && metricLayoutValue === 'with_graph') {
      formObject.element.querySelector('.js-split-graph-checkbox-wrapper').classList.remove('hidden')
      toggleOnMetricSelectTargets.forEach(elem => elem.classList.remove('hidden'))
    } else {
      toggleOnMetricSelectTargets.forEach(elem => elem.classList.add('hidden'))
    }
  })

  const kindRadioValue = formObject.element.querySelector('input[name="kind"]:checked').value;
  const marketingComparisonSelect = formObject.comparisonMetricSelectTargets.filter(comparisonSelect => comparisonSelect.id.includes('marketing'))[0]
  const marketingComparisonSelectValue = marketingComparisonSelect.value
  if (kindRadioValue !== 'marketing') {
    formObject.element.querySelector('.js-split-graph-checkbox-wrapper').classList.add('hidden')
  } else {
    if (marketingComparisonSelectValue) {
      formObject.element.querySelector('.js-split-graph-checkbox-wrapper').classList.add('hidden')
    } else {
      formObject.element.querySelector('.js-split-graph-checkbox-wrapper').classList.remove('hidden')
    }
  }

  if (metricLayoutValue !== 'gauge') {
    if (formObject.hasGaugeOptionsWrapperTarget) formObject.gaugeOptionsWrapperTargets.forEach(wrapper => wrapper.classList.add('hidden'))
  } else {
    if (formObject.hasGaugeOptionsWrapperTarget) formObject.gaugeOptionsWrapperTargets.forEach(wrapper => wrapper.classList.remove('hidden'))
  }
}

const checkGauge = (formObject) => {
  checkMetricOnly(formObject)

  if (formObject.hasGaugeOptionsWrapperTarget) {
    formObject.gaugeOptionsWrapperTargets.forEach(wrapper => wrapper.classList.remove('hidden'))
  }
}

const checkComparisonMetricGraphStyle = (formObject, metricLayoutValue) => {
  const comparisonMetricSelects = formObject.comparisonMetricSelectTargets

  comparisonMetricSelects.forEach((comparisonMetricSelect) => {
    const metricSelectId = comparisonMetricSelect.getAttribute('data-corresponding-metric-select')
    const metricSelectValue = document.getElementById(metricSelectId).value
    const comparisonMetricSelectValue = comparisonMetricSelect.value
    const mainComparisonWrapper = comparisonMetricSelect.closest('[data-comparison-main-wrapper]')
    const compareButtonWrapper = mainComparisonWrapper.querySelector('[data-show-compare-button-wrapper]')
    const comparisonSelectWrapper = mainComparisonWrapper.querySelector('[data-comparison-select-wrapper]')
    const toggleOnComparisonMetricSelectTargets = formObject.element.querySelectorAll(`[data-toggle-on-corresponding-select="${comparisonMetricSelect.id}"]`)

    if (metricSelectValue && comparisonMetricSelectValue && metricLayoutValue === 'with_graph') {
      toggleOnComparisonMetricSelectTargets.forEach(elem => elem.classList.remove('hidden'))

      compareButtonWrapper.classList.add('hidden')
      comparisonSelectWrapper.classList.remove('hidden')

    } else {
      toggleOnComparisonMetricSelectTargets.forEach(elem => elem.classList.add('hidden'))

      if (metricSelectValue && !comparisonMetricSelectValue) {
        compareButtonWrapper.classList.remove('hidden')
        comparisonSelectWrapper.classList.add('hidden')
      } else if (comparisonMetricSelectValue && !metricSelectValue) {
        compareButtonWrapper.classList.add('hidden')
        comparisonSelectWrapper.classList.remove('hidden')
      } else if (!metricSelectValue && !comparisonMetricSelectValue) {
        compareButtonWrapper.classList.remove('hidden')
        comparisonSelectWrapper.classList.add('hidden')
      }

      if (comparisonMetricSelect.id.includes('marketing')) {
        const splitGraphCheckbox = formObject.element.querySelector("input[name=split_graph][type=checkbox]");
        if (splitGraphCheckbox.checked) {
          compareButtonWrapper.classList.add('hidden')
          comparisonSelectWrapper.classList.add('hidden')
        }
      }

    }
  })
}

const checkMetricWithGraph = (formObject, metricLayoutValue = 'with_graph') => {
  checkMetricGraphStyle(formObject, metricLayoutValue)
  checkComparisonMetricGraphStyle(formObject, metricLayoutValue)
}

const updateGoalTargetValueUnit = (formObject) => {
  const goalMetricSelect = formObject.goalMetricSelectTarget
  const goalTargetValueField = formObject.element.querySelector("input[name='goal_target_value']")
  const goalTargetValuePrefix = formObject.element.querySelector(".js-goal-target-value-prefix")

  if (goalMetricSelect.value && goalMetricSelect.length > 0) {
    goalTargetValueField.removeAttribute('disabled')
    if ( ['orders', 'new_customers', 'repeat_customers' ].includes(goalMetricSelect.value) ) {
      goalTargetValuePrefix.textContent = '#'
    } else {
      goalTargetValuePrefix.textContent = goalTargetValuePrefix.dataset.currency
    }
  } else {
    goalTargetValueField.setAttribute('disabled', true)
    goalTargetValuePrefix.textContent = ''
  }
}

const updateGaugeFieldsUnits = (formObject, metricSelect) => {
  const numberFields = formObject.gaugeNumberFieldTargets
  const numberFieldPrefixes = formObject.gaugeNumberFieldPrefixTargets

  let prefixValue = '';

  if (metricSelect.value && metricSelect.length > 0) {
    numberFields.forEach(field => field.removeAttribute('disabled'))

    if ( ['average_number_of_items', 'total_recipients', 'active_users', 'average_session_duration', 'engaged_sessions', 'screen_page_views_per_session', 'sessions', 'total_users', 'user_engagement_duration'].includes(metricSelect.value) ) {
      prefixValue = '#'
    } else if ( metricSelect.value.includes('percent_') || metricSelect.value.includes('_rate') || metricSelect === 'ctr' || metricSelect === 'marketing_efficiency_ratio' ) {
      prefixValue = '%'
    } else if ( metricSelect.value.includes('roas') ) {
      prefixValue = 'X'
    } else {
      prefixValue = numberFieldPrefixes[0].dataset.currency
    }

    numberFieldPrefixes.forEach(prefix => prefix.textContent = prefixValue)
  } else {
    numberFields.forEach(field => field.setAttribute('disabled', true))
    numberFieldPrefixes.forEach(prefix => prefix.textContent = prefixValue)
  }
}

const setGaugeMinMaxDefaultValues = (formObject, metricSelect) => {
  const gaugeMinValueField = formObject.gaugeMinTarget
  if (gaugeMinValueField && metricSelect.value.length > 0 && gaugeMinValueField.value.length === 0) {
    gaugeMinValueField.value = 0
  }

  const gaugeMaxValueField = formObject.gaugeMaxTarget
  const isPercentMetric = metricSelect.value.includes('percent_') || metricSelect.value === 'ctr'
  if (gaugeMaxValueField && isPercentMetric && gaugeMaxValueField.value.length === 0) {
    gaugeMaxValueField.value = 100
  }
}

const updateTitleField = (formObject) => {
  // We have the same code & logic written for the back-end in custom_dashboard_card.rb
  // If we update this method, we need to update the "default_title" and "humanize_metric" methods in ruby too.
  let title = '';
  const card_kind = formObject.element.querySelector('input[name="kind"]:checked').value;
  let card_metric;
  let card_comparison_metric;
  let card_report;
  const checkedReportRadio = formObject.element.querySelector('input[name="report"]:checked')
  if (checkedReportRadio) {
    card_report = checkedReportRadio.value
  }

  if (card_kind === 'report') {
    if (card_report === 'ltv_cohorts') {
      title = 'Lifetime value cohorts'
    } else if (card_report === 'time_lag') {
      title = 'Time between orders'
    } else if (card_report === 'new_customer_repurchase_rate') {
      title = 'New customer repurchase rate'
    } else if (card_report === 'repeat_customer_repurchase_rate') {
      title = 'Returning customer repurchase rate'
    }
  } else {
    if (card_kind === 'metric') {
      card_metric = formObject.element.querySelector('#dashboard_card_metric_select, #edit_dashboard_card_metric_select').value
      card_comparison_metric = formObject.element.querySelector('#dashboard_card_comparison_metric_select, #edit_dashboard_card_comparison_metric_select').value
    } else if (card_kind === 'marketing') {
      card_metric = formObject.element.querySelector('#dashboard_card_marketing_metric_select, #edit_dashboard_card_marketing_metric_select').value
      card_comparison_metric = formObject.element.querySelector('#dashboard_card_comparison_marketing_metric_select, #edit_dashboard_card_comparison_marketing_metric_select').value
    } else if (card_kind === 'sms_and_email') {
      card_metric = formObject.element.querySelector('#dashboard_card_sms_and_email_metric_select, #edit_dashboard_card_sms_and_email_metric_select').value
      card_comparison_metric = formObject.element.querySelector('#dashboard_card_comparison_sms_and_email_metric_select, #edit_dashboard_card_comparison_sms_and_email_metric_select').value
      handleKlaviyoFilters(card_metric)
    } else if (card_kind === 'website_data') {
      card_metric = formObject.element.querySelector('#dashboard_card_website_data_metric_select, #edit_dashboard_card_website_data_metric_select').value
      card_comparison_metric = formObject.element.querySelector('#dashboard_card_comparison_website_data_metric_select, #edit_dashboard_card_comparison_website_data_metric_select').value
    } else if (card_kind === 'goal') {
      card_metric = formObject.element.querySelector('#dashboard_card_goal_metric_select, #edit_dashboard_card_goal_metric_select').value
      card_comparison_metric = '' // No comparison metric for goals
    }

    if (card_comparison_metric && card_comparison_metric.length > 0) {
      title = `${humanizeMetric(card_metric)} vs. ${humanizeMetric(card_comparison_metric)}`
    } else {
      title = humanizeMetric(card_metric)
    }
  }

  const titleField = formObject.element.querySelector("input[name='title']")
  if (titleField.value === '') {
    titleField.placeholder = (title !== '') ? title : "Leave blank to generate default title"
  }
}

const humanizeMetric = (metricString) => {
  let humanizedMetricString = metricString

  if (metricString === 'google_ads_costs') {
    humanizedMetricString = 'Google Ads costs'
  } else if (['facebook_costs', 'bing_costs', 'snapchat_costs', 'tiktok_costs', 'pinterest_costs'].includes(metricString)) {
    humanizedMetricString = capitalizeFirstLetter(humanizedMetricString.replace('_', ' Ads '))
  } else if (metricString.includes('percent')) {
    humanizedMetricString = metricString.split('percent')[1].replaceAll('_', ' ')
    humanizedMetricString = capitalizeFirstLetter(humanizedMetricString)
    humanizedMetricString = '% ' + humanizedMetricString
  } else if (['cpm', 'cpc', 'ctr'].includes(metricString)) {
    humanizedMetricString = metricString.toUpperCase()
  } else {
    humanizedMetricString = metricString.replaceAll('_', ' ')
    humanizedMetricString = capitalizeFirstLetter(humanizedMetricString)
    humanizedMetricString = humanizedMetricString.replace(/cogs/i,'COGS').replace(/cac/i,'CAC').replace(/roas/i,'ROAS').replace(/aov/i,'AOV')
  }

  return humanizedMetricString
}

const handleKlaviyoFilters = (metricString) => {
  const klaviyoAccountMetrics = ['bounce_rate', 'spam_rate', 'bounce_rate', 'unsubscribe_rate'];
  const KlaviyoSubscriberMetrics = ['number_of_subscribers', 'number_of_new_subscribers']
  if(metricString && klaviyoAccountMetrics.includes(metricString)){
    $(".klaviyo-filters").addClass("hidden");
    if(!$("#klaviyo_campaign_filters_wrapper").hasClass("hidden")) {
      $("#klaviyo_campaign_filters_wrapper").addClass("hidden");
    }
  }else {
    $(".klaviyo-filters").removeClass("hidden");
    $("#klaviyo_campaign_filters_wrapper").addClass("hidden");
    $("#klaviyo_campaign_filters_wrapper .klaviyo-filters .js-plus-symbol").removeClass("hidden");
    $("#klaviyo_campaign_filters_wrapper .klaviyo-filters .js-minus-symbol").addClass("hidden");
  }
  let campaignFiltersWrapperId = $("#sms_and_email_toggle_filters").data('campaign-filters-wrapper-id')
  if(metricString && KlaviyoSubscriberMetrics.includes(metricString) && campaignFiltersWrapperId){
    $("#sms_and_email_toggle_filters").attr('data-campaign-filters-wrapper-id', campaignFiltersWrapperId.replace("campaign", "list"));
  }else {
    $("#sms_and_email_toggle_filters").attr('data-campaign-filters-wrapper-id', campaignFiltersWrapperId.replace("list", "campaign"));
    $("#klaviyo_list_filters_wrapper").addClass("hidden");
  }

  let editCampaignFiltersWrapperId = $("#edit_sms_and_email_toggle_filters").data('campaign-filters-wrapper-id')
  if(metricString && KlaviyoSubscriberMetrics.includes(metricString) && editCampaignFiltersWrapperId){
    $("#edit_sms_and_email_toggle_filters").attr('data-campaign-filters-wrapper-id', editCampaignFiltersWrapperId.replace("campaign", "list"));
  }else {
    if(editCampaignFiltersWrapperId) {
      $("#edit_sms_and_email_toggle_filters").attr('data-campaign-filters-wrapper-id', editCampaignFiltersWrapperId.replace("list", "campaign"));
    }
    $("#edit_klaviyo_list_filters_wrapper").addClass("hidden");
  }

  $('#marketing_include_klaviyo_campaign_filters, #marketing_exclude_klaviyo_campaign_filters, #edit_marketing_include_klaviyo_campaign_filters, #edit_marketing_exclude_klaviyo_campaign_filters').on('select2:select', function (e) {
    $('#marketing_include_klaviyo_flow_filters, #marketing_exclude_klaviyo_flow_filters').prop("disabled", true);
    $('#edit_marketing_include_klaviyo_flow_filters, #edit_marketing_exclude_klaviyo_flow_filters').prop("disabled", true);
  });

  $('#marketing_include_klaviyo_campaign_filters, #marketing_exclude_klaviyo_campaign_filters,#edit_marketing_include_klaviyo_campaign_filters, #edit_marketing_exclude_klaviyo_campaign_filters').on('select2:unselect', function (e) {
    let klaviyo_campaign_filters = $('#marketing_include_klaviyo_campaign_filters, #marketing_exclude_klaviyo_campaign_filters,#edit_marketing_include_klaviyo_campaign_filters, #edit_marketing_exclude_klaviyo_campaign_filters').find(':selected');

    if(klaviyo_campaign_filters.length == 0) {
      $('#marketing_include_klaviyo_flow_filters, #marketing_exclude_klaviyo_flow_filters').prop("disabled", false);
      $('#edit_marketing_include_klaviyo_flow_filters, #edit_marketing_exclude_klaviyo_flow_filters').prop("disabled", false);
    }
  });

  $('#marketing_include_klaviyo_flow_filters, #marketing_exclude_klaviyo_flow_filters, #edit_marketing_include_klaviyo_flow_filters, #edit_marketing_exclude_klaviyo_flow_filters').on('select2:select', function (e) {
    $('#marketing_include_klaviyo_campaign_filters, #marketing_exclude_klaviyo_campaign_filters').prop("disabled", true);
    $('#edit_marketing_include_klaviyo_campaign_filters, #edit_marketing_exclude_klaviyo_campaign_filters').prop("disabled", true);
  });

  $('#marketing_include_klaviyo_flow_filters, #marketing_exclude_klaviyo_flow_filters').on('select2:unselect', function (e) {
    let klaviyo_flow_filters = $('#marketing_include_klaviyo_flow_filters, #marketing_exclude_klaviyo_flow_filters').find(':selected');

    if(klaviyo_flow_filters.length == 0) {
      $('#marketing_include_klaviyo_campaign_filters, #marketing_exclude_klaviyo_campaign_filters').prop("disabled", false);
    }
  });

  $('#edit_marketing_include_klaviyo_flow_filters, #edit_marketing_exclude_klaviyo_flow_filters').on('select2:unselect', function (e) {
    let klaviyo_flow_filters = $('#edit_marketing_include_klaviyo_flow_filters, #edit_marketing_exclude_klaviyo_flow_filters').find(':selected');

    if(klaviyo_flow_filters.length == 0) {
      $('#edit_marketing_include_klaviyo_campaign_filters, #edit_marketing_exclude_klaviyo_campaign_filters').prop("disabled", false);
    }
  });

}

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const METRIC_TOOLTIP_CONTENTS = Object.freeze({
  'total_sales': "Total sales (including taxes), after discount codes and refunds have been deducted.",
  'product_revenue': "Sales from products before subtracting discount codes and refunds",
  'shipping_revenue': "Total shipping costs paid for by customers before subtracting discount codes and refunds",
  'discount_codes': "Total value of coupon code discounts as calculated in Shopify. Sale discounts are not included.",
  'refunds': "Total value of refunds",
  'taxes': "Total taxes assessed on orders",
  'products_sold': "Total cost of goods sold (based on Shopify's \"cost per item\" field and/or costs entered in the \"Product costs\" tab)",
  'shipping_costs': "Total shipping costs (calculated according to rates set in the \"Shipping costs\" tab)",
  'transaction_fees': "Total transaction costs (calculated according to rates set in the \"Transaction costs\" tab in Lifetimely)",
  'fulfillment_expenses': "Total handling costs (calculated according to rates set in the \"Handling costs\" tab)",
  'other_cogs': "Total \"other COGS\" custom costs (based on costs manually entered in the \"Custom costs\" tab)",
  'net_sales': "Sales after taxes, discount codes, and refunds have been deducted.",
  'cogs': "Total Cost of goods sold. Product costs + shipping costs + transaction fees + fulfillment expenses + custom \"other cogs\" costs.",
  'marketing_costs': "Total ad spend across all marketing accounts integrated with Lifetimely and/or manually entered as a marketing cost in the \"Custom costs\" tab",
  'google_ads_costs': "Total ad spend for Google Ads",
  'facebook_costs': "Total ad spend for Facebook Ads",
  'bing_costs': "Total ad spend for Bing (Microsoft) Ads",
  'amazon_costs': "Total ad spend for Amazon Ads",
  'snapchat_costs': "Total ad spend for Snapchat Ads",
  'tiktok_costs': "Total ad spend for Tiktok Ads",
  'pinterest_costs': "Total ad spend for Pinterest Ads",
  'other_marketing': "Total ad spend manually entered as a marketing cost in the \"Custom costs\" tab as \"other marketing\", \"other ads channels\", \"agencies\", or \"influencers\"",
  'contribution_margin': "Total sales minus discount codes, refunds, taxes, COGS, and marketing spend",
  'operating_expenses': "Total \"other expenses\" custom costs (based on costs manually entered in the \"Custom costs\" tab)",
  'net_profit': "Total sales minus discount codes, refunds, taxes, COGS, marketing spend, and operating expenses. (Contribution margin - Operating expenses)",
  'percent_gross_margin': "(Net sales - COGS) divided by net sales",
  'percent_net_margin': "Net profit divided by net sales",
  'percent_refunds': "Total value of refunds divided by net sales",
  'percent_cogs': "Total value of COGS divided by net sales",
  'percent_marketing': "Total value of marketing spend divided by net sales",
  'blended_cac': "Total marketing spend divided by the number of new customers",
  'blended_roas': "Net sales divided by total marketing spend",
  'new_customer_roas': "New customer net sales divided by total marketing spend",
  'new_customers': "Number of new customers in the time period",
  'new_customers_net_sales': "New customer net sales",
  'profit_per_new_customer': "(New customer net sales - new customer COGS - total marketing spend) divided by number of new customers",
  'orders': "Number of orders in the time period",
  'aov': "(Average Order Value) Sales (after discount codes and before refunds) divided by number of orders in the time period",
  'new_customer_aov': "New customer sales (after discount codes and before refunds) divided by the number of new customer orders in the time period",
  'repeat_customers': "Number of unique existing customers who made a repeat order during the time period. If customer had multiple orders during the time period, they are only counted once",
  'repeat_customers_net_sales': "Repeat customer net sales",
  'percent_repeat': "No. of repeat orders divided by the total number of orders in the time period.",
  'percent_repeat_net_sales': "Repeat customer net sales divided by net sales",
  'repeat_customer_aov': "Repeat customer sales (after discount codes and before refunds) divided by the number of repeat customer orders in the time period",
  'average_number_of_items': "Average number of total quantity of line items across all orders",
  'spend': "Total ad spend",
  'total_conversion_value': "Total amount of revenue generated from ads",
  'total_conversions': "Total number of conversions generated from ads",
  'impressions': "Total number of views or engagements with ads",
  'clicks': "Total number of clicks on ads",
  'cpm': "(Cost per thousand) Average cost per 1000 impressions (Spend / Impressions / 1000)",
  'cpc': "(Cost per click) Average cost per click (Spend / Total clicks)",
  'ctr': "(Click-through rate) Ratio of clicks to impressions ((Total clicks / Impressions) x 100)",
  'roas': "(Return on ad spend) Ratio of conversation value to spend (Total conversion value / Spend)",
  'cost_per_conversion': "Average cost per conversion (Spend / Total conversions)",
  'revenue': 'The amount of revenue attributed to email',
  'revenue_rate': 'Revenue / Delivered emails',
  'placed_order_rate': 'Placed orders / Delivered emails',
  'unsubscribe_rate': 'Unsubscribed emails / Delivered emails',
  'bounce_rate': 'Bounced emails / Delivered emails',
  'spam_rate': 'Marked Emails as Spam / Delivered emails',
  'total_recipients': 'Delivered emails',
  'open_rate': 'Opened emails / Delivered emails',
  'click_rate': 'Clicked emails / Delivered emails',
  'click_sms_rate': 'Clicked SMS  / Received SMS',
  'unsubscribe_sms_rate': 'Unsubscribed from SMS / Received SMS',
  'number_of_new_subscribers': 'Number of new list subscribers.',
  'number_of_subscribers': 'Number of list subscribers.',
  'active_users': "The number of distinct users who visited your website or application.",
  'average_session_duration': "Average Value Of Session Duration",
  'cart_to_view_rate': "The number of users who added an item (e.g., a product you sell) to their shopping cart per user who viewed the item.",
  'engaged_sessions': "The number of sessions that lasted 10 seconds or longer, or had 1 or more conversion events or 2 or more page or screen views.",
  'engagement_rate': "The percentage of sessions that were engaged sessions.",
  'purchase_to_view_rate': "The number of users who purchased an item (e.g., a product you sell) per user who viewed the item.",
  'purchaser_conversion_rate': "",
  'screen_page_views_per_session': "The average number of pages or screens viewed in a session.",
  'sessions': "The number of sessions that began on your website or application.",
  'total_users': "The total number of unique users who triggered at least one event.",
  'user_engagement_duration': "The average time that your website was in focus in a user's browser or mobile app was in the foreground of a user's device. This metric is calculated as the sum of user engagement durations per active user.",
  "date": "Date",
  "session_medium": "Session medium",
  "session_source": "Session source",
  "session_source_medium": "Session source medium",
  "first_user_default_channel_grouping": "First user default channel grouping",
  "marketing_efficiency_ratio": 'Marketing Efficiency Ratio: Total sales divided total ad spend.'
})

const initMetricTooltip = (metricSelect) => {
  const metricSelectId = metricSelect.id
  const tooltipElement = document.querySelector(`.js-metric-tooltip[data-corresponding-metric-select="${metricSelectId}"]`)
  let tooltipContent = METRIC_TOOLTIP_CONTENTS[metricSelect.value]

  if (tooltipContent === undefined) {
    if (metricSelectId.includes('dimension')) {
      tooltipContent = "Select a dimension"
    } else {
      tooltipContent = "Select a metric to see more info about how it is calculated"
    }
  }

  $(tooltipElement).tooltipster({
    animation: 'grow',
    maxWidth: 180,
    interactive: true,
    contentAsHTML: true,
    content: tooltipContent,
    // if you use a single element as content for several tooltips, set this option to true
    contentCloning: false,
    position: 'top'
  });
}

const updateMetricTooltip = (metricSelect) => {
  const metricSelectId = metricSelect.id
  const tooltipElement = document.querySelector(`.js-metric-tooltip[data-corresponding-metric-select="${metricSelectId}"]`)
  let tooltipContent = METRIC_TOOLTIP_CONTENTS[metricSelect.value]

  if (tooltipContent === undefined) {
    tooltipContent = "Select a metric to see more info about how it is calculated"
  }

  $(tooltipElement).tooltipster('content', tooltipContent);
}

const rebuildMetricSelects = (formObject, metricLayoutValue) => {
  const metricSelects = formObject.metricSelectTargets
  const optionsDataAttribute = (metricLayoutValue === 'gauge') ? 'gauge-options' : 'non-gauge-options'

  metricSelects.forEach((select) => {
    const selectedValue = $(select).val()
    $(select).select2('destroy')

      const optionsData = JSON.parse(select.getAttribute(`data-${optionsDataAttribute}`))
      let optionsHTML = optionsData.map((optionData) => {
        const optionValue = optionData[1]
        const optionTitle = optionData[0]

        if (optionValue === selectedValue) {
          return `<option value=${optionValue} selected>${optionTitle}</option>`
        } else {
          return `<option value=${optionValue}>${optionTitle}</option>`
        }
      }).join('\n')

      // Insert empty placeholder value
      optionsHTML = '<option value>Please select</option>' + "\n" + optionsHTML;

      select.innerHTML = optionsHTML

    formObject.application.getControllerForElementAndIdentifier(select, "select2").connect()
  })
}

const rebuildOnlyMetricSelects = (formObject, metricLayoutValue) => {
  const metricSelects = formObject.metricSelectTargets
  if(metricLayoutValue === 'gauge') return;
  const optionsDataAttribute = (metricLayoutValue === 'metric_only') ? 'metric-only-options' : 'non-gauge-options'
  metricSelects.forEach((select) => {
    const selectedValue = $(select).val()
    if($(select).attr('id').includes('_sms_and_email_')){
      let optionsData = JSON.parse(select.getAttribute(`data-${optionsDataAttribute}`))
      $(select).select2('destroy')
      let optionsHTML = optionsData.map((optionData) => {
        const optionValue = optionData[1]
        const optionTitle = optionData[0]

        if (optionValue === selectedValue) {
          return `<option value=${optionValue} selected>${optionTitle}</option>`
        } else {
          return `<option value=${optionValue}>${optionTitle}</option>`
        }
      }).join('\n')

      // Insert empty placeholder value
      optionsHTML = '<option value>Please select</option>' + "\n" + optionsHTML;

      select.innerHTML = optionsHTML

      formObject.application.getControllerForElementAndIdentifier(select, "select2").connect()
    }else {
      return
    }
  })
}

const updateGaugeParamsSelect = (formObject) => {
  const selectedValue = formObject.gaugeParamsSelectTarget.value
  const selectPrefixBulletWrapper = formObject.element.querySelector('.js-gauge-params-above-threshold-prefix-bullet')

  const otherPrefixBulletWrapper = formObject.element.querySelector('.js-gauge-params-below-threshold-prefix-bullet')
  const otherTitle = formObject.element.querySelector('.js-gauge-params-below-threshold-title')

  selectPrefixBulletWrapper.classList.toggle('text-happy-green')
  selectPrefixBulletWrapper.classList.toggle('text-threatening-red')
  otherPrefixBulletWrapper.classList.toggle('text-happy-green')
  otherPrefixBulletWrapper.classList.toggle('text-threatening-red')

  if (selectedValue === 'success') {
    otherTitle.textContent = 'Warning'
  } else {
    otherTitle.textContent = 'Success'
  }
}

const handleEditKlaviyoFilters = () => {
  let klaviyo_campaign_filters = $('#edit_marketing_include_klaviyo_campaign_filters, #edit_marketing_exclude_klaviyo_campaign_filters').find(':selected');
  let klaviyo_flow_filters = $('#edit_marketing_include_klaviyo_flow_filters, #edit_marketing_exclude_klaviyo_flow_filters').find(':selected');

  if(klaviyo_campaign_filters.length > 0) {
    $('#edit_marketing_include_klaviyo_flow_filters, #edit_marketing_exclude_klaviyo_flow_filters').prop("disabled", true);
  }

  if(klaviyo_flow_filters.length > 0) {
    $('#edit_marketing_include_klaviyo_campaign_filters, #edit_marketing_exclude_klaviyo_campaign_filters').prop("disabled", true);
  }
}

const handleKlviyoMetrics = () => {
  const klaviyoAccountMetricsKeys = ['bounce_rate', 'spam_rate', 'bounce_rate', 'unsubscribe_rate'];
  const klaviyoAccountMetrics = [{text: "", id: ""},{text: "Bounce rate", id: "bounce_rate"},{text: "Spam rate", id: "spam_rate"},{text: "Unsubscribe rate", id: "unsubscribe_rate"}];
  const klaviyoCampaignMetrics = [{text: "", id: ""},{text: "Click rate", id: "click_rate"},{text: "Open rate", id: "open_rate"},{text: "Placed order rate", id: "placed_order_rate"},{text:"Revenue", id: "revenue"},{text: "Revenue rate", id: "revenue_rate"},{text:"Total recipients", id: "total_recipients"}];

  $('#dashboard_card_sms_and_email_metric_select, #edit_dashboard_card_sms_and_email_metric_select').on('select2:select', function (e) {
    if(klaviyoAccountMetricsKeys.includes(e.params.data["id"])){
      $('#dashboard_card_comparison_sms_and_email_metric_select, #edit_dashboard_card_comparison_sms_and_email_metric_select').val(null).empty().select2({
        data: klaviyoAccountMetrics,
        containerCssClass: 'tailwind-select2-wrapper',
        dropdownCssClass: 'tailwind-select2-dropdown',
        allowClear:  false,
        closeOnSelect: true,
        placeholder: "Select metric"
      });
    }
    else {
      $('#dashboard_card_comparison_sms_and_email_metric_select, #edit_dashboard_card_comparison_sms_and_email_metric_select').val(null).empty().select2({
        data: klaviyoCampaignMetrics,
        containerCssClass: 'tailwind-select2-wrapper',
        dropdownCssClass: 'tailwind-select2-dropdown',
        allowClear:  false,
        closeOnSelect: true,
        placeholder: "Select metric",
      });
    }
  })
}

const disableComparisonMetricSelects = (formObject, metricSelect) => {
  const kindRadiosvalue = formObject.element.querySelector('input[name="kind"]:checked').value;
  const smsAndEmailComparisonSelect = formObject.comparisonMetricSelectTargets.filter(comparisonSelect => comparisonSelect.id.includes('sms_and_email'))[0]
  const comparisonMainWrapper = smsAndEmailComparisonSelect.closest('[data-comparison-main-wrapper]')

  if(kindRadiosvalue === "sms_and_email") {
    if (["number_of_new_subscribers", "number_of_subscribers"].includes(metricSelect.value)) {
      comparisonMainWrapper.querySelector('[data-show-compare-button-wrapper]').classList.add('hidden')
      comparisonMainWrapper.querySelector('[data-comparison-select-wrapper]').classList.add('hidden')
    }else {
      comparisonMainWrapper.querySelector('[data-show-compare-button-wrapper]').classList.remove('hidden')
    }
  }
}

// Connects to data-controller="customDashboardCardForm"
export default class extends Controller {
  static targets = [ 'metricSelect', 'dimensionSelect', 'comparisonMetricSelect', 'metricLayoutRadio', 'kindRadio', 'reportRadio', 'goalMetricSelect', 'gaugeOptionsWrapper', 'gaugeParamsSelect', 'gaugeMin', 'gaugeMax', 'gaugeNumberField', 'gaugeNumberFieldPrefix' ]

  connect() {
    const formObject = this
    const metricSelects = this.metricSelectTargets
    const comparisonMetricSelects = this.comparisonMetricSelectTargets
    const metricLayoutRadios = this.metricLayoutRadioTargets
    const kindRadios = this.kindRadioTargets
    const reportRadios = this.reportRadioTargets

    updateTitleField(formObject)
    handleKlviyoMetrics()
    if (this.hasKindRadioTarget) {
      kindRadios.forEach((radio) => {
        radio.addEventListener('change', () => {
          updateTitleField(formObject)
        })
      })
    }

    if (this.hasReportRadioTarget) {
      reportRadios.forEach((radio) => {
        radio.addEventListener('change', () => {
          updateTitleField(formObject)
        })
      })
    }

    if (this.hasMetricLayoutRadioTarget) {
      let previousMetricLayoutRadioValue = formObject.element.querySelector('input[name="metric_layout"]:checked').value
      metricLayoutRadios.forEach((radio) => {
        radio.addEventListener('change', () => {
          const metricLayoutValue = formObject.element.querySelector('input[name="metric_layout"]:checked').value;
          if (metricLayoutValue === 'metric_only') {
            checkMetricOnly(formObject)
          } else if (metricLayoutValue === 'with_graph') {
            checkMetricWithGraph(formObject)
          } else if (metricLayoutValue === 'gauge') {
            checkGauge(formObject)
          }

          if (previousMetricLayoutRadioValue === 'gauge' || metricLayoutValue === 'gauge') {
            // Update metric selects if layout value changed to or from 'gauge'
            // The gauge layout only supports metrics which require division. We need to destroy and rebuild the
            // select2 element because there is no way to dynamically update the options of the select2 element.
            // We *could* do this with AJAX but that would be overkill because the data is static.
            rebuildMetricSelects(formObject, metricLayoutValue)
          }

          if (previousMetricLayoutRadioValue === 'metric_only' || metricLayoutValue === 'metric_only') {
            // Update metric selects if layout value changed to or from 'metric_only'
            rebuildOnlyMetricSelects(formObject, metricLayoutValue)
          }

          previousMetricLayoutRadioValue = metricLayoutValue
        })
      })
    }

    if (this.hasMetricSelectTarget) {
      metricSelects.forEach((metricSelect) => {
        initMetricTooltip(metricSelect)

        $(metricSelect).on('select2:select', function (e) {
          const metricLayoutValue = formObject.element.querySelector('input[name="metric_layout"]:checked').value;
          checkMetricGraphStyle(formObject, metricLayoutValue)
          // disable Comparison for number_of_subscribers metric
          disableComparisonMetricSelects(formObject, metricSelect)
          updateTitleField(formObject)
          updateMetricTooltip(metricSelect)
          updateGaugeFieldsUnits(formObject, metricSelect)
          setGaugeMinMaxDefaultValues(formObject, metricSelect)
        });
      })
    }

    if (this.hasDimensionSelectTarget) {
      const dimensionSelect = this.dimensionSelectTarget
      initMetricTooltip(dimensionSelect)

      $(dimensionSelect).on('select2:select', function (e) {
        updateMetricTooltip(dimensionSelect)
      });
    }

    if (this.hasComparisonMetricSelectTarget) {
      comparisonMetricSelects.forEach((comparisonMetricSelect) => {
        initMetricTooltip(comparisonMetricSelect)

        $(comparisonMetricSelect).on('select2:select', function (e) {
          const metricLayoutValue = formObject.element.querySelector('input[name="metric_layout"]:checked').value;
          checkComparisonMetricGraphStyle(formObject, metricLayoutValue)
          updateTitleField(formObject)
          updateMetricTooltip(comparisonMetricSelect)
        });
      })
    }

    if (this.hasGoalMetricSelectTarget) {
      initMetricTooltip(this.goalMetricSelectTarget)

      $(this.goalMetricSelectTarget).on('select2:select', function (e) {
        updateGoalTargetValueUnit(formObject)
        updateTitleField(formObject)
        updateMetricTooltip(formObject.goalMetricSelectTarget)
      });
    }

    const splitGraphCheckbox = formObject.element.querySelector("input[name=split_graph][type=checkbox]");
    if (splitGraphCheckbox) {
      splitGraphCheckbox.addEventListener('change', function() {
        const showCompareMarketingMetricBtn = formObject.element.querySelector("[data-has-split-graph='true']")
        const marketingMetricSelectValue = formObject.element.querySelector('#dashboard_card_marketing_metric_select, #edit_dashboard_card_marketing_metric_select').value
        if (this.checked) {
          showCompareMarketingMetricBtn.classList.add('hidden')
        } else if (marketingMetricSelectValue) {
          showCompareMarketingMetricBtn.classList.remove('hidden')
        }
      });
    }

    if (this.hasGaugeParamsSelectTarget) {
      $(this.gaugeParamsSelectTarget).on('select2:select', function (e) {
        updateGaugeParamsSelect(formObject)
      });
    }
  }

  showCompareMetric(event) {
    const mainComparisonWrapper = event.target.closest('[data-comparison-main-wrapper]')
    mainComparisonWrapper.querySelector('[data-show-compare-button-wrapper]').classList.add('hidden')
    mainComparisonWrapper.querySelector('[data-comparison-select-wrapper]').classList.remove('hidden')

    const hasSplitGraph = event.target.dataset.hasSplitGraph
    if (hasSplitGraph) {
      this.element.querySelector('.js-split-graph-checkbox-wrapper').classList.add('hidden')
    }
  }

  toggleCampaignFilters(event) {
    const btn = event.target
    const filtersWrapperId = btn.getAttribute('data-campaign-filters-wrapper-id')
    const btnContainer = btn.closest('.js-campaign-filters-container')
    const plusSymbol = btnContainer.querySelector('.js-plus-symbol')
    const minusSymbol = btnContainer.querySelector('.js-minus-symbol')
    const filtersContainer = document.getElementById(filtersWrapperId)

    plusSymbol.classList.toggle('hidden')
    minusSymbol.classList.toggle('hidden')
    filtersContainer.classList.toggle('hidden')

    // Reset selects
    const campaignFilterSelects = filtersContainer.querySelectorAll('select')
    campaignFilterSelects.forEach((select) => {
      // don't reset filter when editing dashboard report
      let select_id = $(select).attr('id')
      if(!/edit.*/.test(select_id)){
        $(select).val(null).trigger('change');
      }
    })
    handleEditKlaviyoFilters();
  }
}
