const initStickyElements = () => {
    //-- Sticky filter bar
    if ($('.cblock__filters').length != 0) {
        // We have override CSS in style.scss for this element when stuck,
        // in order to force it to stick to the top,
        // because sometimes it gets 'position: absolute' instead
        // of 'position: fixed' for an unknown reason
        $('.cblock__filters.cblock__filters--sticky').stick_in_parent().on("sticky_kit:stick", function (e) {
                $('body').addClass('filtersstuck');
        }).on("sticky_kit:unstick", function (e) {
            $('body').removeClass('filtersstuck');
        });

    }
}

module.exports = {
    init_elements: initStickyElements
}