import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="attribution--drill-down--search-input"
export default class extends Controller {
  requestSubmit() {
    // TODO can be extracted into an general controller for
    //   search inputs to submit their forms when their values change
    this.element.form.requestSubmit()

    const turboFrameIdentifier = this.element.form.dataset.turboFrame
    const tableTurboFrame = document.getElementById(turboFrameIdentifier)
    tableTurboFrame.querySelector('tbody').style.opacity = "0.5"
  }
}
