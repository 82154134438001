import { buildLTVHoverCard } from './lifetime_value_hover_cards';
import { updateControlsStates} from "./lifetime_compare_cohorts";
import { injectSkeletons, blockControls } from "./skeleton_helper";

const init = () => {
    // Save metric value when user focuses on the select so that
    // we know when to refresh the report if the select value
    // "customers_who_purchased_x_times" and the user changes it
    $('select[id=lifetime_select]').on('focusin', function(){
        $(this).data('metric-val', $(this).val());
    });

    $('select[id=lifetime_select]').change(function () {
        var current_metric_value = $(this).val();
        var previous_metric_value = $(this).data('metric-val');
        if (current_metric_value == "customers_who_purchased_x_times" || previous_metric_value == "customers_who_purchased_x_times") {
            refreshLTVReportTurbo();
        } else {
            refreshTableCellData();
        }
    });

    $('select[id=breakdown_metric_select]').change(function () {
        refreshLTVReportTurbo();
    });

    const ltvToggleControlChangeEvent = new Event("ltvToggleControlChange");

    $('#format-toggle').click(function () {
        refreshTableCellData();
        const chartElement = document.getElementById('basic-spaghetti-diagram')
        if (chartElement) {
            chartElement.dispatchEvent(ltvToggleControlChangeEvent);
        }
    });

    $('#lifetime_select, #togglePredictiveLTV, #toggleCAC-LTV').change( function () {
        const chartElement = document.getElementById('basic-spaghetti-diagram')
        if (chartElement) {
            chartElement.dispatchEvent(ltvToggleControlChangeEvent);
        }
    });
}

const refreshTableCellData = () => {

    var dataMetric = $('select[id=lifetime_select]').val();
    var is_predictive_metric = ['sales_per_customer', 'sales_per_cohort', 'cac_sales_per_customer', 'sales_margin_per_customer', 'cac_sales_margin_per_customer', 'contribution_margin_per_customer'].includes(dataMetric);

    var current_format = $("#ltv_report_money_format").val();
    if (dataMetric == 'customers' || dataMetric == 'orders_per_customer' || dataMetric == 'transactions_per_cohort' || dataMetric == 'customers_who_purchased_x_times') {
        $("#ltv_show_format_value").html("#");
    } else {
        $("#ltv_show_format_value").html(current_format);
    }
    var ltv_show_format = "value";
    var tableDataBlocks = $('.js-table-data');

    // This logic is just for the UI - If the shop doesn't have PLUS, then we don't send predictive
    // data to the front-end. Or, at least, we shouldn't :)
    var shop_has_plus_plan = $("#togglePredictiveLTV").attr('data-shop-has-plus-plan') === 'true'
    var show_predictive_summary_data = $("#togglePredictiveLTV").is(":checked") && shop_has_plus_plan && is_predictive_metric;

    tableDataBlocks.each(function () {
        let tableCellDataMetric = dataMetric
        $(this).removeClass(function (index, className) {
            return className.match(/tbl--shade[\d]+/);
        });

        if ($(this).hasClass('js-ltv-summary-table-data')) {
            $(this).removeClass('tbl--mixed-predictive');

            if (show_predictive_summary_data) {
                tableCellDataMetric = 'predictive_' + dataMetric
            }
        }

        var saleData = $(this).data(tableCellDataMetric);

        ltv_show_format = $("#format-toggle .active input").attr('data-ltv_show_format') || 'value';
        $(this).html(`<span>${saleData[ltv_show_format]}</span>`);

        if (ltv_show_format == "value") {
          $(this).addClass(saleData["class_tbl_shade_value"]);
        } else {
          $(this).addClass(saleData["class_tbl_shade_percentage_change"]);
        }
    });

    // Dynamically inject HTML content for "Unlock PLUS" button when it is in the UI -
    // that is, when predicted_ltv is enabled and user doesn't have PLUS plan
    const upgradePlanButtonHTML = $('.upgrade-plan-button-content').first().html()
    if (upgradePlanButtonHTML) {
        $('tbody.blur-predictive-data .tbl--predictive').first().html(upgradePlanButtonHTML)
    }


    // Update Lifetime average value stats
    var avgLifeTimeValueDatas = $('.js-avg-ltv-data');
    if (dataMetric == "sales_margin_per_customer" || dataMetric == "cac_sales_margin_per_customer") {
        var avgLTVDataKind = 'sales-margin'
    } else {
        var avgLTVDataKind = 'total-price'
    }

    avgLifeTimeValueDatas.each(function () {
        $(this).html($(this).data(avgLTVDataKind));
    });

    const predictiveLTVToggleBtn = $("#togglePredictiveLTV");
    const predictiveDataSwitchAndLabel = $('.js-predictive-data-switch-and-label-container')
    if ( predictiveLTVToggleBtn.length > 0 ) {
        // Show/hide Predictive LTV toggle button
        if (!is_predictive_metric) {
            togglePredictiveLTVTableCells('hide');
            predictiveDataSwitchAndLabel.addClass('!hidden');
        } else {
            predictiveDataSwitchAndLabel.removeClass('!hidden');
            if ( predictiveLTVToggleBtn.is(":checked") ) {
                togglePredictiveLTVTableCells('show');
            } else {
                togglePredictiveLTVTableCells('hide');
            }
        }
    }

    makeTableCellsUniformWidth();
}

const initToggleSpaghettiGraphBtn = () => {
    $('#graph-icon').click(function () {
        $('.switchable-diagram').show();
        $('.switchable-table').hide();
    });
    $('#table-icon').click(function () {
        $('.switchable-table').show();
        $('.switchable-diagram').hide();
    });

    if ($('#graph-icon').hasClass('active')) {
        $('.switchable-diagram').show();
        $('.switchable-table').hide();
    }
}

const initPredictiveLTVToggleBtn = () => {
    const predictiveLTVToggleBtn = $("#togglePredictiveLTV");
    if ( predictiveLTVToggleBtn.length > 0 ) {
        // Show/hide predictive LTV table cells on toggle btn click
        predictiveLTVToggleBtn.change(
            function(){
                refreshTableCellData();
            });
    }
}

const togglePredictiveLTVTableCells = (action) => {
    if ( action === 'show' ) {
        $("tbody .tbl--predictive").removeClass('hidden');
        setTimeout(function () {
            $("tbody .tbl--predictive").removeClass('opacity-0');
        }, 10);
    } else if ( action === 'hide' ) {
        $("tbody .tbl--predictive").addClass('opacity-0');
        setTimeout(function () {
            $("tbody .tbl--predictive").addClass('hidden');
        }, 10);
    }
}

const initCACPaybackLTVToggleBtn = () => {
    const CACPaybackLTVToggleBtn = $("#toggleCAC-LTV");
    if ( CACPaybackLTVToggleBtn.length > 0 ) {
        // Show/hide CAC Payback info on toggle btn click
        CACPaybackLTVToggleBtn.change(
            function(){
                if (this.checked) {
                    toggleCACPaybackBars('show');
                } else {
                    toggleCACPaybackBars('hide');
                }
            });
    }
}

const toggleCACPaybackBars = (action) => {
    if ( action === 'show' ) {
        $("tbody .tbl--bar-marker").removeClass('tbl--bar-marker--hidden');
    } else if ( action === 'hide' ) {
        $("tbody .tbl--bar-marker").addClass('tbl--bar-marker--hidden');
    }
}

const makeTableCellsUniformWidth = () => {
    // Make shaded data table cells the same width for better visualisation
    const tables = $('.lifetimevalue-table table')

    tables.each(function() {
        const table = $(this);
        let totalDataCellsWidth = 0 // The overall width of the first row of shaded table cells
        const firstRowShadedTableCells = table.find('.row0 .js-table-data');
        const numFirstRowShadedTableCells = firstRowShadedTableCells.length

        if (numFirstRowShadedTableCells > 0) {
            firstRowShadedTableCells.each(function () {
                const width = $(this).outerWidth();
                totalDataCellsWidth += width
            });

            const equalizedWidth = totalDataCellsWidth / numFirstRowShadedTableCells

            // Apply equalized width to all shaded table cells
            table.find('.js-table-data').each(function () {
                $(this).outerWidth(equalizedWidth);
            });
        }
    });
}

const refreshLTVReportTurbo = (page) => {
    const form = document.querySelector('#clickhouse_predictive_lifetime_form, #ltv_breakdown_form')

    if (page) {
        // Update page param in form if it exists - do this when 'pagy' pagintation link is clicked on bottom of report
        const pageInputElement = form.querySelector("input[name='page']")
        pageInputElement.value = page
    }

    updatePredictiveAndCACFormInputs(form)

    form.requestSubmit()

    const turboFrameIdentifier = form.dataset.turboFrame
    blockControls();
    injectSkeletons([turboFrameIdentifier]);
}

const initHoverCards = () => {
    //-- Popout / Hover cards
    $('.tbl--shade').popover(
        {
            html: true,
            boundary: 'window',
            delay: {"show": 150, "hide": 0},
            trigger: 'hover',
            content: buildLTVHoverCard
        });
}

const updatePredictiveAndCACFormInputs = (form) => {
    const showPredictiveLTVInput = form.querySelector("input[name='show_predictive_ltv']")
    if (showPredictiveLTVInput) {
        showPredictiveLTVInput.value = $("#togglePredictiveLTV").is(":checked");
    }

    const showCacPaybackInput = form.querySelector("input[name='show_cac_payback']")
    if (showCacPaybackInput) {
        showCacPaybackInput.value = $("#toggleCAC-LTV").is(":checked");
    }

    const showGraphInput = form.querySelector("input[name='show_graph']")
    const graphElement = document.getElementById('graph-icon')
    if (showGraphInput && graphElement) {
        showGraphInput.value = graphElement.classList.contains('active')
    }
}

export {
    init,
    refreshTableCellData,
    initPredictiveLTVToggleBtn,
    initCACPaybackLTVToggleBtn,
    makeTableCellsUniformWidth,
    initToggleSpaghettiGraphBtn,
    refreshLTVReportTurbo,
    initHoverCards,
    updatePredictiveAndCACFormInputs
};
