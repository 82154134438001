import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="setup-guide-hear-about-lifetimely"
export default class extends Controller {

  static targets = ['hearAboutLifetimelyTextInputContainer', 'thankYouMessage', 'skipToNextStepButtonContainer', 'formSubmitButtonContainer']

  static values = {
    responseOptions: Array
  }

  connect() {
    const hearAboutLifetimelySelect = this.element.querySelector('select')
    const hearAboutLifetimelyTextInput = this.element.querySelector("input[name='hear_about_lifetimely_detail']")
    const hearAboutLifetimelyTextInputLabel = this.element.querySelector("label[for='hear_about_lifetimely_detail']")
    const hearAboutLifetimelyTextInputContainer = this.hearAboutLifetimelyTextInputContainerTarget
    const skipToNextStepButtonContainer = this.skipToNextStepButtonContainerTarget
    const formSubmitButtonContainer = this.formSubmitButtonContainerTarget
    const thankYouMessage = this.thankYouMessageTarget
    const responseOptions = this.responseOptionsValue

    $(hearAboutLifetimelySelect).on('select2:select', function (e) {
      const labelInnerText = responseOptions.find(option => option.value === hearAboutLifetimelySelect.value).label;

      if (labelInnerText !== null) {
        hearAboutLifetimelyTextInputContainer.classList.remove('invisible');
        hearAboutLifetimelyTextInputLabel.innerText = labelInnerText
      } else {
        // The selected response does not require details to be entered into the text input.
        // Display the submit button and show the "thank you" message
        thankYouMessage.classList.remove('invisible')
        skipToNextStepButtonContainer.classList.add('hidden');
        formSubmitButtonContainer.classList.remove('hidden')
      }
    })

    hearAboutLifetimelyTextInput.addEventListener('keyup', () => {
      if (hearAboutLifetimelyTextInput.value.length > 0) {
        thankYouMessage.classList.remove('invisible')
        skipToNextStepButtonContainer.classList.add('hidden');
        formSubmitButtonContainer.classList.remove('hidden')
      }
    })
  }
}
