import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="updatedGridStackItem"
export default class extends Controller {
  static values = {
    params: Object
  }

  connect() {
    // Close modal (maybe there is a better way or place to do this)
    document.querySelectorAll("[data-controller='modal']").forEach(modal => modal.classList.add('hidden'))
    document.body.classList.remove('modal-open')

    const gridStackItemParams = this.paramsValue

    // fire custom event that custom_dashboard_grid_controller will be listening for
    const updatedGridItem = this.element.closest('.grid-stack-item')
    const event = document.createEvent("CustomEvent")
    event.initCustomEvent("updated-grid-stack-item-connected", true, true, { updatedGridItem: updatedGridItem, gridStackItemParams: gridStackItemParams })
    this.element.dispatchEvent(event)
  }
}