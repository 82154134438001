import { Controller } from "@hotwired/stimulus"
import * as CustomerSegmentsHelper from "../customer_segments_helper.js"

// Connects to data-controller="complex-filters-checkbox-select"
export default class extends Controller {
  static values = {
    filterType: String,
    submitCustomerSegmentsOnChange: Boolean
  }

  static targets = ['toggleBtn', 'select', 'search', 'selectedNodesTable', 'allNodesTable', 'applyBtn', 'cancelBtn', 'clearBtn', 'containsInput']

  connect() {
    this.allNodesTable = this.allNodesTableTarget
    const checkboxSelectDatatableOptions = buildCheckboxSelectDatatableOptions(this, this.filterTypeValue)
    const allNodesDatatable = $(this.allNodesTableTarget).DataTable(checkboxSelectDatatableOptions);
    this.allNodesDatatable = allNodesDatatable
    initCheckboxSelect(this)
    initRuleNameOperatorSelect(this)
  }

  disconnect() {
    document.removeEventListener("click", this.handleClickOutsideCheckboxListener)
  }

  reset(newFilterType) {
    clearEverything(this)
    const toggleBtnPlaceholder = buildToggleBtnPlaceholder(newFilterType);
    this.toggleBtnTarget.textContent = toggleBtnPlaceholder;
    this.toggleBtnTarget.dataset.defaultInnerText = toggleBtnPlaceholder;
    this.allNodesDatatable.clear().destroy()
    const checkboxSelectDatatableOptions = buildCheckboxSelectDatatableOptions(this, newFilterType)
    const allNodesDatatable = $(this.allNodesTableTarget).DataTable(checkboxSelectDatatableOptions)
    this.allNodesDatatable = allNodesDatatable
  }

  handleClickOutsideCheckbox(checkboxSelectContainer, event) {
    if (checkboxSelectContainer.classList.contains('active') && !checkboxSelectContainer.contains(event.target)) {
      checkboxSelectContainer.classList.remove('active')
    }
  }
}

const initCheckboxSelect = (checkboxSelectController) => {
  const checkboxSelectContainer = checkboxSelectController.element
  const toggleBtn = checkboxSelectController.toggleBtnTarget
  const selectedNodesTable = checkboxSelectController.selectedNodesTableTarget
  const nodesSearchInput = $(checkboxSelectController.searchTarget)
  const selectElement = checkboxSelectController.selectTarget
  const applyBtn = checkboxSelectController.applyBtnTarget
  const cancelBtn = checkboxSelectController.cancelBtnTarget
  const clearBtn = checkboxSelectController.clearBtnTarget
  const allNodesTable = checkboxSelectController.allNodesTable
  const containsInput = checkboxSelectController.containsInputTarget
  const submitCustomerSegmentsOnChange = checkboxSelectController.submitCustomerSegmentsOnChangeValue

  toggleBtn.addEventListener('click', (event) => {
    if (checkboxSelectContainer.classList.contains('active')) {
      // Select is open, so close it
      checkboxSelectContainer.classList.remove('active')
    } else {
      // Select is closed, so open it
      checkboxSelectContainer.classList.add('active')

      nodesSearchInput.focus()
      checkboxSelectController.allNodesDatatable.ajax.reload()
    }

    const windowHeight = (window.innerHeight || document.documentElement.clientHeight)
    const nodesContainer = checkboxSelectContainer.querySelector('.checkbox-select-nodes-container')
    const nodesContainerBottom = nodesContainer.getBoundingClientRect().bottom
    const nodesContainerHeight = nodesContainer.getBoundingClientRect().height
    const defaultNodesContainerHeight = 353
    const adjustedNodesContainerBottom = (nodesContainerHeight < defaultNodesContainerHeight) ? (nodesContainerBottom + (defaultNodesContainerHeight - nodesContainerHeight)) : nodesContainerBottom;

    if (adjustedNodesContainerBottom >= windowHeight) {
      // Container is out of view, open it above the button
      nodesContainer.classList.add('bottom-9')
    } else {
      // Container is in view, open below the button (default)
      nodesContainer.classList.remove('bottom-9')
    }
  })

  cancelBtn.addEventListener('click', (event) => {
    checkboxSelectContainer.classList.remove('active')
  })

  selectedNodesTable.querySelectorAll('tbody tr').forEach((row) => {
    createToggleCheckmarkEventListener(row)
  })

  clearBtn.addEventListener('click', (event) => {
    clearEverything(checkboxSelectController)
    checkboxSelectController.allNodesDatatable.ajax.reload()
  })

  nodesSearchInput.on("keypress keydown", delay(function (event) {
    if (event.key === 'Enter' || event.keyCode === 13) {
      applyBtn.click();
    } else {
      redrawSelectedValuesTableAndSelectElement(selectedNodesTable, allNodesTable, selectElement, clearBtn, toggleBtn, checkboxSelectController)
      checkboxSelectController.allNodesDatatable.ajax.reload()
    }
  }, 300));

  applyBtn.addEventListener('click', (event) => {
    checkboxSelectContainer.classList.remove('active')
    const selectedValues = redrawSelectedValuesTableAndSelectElement(selectedNodesTable, allNodesTable, selectElement, clearBtn, toggleBtn, checkboxSelectController)
    toggleBtn.textContent = selectedValues.join(', ')
    checkboxSelectController.allNodesDatatable.ajax.reload()
    console.log(submitCustomerSegmentsOnChange)
    if (submitCustomerSegmentsOnChange) {
      CustomerSegmentsHelper.refreshSegmentReport()
    }
  })

  if (submitCustomerSegmentsOnChange) {
    containsInput.addEventListener('focusout', (event) => {
      if (typeof containsInput.value === 'string' && containsInput.value.length > 0) {
        CustomerSegmentsHelper.refreshSegmentReport()
      }
    })
  }

  checkboxSelectController.handleClickOutsideCheckboxListener = checkboxSelectController.handleClickOutsideCheckbox.bind(null, checkboxSelectContainer)
  document.addEventListener("click", checkboxSelectController.handleClickOutsideCheckboxListener)
}

const initRuleNameOperatorSelect = (checkboxSelectController) => {
  const checkboxSelectContainer = checkboxSelectController.element
  const ruleNameOperatorSelect = checkboxSelectContainer.closest(".complex-filter-rule").querySelector("select[id*='rule_name_operator']")

  if (ruleNameOperatorSelect.getAttribute('data-controller') === 'select2') {
    addEventListenerForRuleNameOperatorSelect2(ruleNameOperatorSelect, checkboxSelectController)
  } else {
    addEventListenerForRuleNameOperatorSelect(ruleNameOperatorSelect, checkboxSelectController)
  }
}

const buildToggleBtnPlaceholder = (filterType) => {
  let pluralFilterType;
  if (filterType.replace("_filter", '').endsWith('y')) {
    pluralFilterType = filterType.replace("y_filter", "ies")
  } else {
    pluralFilterType = filterType.replace("_filter", "s")
  }

  return 'All ' + pluralFilterType.replace("_", " ").toLowerCase()
}

const delay = (fn, ms) => {
  let timer = 0
  return function(...args) {
    clearTimeout(timer)
    timer = setTimeout(fn.bind(this, ...args), ms || 0)
  }
}

const createSelectedValuesTableRow = (tableBodyElement, value) => {
  const rowElement = tableBodyElement.insertRow()
  rowElement.classList.add('checked')

  const firstCell = rowElement.insertCell()
  firstCell.innerHTML = checkboxTableCellContent
  firstCell.style.width = '1%'

  const secondCell = rowElement.insertCell()
  secondCell.innerText = value

  rowElement.setAttribute('data-node-value', value)
  rowElement.setAttribute('data-node-id', value)
  createToggleCheckmarkEventListener(rowElement)
}

const createToggleCheckmarkEventListener = (rowElement) => {
  rowElement.addEventListener('click', (event) => {
    if (rowElement.classList.contains('checked')) {
      rowElement.classList.remove('checked')
    } else {
      rowElement.classList.add('checked')
    }
  })
}

const redrawSelectedValuesTableAndSelectElement = (selectedNodesTable, allNodesTable, selectElement, clearBtn, toggleBtn, checkboxSelectController) => {
  // Get existing selected values from Table
  const existingSelectedValues = Array.from(selectedNodesTable.querySelectorAll('tr.checked')).map((row) => {
    return row.getAttribute('data-node-value')
  })
  // Get selected values from dropdown table
  const recentlySelectedValuesElements = allNodesTable.querySelectorAll('tr.checked')
  const recentlySelectedValues = Array.from(recentlySelectedValuesElements).map((row) => {
    return row.getAttribute('data-node-value')
  })
  // find unique values
  const allSelectedValues = existingSelectedValues.concat(recentlySelectedValues)
  const uniqueSelectedValues = [... new Set(allSelectedValues)]

  // clear table and select
  clearSelectedValues(selectedNodesTable, selectElement)

  // re-create unique values
  if (uniqueSelectedValues.length > 0) {
    const tableHead = selectedNodesTable.createTHead()
    const tableHeadRow = tableHead.insertRow()
    tableHeadRow.insertCell(0).outerHTML = "<th colspan='2'>Selected</th>"

    const tableBodyElement = selectedNodesTable.createTBody()
    uniqueSelectedValues.forEach((selectedValue) => {
      createSelectedValuesTableRow(tableBodyElement, selectedValue)
      createOptionForSelect(selectElement, selectedValue)
    })

    clearBtn.classList.remove('hidden')
    toggleBtn.parentElement.classList.remove('text-gray-40')
  } else {
    // If values are empty
    clearToggleBtnValues(checkboxSelectController)
  }

  return uniqueSelectedValues
}

const createOptionForSelect = (selectElement, value) => {
  const optionElement = document.createElement('option');
  optionElement.value = value;
  optionElement.innerHTML = value;
  optionElement.setAttribute('selected', '')
  optionElement.selected = true
  selectElement.appendChild(optionElement);
}

const removeRedudantSelectedRows = (selectElement, allNodesTable) => {
  const selectedIds = Array.from(selectElement.querySelectorAll('option')).map((option) => option.value)

  if (selectedIds.length > 0) {
    const querySelectorString = selectedIds.map((selectedId) => `tr[data-node-id='${CSS.escape(selectedId)}']`).join(', ')
    allNodesTable.querySelectorAll(querySelectorString).forEach((selectedRow) => selectedRow.remove())
  }
}

const clearSelectedValues = (selectedValuesTable, selectElement) => {
  // clear table and select
  selectedValuesTable.textContent = ''
  selectElement.textContent = ''
}

const clearToggleBtnValues = (checkboxSelectController) => {
  const toggleBtn = checkboxSelectController.toggleBtnTarget
  const clearBtn = checkboxSelectController.clearBtnTarget
  clearBtn.classList.add('hidden')
  toggleBtn.parentElement.classList.add('text-gray-40')
}

const clearEverything = (checkboxSelectController) => {
  const selectedNodesTable = checkboxSelectController.selectedNodesTableTarget
  const selectElement = checkboxSelectController.selectTarget
  const nodesSearchInput = $(checkboxSelectController.searchTarget)
  const containsInput = checkboxSelectController.containsInputTarget
  const toggleBtn = checkboxSelectController.toggleBtnTarget

  toggleBtn.innerText = toggleBtn.dataset.defaultInnerText
  containsInput.value = ''
  clearSelectedValues(selectedNodesTable, selectElement)
  clearToggleBtnValues(checkboxSelectController)
  nodesSearchInput.val('')
}

const toggleHideContainsInput = (checkboxSelectController) => {
  const toggleBtn = checkboxSelectController.toggleBtnTarget
  const selectElement = checkboxSelectController.selectTarget
  const containsInput = checkboxSelectController.containsInputTarget

  containsInput.classList.toggle('hidden')
  containsInput.toggleAttribute("disabled")

  selectElement.toggleAttribute("disabled")
  toggleBtn.classList.toggle('hidden')
  toggleBtn.toggleAttribute("disabled")
}

const checkboxTableCellContent = `<svg class="checked-input rounded" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 4C0 1.79086 1.79086 0 4 0H12C14.2091 0 16 1.79086 16 4V12C16 14.2091 14.2091 16 12 16H4C1.79086 16 0 14.2091 0 12V4Z" fill="#112C7B"/>
                                    <path d="M12.2069 4.79279C12.3944 4.98031 12.4997 5.23462 12.4997 5.49979C12.4997 5.76495 12.3944 6.01926 12.2069 6.20679L7.20692 11.2068C7.01939 11.3943 6.76508 11.4996 6.49992 11.4996C6.23475 11.4996 5.98045 11.3943 5.79292 11.2068L3.79292 9.20679C3.61076 9.01818 3.50997 8.76558 3.51224 8.50339C3.51452 8.24119 3.61969 7.99038 3.8051 7.80497C3.99051 7.61956 4.24132 7.51439 4.50352 7.51211C4.76571 7.50983 5.01832 7.61063 5.20692 7.79279L6.49992 9.08579L10.7929 4.79279C10.9804 4.60532 11.2348 4.5 11.4999 4.5C11.7651 4.5 12.0194 4.60532 12.2069 4.79279Z" fill="white"/>
                                    <path d="M4 1H12V-1H4V1ZM15 4V12H17V4H15ZM12 15H4V17H12V15ZM1 12V4H-1V12H1ZM4 15C2.34315 15 1 13.6569 1 12H-1C-1 14.7614 1.23858 17 4 17V15ZM15 12C15 13.6569 13.6569 15 12 15V17C14.7614 17 17 14.7614 17 12H15ZM12 1C13.6569 1 15 2.34315 15 4H17C17 1.23858 14.7614 -1 12 -1V1ZM4 -1C1.23858 -1 -1 1.23858 -1 4H1C1 2.34315 2.34315 1 4 1V-1Z" fill="#112C7B"/>
                                  </svg>
                                  <svg class="unchecked-input rounded" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 4C0 1.79086 1.79086 0 4 0H12C14.2091 0 16 1.79086 16 4V12C16 14.2091 14.2091 16 12 16H4C1.79086 16 0 14.2091 0 12V4Z" fill="white"/>
                                    <path d="M4 1H12V-1H4V1ZM15 4V12H17V4H15ZM12 15H4V17H12V15ZM1 12V4H-1V12H1ZM4 15C2.34315 15 1 13.6569 1 12H-1C-1 14.7614 1.23858 17 4 17V15ZM15 12C15 13.6569 13.6569 15 12 15V17C14.7614 17 17 14.7614 17 12H15ZM12 1C13.6569 1 15 2.34315 15 4H17C17 1.23858 14.7614 -1 12 -1V1ZM4 -1C1.23858 -1 -1 1.23858 -1 4H1C1 2.34315 2.34315 1 4 1V-1Z" fill="#D1D5DB"/>
                                  </svg>`

const buildCheckboxSelectDatatableOptions = (checkboxSelectController, filterType) => {
  const toggleBtn = checkboxSelectController.toggleBtnTarget
  const allNodesTable = checkboxSelectController.allNodesTableTarget
  const selectedNodesTable = checkboxSelectController.selectedNodesTableTarget
  const nodesSearchInput = $(checkboxSelectController.searchTarget)
  const selectElement = checkboxSelectController.selectTarget
  const clearBtn = checkboxSelectController.clearBtnTarget

  return {
    "columnDefs": [
      {
        "targets": 0,
        "searchable": false,
        "render": function ( data, type, row, meta ) {
          return checkboxTableCellContent;
        },
        "data": null
      },{
        "targets": 1,
        "searchable": true,
        "render": function ( data, type, row, meta ) {
          return row;
        },
      }],
    "order": [[ 1, "desc" ]],
    "searching": true,
    "deferLoading": 0,
    "processing": true,
    "serverSide": true,
    "ordering": false,
    "bLengthChange": false,
    "dom": "tS",
    "ajax": {
      "url": `/complex_filters/search_filters?filter_type=${filterType}`,
      "type": "GET",
      "data": function (data) {
        data.for_datatable = true
        data.search = nodesSearchInput.val()
      }
    },
    "scrollY":        200,
    "scrollCollapse": true,
    "scroller": {
      loadingIndicator: true
    },
    createdRow: function( row, data, dataIndex ) {
      row.querySelector('td:first-child').style.width = '1%'
      row.setAttribute('data-node-value', data)
      row.setAttribute('data-node-id', data)
      createToggleCheckmarkEventListener(row)
    },
    "preDrawCallback": function( settings ) {
      redrawSelectedValuesTableAndSelectElement(selectedNodesTable, allNodesTable, selectElement, clearBtn, toggleBtn, checkboxSelectController)
    },
    "drawCallback": function( settings ) {
      removeRedudantSelectedRows(selectElement, allNodesTable)
    }
  }
}

const addEventListenerForRuleNameOperatorSelect = (ruleNameOperatorSelect, checkboxSelectController) => {
  ruleNameOperatorSelect.addEventListener('change', (event) => {
    // Check if the "operator" has changed the type, like from 'exact_name' to 'name_contains' or vice-versa
    toggleHideContainsInput(checkboxSelectController)
    clearEverything(checkboxSelectController)

    // Focus appropriate input
    if (ruleNameOperatorSelect.value == 'name_contains') {
      checkboxSelectController.containsInputTarget.focus()
    } else {
      checkboxSelectController.toggleBtnTarget.click()
    }
  })
}

const addEventListenerForRuleNameOperatorSelect2 = (ruleNameOperatorSelect, checkboxSelectController) => {
  $(ruleNameOperatorSelect).on('select2:select', function (e) {
    // Check if the "operator" has changed the type, like from 'exact_name' to 'name_contains' or vice-versa
    toggleHideContainsInput(checkboxSelectController)
    clearEverything(checkboxSelectController)

    // Focus appropriate input
    if (ruleNameOperatorSelect.value == 'name_contains') {
      checkboxSelectController.containsInputTarget.focus()
    } else {
      checkboxSelectController.toggleBtnTarget.click()
    }
  });
}