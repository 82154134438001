import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="single-datepicker"
export default class extends Controller {
  static targets = ['input', 'clearBtn']

  static values = {
    container: String,
    opens: String,
    drops: String
  }

  connect() {
    const singleDatepickerInput = this.inputTarget;

    let datepickerOptions = {
      singleDatePicker: true,
      showDropdowns: true,
      autoUpdateInput: false,
      autoApply: true
    }

    if (this.hasContainerValue) {
      datepickerOptions.parentEl = this.containerValue
    }

    if (this.hasOpensValue) {
      datepickerOptions.opens = this.opensValue
    }

    if (this.hasDropsValue) {
      datepickerOptions.drops = this.dropsValue
    }

    $(singleDatepickerInput).daterangepicker(datepickerOptions).on("apply.daterangepicker", function (e, picker) {
      picker.element.val(picker.startDate.format(picker.locale.format));
    });

    if (this.hasClearBtnTarget) {
      this.clearBtnTarget.addEventListener('click', (event) => {
        event.preventDefault();
        singleDatepickerInput.value = '';
      })
    }
  }
}
